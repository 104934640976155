import React from 'react';
import { useDispatch } from 'react-redux';
import Lottie from 'lottie-react';
import useCreateScreening from '../../../hooks/useCreateScreening';
import timerIcon from '../../../assets/screening/timer.svg';
import EmptyState from '../../../assets/screening/EmptyState.json';
import { t } from '../../../i18n/i18n';
import { setNewScreeningData } from '../../../redux/actions/screeningActions';
import { formatOrder } from '../../../utils/formatArrayOrder';
import { formatMinutesToHours } from '../../../utils/formatMinutesToHours';
import TestItem from '../../TestItem/TestItem';

const SelectedTests = ({ setShow, setButtonSelected, isCheck }) => {
  const { newScreening, testArray, testsDuration, handleTests } = useCreateScreening();
  const dispatch = useDispatch();

  const deselectAll = () => {
    const idsToDeselect = newScreening?.testGroups?.map((item) => item.id);
    const filteredTestGroups = newScreening?.testGroups?.filter(
      (item) => !idsToDeselect.includes(item.id)
    );
    const allTestsFormatted = newScreening?.allTests.filter(
      (item) => !idsToDeselect.includes(item.id)
    );
    dispatch(
      setNewScreeningData({
        ...newScreening,
        testGroups: filteredTestGroups,
        allTests: formatOrder(allTestsFormatted),
      })
    );
  };

  return (
    <>
      <div
        data-testid="create-evaluation-selected-tests"
        className="d-flex justify-content-between pb-2"
      >
        <div className="d-flex">
          <div className="my-1 my-sm-0 mr-0 mr-sm-2 d-flex align-items-center">
            <span
              className="total-number-text  text-base mr-1 font-weight-bold"
              data-testid="selected-tests-count-tg-span"
            >
              {newScreening?.testGroups?.length}
            </span>
            <span
              className=" text-muted text-base font-weight-500"
              data-testid="selected-tests-count-tg-text-span"
            >
              {newScreening?.testGroups?.length === 1 ? 'Seleccionado' : 'Seleccionados'}
            </span>
          </div>
          {newScreening?.testGroups?.length > 0 && (
            <button
              onClick={() => deselectAll()}
              className="create-evaluation-secondary-button ml-2  font-weight-700 bg-transparent py-2 px-4 text-xs"
              type="button"
              data-testid="selected-tests-deselect-all-btn"
            >
              {t('CREATE_EVALUATION_DESELECT_ALL')}
            </button>
          )}
        </div>
        <div className="my-1 my-sm-0 mr-0 mr-sm-2 d-flex align-items-center ml-2">
          <img
            src={timerIcon}
            className="mr-1"
            alt={t('TIMER_ALT')}
            data-testid="selected-tests-timer-img"
          />
          <span
            className=" text-muted text-base font-weight-500"
            data-testid="selected-tests-timer-duration-span"
          >
            {t('CREATE_SCREENING_TOTAL_TIME')}
          </span>
          <span
            className="total-number-text  text-base ml-1 font-weight-bold"
            data-testid="selected-tests-timer-number-duration-span"
          >
            {formatMinutesToHours(testsDuration)}
          </span>
        </div>
      </div>
      {!newScreening?.testGroups?.length && (
        <div className="w-100 bg-white mt-3">
          <div className="w-75 d-flex flex-column align-items-center mx-auto text-center">
            <div
              data-testid="create-evaluation-selected-tests-lottie"
              className="empty-state-container mx-auto mb-2"
            >
              <Lottie animationData={EmptyState} loop className="my-5" />
            </div>
            <div className="alkemy-tests-empty-state-texts d-flex flex-column">
              <span
                className="font-weight-600  px-2 text-base"
                data-testid="create-evaluation-selected-tests-title-span"
              >
                {t('CREATE_EVALUATION_SELECTED_TESTS_EMPTY_STATE_TITLE')}
              </span>
              <span
                data-testid="create-evaluation-selected-tests-subtitle"
                className="font-weight-400 mt-2 mb-4  px-2 text-base"
              >
                {t('CREATE_EVALUATION_SELECTED_TESTS_EMPTY_STATE_SUBTITLE_1')}
                <button
                  className="bg-none ml-1 border-0 bg-transparent create-evaluation-gray-underline"
                  type="button"
                  onClick={() => setButtonSelected('TEST_ALKEMY')}
                  data-testid="selected-tests-empty-state-2-btn"
                >
                  {t('CREATE_EVALUATION_SELECTED_TESTS_EMPTY_STATE_BUTTON_2')}
                </button>
                {t('CREATE_EVALUATION_SELECTED_TESTS_EMPTY_STATE_SUBTITLE_3')}
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="test-grid-container bg-gray px-2 px-md-4 py-4 overflow-auto test-grid-container-tests px-2 px-md-4 py-4">
        {newScreening?.testGroups?.map((item) => (
          <TestItem
            key={item.id}
            test={item}
            setShow={setShow}
            isStar
            isFavorite={item?.favorite ?? false}
            testArray={testArray}
            isCheck={isCheck}
            isSelectTests={false}
            handleTests={handleTests}
            newScreening={newScreening}
          />
        ))}
      </div>
    </>
  );
};

export default SelectedTests;
