import axios from 'axios';
import { alkymetricsClient } from '../alkymetricsClient';
import { screeningsClient } from '../screeningsClient';

export const getScreenings = async (workspaceId) => {
  try {
    const { data } = await screeningsClient.get(`/screenings/workspace?workspace=${workspaceId}`);

    return data;
  } catch (err) {
    return [];
  }
};

export const getScreeningsPaginated = async (params) => {
  try {
    const response = await screeningsClient.get(`/screenings/filters`, {
      params: { ...params, paginated: true },
    });

    return response?.data;
  } catch (err) {
    return { collection: [], pagination: null };
  }
};

export const createScreeningInvitations = async (body) => {
  const { data } = await alkymetricsClient.post('/assessmentInvitation/batch', body);
  return data;
};

export const getPsychotechnicByScreeningInstanceId = async (screeningInstanceId = '') => {
  const { data } = await screeningsClient.get(
    `/psychotechnics/screeningInstance/${screeningInstanceId}`
  );
  return data;
};

export const getScreeningsInvitations = async ({
  search,
  orderBy,
  status,
  processStatus,
  screening,
  offset = 0,
  limit = 15,
  order,
  stage,
  sex,
  minScore,
  maxScore,
  academicLevel,
  country,
  languages,
  visible,
  state,
  minEvents,
  maxEvents,
  qualitativeResultsIds,
}) => {
  const { data } = await alkymetricsClient.get('/assessmentInvitation', {
    params: {
      search,
      orderBy,
      status,
      processStatus,
      screening,
      offset,
      limit,
      order,
      stage,
      sex,
      minScore,
      maxScore,
      academicLevel,
      country,
      languages,
      state,
      minEvents,
      maxEvents,
      qualitativeResultsIds,
      visible,
    },
  });

  return data;
};

export const getScreeningInvitationsStatsCount = async (recruitSessionStageId) => {
  const { data } = await alkymetricsClient.get(
    `/assessmentInvitation/statusCount/${recruitSessionStageId}`
  );

  return data;
};

export const updateProctoringEnabledRecruitSessionStage = async (recruitSessionStageId, body) => {
  const url = `/recruitSessionStage/proctoringEnabled/${recruitSessionStageId}`;
  const { data } = await alkymetricsClient.patch(url, body);
  return data;
};
export const updateRecruitSessionStage = async (recruitSessionStageId, body) => {
  const url = `/recruitSessionStage/${recruitSessionStageId}`;
  const { data } = await alkymetricsClient.patch(url, body);
  return data;
};

export const updateScreeningCandidatesBatch = async ({
  ids,
  processStatus,
  allCandidates,
  deletedByPartner,
  filters,
  toggleVisibility,
  limitDate,
}) =>
  alkymetricsClient.post('/assessmentInvitation/batch-edit', {
    ids,
    processStatus,
    allCandidates,
    deletedByPartner,
    filters,
    toggleVisibility,
    limitDate,
  });

export const getCandidatesGeneralDashboard = async (data, params) =>
  screeningsClient.post('/users/summary', data, { params });

export const createCustomSearch = async (searchData) => {
  try {
    const { data } = await alkymetricsClient.post('/recruitSession', searchData);
    return data;
  } catch (error) {
    return {};
  }
};

export const getCustomSearch = async (id) => {
  try {
    const { data } = await alkymetricsClient.get(`/recruitSession/${id}`);
    return data;
  } catch (error) {
    return {};
  }
};

export const getCustomSearchs = async ({ offset = 0, active, search, limit = 6 }) => {
  try {
    const { data } = await alkymetricsClient.get(`/recruitSession`, {
      params: {
        offset,
        ...(active !== '' && { active }),
        search,
        limit,
      },
    });
    return data;
  } catch (error) {
    return {};
  }
};

export const getAllCustomSearchs = async (filters) => {
  try {
    const { data } = await alkymetricsClient.get(`/recruitSession/all`, {
      params: {
        ...filters,
      },
    });
    return data;
  } catch (error) {
    return {};
  }
};

export const getAllWorkspaces = async ({ offset = 0, limit = 12, search, owner }) => {
  try {
    const { data } = await alkymetricsClient.get(`/workspace/all`, {
      params: { offset, limit, search, owner },
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const getWorkspaceOwners = async () => {
  try {
    const { data } = await alkymetricsClient.get('/workspace/owners');
    return data;
  } catch (error) {
    return [];
  }
};

export const setFavoriteWorkspace = async (body) => {
  try {
    const { data } = await alkymetricsClient.patch('/workspace/favorite', body);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const leaveWorkspace = async (workspaceId) => {
  try {
    const { data } = await alkymetricsClient.post(`/workspace/leave/${workspaceId}`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getDraft = async ({ offset = 0, search, limit = 6, workspaceIds }) => {
  try {
    const { data } = await screeningsClient.get(`/recruitsessiondraft`, {
      params: {
        offset,
        name: search,
        limit,
        workspaceIds,
      },
    });
    return data;
  } catch (error) {
    return {};
  }
};

export const deleteDraft = async (draftId) => {
  try {
    const { data } = await screeningsClient.delete(`/recruitsessiondraft/${draftId}`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCustomSearchStageInvitations = async (id, query) => {
  try {
    const { data } = await alkymetricsClient.get(`/recruitSessionStage/${id}`, {
      params: {
        ...query,
      },
    });
    return data;
  } catch (error) {
    return {};
  }
};

export const exportCsvInvitations = async (id, query, lang) => {
  try {
    const { data } = await alkymetricsClient.get(`/recruitSessionStage/${id}/export`, {
      params: {
        ...query,
        exportLanguage: lang,
      },
      responseType: 'blob',
    });
    return data;
  } catch (error) {
    return { error: 'EXPORT_ERROR' };
  }
};

export const getCustomSearchStageInvitationsData = async (id) => {
  try {
    const { data } = await alkymetricsClient.get(`/recruitSessionStage/${id}/invitations/data`);
    return data;
  } catch (error) {
    return {};
  }
};

export const updateRecruitSession = async (id, dataToUpdate) => {
  try {
    const { data } = await alkymetricsClient.patch(`/recruitSession/${id}`, dataToUpdate);
    return data;
  } catch (error) {
    return {};
  }
};

export const getInvitationComments = async (id, recruitSessionId, params) => {
  try {
    const { data } = await alkymetricsClient.get(
      `/recruitSessionComment/recruitSession/${recruitSessionId}/profile/${id}`,
      {
        params,
      }
    );
    return data;
  } catch (error) {
    return [];
  }
};

export const createComment = async (commentData) => {
  try {
    const { data } = await alkymetricsClient.post('/recruitSessionComment', commentData);
    return data;
  } catch (error) {
    return {};
  }
};

export const deleteComment = async (id) => {
  try {
    const { data } = await alkymetricsClient.delete(`/recruitSessionComment/${id}`);
    return data;
  } catch (error) {
    return {};
  }
};

export const passStageInBatch = async (body) => {
  try {
    const { data } = await alkymetricsClient.post('/assessmentInvitation/passStage/batch', body);
    return data;
  } catch (error) {
    return null;
  }
};

export const sendInvitationEmailInBatch = async (body) => {
  try {
    await alkymetricsClient.post('/assessmentInvitation/sendEmail/batch', body);
    return true;
  } catch (error) {
    return false;
  }
};

export const getSearchesQuantity = async () => {
  try {
    const { data } = await alkymetricsClient.get('/recruitSession/quantity');
    return data;
  } catch (error) {
    return null;
  }
};

export const getTestGroups = async (ids) => {
  if (!ids?.length) return [];
  try {
    const { data } = await screeningsClient.get(`/screenings/testGroups`, {
      params: {
        screeningsInstances: ids.toString(),
      },
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const getTestGroupsDetailsBatch = async (ids) => {
  if (!ids?.length) return [];
  try {
    const { data } = await screeningsClient.get(`/testGroups/batch`, {
      params: {
        ids: ids.toString(),
      },
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const getRecommendedTestgroups = async (ids) => {
  if (!ids?.length) return [];
  try {
    const { data } = await screeningsClient.get(`/testGroups/recommendedTestgroups`, {
      params: {
        grouperKeys: ids.toString(),
      },
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const getTags = async ({ types }) => {
  try {
    const { data } = await screeningsClient.get(`/tags`, {
      params: {
        types,
      },
    });
    return data;
  } catch (error) {
    return [];
  }
};
export const getEndorsements = async (request) => {
  try {
    const { data } = await screeningsClient.get(`/endorsement`, {
      params: request,
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const getCategories = async () => {
  try {
    const { data } = await screeningsClient.get(`/categories`);
    return data;
  } catch (error) {
    return [];
  }
};
export const getCategoriesAll = async () => {
  try {
    const { data } = await screeningsClient.get(`/categories/all`);
    return data;
  } catch (error) {
    return [];
  }
};

export const updateCategories = async (id, body, newFile = null) => {
  try {
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (Array.isArray(value) || typeof value === 'object') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    formData.append('file', newFile);

    const { data } = await screeningsClient.patch(`/categories/${id}`, formData, {
      'Content-Type': 'multipart/form-data',
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const createCategory = async (body, newFile) => {
  try {
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (Array.isArray(value) || typeof value === 'object') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    formData.append('file', newFile);

    const { data } = await screeningsClient.post(`/categories`, formData, {
      'Content-Type': 'multipart/form-data',
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const deleteCategory = async (id) => {
  try {
    const { data } = await screeningsClient.delete(`/categories/${id}`);
    return data;
  } catch (error) {
    return null;
  }
};

export const getCategoriesWithTestGroups = async () => {
  try {
    const { data } = await screeningsClient.get(`/categories/withTestGroups`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getRolesFilters = async () => {
  try {
    const { data } = await screeningsClient.get(`/roles`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getRolesAll = async () => {
  try {
    const { data } = await screeningsClient.get(`/roles/all`);
    return data;
  } catch (error) {
    return [];
  }
};

export const updateRoles = async (id, body) => {
  try {
    const { data } = await screeningsClient.patch(`/roles/${id}`, body);
    return data;
  } catch (error) {
    return null;
  }
};

export const createRol = async (body) => {
  try {
    const { data } = await screeningsClient.post(`/roles`, body);
    return data;
  } catch (error) {
    return null;
  }
};

export const deleteRol = async (id) => {
  try {
    const { data } = await screeningsClient.delete(`/roles/${id}`);
    return data;
  } catch (error) {
    return null;
  }
};

export const getRolesWithTestGropusFilters = async () => {
  try {
    const { data } = await screeningsClient.get(`/roles/withTestGroups`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getQuickRolesWithTestGroups = async () => {
  try {
    const { data } = await screeningsClient.get(`/quick-role/withTestGroups`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getScreeningTestGroups = async ({
  offset = 0,
  limit = 15,
  tagsIds,
  categoryId,
  name,
  favorites,
  rolesIds,
  recommendTestGroups,
  testgroupsIds,
  lang = 'es',
}) => {
  try {
    const { data } = await screeningsClient.get('/testGroups', {
      params: {
        offset,
        limit,
        tagsIds,
        categoryId,
        text: name,
        favorites,
        rolesIds,
        recommendTestGroups,
        searchInDescription: true,
        metrics: true,
        testgroupsIds,
        lang,
      },
    });
    return {
      collection: data.collection.map((data) => ({ ...data, isSelected: false })),
      pagination: data.pagination,
    };
  } catch (error) {
    return [];
  }
};

export const postScreening = async (screeningData) => {
  try {
    const { data } = await screeningsClient.post('/screenings', screeningData);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const addTestGroupFavorite = async (favoriteData) => {
  try {
    const { data } = await screeningsClient.post('/testGroupsFavorites/create', favoriteData);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const removeTestGroupFavorite = async (testGroupId) => {
  try {
    const { data } = await screeningsClient.delete(`/testGroupsFavorites/${testGroupId}`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postTestGroup = async (testGroupData) => {
  try {
    const { data } = await screeningsClient.post('/testGroups/create', testGroupData);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const patchTestGroup = async (testGroupData, replaceFlag) => {
  try {
    const { data } = await screeningsClient.patch(
      `/testGroups?replaceFlag=${replaceFlag}`,
      testGroupData
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const patchScreeningAlkemy = async (challengeData) => {
  try {
    const { data } = await screeningsClient.patch(`/screenings/alkemy`, challengeData);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postSendEmailCustom = async (body) => {
  const { data } = await alkymetricsClient.post('/email/partner/custom', body);
  return data;
};

export const getSubmissionAnswers = async (screeningInstanceId, testGroupId) => {
  try {
    const { data } = await screeningsClient.get(
      `/submissions/${screeningInstanceId}/${testGroupId}`
    );
    return data;
  } catch (error) {
    return [];
  }
};
export const getTestGroup = async (testGroupId, sample = false) => {
  try {
    const { data } = await screeningsClient.get(`/testGroups/${testGroupId}?sample=${sample}`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getTestGroupAllTests = async (testGroupId) => {
  try {
    const { data } = await screeningsClient.get(`/testGroups/randomComplete/${testGroupId}`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getScreeningAllTests = async (screeningId) => {
  try {
    const { data } = await screeningsClient.get(`/screenings/complete/${screeningId}`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getScreeningByInstanceId = async (screeningInstanceId) => {
  const { data } = await screeningsClient.get(`/screenings/instance/${screeningInstanceId}`);
  return data;
};

export const getCompanyScreenings = async (query = {}) => {
  const { data } = await screeningsClient.get('/screenings/alkemy', {
    params: {
      ...query,
    },
  });
  return data;
};

export const getCompanyTestgroups = async (query = {}) => {
  const { data } = await screeningsClient.get('/testGroups/all', {
    params: {
      ...query,
      metrics: true,
    },
  });

  return data;
};

export const editTestgroup = async (options = {}) => {
  const { data } = await screeningsClient.patch(`/testgroups?replaceFlag=false`, options);
  return data;
};

export const editScreening = async (id, options = {}) => {
  const { data } = await screeningsClient.patch(`/screenings/screening/${id}`, options);
  return data;
};

export const getLevels = async () => {
  const { data } = await screeningsClient.get('/level/levels');
  return data;
};

export const createScreeningAlkemy = async (screeningValues = {}) => {
  const { data } = await screeningsClient.post('/screenings/alkemy', screeningValues);
  return data;
};

export const getImageSignedUrl = async (mimeType) => {
  const { data } = await screeningsClient.get('/tests/upload/image', {
    params: {
      mimeType,
    },
  });
  return data;
};

export const getAudioSignedUrl = async (mimeType) => {
  const { data } = await screeningsClient.get('/tests/upload/audio', {
    params: {
      mimeType,
    },
  });
  return data;
};

export const uploadFileToS3 = async (imageUrl, data, mimeType) => {
  const { res } = await axios.put(imageUrl, data, {
    headers: {
      'Content-Type': mimeType,
    },
    data,
  });
  return res;
};

export const uploadVideoFileToS3 = async (videoUrl, data) => {
  const { res } = await axios.put(videoUrl, Buffer.from(data), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
  });
  return res;
};

export const getTestDifficulty = async () => {
  try {
    const { data } = await screeningsClient.get(`/testDifficulty`);
    return data;
  } catch (err) {
    return [];
  }
};

export const getLangCodesAI = async () => {
  try {
    const { data } = await screeningsClient.get(`/langCodesAI`);
    return data;
  } catch (err) {
    return [];
  }
};

export const getStageScreening = async ({ companyId, levelId }) => {
  const { data } = await screeningsClient.get(`/screenings/random`, {
    params: {
      companyId,
      levelId,
    },
  });
  return data;
};

export const getScreeningDetails = async (screeningId) => {
  const { data } = await screeningsClient.get(`/screenings/${screeningId}`);
  return data;
};

export const getRestartScreeningData = async ({ levelId, companyId }) => {
  const { data } = await screeningsClient.get('/screenings/restartdata', {
    params: {
      levelId,
      companyId,
    },
  });
  return data;
};

export const postRestartTestgroup = async (body) => {
  const { data } = await screeningsClient.post('/screenings/restart/testgroup', body);
  return data;
};

export const postRecruitSessionDraft = async (body) => {
  const { data } = await screeningsClient.post('/recruitsessiondraft', body);
  return data;
};

export const editRecruitSessionDraft = async (draftId, body) => {
  const { data } = await screeningsClient.patch(`/recruitsessiondraft/${draftId}`, body);
  return data;
};

export const getRecruitSessionDraft = async (draftId) => {
  const { data } = await screeningsClient.get(`/recruitsessiondraft/${draftId}`);
  return data;
};

export const openEvaluation = async (body) => {
  const { data } = await screeningsClient.post('/recruitsessiondraft/opendraft', body);
  return data;
};

export const duplicateRecruitSessionDraft = async (body) => {
  const { data } = await screeningsClient.post('/recruitsessiondraft/duplicate', body);
  return data;
};

export const getRecruitSessionMetricsById = async (id) => {
  try {
    const { data } = await screeningsClient.get(`/metrics/stage-metric/${id}`);
    return data;
  } catch (error) {
    return null;
  }
};

export const createWorkspaceAsync = async (body) => {
  try {
    const { data } = await alkymetricsClient.post('/workspace/create', body);
    return data;
  } catch (error) {
    return null;
  }
};

export const getAIQuestionTrial = async (body) => {
  try {
    const { data } = await screeningsClient.post('/ai/open-question/trial', body);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserChallengeCertificates = async (userId, token = null) => {
  const { data } = await screeningsClient.get(
    `/screenings/instances/certificates/${userId}?visible=true`,
    token
      ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      : {}
  );
  return data;
};

export const getTestFormats = async () => {
  const data = await screeningsClient.get('/testformat');
  return data?.data;
};

export const transferScreeningDraft = async (draftId, screeningData) => {
  const data = await screeningsClient.post(
    `/recruitsessiondraft/transfer/${draftId}`,

    screeningData
  );
  return data?.data;
};

export const searchInPrefilterQuesitons = async (companyId, workspaceId, text) => {
  try {
    const { data } = await screeningsClient.get('/prefilterQuestions/search', {
      companyId,
      workspaceId,
      text,
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const getPrefilterQuesitons = async ({ companyId, limit = 20, offset = 0 }) => {
  try {
    const { data } = await screeningsClient.get('/prefilterQuestions/all', {
      params: { companyId, limit, offset },
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const deletePrefilterQuesiton = async (prefilterQuestionId) => {
  const { data } = await screeningsClient.delete(`/prefilterQuestions/test/${prefilterQuestionId}`);
  return data;
};

export const postRestartPrefilterTest = async (body) => {
  const { data } = await screeningsClient.post('/prefilterQuestions/restartTest', body);
  return data;
};

export const getSearchTypes = async (parentId, roleId = null) => {
  const data = await screeningsClient.get(
    `/searchTypes?parentId=${parentId ?? ''}&roleId=${roleId ?? ''}`
  );
  return data?.data;
};
