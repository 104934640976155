import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCustomSearch,
  getCustomSearchStageInvitations,
  updateRecruitSession,
  getStageScreening,
  getScreeningsInvitations,
} from '../API/services/screeningService';
import { Orders, ProcessStatusStage, ScreeningInvitationStatus } from '../constants/enums';
import { t } from '../i18n/i18n';
import {
  setSelectedSearch,
  setStageCandidates,
  setStageScreening,
} from '../redux/actions/screeningActions';
import { setAppliedFilters, setProfilesQuery } from '../redux/actions/profilesActions';
import processQueryString from '../utils/processQueryString';
import Spinner from '../components/spinner/Spinner';

const useSearchDashboard = (id, isCardScreeningSearch, reloadSearches) => {
  const { selectedSearch, stageCandidates, keepSearchData, screeningIdToKeep, stageScreening } =
    useSelector((state) => state.screenings);
  const appliedFilters = useSelector((state) => state.profiles.appliedFilters);
  const { query } = useSelector((state) => state.profiles);
  const [loading, setLoading] = useState(false);
  const [loadingStage, setLoadingStage] = useState(false);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [textInput, setTextInput] = useState('');
  const [selectedStage, setSelectedStage] = useState(query?.selectedStage ?? 1);
  const [screeningId, setScreeningId] = useState(screeningIdToKeep);

  useEffect(() => {
    if (!keepSearchData && !isCardScreeningSearch) {
      setLoading(true);
      getCustomSearch(id)
        .then((res) => {
          dispatch(setSelectedSearch(res));
        })
        .finally(() => setLoading(false));
    }
    return () => {
      if (
        !history.location.pathname.includes('/screening/profiles') &&
        !history.location.pathname.includes('/profiles/') &&
        !history.location.pathname.includes('/createsearch/send')
      ) {
        dispatch(setSelectedSearch({}));
      }
      if (!history.location.pathname.includes('/profiles/')) {
        dispatch(setProfilesQuery({}));
      }
    };
  }, []);

  useEffect(() => {
    if (Object.keys(selectedSearch)?.length && !keepSearchData) {
      setLoadingStage(true);
      getCustomSearchStageInvitations(selectedSearch?.stages[selectedStage - 1]?.id, query)
        .then((response) => {
          dispatch(setStageCandidates(response?.invitations));
        })
        .finally(() => setLoadingStage(false));
    }
  }, [selectedStage]);

  useEffect(() => {
    if (Object.keys(selectedSearch).length && !keepSearchData && !isCardScreeningSearch) {
      setLoadingStage(true);
      getStageScreening({
        companyId: selectedSearch?.stages[0]?.companyId,
        levelId: selectedSearch?.stages[0]?.levelId,
      })
        .then((res) => {
          setScreeningId(res.id);
          dispatch(setStageScreening(res));
        })
        .finally(setLoadingStage(false));
    }
  }, [selectedSearch]);

  const processStatus = Object.values(ProcessStatusStage).map((status) => ({
    name: t(`SCREENING_INVITATIONS_PROCESS_STATUS_${status}`),
    value: status,
  }));
  const score = [
    {
      value: 1,
      name: t('SCORE_FIRST_OPTION'),
      minScore: 0,
      maxScore: 20,
    },
    {
      value: 2,
      name: t('SCORE_SECOND_OPTION'),
      minScore: 21,
      maxScore: 40,
    },
    {
      value: 3,
      name: t('SCORE_THIRD_OPTION'),
      minScore: 41,
      maxScore: 60,
    },
    {
      value: 4,
      name: t('SCORE_FOURTH_OPTION'),
      minScore: 61,
      maxScore: 80,
    },
    {
      value: 5,
      name: t('SCORE_FIFTH_OPTION'),
      minScore: 81,
      maxScore: 100,
    },
  ];
  const status = [
    {
      name: t('SCREENINGS_INVITATIONS_FINISHED'),
      value: ScreeningInvitationStatus.FINISHED,
    },
    {
      name: t('SCREENINGS_INVITATIONS_IN_PROGRESS'),
      value: ScreeningInvitationStatus.IN_PROGRESS,
    },
    {
      name: t('SCREENINGS_INVITATIONS_INVITED'),
      value: ScreeningInvitationStatus.INCOMPLETE,
    },
  ];

  const handleInput = (event) => {
    setTextInput(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setProfilesQuery({ ...query, search: textInput }));
  };

  const handleOrder = (key) => {
    if (!loading) {
      const toggle = key === query?.orderBy;
      const order = query?.order === Orders.ASC ? Orders.DESC : Orders.ASC;
      const arrOrderNew = [
        { name: 'order', id: toggle ? order : Orders.DESC, type: 'order' },
        { name: 'orderBy', id: key, type: 'orderBy' },
      ];
      const filterSet = [];
      appliedFilters.concat(arrOrderNew).forEach((obj) => {
        if (!filterSet.find((filter) => filter.name === obj.name)) {
          filterSet.push(obj);
        }
      });
      dispatch(
        setAppliedFilters(
          filterSet.map((obj) => arrOrderNew.find((o) => o.type === obj.type) || obj)
        )
      );
    }
  };

  const handleStatus = (event) => {
    dispatch(setProfilesQuery({ ...query, status: event.id }));
  };

  const handleProcessStatus = (event) => {
    dispatch(setProfilesQuery({ ...query, processStatus: event.id }));
  };

  const handleScoreFilter = (minScore, maxScore) => {
    dispatch(
      setProfilesQuery({
        ...query,
        minScore,
        maxScore,
      })
    );
  };

  const handleFinishSearch = async () => {
    await updateRecruitSession(selectedSearch?.id || id, { active: false }).then(() => {
      notification.open({
        message: t('SEARCH_FINISHED'),
        type: 'success',
      });
      dispatch(
        setSelectedSearch({
          ...selectedSearch,
          active: false,
        })
      );
    });
  };

  const handleSelectedStage = (stage) => {
    setSelectedStage(stage);
    dispatch(
      setProfilesQuery({
        ...query,
        selectedStage: stage,
      })
    );
  };

  const fetchNextPage = () => {
    if (stageCandidates?.pagination?.nextPage) {
      if (Object.keys(selectedSearch)?.length && !keepSearchData) {
        setLoadingNextPage(true);
        getScreeningsInvitations(processQueryString(stageCandidates?.pagination?.nextPage))
          .then((response) => {
            dispatch(
              setStageCandidates({
                pagination: response.pagination,
                collection: [...stageCandidates?.collection, ...response?.collection],
              })
            );
          })
          .finally(() => setLoadingNextPage(false));
      }
    }
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [closeSearchLoading, setCloseSearchLoading] = useState(false);

  const closeSearchPostConfirmation = async () => {
    setCloseSearchLoading(true);
    handleFinishSearch().then(() => {
      setCloseSearchLoading(false);
      if (isCardScreeningSearch) {
        setShowConfirmationModal(false);
        reloadSearches();
      }
    });
  };

  const confirmCloseSearchModalContent = () =>
    closeSearchLoading ? (
      <Spinner show />
    ) : (
      <div className="d-flex flex-column text-center justify-content-around h-100">
        <span className=" font-weight-700 text-xs">
          {t('CLOSE_SEARCH_CONFIRMATION_MODAL_TITLE')}
        </span>
        <span className=" font-weight-500 text-xs">
          {t('CLOSE_SEARCH_CONFIRMATION_MODAL_SUBTITLE')}
        </span>
        <button
          onClick={closeSearchPostConfirmation}
          type="button"
          className="text-xs mx-auto  bg-red-danger close-search-modal-confirmation-button text-white font-weight-700"
        >
          {t('CLOSE_SEARCH_CONFIRMATION_MODAL_ACCEPT_BUTTON')}
        </button>
        <button
          onClick={() => setShowConfirmationModal(false)}
          type="button"
          className="border-0 bg-transparent p-2  text-xs font-weight-700 text-red-danger"
        >
          {t('CLOSE_SEARCH_CONFIRMATION_MODAL_CLOSE_MODAL')}
        </button>
      </div>
    );

  return {
    selectedSearch,
    query,
    processStatus,
    status,
    score,
    textInput,
    selectedStage,
    loading,
    loadingStage,
    screeningId,
    handleInput,
    setTextInput,
    handleSubmit,
    handleOrder,
    setSelectedStage,
    handleStatus,
    handleProcessStatus,
    handleScoreFilter,
    handleFinishSearch,
    handleSelectedStage,
    fetchNextPage,
    loadingNextPage,
    showConfirmationModal,
    closeSearchLoading,
    setShowConfirmationModal,
    closeSearchPostConfirmation,
    confirmCloseSearchModalContent,
    stageScreening,
  };
};

export default useSearchDashboard;
