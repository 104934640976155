import './ChartLayout.scss';

export default function ChartLayout({ children, title, fixedHeight = true, styles }) {
  return (
    <div
      data-testid="chart-layout"
      className={`layout-container mb-4 ${fixedHeight ? 'layout-fixed-height' : ''}`}
      style={styles}
    >
      <div className="layout-title-container">
        <span className=" layout-title-container-text font-weight-600 text-xs">{title}</span>
      </div>
      {children}
    </div>
  );
}
