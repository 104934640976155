import { useLottie } from 'lottie-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaLinkedinIn, FaFacebookF, FaWhatsapp } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { IoShareSocialOutline } from 'react-icons/io5';
import { IoIosLink } from 'react-icons/io';
import { getCustomSearchStageInvitationsData } from '../../API/services/screeningService';
import rocketWithSucccess from '../../assets/lottie/rocket-with-success-animation.json';
import CopyCheck from '../../assets/search/Check';
import LinkIcon from '../../assets/search/Link';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import { CREATE_EVALUATION, CREATE_JOB_POST, NEW_SEARCH_SEND } from '../../constants/routes';
import { t } from '../../i18n/i18n';
import { setSearchInvitationsData, setSelectedSearch } from '../../redux/actions/screeningActions';
import './NewSearchSuccess.scss';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import DisabledFeature from '../../components/DisabledFeature/DisabledFeature';
import { PlanFeaturesIds } from '../../constants/enums';
import { determineDisabledFeature } from '../../utils/determineDisabled';
import CreationSuccess from '../../assets/createEvaluation/creationSuccess';

export default function NewSearchSuccess() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { newSearch, searchInvitations } = useSelector((state) => state.screenings);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyUrlIcon, setCopyUrlIcon] = useState(false);

  useEffect(() => {
    const stage = newSearch?.stages[0];
    getCustomSearchStageInvitationsData(stage?.id).then((res) => {
      dispatch(setSearchInvitationsData(res));
    });
  }, []);

  const copyLink = (link, fromInput = true) => {
    if (!copySuccess) {
      navigator.clipboard.writeText(link);
      if (fromInput) {
        setCopySuccess(true);
      } else {
        setCopyUrlIcon(true);
      }
      setTimeout(() => {
        setCopySuccess(false);
        setCopyUrlIcon(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (!newSearch?.stages?.length) {
      history.replace('/screening/searches');
    }
  }, []);

  const shareButtons = [
    {
      icon: <FaLinkedinIn />,
      text: t('SHARE_ON_LINKEDIN'),
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${searchInvitations.invitationPublicLink}`,
    },
    {
      icon: <FaFacebookF />,
      text: t('SHARE_ON_FACEBOOK'),
      url: `https://www.facebook.com/sharer.php?u=${searchInvitations.invitationPublicLink}`,
    },
    {
      icon: <FaXTwitter />,
      text: t('SHARE_ON_X'),
      url: `https://x.com/share?text=${encodeURIComponent(
        t('CREATE_SEARCH_SUCCESS_SHARE_TEXT')
      )}%0A&url=${encodeURIComponent(searchInvitations.invitationPublicLink)}`,
    },
    {
      icon: <FaWhatsapp />,
      text: t('SHARE_ON_WHATSAPP'),
      url: `https://wa.me/?text=${encodeURIComponent(
        t('CREATE_SEARCH_SUCCESS_SHARE_TEXT')
      )}: %0A${encodeURIComponent(searchInvitations.invitationPublicLink)}`,
    },
    {
      icon: <IoShareSocialOutline />,
      text: t('COPY_LINK'),
      copyUrl: true,
    },
  ];

  return (
    <div
      data-testid="new-search-success"
      className="w-100 mx-auto h-100 d-flex w-100 justify-content-center align-items-center"
    >
      <div className="px-2 mt-2">
        <div className=" search-success-container position-relative description-container new-search-container d-flex flex-column justify-content-around align-items-center px-3 py-2 bg-white rounded mx-auto gap-3">
          <span className="serrat text-title font-weight-700 text-center">
            {t('CREATE_SEARCH_SUCCESS_SEARCH_ACTIVATED')}
          </span>
          <CreationSuccess />
          <div className="d-flex flex-column align-items-center px-5 w-75">
            <span className="serrat text-center mt-2 text-base font-weight-600 m-2">
              {t('CREATE_SEARCH_SUCCESS_FIND_TALENT')}
            </span>
            <span
              className="serrat text-center text-base w-66 font-weight-400"
              dangerouslySetInnerHTML={{
                __html: t('CREATE_SEARCH_SUCCESS_FIND_TALENT_SUBTITLE').replace(
                  '{name}',
                  `<b class="serrat">${newSearch.name}</b>`
                ),
              }}
            />
          </div>
          <div className="w-75">
            <DisabledFeature
              className="d-flex flex-column align-items-center"
              childrenClassname="w-100 mt-2"
              featureId={PlanFeaturesIds.SEARCH_LINKS}
            >
              <div className="d-flex justify-content-center">
                <input
                  type="text"
                  name="search_link"
                  value={
                    determineDisabledFeature({ featureId: PlanFeaturesIds.SEARCH_LINKS })
                      ? t('NOT_AVAILABLE')
                      : searchInvitations.invitationPublicLink
                  }
                  className="url-input-placeholder"
                  disabled
                  data-testid="input-search-link"
                />
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip open={copySuccess}> {t('COPIED_INVITATION_LINK')}</Tooltip>}
                >
                  <button
                    type="button"
                    data-testid="copy-link-button"
                    className="copy-url-button"
                    onClick={() => {
                      if (!determineDisabledFeature({ featureId: PlanFeaturesIds.SEARCH_LINKS })) {
                        TrackEvent('create-evaluation-copy-link');
                        copyLink(searchInvitations.invitationPublicLink);
                      }
                    }}
                  >
                    {copySuccess ? <CopyCheck /> : <IoIosLink size="24" />}
                  </button>
                </OverlayTrigger>
              </div>
            </DisabledFeature>
          </div>
          <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
            <span className="share-buttons-title">{t('CREATE_SEARCH_SUCCESS_SHARE_LINK')}</span>
            <div className="d-flex justify-content-center align-items-center gap-1">
              {shareButtons.map((button, index) => (
                <OverlayTrigger
                  key={index}
                  placement="top"
                  overlay={
                    <Tooltip>{copyUrlIcon ? t('COPIED_INVITATION_LINK') : button.text}</Tooltip>
                  }
                >
                  <button
                    key={index}
                    type="button"
                    className="share-button-item"
                    onClick={() => {
                      button.copyUrl
                        ? copyLink(searchInvitations.invitationPublicLink, false)
                        : window.open(button.url, '_blank');
                    }}
                  >
                    {button.icon}
                  </button>
                </OverlayTrigger>
              ))}
            </div>
          </div>
          <div className="d-flex gap-2 serrat font-weight-700 text-xs mb-2">
            <button
              type="button"
              data-testid="create-evaluation-button"
              className="border-0 bg-transparent details-button-testgroup py-2"
              onClick={() => {
                history.push(NEW_SEARCH_SEND);
                dispatch(setSelectedSearch(newSearch));
              }}
            >
              {t('INVITE_CANDIDATES_EMAIL')}
            </button>
            <button
              type="button"
              data-testid="create-job-post-button"
              className="font-weight-700 text-xs text-white bg-blue-principal px-2 py-2 border-0"
              onClick={() => {
                // TODO: Hay un evento de GTM para trackear jobpost?
                // TrackEvent('create-evaluation-send-email');
                history.push(CREATE_JOB_POST);
              }}
            >
              {t('CREATE_JOB_POST')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
