import './RolesTestGroup.scss';

import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { t } from '../../i18n/i18n';

import {
  createRol,
  deleteRol,
  getRolesAll,
  updateRoles,
} from '../../API/services/screeningService';
import { RolesTestGroupRow } from './RolesTestGroupRow';
import { DeleteRolesTestGroupModal } from './modal/DeleteRolesTestGroupModal';
import NewRolesTestGroupModal from './modal/NewRolesTestGroupModal';
import UpdateRolesTestGroupModal from './modal/UpdateRolesTestGroupModal';
import { ConfirmModal } from './modal/ConfirmModal';
import SearchIcon from '../../assets/screening/SearchIcon';
import useTranslations from '../../hooks/useTranslations';
import { AutoScroll } from '../AutoScroll/AutoScroll';

const RowSelected = ({ rol, onClickCancel = () => {} }) => {
  const { key } = useTranslations;

  return (
    <div
      className="test-button-filters-selected d-flex justify-content-between align-items-center"
      style={{ backgroundColor: '#3A6EA5', color: 'white' }}
    >
      <div className="m-2">
        <span>{rol?.languages ? rol?.languages[key] ?? rol?.displayName : rol?.displayName}</span>
      </div>
      <div>
        <button
          type="button"
          data-testid="create-evaluation-second-step-button"
          className={`bg-transparent p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
          style={{ borderColor: '#FFFF' }}
          onClick={onClickCancel}
        >
          <span
            className={` test-text-filters ${'test-text-filters-selected'}`}
            data-testid="create-evaluation-ss-filters-selected-span"
            style={{ color: 'white' }}
          >
            {t('SELECTED')}
          </span>
        </button>
      </div>
    </div>
  );
};

const RowSwap = ({
  currentRol,
  onClickSetRolSelected = () => {},
  rolSelected,
  canSelect = true,
  onClickCancel = () => {},
  onAddRol = () => {},
  onRemoveRol = () => {},
  onSelectRolToUpdate = () => {},
  onDragStart = () => {},
  onDragOver = () => {},
  onDrop = () => {},
  onDragEnd = () => {},
  overThis = false,
  draggable = false,
  buttonPlus = false,
}) => (
  <div
    draggable={draggable}
    onDrop={onDrop}
    onDragOver={onDragOver}
    onDragStart={onDragStart}
    onDragEnd={onDragEnd}
    style={{ outline: overThis ? '2px solid green' : '', cursor: draggable ? 'grab' : '' }}
  >
    {currentRol?.id === rolSelected?.id ? (
      <RowSelected rol={currentRol} onClickCancel={onClickCancel} />
    ) : (
      <RolesTestGroupRow
        rol={currentRol}
        onClickSetRolSelected={onClickSetRolSelected}
        rolSelected={rolSelected}
        canSelect={canSelect}
        onAddRol={onAddRol}
        onRemoveRol={onRemoveRol}
        onSelectRolToUpdate={onSelectRolToUpdate}
        buttonPlus={buttonPlus}
      />
    )}
  </div>
);

const RolesTestGroup = () => {
  const [rolSelected, setRolSelected] = useState(null);
  const [itemDragging, setItemDragging] = useState(null);
  const [itemOver, setItemOver] = useState(null);
  const [roles, setRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [subRoles, setSubRoles] = useState([]);
  const [subRoles2, setSubRoles2] = useState([]);
  const [rolToUpdate, setRolToUpdate] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(null);
  const [rolToDelete, setRolToDelete] = useState(null);
  const [filter, setFilter] = useState({ name: '' });
  const [showModalToCreate, setShowModalToCreate] = useState(null);
  const [modalConfirm, setModalConfirm] = useState({
    title: '',
    description: '',
    show: false,
    rol: {},
    onSubmit: () => {},
  });

  const { key } = useTranslations;

  const updateModalConfirm = (data) => {
    setModalConfirm({ ...modalConfirm, ...data });
  };
  const sortParents = (array = []) =>
    array.sort((a, b) => {
      if (a.parentId === null) return -1;
      if (b.parentId === null) return 1;
      return Number(a.parentId) - Number(b.parentId);
    });

  const loadRolesColumns = (responseRoles, nameFilter = '') => {
    let rolesCopy = responseRoles?.filter((item) => !item?.parent);

    let subRolesCopy = responseRoles?.filter((item) => item?.parent && !item?.parent?.parentId);

    sortParents(subRolesCopy);

    let subRoles2Copy = responseRoles?.filter((item) => item?.parent && item?.parent?.parentId);

    sortParents(subRoles2Copy);

    if (nameFilter) {
      const funcFilter = (item) =>
        item?.id === rolSelected?.id ||
        item?.displayName?.toLowerCase()?.includes(nameFilter?.toLowerCase()) ||
        (item?.languages && item?.languages[key]?.includes(nameFilter?.toLowerCase()));

      rolesCopy = rolesCopy?.filter(funcFilter);
      subRolesCopy = subRolesCopy?.filter(funcFilter);
      subRoles2Copy = subRoles2Copy?.filter(funcFilter);
    }

    setRoles(rolesCopy);
    setSubRoles(subRolesCopy);
    setSubRoles2(subRoles2Copy);
    setRolToUpdate(null);
    if (rolSelected) {
      const findSelected = responseRoles?.find((item) => item?.id === rolSelected?.id);
      setRolSelected(findSelected);
    }
  };

  const init = async () => {
    const responseRoles = await getRolesAll();
    setAllRoles(responseRoles);
    loadRolesColumns(responseRoles, filter?.name);
  };

  const onSelectRolToUpdate = (rol) => {
    setRolToUpdate(rol);
  };

  useEffect(() => {
    init();
  }, []);

  const onClickSetRolSelected = (rol) => {
    setRolSelected(rol);
  };

  const onClickCancel = () => {
    setRolSelected(null);
    // init();
  };
  const alertSuccesss = (message = t('ROL_UPDATED_MESSAGE')) => {
    notification.success({
      message,
      type: 'success',
    });
  };
  const alertError = (message = t('ROL_CANCELLED_MESSAGE')) => {
    notification.error({
      message,
      type: 'error',
    });
  };
  const onAddRol = ({ parent, rolToAdd }) => {
    if (rolToAdd?.children?.length > 0) {
      alertError(
        t('ROL_ASSOCIATES').replace(
          '{{name}}',
          rolToAdd?.languages
            ? rolToAdd?.languages[key] ?? rolToAdd?.displayName
            : rolToAdd?.displayName
        )
      );
      return;
    }
    if (rolToAdd?.id === parent?.id) {
      alertError();
      return;
    }

    updateRoles(rolToAdd?.id, { parentId: parent?.id })
      .then((response) => {
        alertSuccesss();
        init();
      })
      .catch(() => alertError());
  };
  const confirmOnSubmit = ({ rol, parentId }) => {
    updateRoles(rol?.id, { parentId })
      .then((response) => {
        init();
        alertSuccesss();
      })
      .catch(() =>
        notification.error({
          message: t('ROL_CANCELLED_MESSAGE'),
          type: 'error',
        })
      )
      .finally(() => updateModalConfirm({ show: false }));
  };

  const onRemoveRol = ({ rolToRemove }) => {
    const parentId = rolToRemove?.parent?.parentId ?? null;

    if (rolToRemove?.children?.length > 0) {
      updateModalConfirm({
        show: true,
        onSubmit: () => confirmOnSubmit({ rol: rolToRemove, parentId }),
        rol: rolToRemove,
        title: t('DO_YOU_WANT_CONTINUE'),
        description: t('ROL_ASSOCIATES')?.replace(
          '{{name}}',
          rolToRemove?.languages
            ? rolToRemove?.languages[key] ?? rolToRemove?.displayName
            : rolToRemove?.displayName
        ),
      });
    } else {
      confirmOnSubmit({ rol: rolToRemove, parentId });
    }
  };

  const createNewRol = (_newRol, newFile) => {
    createRol({ ..._newRol, parentId: null }, newFile)
      .then((response) => {
        if (!response) {
          alertError();
          return;
        }
        init();
        alertSuccesss();
      })
      .catch(() => alertError())
      .finally(() => setShowModalToCreate(false));
  };
  const updateRol = (_updateRol, updateFile) => {
    updateRoles(rolToUpdate?.id, { ..._updateRol }, updateFile)
      .then((response) => {
        if (!response) {
          alertError();
          return;
        }
        init();
        alertSuccesss();
      })
      .catch(() => alertError())
      .finally(() => setShowModalToCreate(false));
  };

  const onDeleteRol = () => {
    deleteRol(rolToDelete?.id)
      .then((response) => {
        if (!response) {
          alertError();
          return;
        }
        alertSuccesss(t('DELETED_SUCCESS'));
        setShowModalDelete(false);
        init();
      })
      .catch(() => {
        alertError();
        setShowModalDelete(false);
      });
  };
  const onClickDeleteRol = (_rolToDelete) => {
    setRolToDelete(_rolToDelete);
    setShowModalDelete(true);
  };

  const handleInput = (e) => {
    setFilter({ ...filter, name: e?.target?.value });
    loadRolesColumns(allRoles, e?.target?.value);
  };
  return (
    <div data-testid="create-evaluation-second-step" className="d-flex flex-column h-100">
      <AutoScroll isDragging={itemDragging} scrollAreaHeight={500} />
      <span
        className="font-weight-600  create-evaluation-step-title text-light-black mb-3"
        data-testid="create-evaluation-ss-title-span"
      >
        {t('ROL_PAGE_TITLE')}
      </span>
      <div className="d-flex">
        <span
          className=" font-weight-600 text-base text-light-black justify-content-start"
          data-testid="create-evaluation-ss-test-title-span"
        >
          {t('ROL_QUESTION')}
        </span>
        <div className="flex-fill" />
      </div>

      <span className="text-light-black font-weight-400 text-base my-2 ">
        {t('ROL_DESCRIPTION')}
      </span>

      <div
        className="test-button-filters-selected d-flex justify-content-start align-items-center my-3"
        // style={{ maxWidth: '130px' }}
      >
        <div>
          <button
            type="button"
            data-testid="create-evaluation-second-step-button"
            className={` p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
            style={{ backgroundColor: '#449cc8' }}
            onClick={() => setShowModalToCreate(true)}
          >
            <span
              className={` test-text-filters ${'test-text-filters-selected'} text-white`}
              data-testid="create-evaluation-ss-filters-selected-span"
            >
              {t('NEW_ROL')}
            </span>
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-start pr-4">
          <div
            className={`my-1 my-sm-0 rounded transition-2-ms p-2 d-flex align-items-center w-100 tests-search-input-full bg-white `}
          >
            <SearchIcon testid="alkemy-tests-search-icon" />
            <input
              onChange={handleInput}
              value={filter?.name}
              className=" text-xs w-100 bg-transparent border-0 outline-none ml-2"
              style={{ flex: '1' }}
              placeholder={t('ROL_ABM_SEARCH_PLACEHOLDER')}
              type="text"
              data-testid="alkemy-tests-search-input"
            />
          </div>
        </div>
      </div>

      <div
        className="w-100 mx-auto h-100 bg-gray p-4 d-flex justify-content-around"
        style={{ gap: 10 }}
      >
        <div className="w-100 ">
          <h2 className="text-center mb-2 ">{t('COLUMN_ROL')}</h2>
          <div
            className="border border-1 border-grey w-100 d-flex flex-column p-2"
            style={{ gap: 10 }}
          >
            {roles?.map((item, index) => (
              <RowSwap
                key={index}
                currentRol={item}
                rolSelected={rolSelected}
                onClickSetRolSelected={onClickSetRolSelected}
                onClickCancel={onClickCancel}
                onAddRol={onAddRol}
                onSelectRolToUpdate={onSelectRolToUpdate}
                onDragStart={(e) => {
                  setItemDragging(item);
                  setRolSelected(item);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  setItemOver(item);
                }}
                onDragEnd={(e) => {
                  e.preventDefault();
                  setItemOver(null);
                  setItemDragging(null);
                }}
                onDrop={(e) => {
                  onAddRol({ parent: item, rolToAdd: itemDragging });
                }}
                overThis={itemOver?.id === item?.id}
                draggable={rolSelected && rolSelected?.id === item?.id}
              />
            ))}
          </div>
        </div>
        <div className="w-100 ">
          <h2 className="text-center">{t('COLUMN_SUBROL')}</h2>
          <div
            className="border border-1 border-grey w-100 d-flex flex-column p-2"
            style={{ gap: 10 }}
          >
            {subRoles?.map((item, index) => (
              <RowSwap
                key={index}
                currentRol={item}
                rolSelected={rolSelected}
                onClickSetRolSelected={onClickSetRolSelected}
                onClickCancel={onClickCancel}
                onAddRol={onAddRol}
                onRemoveRol={onRemoveRol}
                onSelectRolToUpdate={onSelectRolToUpdate}
                onDragStart={(e) => {
                  setItemDragging(item);
                  setRolSelected(item);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  setItemOver(item);
                }}
                onDragEnd={(e) => {
                  e.preventDefault();
                  setItemOver(null);
                  setItemDragging(null);
                }}
                onDrop={(e) => {
                  onAddRol({ parent: item, rolToAdd: itemDragging });
                }}
                overThis={itemOver?.id === item?.id}
                draggable={rolSelected && rolSelected?.id === item?.id}
              />
            ))}
          </div>
        </div>
        <div className="w-100 ">
          <h2 className="text-center">{t('COLUMN_SUBROL_2')}</h2>
          <div
            className="border border-1 border-grey w-100 d-flex flex-column p-2"
            style={{ gap: 10 }}
          >
            {subRoles2?.map((item, index) => (
              <RowSwap
                key={index}
                currentRol={item}
                rolSelected={rolSelected}
                onClickSetRolSelected={onClickSetRolSelected}
                onClickCancel={onClickCancel}
                onAddRol={onAddRol}
                onRemoveRol={onRemoveRol}
                onSelectRolToUpdate={onSelectRolToUpdate}
                onDragStart={(e) => {
                  setItemDragging(item);
                  setRolSelected(item);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  setItemOver(item);
                }}
                onDragEnd={(e) => {
                  e.preventDefault();
                  setItemOver(null);
                  setItemDragging(null);
                }}
                onDrop={(e) => {
                  alertError();
                }}
                draggable={rolSelected && rolSelected?.id === item?.id}
              />
            ))}
          </div>
        </div>
      </div>
      {showModalDelete && (
        <DeleteRolesTestGroupModal
          showModal={showModalDelete}
          setShowModal={setShowModalDelete}
          submitAction={onDeleteRol}
          roleToDelete={rolToDelete}
        />
      )}
      {showModalToCreate && (
        <NewRolesTestGroupModal
          showModal={showModalToCreate}
          setShowModal={setShowModalToCreate}
          onSubmit={createNewRol}
        />
      )}
      {rolToUpdate && (
        <UpdateRolesTestGroupModal
          showModal={rolToUpdate}
          setShowModal={(value) => !value && setRolToUpdate(null)}
          onSubmit={updateRol}
          onClickDeleteRol={onClickDeleteRol}
          rolToUpdate={rolToUpdate}
        />
      )}
      <ConfirmModal
        showModal={modalConfirm.show}
        rol={modalConfirm?.rol}
        setShowModal={(value) => updateModalConfirm({ show: value })}
        submitAction={modalConfirm?.onSubmit}
        title={modalConfirm.title}
        description={modalConfirm.description}
      />
    </div>
  );
};

export default RolesTestGroup;
