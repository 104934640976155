import { useLocation } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AnswerTypeEnum } from '../../../constants/enums';
import useCreateScreening from '../../../hooks/useCreateScreening';
import { t } from '../../../i18n/i18n';
import { TrackEvent } from '../../../utils/filters/segmentUtils';
import '../AddQuestion.scss';

const AnswerTypeButton = ({ button, index }) => {
  const { pathname } = useLocation();
  const track = pathname === '/createscreening';
  const { currentTypeQuestion, handleCurrentTypeQuestion } = useCreateScreening();
  return (
    <button
      type="button"
      key={button.id}
      onClick={() => {
        if (track) {
          TrackEvent(button.track);
        }
        if (button.id) handleCurrentTypeQuestion(button.id);
      }}
      className={`w-100 gap-1 border-none text-buttons text-title font-weight-500 d-flex justify-content-start align-items-center position-relative ${
        currentTypeQuestion === button.id ||
        ([AnswerTypeEnum.TEXT_AI, AnswerTypeEnum.TEXT].includes(currentTypeQuestion) &&
          [AnswerTypeEnum.TEXT_AI, AnswerTypeEnum.TEXT].includes(button.id))
          ? 'bg-blue-principal color-white svg-selected'
          : 'bg-blue-light color-grey-1'
      }
      ${index === 0 && 'mt-0'}
      `}
      data-testid={`add-questions-current-type-${index}-btn`}
    >
      {button?.img}
      {button.name}
      {button?.img2 && (
        <div className="additional-image">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip className=" font-size-tiny">{t('CORRECTION_POWERED_BY_AI')}</Tooltip>}
          >
            <div>{button?.img2}</div>
          </OverlayTrigger>
        </div>
      )}
    </button>
  );
};

export default AnswerTypeButton;
