import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Spinner from '../components/spinner/Spinner';
import useRoles from '../hooks/useRoles';
import './Home.scss';
import { t } from '../i18n/i18n';
import WorkspaceOptions from '../components/WorkspaceOptions/WorkspaceOptions';
import ActiveSearches from '../components/ActiveSearches/ActiveSearches';
import { inHtmlTag } from '../utils/inHtmlTag';
import { getAllCustomSearchs } from '../API/services/screeningService';
import { getLocalStorageWorkspace } from '../utils/workspaceUtils';
import HomeProfile from '../components/HomeProfile/HomeProfile';
import { getPlanByWorkspaceId, getPlanFeatures } from '../API/services/alkymersService';
import { setPlan, setPlanFeatures } from '../redux/actions/authActions';
import { disableForWorkspaceRole } from '../utils/workspaceRoleValidation';
import { PartnerWorkspaceRole } from '../constants/enums';
import WorkspaceDropdown from '../components/WorkspaceDropdown/WorkspaceDropdown';
import Rocket from '../assets/home/Rocket.webp';
import { CREATE_EVALUATION } from '../constants/routes';

const Home = () => {
  const [activeSearches, setActiveSearches] = useState(null);
  const [loadingSearches, setLoadingSearches] = useState(true);
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [shouldSeePlanInformation, setShouldSeePlanInformation] = useState(false);
  const activeWorkspace = getLocalStorageWorkspace();
  const { currentWorkspace, plan, currentRole } = useSelector((state) => state.auth);
  const { userRoles } = useRoles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetchSearches = async () => {
      try {
        const filters = {
          status: 'open',
          inuse: false,
          psychotechnics: false,
          createdby: null,
          sort: 'createdAt',
          search: null,
          offset: 0,
          limit: 6,
        };

        const res = await getAllCustomSearchs(filters);
        setActiveSearches(res.collection);
      } catch (error) {
      } finally {
        setLoadingSearches(false);
      }
    };
    fetchSearches();
    const fetchCreditsData = async () => {
      try {
        setLoadingPlan(true);
        const res = await getPlanByWorkspaceId(currentWorkspace);
        dispatch(setPlan(res));
        const featureRes = await getPlanFeatures(res.subscription.subscriptionPending.planId);
        dispatch(setPlanFeatures(featureRes));
      } catch (error) {
        dispatch(setPlan({}));
        dispatch(setPlanFeatures({}));
      } finally {
        setLoadingPlan(false);
      }
    };
    if (currentWorkspace) {
      const isAbleToSeePlanInfo = !disableForWorkspaceRole(currentRole, [
        PartnerWorkspaceRole.OWNER,
        PartnerWorkspaceRole.ADMINISTRATOR,
      ]);

      setShouldSeePlanInformation(isAbleToSeePlanInfo);
      if (isAbleToSeePlanInfo) fetchCreditsData();
    }
  }, [currentWorkspace]);

  if (!userRoles.length || loadingSearches) {
    return <Spinner show />;
  }
  return (
    <div className="home-container">
      <div className="home-display-content">
        <div className="my-2 w-90 z-index-10">
          <WorkspaceDropdown
            collapsed={false}
            setCollapsed={() => { }}
            className="dropdown-no-border pb-0 z-index-10"
            limit={false}
            noLogo
          />
        </div>
        <div className="d-block d-lg-flex bg-white justify-content-center py-3 home-section-plan-container justify-self-center rounded-top position-relative z-index-0">
          <HomeProfile isCredits={false} />
          <div className="home-profile-separator" />
          {shouldSeePlanInformation ? (
            loadingPlan ? (
              <Spinner show />
            ) : Object.values(plan).length ? (
              <>
                <HomeProfile isCredits />
              </>
            ) : null
          ) : null}
        </div>
        <WorkspaceOptions
          className="home-section-plan-container d-flex justify-content-center"
          hideFourth={activeWorkspace && activeWorkspace.createdAt !== activeWorkspace?.updatedAt}
          currentRole={currentRole}
          fromHome
        />
        {Boolean(activeSearches?.length) && (
          <ActiveSearches
            setActiveSearches={setActiveSearches}
            setLoading={setLoadingSearches}
            loading={loadingSearches}
            activeSearches={activeSearches}
          />
        )}
        {!activeSearches?.length && (
          <div className="w-100 h-100 d-flex justify-content-center pt-4">
            <div className="bg-white d-flex flex-column align-items-center no-searches-content gap-1 h-100">
              <img src={Rocket} alt="Rocket" className="home-rocket-no-active-searches" />
              <span className="text-title font-weight-1000">{t('HOME_TITLE_NO_SCREENINGS')}</span>
              <p className="text-sm font-weight-300  text-center">
                {t('HOME_SUBTITLE_NO_SCREENINGS')}
              </p>
              <Button
                type="button"
                onClick={() => history.push(CREATE_EVALUATION)}
                className="bg-blue-dark font-weight-700 text-xs color-white d-flex flex-column justify-content-center align-items-center home-button-custom-size mt-4"
                disabled={currentRole === PartnerWorkspaceRole.VISUALIZATOR}
              >
                <span>
                  {t('HOME_BUTTON_CREATE_FIRST_EVALUATION').split(' ').slice(0, 3).join(' ')}
                </span>
                <span>{t('HOME_BUTTON_CREATE_FIRST_EVALUATION').split(' ')?.[3]}</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
