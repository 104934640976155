import Lottie from 'lottie-react';
import SalesSuccess from '../../../assets/profileItem/salesSuccess.json';
import { t } from '../../../i18n/i18n';
import '../NotApprovedModal/NotApprovedModal.scss';

const ConfirmSendEmailModal = ({ setShow, textButton }) => (
  <div className="d-flex flex-column justify-content-between h-100 align-items-center">
    <div className="d-flex flex-column justify-center align-items-center">
      <span className=" font-weight-500 text-title text-center">
        {t('CONTACT_SALES_MODAL_SUCCESS_TITLE')}
      </span>
      <span className=" font-weight-400 text-title px-3 text-center mt-2 modal-subtitle">
        {t('CONTACT_SALES_MODAL_SUCCESS_SUBTITLE')}
      </span>
    </div>
    <Lottie className="lottie-container" animationData={SalesSuccess} loop />
    <div className="d-flex flex-column mt-3">
      <button
        type="button"
        className=" font-weight-700 text-xs modal-mail-button"
        onClick={() => setShow(false)}
      >
        {textButton}
      </button>
    </div>
  </div>
);

export default ConfirmSendEmailModal;
