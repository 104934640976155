import React from 'react';
import { useHistory } from 'react-router-dom';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import { t } from '../../i18n/i18n';
import placeholderImage from '../../assets/createSearch/no-profile-img.svg';
import buttonArrow from '../../assets/createSearch/small-arrow-blue.svg';
import processQueryString from '../../utils/processQueryString';
import './ScreeningProfile.scss';

const ScreeningProfile = () => {
  const history = useHistory();
  const { name } = processQueryString(history.location.search);
  const email = decodeURIComponent(history.location.search.split('&email=')[1]);

  return (
    <div className="w-100 mx-auto h-100">
      <BackgroundTop
        title={t('UNKNOWN_PROFILE_PAGE_TITLE')}
        className="position-relative"
        titleClassname="text-title"
      />
      <div className="position-relative description-container d-flex justify-content-center align-items-center new-search-container d-flex flex-column p-4 bg-white rounded mx-auto">
        <div className="d-flex justify-content-between mb-2 align-items-start">
          <span className=" font-weight-bold text-base mt-2">
            {t('UNKNOWN_PROFILE_SECTION_TITLE').replace('{name}', name)}
          </span>
        </div>
        <div className="d-flex justify-content-between mb-3 align-items-start">
          <span className=" text-xs mt-2 gray-subtitle">
            {t('UNKNOWN_PROFILE_SECTION_SUBTITLE_TITLE').replace('{email}', email)}
          </span>
        </div>
        <div>
          <img src={placeholderImage} alt="" />
        </div>
        <button
          className="mt-3 button-apply-screening rounded-pill px-3 py-1  font-weight-bold text-blue-principal bg-transparent"
          onClick={() => history.goBack()}
          type="button"
        >
          <img className="mr-2" src={buttonArrow} alt={t('ARROW_ALT')} />
          {t('UNKNOWN_PROFILE_GO_BACK')}
        </button>
      </div>
    </div>
  );
};

export default ScreeningProfile;
