import { Slider } from 'antd';
import React, { useState, useEffect } from 'react';
import CameraEmptyState from '../../assets/screening/CameraEmptyState';
import PauseButton from '../../assets/screening/PauseButton';
import PlayButton from '../../assets/screening/PlayButton';
import { t } from '../../i18n/i18n';
import './ProctoringCamera.scss';

const ProctoringCamera = ({ imagesToShow = [], imagesDeleted = false }) => {
  const [play, setPlay] = useState(imagesToShow.length);
  const [selectedImage, setSelectedImage] = useState(0);
  let changeImageTimeout;

  useEffect(() => {
    if (play && selectedImage < imagesToShow?.length - 1) {
      changeImageTimeout = setTimeout(() => {
        setSelectedImage(selectedImage + 1);
      }, 1000);
    } else if (selectedImage === imagesToShow?.length - 1) {
      setPlay(false);
    }
  }, [play, selectedImage]);

  return (
    <div className="d-flex flex-column">
      {imagesToShow?.length && !imagesDeleted ? (
        <>
          <img
            data-testid="img-snapshots"
            className="camera-image-container non-selectable"
            src={imagesToShow[selectedImage]}
            alt=""
          />
          <div className="d-flex align-items-center">
            <button
              data-testid="button-play-pause"
              type="button"
              className="mr-2 border-0 outline-none bg-transparent"
              onClick={() => {
                if (selectedImage === imagesToShow?.length - 1) {
                  setSelectedImage(0);
                }
                setPlay(!play);
                clearTimeout(changeImageTimeout);
              }}
            >
              {play ? <PauseButton /> : <PlayButton />}
            </button>
            <Slider
              className="slider-custom-styles"
              value={selectedImage}
              onChange={(value) => {
                setSelectedImage(value);
                clearTimeout(changeImageTimeout);
              }}
              max={imagesToShow?.length - 1}
            />
          </div>
        </>
      ) : (
        <>
          <div
            data-testid="empty-state-camera"
            className="camera-image-container bg-gray d-flex justify-content-center align-items-center"
          >
            <CameraEmptyState />
          </div>
          {imagesDeleted && (
            <div className="my-2 d-flex align-items-center justify-content-center">
              <span className=" text-xs font-weight-600">
                {t('PROCTORING_IMAGES_NOT_AVAILABLE')}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProctoringCamera;
