import React from 'react';

const CreateEvaluationIcon = ({ className }) => (
  <svg
    className={className}
    width="73"
    height="64"
    viewBox="0 0 73 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.6741 43.3971L10.3998 38.0029C9.25269 37.8225 8.30591 36.8866 7.98765 35.6187L0.115768 4.24642C-0.150968 3.18312 0.0481633 2.0821 0.662118 1.2254C1.27607 0.368709 2.18317 -0.0740313 3.15178 0.0101435L37.6831 3.0226C38.9324 3.13155 39.9811 4.13328 40.2929 5.51506L47.9076 39.269C48.1506 40.3462 47.9076 41.4847 47.2575 42.3146C46.6977 43.0294 45.9039 43.4286 45.0764 43.4286C44.943 43.4286 44.8085 43.4182 44.6741 43.3971Z"
      fill="#E6E6E6"
    />
    <path
      d="M31.3084 17.62C31.8881 16.9885 32.6838 16.7757 33.0856 17.1446C33.4874 17.5136 33.3432 18.3245 32.7633 18.9561C32.5351 19.2116 32.249 19.4088 31.929 19.531L29.4393 22.1757L28.2231 20.9809L30.8067 18.5006C30.9012 18.1712 31.0733 17.8692 31.3084 17.62Z"
      fill="#FFB6B6"
    />
    <path
      d="M17.1142 18.2086C17.1142 18.2086 18.569 16.8778 20.31 18.0397C22.051 19.2015 26.1053 22.1733 26.1053 22.1733L28.9461 18.65L31.1847 20.6791C31.1847 20.6791 27.236 27.6342 24.6848 26.1035C22.1337 24.5728 16.4384 20.203 16.697 19.2989C16.9556 18.3947 17.1142 18.2086 17.1142 18.2086Z"
      fill="#4987be"
    />
    <path
      d="M27.8868 50.6689L25.5688 54.9882L23.6597 52.6778L25.8558 49.7102L27.8868 50.6689Z"
      fill="#FFB6B6"
    />
    <path
      d="M26.3882 54.3911L25.4075 59.6905C25.4075 59.6905 24.7492 61.5284 23.691 59.6616C22.6329 57.7947 20.7169 53.9324 21.8184 53.2246C22.9199 52.5167 24.2503 51.9448 24.2503 51.9448L26.3882 54.3911Z"
      fill="#2F2E41"
    />
    <path
      d="M29.2553 55.3729L31.7008 59.7749L28.6439 60.1417L27.2988 56.5956L29.2553 55.3729Z"
      fill="#FFB6B6"
    />
    <path
      d="M16.2936 15.5102C16.2936 15.5102 16.0491 17.9557 15.6822 18.2003C15.3154 18.4449 19.9639 18.2003 19.9639 18.2003L18.6169 15.5102H16.2936Z"
      fill="#FFB6B6"
    />
    <path
      d="M21.3063 18.9358L19.5953 17.5889H15.4119L12.6255 19.9121L14.3985 28.1659L13.5426 32.2011L24.4253 32.5679L22.4689 28.8996C22.4689 28.8996 24.7921 26.454 24.0585 24.8644C23.3248 23.2748 21.3063 18.9358 21.3063 18.9358Z"
      fill="#4987be"
    />
    <path
      d="M23.3246 31.5895L24.9143 32.4454C24.9143 32.4454 33.4737 40.5158 32.74 42.9613C32.0064 45.4069 27.1153 52.3767 27.1153 52.3767L24.792 51.1539L28.2158 43.695L21.6739 37.1531L25.1856 43.3435L30.1722 56.4119L27.2375 57.6346L19.6563 42.3499C19.6563 42.3499 13.4201 38.0702 13.0533 35.3801C12.6865 32.69 13.5424 32.2009 13.5424 32.2009L23.3246 31.5895Z"
      fill="#2F2E41"
    />
    <path
      d="M31.6393 58.7354L35.6745 62.526C35.6745 62.526 36.8973 64.1156 34.6963 63.9933C32.4953 63.871 28.0933 63.3819 28.0933 62.0369C28.0933 60.6918 28.3378 59.2245 28.3378 59.2245L31.6393 58.7354Z"
      fill="#2F2E41"
    />
    <path
      d="M17.516 16.1214C19.0692 16.1214 20.3284 14.8623 20.3284 13.309C20.3284 11.7558 19.0692 10.4966 17.516 10.4966C15.9628 10.4966 14.7036 11.7558 14.7036 13.309C14.7036 14.8623 15.9628 16.1214 17.516 16.1214Z"
      fill="#FFB6B6"
    />
    <path
      d="M29.717 24.5309L27.3992 31.5896C27.3375 31.7778 27.177 31.9165 26.9819 31.9505L22.5493 32.7213C22.1518 32.7905 21.8233 32.4122 21.9475 32.0283L24.2623 24.8737C24.3247 24.6806 24.4909 24.5394 24.6916 24.5088L29.1272 23.8339C29.5222 23.7738 29.8417 24.1513 29.717 24.5309Z"
      fill="white"
    />
    <path
      d="M22.4549 32.8519C22.2663 32.8519 22.0871 32.7713 21.9601 32.625C21.8075 32.4494 21.7592 32.2122 21.8308 31.9909L24.1456 24.8363C24.2228 24.5976 24.4249 24.4258 24.6729 24.3882L29.1084 23.7132C29.336 23.6784 29.5611 23.7629 29.71 23.9388C29.8588 24.1147 29.9047 24.3504 29.8329 24.5693L27.5151 31.6279C27.4391 31.8595 27.2427 32.0293 27.0026 32.0712L22.5699 32.842C22.5314 32.8487 22.493 32.8519 22.4549 32.8519ZM29.2086 23.9501C29.1876 23.9501 29.1664 23.9517 29.1452 23.9549L24.7096 24.6299C24.5537 24.6536 24.4268 24.7615 24.3783 24.9115L22.0696 32.0476C22.0272 32.1785 22.0411 32.3257 22.1224 32.4367C22.2175 32.5664 22.3723 32.6276 22.528 32.601L24.3067 32.2917C26.0907 31.9815 27.5582 30.7128 28.1231 28.9925L29.5961 24.5063C29.6332 24.3933 29.6286 24.2674 29.57 24.1639C29.4929 24.0278 29.3554 23.9501 29.2086 23.9501Z"
      fill="#3F3D56"
    />
    <path
      d="M22.9506 30.0299C23.8072 30.0616 24.4852 30.5293 24.4649 31.0745C24.4446 31.6196 23.7338 32.0357 22.8769 32.0038C22.5345 31.9958 22.1989 31.9059 21.8983 31.7416L18.2705 31.5614L18.3914 29.8608L21.9549 30.219C22.2669 30.0774 22.6083 30.0125 22.9506 30.0299Z"
      fill="#FFB6B6"
    />
    <path
      d="M13.2975 19.6064C13.2975 19.6064 15.254 19.851 15.4985 21.9297C15.7431 24.0084 16.1099 29.0218 16.1099 29.0218L20.6342 28.8996L20.5413 31.9194C20.5413 31.9194 12.6862 33.4238 12.197 30.4892C11.7079 27.5545 11.3411 20.3854 12.197 19.9959C13.053 19.6064 13.2975 19.6064 13.2975 19.6064Z"
      fill="#4987be"
    />
    <path
      d="M28.8805 25.6221L24.7108 25.9735C24.6057 25.9823 24.513 25.904 24.5041 25.7989C24.4952 25.6938 24.5736 25.6011 24.6787 25.5922L28.8483 25.2409C28.9535 25.232 29.0462 25.3103 29.055 25.4154C29.0639 25.5205 28.9856 25.6133 28.8805 25.6221Z"
      fill="#3F3D56"
    />
    <path
      d="M28.5143 26.8451L24.3446 27.1965C24.2395 27.2054 24.1467 27.127 24.1379 27.0219C24.129 26.9168 24.2074 26.8241 24.3125 26.8152L28.4821 26.4639C28.5872 26.455 28.6799 26.5333 28.6888 26.6384C28.6977 26.7436 28.6194 26.8363 28.5143 26.8451Z"
      fill="#3F3D56"
    />
    <path
      d="M28.1476 28.0678L23.9779 28.4192C23.8728 28.428 23.78 28.3497 23.7712 28.2446C23.7623 28.1394 23.8407 28.0467 23.9458 28.0379L28.1154 27.6865C28.2205 27.6777 28.3132 27.756 28.3221 27.8611C28.331 27.9662 28.2527 28.0589 28.1476 28.0678Z"
      fill="#3F3D56"
    />
    <path
      d="M16.7635 14.3204L18.731 12.0613L18.403 11.1084L19.4592 12.1121L20.0044 11.964C20.0044 11.964 19.9997 10.0294 18.4549 9.94205C16.9101 9.85466 15.802 10.0174 15.802 10.0174C15.802 10.0174 13.5933 10.7095 13.5069 11.24C13.4205 11.7705 12.1832 12.4753 12.1832 12.4753C12.1832 12.4753 11.1095 13.274 11.6114 14.6126C12.1133 15.9512 13.2586 17.6221 12.4658 18.4366C11.673 19.2512 10.5528 19.8781 11.0986 20.1908C11.6444 20.5034 12.629 19.0376 13.5257 19.6237C14.4224 20.2097 15.9375 22.5637 17.3588 21.348C18.7801 20.1322 19.0429 18.7242 19.0429 18.7242L16.7747 16.4826C16.7747 16.4826 15.4005 14.1386 16.7635 14.3204Z"
      fill="#2F2E41"
    />
    <path
      d="M66.456 8H37.0376C36.7625 8.00039 36.4988 8.10983 36.3043 8.30433C36.1098 8.49883 36.0004 8.76252 36 9.03758V20.3899C36.0004 20.665 36.1098 20.9287 36.3043 21.1232C36.4988 21.3177 36.7625 21.4271 37.0376 21.4275H66.456C66.731 21.4271 66.9947 21.3177 67.1892 21.1232C67.3837 20.9287 67.4932 20.665 67.4936 20.3899V9.03758C67.4932 8.76252 67.3837 8.49883 67.1892 8.30433C66.9947 8.10983 66.731 8.00039 66.456 8ZM67.3715 20.3899C67.3712 20.6326 67.2747 20.8653 67.103 21.0369C66.9314 21.2086 66.6987 21.3051 66.456 21.3054H37.0376C36.7949 21.3051 36.5622 21.2086 36.3905 21.0369C36.2189 20.8653 36.1224 20.6326 36.1221 20.3899V9.03758C36.1224 8.79486 36.2189 8.56217 36.3905 8.39054C36.5622 8.21891 36.7949 8.12236 37.0376 8.12207H66.456C66.6987 8.12236 66.9314 8.21891 67.103 8.39054C67.2747 8.56217 67.3712 8.79486 67.3715 9.03758V20.3899Z"
      fill="#3F3D56"
    />
    <path
      d="M42.3788 18.2537C41.6787 18.2537 40.9943 18.0461 40.4121 17.6571C39.83 17.2681 39.3763 16.7153 39.1083 16.0684C38.8404 15.4216 38.7703 14.7098 38.9069 14.0231C39.0435 13.3364 39.3806 12.7057 39.8757 12.2106C40.3708 11.7155 41.0015 11.3784 41.6882 11.2418C42.3749 11.1052 43.0867 11.1753 43.7335 11.4432C44.3804 11.7112 44.9332 12.1649 45.3222 12.747C45.7112 13.3292 45.9188 14.0136 45.9188 14.7137C45.9178 15.6523 45.5445 16.5521 44.8808 17.2157C44.2172 17.8794 43.3174 18.2527 42.3788 18.2537ZM42.3788 11.2958C41.7028 11.2958 41.042 11.4963 40.48 11.8719C39.9179 12.2474 39.4798 12.7812 39.2211 13.4058C38.9624 14.0303 38.8947 14.7175 39.0266 15.3805C39.1585 16.0436 39.484 16.6526 39.962 17.1306C40.44 17.6086 41.049 17.9341 41.712 18.066C42.3751 18.1979 43.0623 18.1302 43.6868 17.8715C44.3114 17.6128 44.8452 17.1747 45.2207 16.6126C45.5963 16.0506 45.7968 15.3897 45.7968 14.7137C45.7957 13.8076 45.4353 12.9388 44.7945 12.2981C44.1538 11.6573 43.285 11.2969 42.3788 11.2958Z"
      fill="#3F3D56"
    />
    <path
      d="M63.8306 12.4554H49.7317C49.5347 12.4556 49.3453 12.5319 49.2033 12.6686C49.0613 12.8052 48.9777 12.9915 48.97 13.1885C48.969 13.1984 48.9686 13.2084 48.9688 13.2184C48.9691 13.4206 49.0496 13.6145 49.1926 13.7575C49.3356 13.9005 49.5295 13.981 49.7317 13.9813H63.8306C64.0329 13.9813 64.227 13.9009 64.3701 13.7578C64.5131 13.6148 64.5935 13.4207 64.5935 13.2184C64.5935 13.016 64.5131 12.822 64.3701 12.6789C64.227 12.5358 64.0329 12.4554 63.8306 12.4554Z"
      fill="#4987be"
    />
    <path
      d="M63.8306 15.4462H49.7317C49.5347 15.4463 49.3453 15.5227 49.2033 15.6594C49.0613 15.796 48.9777 15.9823 48.97 16.1792C48.969 16.1892 48.9686 16.1992 48.9688 16.2092C48.9691 16.4114 49.0496 16.6053 49.1926 16.7483C49.3356 16.8913 49.5295 16.9718 49.7317 16.9721H63.8306C64.0329 16.9721 64.227 16.8917 64.3701 16.7486C64.5131 16.6055 64.5935 16.4115 64.5935 16.2092C64.5935 16.0068 64.5131 15.8128 64.3701 15.6697C64.227 15.5266 64.0329 15.4462 63.8306 15.4462Z"
      fill="#4987be"
    />
    <path
      d="M41.9833 16.2606C41.8846 16.2608 41.7884 16.2288 41.7094 16.1696L41.7045 16.1659L40.6728 15.376C40.625 15.3394 40.5849 15.2937 40.5547 15.2416C40.5246 15.1895 40.505 15.1319 40.4971 15.0723C40.4892 15.0126 40.4932 14.952 40.5087 14.8938C40.5242 14.8356 40.5511 14.7811 40.5877 14.7333C40.6243 14.6856 40.67 14.6455 40.7221 14.6153C40.7742 14.5852 40.8317 14.5656 40.8914 14.5577C40.9511 14.5498 41.0117 14.5537 41.0699 14.5693C41.128 14.5848 41.1825 14.6117 41.2303 14.6483L41.8986 15.1607L43.4778 13.1012C43.5144 13.0534 43.5601 13.0133 43.6122 12.9832C43.6643 12.9531 43.7219 12.9335 43.7816 12.9256C43.8412 12.9178 43.9019 12.9217 43.96 12.9373C44.0182 12.9528 44.0727 12.9797 44.1205 13.0163L44.1106 13.0297L44.1207 13.0165C44.2171 13.0906 44.2801 13.1998 44.296 13.3203C44.3119 13.4408 44.2794 13.5626 44.2055 13.6592L42.348 16.0817C42.305 16.1375 42.2498 16.1827 42.1866 16.2137C42.1233 16.2447 42.0538 16.2608 41.9833 16.2606Z"
      fill="#4987be"
    />
    <path
      d="M71.0829 23.144H41.6645C41.3895 23.1444 41.1258 23.2538 40.9313 23.4483C40.7368 23.6428 40.6273 23.9065 40.627 24.1816V35.5339C40.6273 35.809 40.7368 36.0726 40.9313 36.2671C41.1258 36.4616 41.3895 36.5711 41.6645 36.5715H71.0829C71.358 36.5711 71.6217 36.4616 71.8162 36.2671C72.0107 36.0726 72.1201 35.809 72.1205 35.5339V24.1816C72.1201 23.9065 72.0107 23.6428 71.8162 23.4483C71.6217 23.2538 71.358 23.1444 71.0829 23.144ZM71.9985 35.5339C71.9982 35.7766 71.9016 36.0093 71.73 36.1809C71.5583 36.3526 71.3257 36.4491 71.0829 36.4494H41.6645C41.4218 36.4491 41.1891 36.3526 41.0175 36.1809C40.8459 36.0093 40.7493 35.7766 40.749 35.5339V24.1816C40.7493 23.9388 40.8459 23.7062 41.0175 23.5345C41.1891 23.3629 41.4218 23.2663 41.6645 23.2661H71.0829C71.3257 23.2663 71.5583 23.3629 71.73 23.5345C71.9016 23.7062 71.9982 23.9388 71.9985 24.1816V35.5339Z"
      fill="#3F3D56"
    />
    <path
      d="M47.0063 33.3977C46.3061 33.3977 45.6217 33.1901 45.0396 32.8011C44.4574 32.4121 44.0037 31.8593 43.7358 31.2124C43.4678 30.5656 43.3977 29.8538 43.5343 29.1671C43.6709 28.4804 44.0081 27.8497 44.5031 27.3546C44.9982 26.8595 45.629 26.5224 46.3157 26.3858C47.0024 26.2492 47.7141 26.3193 48.361 26.5872C49.0078 26.8551 49.5607 27.3089 49.9497 27.891C50.3386 28.4732 50.5463 29.1576 50.5463 29.8577C50.5452 30.7963 50.1719 31.696 49.5083 32.3597C48.8446 33.0233 47.9448 33.3966 47.0063 33.3977ZM47.0063 26.4398C46.3303 26.4398 45.6695 26.6403 45.1074 27.0158C44.5453 27.3914 44.1072 27.9252 43.8486 28.5497C43.5899 29.1743 43.5222 29.8615 43.6541 30.5245C43.7859 31.1875 44.1115 31.7965 44.5895 32.2745C45.0675 32.7526 45.6765 33.0781 46.3395 33.21C47.0025 33.3418 47.6897 33.2742 48.3143 33.0155C48.9388 32.7568 49.4726 32.3187 49.8482 31.7566C50.2237 31.1945 50.4242 30.5337 50.4242 29.8577C50.4232 28.9516 50.0627 28.0828 49.422 27.442C48.7812 26.8013 47.9125 26.4408 47.0063 26.4398Z"
      fill="#3F3D56"
    />
    <path
      d="M68.458 27.5994H54.3592C54.1621 27.5995 53.9727 27.6759 53.8307 27.8125C53.6887 27.9492 53.6051 28.1355 53.5975 28.3324C53.5964 28.3423 53.596 28.3523 53.5962 28.3623C53.5965 28.5645 53.677 28.7584 53.82 28.9014C53.963 29.0445 54.1569 29.1249 54.3592 29.1252H68.458C68.6604 29.1252 68.8544 29.0448 68.9975 28.9018C69.1406 28.7587 69.221 28.5646 69.221 28.3623C69.221 28.16 69.1406 27.9659 68.9975 27.8228C68.8544 27.6797 68.6604 27.5994 68.458 27.5994Z"
      fill="#4987be"
    />
    <path
      d="M68.458 30.5902H54.3592C54.1621 30.5903 53.9727 30.6667 53.8307 30.8034C53.6887 30.94 53.6051 31.1263 53.5975 31.3232C53.5964 31.3332 53.596 31.3432 53.5962 31.3531C53.5965 31.5554 53.677 31.7493 53.82 31.8923C53.963 32.0353 54.1569 32.1158 54.3592 32.1161H68.458C68.6604 32.1161 68.8544 32.0357 68.9975 31.8926C69.1406 31.7495 69.221 31.5555 69.221 31.3531C69.221 31.1508 69.1406 30.9567 68.9975 30.8137C68.8544 30.6706 68.6604 30.5902 68.458 30.5902Z"
      fill="#4987be"
    />
    <path
      d="M46.6108 31.4046C46.512 31.4047 46.4158 31.3728 46.3368 31.3135L46.3319 31.3099L45.3002 30.52C45.2524 30.4833 45.2123 30.4377 45.1822 30.3856C45.152 30.3335 45.1325 30.2759 45.1246 30.2163C45.1167 30.1566 45.1206 30.0959 45.1361 30.0378C45.1517 29.9796 45.1785 29.9251 45.2151 29.8773C45.2517 29.8295 45.2974 29.7894 45.3495 29.7593C45.4016 29.7292 45.4592 29.7096 45.5188 29.7017C45.5785 29.6938 45.6391 29.6977 45.6973 29.7133C45.7555 29.7288 45.81 29.7556 45.8578 29.7923L46.526 30.3047L48.1052 28.2451C48.1419 28.1974 48.1876 28.1573 48.2397 28.1272C48.2918 28.097 48.3493 28.0775 48.409 28.0696C48.4687 28.0617 48.5293 28.0657 48.5875 28.0813C48.6456 28.0968 48.7001 28.1237 48.7479 28.1603L48.7381 28.1736L48.7482 28.1605C48.8445 28.2346 48.9076 28.3438 48.9235 28.4643C48.9394 28.5848 48.9068 28.7066 48.833 28.8032L46.9754 31.2257C46.9325 31.2815 46.8772 31.3267 46.814 31.3577C46.7508 31.3887 46.6812 31.4047 46.6108 31.4046Z"
      fill="#4987be"
    />
  </svg>
);

export default CreateEvaluationIcon;
