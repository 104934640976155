import { useEffect, useState } from 'react';
import MathJax from 'react-mathjax';
import DOMPurify from 'dompurify';
import { optionIcon, optionIsAccepted, optionsMatch } from '../../../utils/modal/optionsToMatch';
import { t } from '../../../i18n/i18n';
import ModalTestFile from '../../ModalTestFile/ModalTestFile';
import './OptionMultipleComponent.scss';
import { parseLineHTML } from '../../../utils/parseLineHTML';
import mathJaxConfig from '../../../utils/mathJaxConfig';

const OptionMultipleComponent = ({ submission, showResult, isTestgroupListDetail }) => {
  const [populatedTests, setPopulatedTests] = useState({});
  const sortAcceptedOptions = submission?.acceptedOptions.sort((a, b) => b.weight - a.weight);
  const isBestOption = optionsMatch([sortAcceptedOptions[0]], submission?.submittedOptions);

  useEffect(() => {
    if (!submission?.hasGlobalScore) {
      const populatedTest = {};
      submission?.options?.forEach((option) => {
        const acceptedOption = submission?.acceptedOptions.find(
          (acceptedOption) => option?.id === acceptedOption?.options[0]
        );
        populatedTest[option?.id] = {
          weight: acceptedOption?.weight,
          text: DOMPurify.sanitize(parseLineHTML(option?.text)),
          formula: option?.formula,
          text: DOMPurify.sanitize(parseLineHTML(option?.text)),
          formula: option?.formula,
          submitted: submission?.submittedOptions?.includes(option?.id),
        };
      });
      setPopulatedTests(populatedTest);
    }
  }, [submission]);

  const markSubmission = (option) => {
    let responseStyle = '';
    if (showResult && submission?.submittedOptions?.includes(option?.id)) {
      if (submission?.hasGlobalScore) {
        if (optionIsAccepted(option?.id, submission)) {
          responseStyle = 'correct-option';
        } else {
          responseStyle = 'incorrect-option';
        }
      } else {
        responseStyle =
          populatedTests[option?.id]?.weight > 0
            ? isBestOption
              ? 'correct-option'
              : 'partial-correct-option'
            : populatedTests[option?.id]?.weight === 0
            ? 'cero-option'
            : 'incorrect-option';
      }
    }

    return responseStyle;
  };

  const showAnswerLabel = (submission) => {
    let response = '';
    if (submission?.hasGlobalScore) {
      if (showResult) {
        response = `${t('MODAL_ANSWER')} 
        ${
          optionsMatch(
            submission?.acceptedOptions.filter((x) => x.weight > 0) ?? [],
            submission?.submittedOptions
          )
            ? `(${t('MODAL_OPTION_CORRECT')})`
            : ` (${t('MODAL_OPTION_INCORRECT')})`
        }`;
      }
    } else {
      response = t('MODAL_SCORED_ANSWERS');
    }

    return response;
  };

  return (
    <div className="option-multiple-container m-3">
      <span className=" font-weight-600 text-xs text-color-item-test-group mt-2">
        {showAnswerLabel(submission)}
      </span>
      <div className={`d-flex flex-column gap`}>
        {submission?.options?.map((option, index) => (
          <div>
            <div
              className={`d-flex flex-row align-items-center ${
                !isTestgroupListDetail ? 'mt-3' : ''
              }`}
              key={index}
            >
              <div style={{ position: 'relative', width: '100%' }}>
                <div
                  data-testid="OPTION_TEXT"
                  data-key={option?.id}
                  disabled
                  value={option?.text}
                  className={`p-2 w-100  rounded min-height-text-area
                outline-none option-text font-weight-500  ${markSubmission(option)} ${
                    isTestgroupListDetail ? 'bg-grey' : 'maya-blue-solid-border '
                  }`}
                  style={{ position: 'relative', zIndex: 1 }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(parseLineHTML(option?.text)),
                    }}
                  />
                </div>
                {option?.formula && (
                  <div className="option-formula-position-format">
                    <MathJax.Provider>
                      <MathJax.Node
                        className="option-formula-background"
                        formula={option?.formula.replace(/(?<!\\)\$/g, '')}
                      />
                    </MathJax.Provider>
                  </div>
                )}
              </div>
              {!submission?.hasGlobalScore && !isTestgroupListDetail && (
                <div
                  className={`d-flex option-score-w flex-row justify-content-center mx-2 align-items-center ${
                    index > 0 ? 'mt-1' : ''
                  }`}
                >
                  <div
                    data-testid="OPTION_WEIGTH_CONTAINER"
                    className={`d-flex flex-row justify-content-center align-items-center modal-container-check-result-custom-height p-1 modal-container-check-border-color${
                      option?.weight !== 0
                        ? populatedTests[option?.id]?.weight > 0
                          ? sortAcceptedOptions[0].options.includes(option.id)
                            ? '-green'
                            : '-yellow'
                          : populatedTests[option?.id]?.weight === 0
                          ? '-gray'
                          : '-red'
                        : ''
                    }`}
                  >
                    <span className=" text-xs text-purple">{`${
                      populatedTests[option?.id]?.weight
                    } ${t('OPTION_WEIGHT_PTS_TEXT')}`}</span>
                  </div>
                </div>
              )}
              {!isTestgroupListDetail && (
                <div
                  className={`d-flex option-icon-w flex-row justify-content-center align-items-center ${
                    index > 0 ? 'mt-1' : ''
                  } ${submission?.hasGlobalScore ? 'ml-2' : ''}`}
                >
                  {optionIcon(option, populatedTests, submission, showResult)}
                </div>
              )}
            </div>
            {option.files?.map((file) => (
              <ModalTestFile file={file} key={file.id} />
            ))}
          </div>
        ))}
        {submission?.options && isTestgroupListDetail && (
          <p>
            {t('OPTION_MULTIPLE_CHOICE_QUANTITY')}{' '}
            <span className="font-weight-700">{submission?.options?.length}</span>{' '}
          </p>
        )}
      </div>
    </div>
  );
};

export default OptionMultipleComponent;
