import './profileInvitationsAndChallenges.scss';
import { t } from '../../../i18n/i18n';
import { IoSearch } from 'react-icons/io5';
import InvitationOrChallengeItem from './InvitationOrChallengeItem';
import { useEffect, useRef, useState } from 'react';
import HorizontalLoader from '../../HorizontalLoader/HorizontalLoader';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileInvitationsAndChallenges } from '../../../redux/actions/profilesActions';
import { Input } from 'antd';

const ProfileInvitationsAndChallenges = () => {
  const dispatch = useDispatch();
  const { profileInvitationsAndChallenges } = useSelector((state) => state.profiles);
  const [search, setSearch] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (e) => {
    const value = e?.target?.value;
    setSearchValue(value);
    if (value) {
      const invitations = profileInvitationsAndChallenges.invitations.filter((invitation) => {
        return invitation.name?.toLowerCase()?.includes(value?.toLowerCase());
      });
      setSearch(invitations);
    } else {
      setSearch([]);
    }
  };
  const containerRef = useRef(null);

  useEffect(() => {
    () => {
      dispatch(setProfileInvitationsAndChallenges({}));
      setSearch([]);
      setSearchValue('');
    };
  }, []);
  return (
    <div className="card-profile description-container position-relative h-100 bg-blue-light b-radius-8">
      <div className="bg-blue-principal d-flex px-3 py-2 profile-border-radius align-items-center">
        <span className="text-sm font-weight-500 color-white">
          {t('PROFILE_CANDIDATE_ALL_INVITATIONS_AND_CHALLENGES_TITLE')}
        </span>
        <div className="d-flex align-items-center input-profile-custom pl-2">
          <div onClick={(e) => handleSearch(e)}>
            <IoSearch />
          </div>
          <Input
            onChange={handleSearch}
            className="bg-transparent"
            placeholder={t('PROFILE_SEARCH_EVALUATION')}
            allowClear
          />
        </div>
      </div>
      <div
        className="d-flex invitations-and-challenges-container gap-2 px-2 py-4"
        ref={containerRef}
      >
        {Boolean(searchValue?.length) && !search?.length ? (
          <div className="invitation-empty-container bg-transparent d-flex align-items-center justify-content-center w-100">
            <span className="text-sm font-weight-500 color-white">
              {t('PROFILE_SEARCH_NOT_FOUND_RESULTS')}
            </span>
          </div>
        ) : search?.length ? (
          search.map((item) => {
            return <InvitationOrChallengeItem item={item} search />;
          })
        ) : profileInvitationsAndChallenges.invitations ? (
          profileInvitationsAndChallenges?.invitations?.map((item, index) => {
            return <InvitationOrChallengeItem item={item} index={index} />;
          })
        ) : (
          <div className="invitation-empty-container bg-transparent d-flex align-items-center justify-content-center w-100">
            <HorizontalLoader white className={'custom-loader-position'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileInvitationsAndChallenges;
