import DeleteForever from '../../assets/screening/DeleteForever';
import InputOutputArrow from '../../assets/screening/InputOutputArrows';
import { t } from '../../i18n/i18n';

const CodeAnswer = ({ input, output, onChange, index, onRemove }) => (
  <>
    <div
      className="row ml-1 mt-4 d-flex flex-nowrap justify-items-between"
      data-testid="row-code-answer"
      key={`code-test-case-${index}`}
    >
      <textarea
        data-testid="row-input-text-area"
        className="w-100 py-2 px-3 my-1 mx-3  border-thin-blue-principal outline-none text-xs min-height-text-area max-height-text-area"
        rows="2"
        type="text"
        onChange={(e) => onChange('input', e.target.value)}
        value={input}
        placeholder={t('CREATE_QUESTION_CODE_INPUT_ANSWER')}
        maxLength={1000}
      />
      <InputOutputArrow />
      <textarea
        data-testid="row-output-text-area"
        className="w-100 py-2 px-3 my-1 mx-3  border-thin-blue-principal outline-none text-xs min-height-text-area max-height-text-area"
        type="text"
        onChange={(e) => onChange('output', e.target.value)}
        value={output}
        placeholder={t('CREATE_QUESTION_CODE_OUTPUT_ANSWER')}
        maxLength={1000}
      />
      <button
        type="button"
        data-testid="row-code-answer-delete-question-button"
        onClick={onRemove}
        className="bg-transparent border-0 outline-none ml-sm-1"
      >
        <DeleteForever fill="#4987be" />
      </button>
    </div>
  </>
);

export default CodeAnswer;
