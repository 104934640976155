import React, { useEffect } from 'react';
import './SliderSelector.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillInfoCircle } from 'react-icons/ai';
import { t } from '../../i18n/i18n';

const SliderSelector = ({ handleLangCodesAIRubrics, sliders }) => {
  useEffect(
    () =>
      handleLangCodesAIRubrics(
        sliders.reduce((acc, slider) => {
          if (slider.value !== 0) {
            acc[slider.label] = slider.initialValue;
          }
          return acc;
        }, {})
      ),
    []
  );

  const adjustSlidersProportionally = (index, newValue) => {
    const oldValue = sliders[index].value;
    const difference = newValue - oldValue;

    let nextIndex = (index + 1) % sliders.length;

    while (sliders[nextIndex].value === 0 && nextIndex !== index && difference > 0) {
      nextIndex = (nextIndex + 1) % sliders.length;
    }

    const nextSlider = sliders[nextIndex];
    if (nextSlider.value - difference < nextSlider.min || newValue > 100 || newValue < 0) return;

    const nextNewValue = nextSlider.value - difference;

    const sliderValuesObject = sliders.reduce((acc, slider) => {
      if (slider.value !== 0) {
        acc[slider.label] = slider.value;
      }
      return acc;
    }, {});

    sliders[index].setter(newValue);
    nextSlider.setter(nextNewValue);
    sliderValuesObject[sliders[index].label] = newValue;
    sliderValuesObject[nextSlider.label] = nextNewValue;

    handleLangCodesAIRubrics(sliderValuesObject);
  };

  const handleSliderChange = (index, event) => {
    const newValue = Math.max(Number(event.target.value), sliders[index].min);
    adjustSlidersProportionally(index, newValue);
  };

  const resetValues = () => {
    sliders.forEach((slider) => slider.setter(slider.initialValue));

    const sliderValuesObject = sliders.reduce((acc, slider) => {
      acc[slider.label] = slider.initialValue;
      return acc;
    }, {});

    handleLangCodesAIRubrics(sliderValuesObject);
  };

  return (
    <div>
      <div className="titleSlider text-title font-weight-800 mx-2 ">
        <span>{t('LANG_CODES_RUBRICS')}</span>
        <button className="ResetSlider text-xs" onClick={resetValues}>
          {t('LANG_CODE_RESET')}
        </button>
      </div>
      {sliders.map((slider, index) => (
        <div className="slider-container mb-1 text-sm" key={index}>
          <div className="sliderLabel d-flex align-items-center">
            <span className="mr-2">{slider.label}</span>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip align="center" placement="top">
                  <span className="font-montserrat text-xs font-weight-700 text-center w-100">
                    {t(slider.tooltipLabel)}
                  </span>
                </Tooltip>
              }
            >
              <AiFillInfoCircle color="lightgray" size="1em" />
            </OverlayTrigger>
          </div>
          <input
            className="inputSlider"
            type="range"
            max="100"
            value={slider.value}
            onChange={(e) => handleSliderChange(index, e)}
            style={{
              background: `linear-gradient(to right, #4987be ${slider.value}%, #ddd ${slider.value}%)`,
            }}
          />
          <span className="sliderValue font-weight-600">{slider.value}%</span>
        </div>
      ))}
    </div>
  );
};

export default SliderSelector;
