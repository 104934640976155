import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { determineDisabledFeature } from '../../utils/determineDisabled';
import DiamondIcon from '../../assets/disabledFeature/DiamondIcon';
import './DisabledFeature.scss';
import { t } from '../../i18n/i18n';

const DisabledFeatureMinTier = ({
  children,
  featureId,
  validateAmount,
  usedAmount,
  className,
  iconClassName,
  childrenClassname,
  iconSize = 'small',
  isDisabledFeature = () => determineDisabledFeature({ featureId, validateAmount, usedAmount }),
  tooltipLabel = validateAmount
    ? t('NO_MORE_AVAILABLE_USAGE_FOR_THIS_FUNCTION_CONTACT_SALES_TEAM')
    : t('AVAILABLE_IN_PREMIUM_CONTACT_SALES_TEAM'),
}) =>
  isDisabledFeature() ? (
    <div className={`border-1 ${className ?? 'd-flex flex-column'}`}>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <span className=" font-weight-bold text-xs mb-2">{tooltipLabel}</span>
          </Tooltip>
        }
      >
        <a
          className={`pt-2  text-xs diamond-background d-flex align-items-center justify-content-center rounded-circle ${iconClassName}`}
          href={process.env.RAZZLE_RUNTIME_SALES_CALENDAR_URL}
        >
          <DiamondIcon className={`diamond-icon-${iconSize}`} />
        </a>
      </OverlayTrigger>
      <div
        style={{ pointerEvents: 'none', filter: 'grayscale(1)' }}
        role="button"
        className={`cursor-disabled ${childrenClassname}`}
        tabIndex={0}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  ) : (
    children
  );

export default DisabledFeatureMinTier;
