import React from 'react';
import { useLottie } from 'lottie-react';
import rocketWithSuccess from '../../assets/lottie/rocket-with-success-animation.json';
import { t } from '../../i18n/i18n';

const CreateTestGroupSuccess = ({ name }) => {
  const options = {
    animationData: rocketWithSuccess,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <div className="d-flex flex-column align-items-center py-4">
      <div className="lottie-container">{View}</div>
      <div className="my-3 d-flex flex-column align-items-center">
        <span className="my-1  font-weight-bold text-title">
          {t('CREATE_TEST_GROUP_SUCCESS_STEP_TITLE')}
        </span>
        <span className="my-1  text-muted text-xs font-italic">
          {t('CREATE_TEST_GROUP_SUCCESS_STEP_SUBTITLE').replace('{testGroupName}', `"${name}"`)}
        </span>
      </div>
    </div>
  );
};

export default CreateTestGroupSuccess;
