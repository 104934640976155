import React from 'react';
import { t } from '../../i18n/i18n';
import Modal from '../Modal/Modal';
import PDFViewer from '../PDFViewer/PDFViewer';
import Spinner from '../spinner/Spinner';

function TermsAndConditionsModal(props) {
  const { showModal, setShowModal, termsAndConditionsLoading, termsAndConditionsData, closeModal } =
    props;
  return (
    <Modal
      className="h-100 pt-3 pb-2 px-4 modal-tac-container b-radius-8"
      show={showModal}
      setShow={setShowModal}
      closeBtnClassname="text-blue-principal modal-close-icon"
    >
      <>
        {termsAndConditionsLoading ? (
          <Spinner show />
        ) : termsAndConditionsData ? (
          <div className="d-flex flex-column h-100 py-4">
            <span
              className=" font-weight-600 text-xs mb-2"
              data-testid="terms-and-conditions-title"
            >
              {t('TERMS_AND_CONDITIONS_MODAL_TITLE')}
            </span>
            <PDFViewer pdf={termsAndConditionsData?.link} />
            <button
              type="button"
              onClick={() => closeModal()}
              className="rounded-pill border-0 text-white px-4 text-sm font-weight-500  login-form-button bg-maya-blue w-fit close-modal-button mt-3"
              data-testid="terms-and-conditions-back-btn"
            >
              {t('TERMS_AND_CONDITIONS_GO_BACK')}
            </button>
          </div>
        ) : (
          <span data-testid="terms-and-conditions-cannot-loading-span">
            {t('CANNOT_LOAD_TERMS_AND_CONDITIONS')}
          </span>
        )}
      </>
    </Modal>
  );
}

TermsAndConditionsModal.propTypes = {};

export default TermsAndConditionsModal;
