import { useEffect, useState } from 'react';
import Play from '../../../assets/modal/Play';
import Pause from '../../../assets/modal/Pause';
import { formatSecondsToSSMM } from '../../../utils/formatSecondsToSSMM';
import './ModalTestAudio.scss';

const ModalTestAudio = ({ file, open }) => {
  const [audioData, setAudioData] = useState({});

  useEffect(() => {
    if (!open && audioData?.audioElement && !audioData.audioElement.paused) {
      audioData?.audioElement?.pause();
      setAudioData({
        ...audioData,
        playing: false,
      });
    }
  }, [open]);

  const togglePlay = () => {
    if (audioData?.audioElement?.paused) {
      audioData?.audioElement?.play();
      setAudioData({
        ...audioData,
        playing: true,
      });
    } else {
      audioData?.audioElement?.pause();
      setAudioData({
        ...audioData,
        playing: false,
      });
    }
  };

  return (
    <div className="d-flex align-items-center my-2" data-testid="modal-test-audio-container">
      <button
        data-testid="modal-test-audio-play-button"
        className="border-0 outline-none bg-transparent"
        onClick={togglePlay}
        type="button"
      >
        {audioData?.playing ? <Play /> : <Pause />}
      </button>
      <audio
        className="d-none"
        data-testid="modal-test-audio-player"
        onTimeUpdate={() => {
          setAudioData({
            ...audioData,
            duration: Math.round(
              audioData.audioElement.duration - audioData.audioElement.currentTime
            ),
          });
        }}
        onEnded={() => {
          setAudioData({
            ...audioData,
            duration: Math.round(audioData.audioElement.duration),
            playing: false,
          });
        }}
        id={file.id}
        controls
        src={file.url}
        onLoadedData={(audio) => {
          setAudioData({
            audioElement: audio.target,
            playing: false,
            duration: Math.round(audio.target.duration),
          });
        }}
      />
      <span className=" font-weight-bold text-xs ml-2">
        {formatSecondsToSSMM(audioData.duration)}
      </span>
    </div>
  );
};

export default ModalTestAudio;
