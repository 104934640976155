import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../i18n/i18n';
import useClickOutside from '../../hooks/useClickOutside';
import CrossIcon from '../../assets/search/CrossIcon';
import DropdownSelectDot from '../DropdownSelectDot/DropdownSelectDot';
import SmallCrossIcon from '../../assets/search/SmallCrossIcon';
import './QualitativeFilters.scss';

const filterType = 'qualitativeResultsIds';
const balancedExtreme = 'BALANCED';

function QualitativeFilters({
  isLoading,
  addFilterObject,
  show,
  setShow,
  testGroups,
  setQuantity,
  removeFilter,
  removeFilters,
}) {
  const appliedFilters = useSelector((state) => state.profiles.appliedFilters);
  const [appliedExtremes, setAppliedExtremes] = useState({});
  const [formattedTestGroups, setFormattedTestGroups] = useState([]);
  const [selectedTestGroup, setSelectedTestGroup] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedTestGroupAppliedFilters, setSelectedTestGroupAppliedFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState(true);
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => setShow(false));

  const buildResultId = (testGroupId, extremeId) =>
    `${testGroupId}:${extremeId ?? balancedExtreme}`;

  useEffect(() => {
    setActiveFilters(
      !isLoading &&
        selectedTestGroupAppliedFilters?.length < 2 &&
        !appliedExtremes[buildResultId(selectedTestGroup?.id)]
    );
  }, [isLoading, selectedTestGroupAppliedFilters, appliedExtremes, selectedTestGroup]);

  useEffect(() => {
    const extremes = {};
    appliedFilters.forEach((filter) => {
      if (filter.type === filterType) extremes[filter.id] = filter;
    });
    setAppliedExtremes(extremes);
    setQuantity(Object.values(extremes).length);
  }, [appliedFilters]);

  useEffect(() => {
    setFormattedTestGroups(
      testGroups.map((testGroup) => ({
        id: testGroup.id,
        name: testGroup.name,
        axes: testGroup.qualitativeConfig.map((axis) => ({
          id: axis.id,
          visible: !axis.extremes.some(
            (extreme) => appliedExtremes[buildResultId(testGroup.id, extreme.id)]
          ),
          extremes: axis.extremes.map((extreme) => ({
            ...extreme,
            id: buildResultId(testGroup.id, extreme.id),
          })),
        })),
      }))
    );
  }, [testGroups, appliedExtremes]);

  useEffect(() => {
    if (!selectedTestGroup && formattedTestGroups.length) {
      setSelectedTestGroup(formattedTestGroups[0]);
      setSelectedOption(formattedTestGroups[0]);
    }
    if (selectedTestGroup) {
      setSelectedTestGroup(
        formattedTestGroups.find((testGroup) => testGroup.id === selectedOption.id)
      );
    }
  }, [formattedTestGroups]);

  useEffect(() => {
    setSelectedTestGroup(
      formattedTestGroups.find((testGroup) => testGroup.id === selectedOption.id)
    );
  }, [selectedOption]);

  useEffect(() => {
    if (selectedTestGroup) {
      setSelectedTestGroupAppliedFilters(
        Object.values(appliedExtremes).filter(
          (extreme) => extreme.id.split(':')[0] === selectedTestGroup.id
        )
      );
    }
  }, [selectedTestGroup, appliedExtremes]);

  const addFilter = (extreme) => {
    if (activeFilters) addFilterObject({ id: extreme.id, type: filterType, name: extreme.name });
  };

  const clearFilters = () => {
    if (selectedTestGroupAppliedFilters.length && !isLoading)
      removeFilters(selectedTestGroupAppliedFilters.map((filter) => filter.id));
  };

  return (
    <div
      className="qualitative-filters-container overflow-auto"
      data-opened={show}
      ref={containerRef}
    >
      <div className="d-flex justify-content-between mb-3">
        <span className="font-weight-600 ">{t('QUALITATIVE_FILTERS_TITLE')}</span>
        <span className="cursor-pointer" onClick={() => setShow(false)} aria-hidden>
          <CrossIcon />
        </span>
      </div>
      <div className="mb-3">
        <span className="font-weight-500  text-xs">{t('QUALITATIVE_FILTERS_SUBTITLE')}</span>
      </div>
      <div className="mb-2">
        <DropdownSelectDot
          options={formattedTestGroups}
          setState={setSelectedOption}
          selected={selectedOption?.id}
          selectedPlaceholder
          className="qualitative-testgroup-dropdown pl-2"
          placeholderClassname="qualitative-testgroup-dropdown-placeholder text-xs font-weight-600"
          arrowLightBlue
        />
      </div>
      <div className="mb-4">
        <span className="font-weight-500  text-xs">{t('QUALITATIVE_FILTERS_OPTIONS_TEXT')}</span>
      </div>
      {Boolean(selectedTestGroupAppliedFilters.length) && (
        <div className="mb-4 d-flex flex-wrap">
          {selectedTestGroupAppliedFilters.map((filter) => (
            <div
              key={filter.id}
              data-testid={`QUALITATIVE_FILTER_APPLIED_${filter.id}`}
              className="qualitative-filters-applied d-flex align-items-center non-selectable mr-2 mb-2"
            >
              <span className="qualitative-filters-applied-text  text-xs font-weight-bold">
                {filter.name}
              </span>
              <div
                onClick={() => removeFilter(filter.id)}
                aria-hidden
                data-testid={`QUALITATIVE_FILTER_APPLIED_REMOVE_BTN_${filter.id}`}
                className="qualitative-filters-applied-cross cursor-pointer d-flex align-items-center"
              >
                <SmallCrossIcon />
              </div>
            </div>
          ))}
        </div>
      )}
      <div
        className="qualitative-filters-options-container mb-4 non-selectable"
        data-active={activeFilters}
      >
        {selectedTestGroup?.axes
          ?.filter((axis) => axis.visible)
          .map((axis) => (
            <div key={axis.id} className="my-3 d-flex justify-content-between">
              {axis.extremes.map((extreme) => (
                <div
                  key={extreme.id}
                  data-testid={`QUALITATIVE_FILTER_EXTREME_${extreme.id}`}
                  className="qualitative-filter-extreme cursor-pointer d-flex align-items-center"
                  onClick={() => addFilter(extreme)}
                  aria-hidden
                >
                  <div className="qualitative-filters-radio-button mr-2" />
                  <span className="font-weight-500  text-xs">{extreme.name}</span>
                </div>
              ))}
            </div>
          ))}
        {selectedTestGroup && !appliedExtremes[buildResultId(selectedTestGroup.id)] && (
          <div className="my-3 d-flex justify-content-between">
            <div
              className="qualitative-filter-balanced cursor-pointer d-flex align-items-center"
              data-active={!selectedTestGroupAppliedFilters?.length}
              onClick={() => {
                if (!selectedTestGroupAppliedFilters?.length)
                  addFilter({
                    id: buildResultId(selectedTestGroup.id),
                    name: t('QUALITATIVE_FILTERS_BALANCED_OPTION'),
                    type: filterType,
                  });
              }}
              aria-hidden
            >
              <div className="qualitative-filters-radio-button mr-2" />
              <span className="font-weight-500  text-xs">
                {t('QUALITATIVE_FILTERS_BALANCED_OPTION')}
              </span>
            </div>
          </div>
        )}
      </div>
      <button
        type="button"
        onClick={clearFilters}
        className="qualitative-filters-clear-button  text-xs font-weight-bold"
      >
        {t('QUALITATIVE_FILTERS_CLEAR')}
      </button>
    </div>
  );
}

export default QualitativeFilters;
