import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { intervalToDuration } from 'date-fns';
import { Modal } from 'antd';
import Arrow from '../../assets/screening/arrow-right.svg';
import Ticket from '../Ticket/Ticket';
import { t } from '../../i18n/i18n';
import { getScreenings } from '../../API/services/screeningService';
import { setConfirmed, setScreenings, setSelected } from '../../redux/actions/screeningActions';
import Spinner from '../spinner/Spinner';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import { minTwoDigits } from '../../utils/numbersUtils';
import './ScreeningList.scss';

const ticketClass = {
  FRONTEND: 'ticket-technology-frontend',
  FULLSTACK: 'ticket-technology-fullstack',
  DEFAULT: 'ticket-sprint-text',
};

const ScreeningList = ({ selected }) => {
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState('');
  const { texts } = useSelector((state) => state.auth.whitelabel);
  const screenings = useSelector((state) => state.screenings.screenings);
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState();

  const formatScreeningDescription = (description) =>
    `SCREENING_DESCRIPTION_${description?.split(' ')?.join('_')?.toUpperCase()}`;

  useEffect(() => {
    const fetchTechnologies = async () => {
      if (!screenings?.generalScreenings?.length) {
        setLoading(true);
        const fetchedScreenings = await getScreenings(user?.workspaces[0]?.workspaceId);
        dispatch(setScreenings(fetchedScreenings));
        setLoading(false);
      }
    };
    fetchTechnologies();
  }, []);

  const duration = (screening) =>
    intervalToDuration({
      start: 0,
      end: screening?.averageDuration * 60 * 1000,
    });

  return (
    <div className="bg-white rounded p-4">
      {(Boolean(screenings?.generalScreenings?.length) || loading) && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h2 className=" font-weight-bold my-3">{t('SCREENINGS_TITLE')}</h2>
          <h4 className=" font-weight-400 my-3">{t('SCREENINGS_SUBTITLE')}</h4>
        </div>
      )}
      <div className="row p-1 px-md-5">
        {loading ? (
          <Spinner show />
        ) : (
          <>
            <Modal
              visible={visibleModal}
              className="screening-modal m-auto"
              width="100%"
              title={selectedDetails}
              okText={t('CLOSE')}
              onOk={() => {
                setVisibleModal(false);
                setSelectedDetails('');
              }}
              okButtonProps={{
                className:
                  ' font-weight-bold bg-maya-blue px-4 py-2 rounded-pill border-0 d-flex align-items-center',
              }}
              cancelButtonProps={{
                hidden: true,
              }}
            >
              {t(formatScreeningDescription(selectedDetails))}
            </Modal>
            {screenings?.generalScreenings?.length ? (
              <>
                {screenings?.generalScreenings?.map((screening) => (
                  <div
                    className="col col-12 col-sm-6 col-md-4 col-lg-3 p-0 my-2 screening-card-container"
                    key={screening?.technology?.id}
                  >
                    <div className="bg-white p-3 screening-card card-3 mx-2 d-flex flex-column h-100 justify-content-between">
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row justify-content-between">
                          <Ticket
                            text={screening?.technology?.technologyType || 'Backend'}
                            className={`${
                              ticketClass[screening?.technology?.technologyType?.toUpperCase()] ??
                              ticketClass.DEFAULT
                            } ticket-padding font-weight-bold  text-uppercase px-1 mr-3`}
                          />
                          <span className="font-medium">🚀</span>
                        </div>
                        <span className=" font-weight-bold font-weight-bold text-screening-technology mt-3">
                          {screening?.technology?.displayName}
                        </span>
                        <span className=" font-weight-bold text-gray-screening">
                          {screening?.levelDescription}
                        </span>
                        <span className=" font-weight-bold text-gray-screening mt-3">
                          {t('SCREENING_AVERAGE_DURATION')}
                        </span>
                        <span className=" font-weight-bold text-gray-screening">
                          {`${minTwoDigits(duration(screening).hours)}:${minTwoDigits(
                            duration(screening).minutes
                          )}hs`}
                        </span>
                      </div>
                      <div
                        className={`mt-3 align-self-end w-100 d-flex ${
                          texts?.es[formatScreeningDescription(screening?.screeningsName)]
                            ? 'justify-content-between'
                            : 'justify-content-end'
                        }`}
                      >
                        {!!texts?.es[formatScreeningDescription(screening?.screeningsName)] && (
                          <span
                            onClick={() => {
                              setVisibleModal(true);
                              setSelectedDetails(screening?.screeningsName);
                            }}
                            onKeyPress={() => {}}
                            role="button"
                            tabIndex={0}
                            className=" text-xs d-flex pt-2 align-items-center align-self-start text-blue-principal font-weight-bold"
                          >
                            {t('SEE_MORE')}
                            <img src={Arrow} alt={t('ICON_ARROW')} />
                          </span>
                        )}
                        <button
                          className="button-apply-screening px-3 position-relative overflow-hidden"
                          type="button"
                          onClick={() =>
                            dispatch(
                              setSelected(
                                selected?.technologyId === screening?.technologyId &&
                                  selected?.levelId === screening?.levelId
                                  ? null
                                  : screening
                              )
                            )
                          }
                          data-selected={
                            selected?.technologyId === screening?.technologyId &&
                            selected?.levelId === screening?.levelId
                          }
                        >
                          <span className="button-apply-screening-text  font-weight-bold">
                            {selected?.technologyId === screening?.technologyId &&
                            selected?.levelId === screening?.levelId
                              ? t('SCREENING_SELECTED')
                              : t('SCREENING_SELECT')}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="my-4 mx-2 d-flex justify-content-end w-100">
                  <button
                    className="button-screening-continue px-4"
                    type="button"
                    onClick={() => dispatch(setConfirmed(true))}
                    data-active={Boolean(selected)}
                    disabled={!selected}
                  >
                    <span className="button-screening-continue-text  font-weight-bold">
                      {t('SCREENING_CONTINUE')}
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <EmptyComponent
                className="py-5 px-3"
                title={t('SCREENINGS_LIST_EMPTY_TITLE')}
                subtitle={t('SCREENINGS_LIST_EMPTY_SUBTITLE')}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ScreeningList;
