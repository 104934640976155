import { useState } from 'react';
import Arrow from '../../assets/modal/Arrow';
import Accordion from '../Accordion/Accordion';
import './Collapsed.scss';

const Collapsed = ({
  collapsedSelected,
  setCollapsedSelected,
  index,
  options,
  collapsedEnabled,
  containerClassname,
  titleClassnames,
  backgroundColor,
  maxHeight,
  onClick,
}) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const handleShow = (selectedIndex) => {
    if (collapsedSelected.includes(selectedIndex)) {
      setCollapsedSelected(collapsedSelected?.filter((selected) => selected !== selectedIndex));
    } else {
      setCollapsedSelected([...collapsedSelected, selectedIndex]);
    }
  };
  return (
    <div
      className={`position-relative bg-color-transition  ${
        containerClassname ?? 'description-container mt-3 mx-auto py-3 b-radius-8 px-4'
      } ${
        backgroundColor
          ? collapsedSelected.includes(index)
            ? backgroundColor
            : 'bg-white'
          : 'bg-white'
      }  `}
    >
      {collapsedEnabled && (
        <div
          className={`d-flex flex-row ${
            titleClassnames ?? 'justify-content-between  font-weight-600 text-base text-black'
          } cursor-pointer`}
          aria-hidden="true"
          onClick={() => {
            handleShow(index);
            onClick && firstLoad && onClick();
            if (firstLoad) setFirstLoad(false);
          }}
        >
          <span className="font-montserrat">{options?.title}</span>
          <Arrow
            className={`mt-1 ${
              collapsedSelected.includes(index)
                ? 'arrow-dropdown-modal-collapsed'
                : 'arrow-dropdown-modal-uncollapsed'
            }`}
          />
        </div>
      )}
      <Accordion
        open={!collapsedEnabled || collapsedSelected.includes(index)}
        maxHeight={maxHeight}
      >
        {options?.content}
      </Accordion>
    </div>
  );
};

export default Collapsed;
