import { useEffect, useState } from 'react';
import './QualitativeCollapsed.scss';
import DropdownSelect from '../../DropdownSelect/DropdownSelect';
import { t } from '../../../i18n/i18n';
import { getCandidateQualitativePdfExport } from '../../../API/services/alkymersService';
import HorizontalLoader from '../../HorizontalLoader/HorizontalLoader';
import CloudDownload from '../../../assets/dashboard/CloudDownload';

const QualitativeCollapsed = ({
  testgroups,
  recruitSessionId,
  profile,
  qualitativeResultType,
  setQualitativeResultType,
}) => {
  const [selectedTestgroup, setSelectedTestgroup] = useState(testgroups[0]);
  const finishedQualitative = Boolean(selectedTestgroup.testGroupQualitativeResults?.axisResults);
  const [reportLoading, setReportLoading] = useState(false);
  const calculateRangeWidth = (section) => {
    const availableWidth = 200;
    const { minPercent, maxPercent } = section;
    const differenceBetweenPercents = Math.abs(minPercent - maxPercent);

    return `${(differenceBetweenPercents * 100) / availableWidth}%`;
  };

  const calculatePointPosition = (bar) => {
    const barElement = document.getElementById(bar.id);
    const result = selectedTestgroup.testGroupQualitativeResults.axisResults.find(
      (result) => result.axisId === bar.id
    );
    if (barElement) {
      const totalSize = barElement.offsetWidth - 24;
      const parsedRatio = (result.ratio + 1) * 50;

      return (parsedRatio * totalSize) / 100;
    }
    return 0;
  };

  const handleSelectTestgroup = (tg) => {
    setSelectedTestgroup(testgroups.find((item) => item.id === tg.id));
  };
  const generateQualitativeReport = async () => {
    setReportLoading(true);
    getCandidateQualitativePdfExport(
      recruitSessionId,
      encodeURI(profile?.id),
      qualitativeResultType
    )
      .then((res) => {
        const blob = window.URL.createObjectURL(res);
        const fileLink = document.createElement('a');
        fileLink.href = blob;
        fileLink.setAttribute(
          'download',
          `${profile.firstName.toLowerCase()}-${profile.lastName.toLowerCase()}-qualitative.pdf`
        );
        fileLink.setAttribute('target', '_blank');
        document.body.appendChild(fileLink);
        fileLink.click();
        setReportLoading(false);
      })
      .catch(() => {
        setReportLoading(false);
      });
  };

  useEffect(() => {
    if (
      selectedTestgroup &&
      selectedTestgroup.completeData.qualitativeConfigResults &&
      selectedTestgroup.testGroupQualitativeResults?.axisResults
    ) {
      let result = getQualitativeType(selectedTestgroup.testGroupQualitativeResults.axisResults);
      setQualitativeResultType(result);
    }
  }, [selectedTestgroup]);

  const getQualitativeType = (axisResults) => {
    let qualitativeType = '';
    const resultValues = selectedTestgroup.completeData.qualitativeConfigResults.howToDoTypes;
    axisResults.forEach((axis, index) => {
      if (axis.resultId[2] === '1') {
        qualitativeType += resultValues[index][0];
      } else {
        qualitativeType += resultValues[index][1];
      }
    });
    return qualitativeType;
  };

  const getQualitativeResult = (sectionId, configId) => {
    return (
      sectionId ==
      selectedTestgroup.testGroupQualitativeResults.axisResults.find(
        (result) => result.axisId === configId
      )?.resultId
    );
  };

  return (
    <div data-testid="qualitative-collapsed-container" className="d-flex flex-column">
      {testgroups.length === 1 ? (
        <div className="d-flex justify-content-between py-2 ">
          <span
            data-testid="qualitative-collapsed-testgroup-name"
            className="my-2  text-muted text-base font-weight-600"
          >
            {selectedTestgroup.name}
          </span>
          {finishedQualitative && (
            <button
              type="button"
              onClick={generateQualitativeReport}
              className="maya-blue px-3 text-xs border-maya-blue cursor-pointer rounded-pill d-flex align-items-center font-weight-700  bg-white border-button"
            >
              {!reportLoading ? (
                <>
                  <span className="text-blue-principal  justify-items-center mr-2">
                    {t('DOWNLOAD_QUALITATIVE_REPORT')}
                  </span>
                  <CloudDownload fill="#4987BE" />
                </>
              ) : (
                <HorizontalLoader className="loader-report" />
              )}
            </button>
          )}
        </div>
      ) : (
        <div className="my-2">
          <DropdownSelect
            maxHeight={false}
            className="qualitative-testgroup-select b-radius-8 p-1 text-dark"
            setState={handleSelectTestgroup}
            selected={selectedTestgroup.id}
            selectedPlaceholder
            arrowPurple
            arrow={false}
            options={testgroups.map((tg) => ({ id: tg.id, name: tg.name }))}
            key={selectedTestgroup.id}
          />
        </div>
      )}
      {finishedQualitative ? (
        <div className="d-flex flex-column">
          {selectedTestgroup.completeData.qualitativeConfig.map((config) => (
            <div key={config.id} className="my-2">
              <div className="w-100 d-flex justify-content-between mb-2">
                {config.extremes.map((extreme, index) => (
                  <span
                    key={extreme.id}
                    data-testid={`qualitative-bar-${index}-extreme`}
                    className={` font-weight-600 text-base ${
                      selectedTestgroup.completeData.qualitativeConfigResults &&
                      getQualitativeResult(extreme.id, config.id)
                        ? 'qualitative-extreme-selected'
                        : 'text-light-black'
                    }`}
                  >
                    {extreme.name}
                  </span>
                ))}
              </div>
              <div id={`${config.id}`} className="qualitative-result-bar position-relative mb-3">
                {config.results.map((section, index) => {
                  const sectionWidth = calculateRangeWidth(section);
                  return (
                    <span
                      key={section.id}
                      data-testid="progress-bar-section"
                      style={{ width: sectionWidth }}
                      className={`qualitative-result-bar ${index === 0 && 'b-left-radius-8'} ${
                        index === config.results.length - 1 && 'b-right-radius-8'
                      } ${
                        index % 2
                          ? 'qualitative-result-bar-color-1'
                          : 'qualitative-result-bar-color-2'
                      }
                      ${
                        getQualitativeResult(section?.id, config?.id) &&
                        'qualitative-result-bar-color-3'
                      }
                      `}
                    />
                  );
                })}
                <span
                  style={{
                    left: `${calculatePointPosition(config)}px`,
                  }}
                  data-testid="qualitative-result-bar-point"
                  className="qualitative-result-point position-absolute"
                />
              </div>
              <div>
                <span
                  data-testid="qualitative-collapsed-result-description"
                  className=" font-weight-500 text-base text-light-black"
                >
                  {
                    config.results.find((result) => {
                      const axisData =
                        selectedTestgroup.testGroupQualitativeResults.axisResults.find(
                          (axis) => axis.axisId === config.id
                        );
                      return result.id === axisData.resultId;
                    }).text
                  }
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <span data-testid="quantitative-collapsed-empty-state" className=" text-xs font-weight-400">
          {t('QUALITATIVE_TEST_EMPTY_STATE')}
        </span>
      )}
    </div>
  );
};

export default QualitativeCollapsed;
