import OpenExternalLinkIcon from '../../../assets/testgroupABM/OpenExternalLinkIcon';
import { t } from '../../../i18n/i18n';
import { Window } from '../../../utils/window';
import './ModalTestImage.scss';

const ModalTestImage = ({ file }) => (
  <div data-testid="modal-test-image-container" className="d-flex align-items-start my-2">
    <img src={file.url} alt={file.name} className="modal-test-image" />
    <button
      type="button"
      onClick={() => Window().open(file.url, '_blank')}
      className="modal-test-image-open-button d-flex align-items-center text-xs  font-weight-600 ml-3"
      data-testid="modal-test-image-open-button"
    >
      <OpenExternalLinkIcon className="mr-1" />
      {t('UPLOAD_TEST_FILES_OPEN_IMAGE')}
    </button>
  </div>
);

export default ModalTestImage;
