import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setTextQuery } from '../../redux/actions/profilesActions';
import { t } from '../../i18n/i18n';
import search from '../../assets/profile/search.svg';
import smallArrow from '../../assets/header/arrow.svg';
import './ProfilesNavbar.scss';
import '../../index.scss';
import { SAVED_PROFILES, SAVED_SEARCHES } from '../../constants/routes';

const ProfilesNavbar = ({ alkymers, showFilters, handleSearch, searchInput }) => {
  const history = useHistory();
  const { textQuery, appliedFilters, ageQuery, engagementQuery } = useSelector(
    (state) => state.profiles
  );
  const dispatch = useDispatch();
  const parsedFiltersQuantity = appliedFilters?.filter(
    (item) => item.type !== 'search' || (item.type === 'search' && item.id.length)
  )?.length;
  function handleInput(e) {
    dispatch(setTextQuery(e.target.value));
  }

  const determineFilterQuantity = () => {
    let totalFilters = parsedFiltersQuantity;

    if (typeof ageQuery.min === 'number' && typeof ageQuery.max === 'number') {
      totalFilters += 1;
    }

    if (
      typeof engagementQuery.minEngagement === 'number' &&
      typeof engagementQuery.maxEngagement === 'number'
    ) {
      totalFilters += 1;
    }

    return totalFilters;
  };

  return (
    <div className="w-100">
      <div className="Navbar-container w-100 d-flex justify-content-space-between my-4 mt-lg-0 mb-lg-4">
        {searchInput && (
          <form
            onSubmit={handleSearch}
            className="Navbar-container-input w-100 d-none d-lg-flex align-items-center p-1"
          >
            <img src={search} alt="search icon" />
            <input
              className="border-0 input pl-2  input-search text-xs"
              onChange={handleInput}
              type="text"
              placeholder={t('DASHBOARD_INPUT_SEARCH')}
              value={textQuery}
            />
          </form>
        )}
        <div className="Navbar-container-options d-flex align-items-center w-50 justify-content-between justify-content-lg-start">
          <div className="Navbar-container-options-alkymers pl-4 border-left">
            <span className="text-purple  font-weight-500 text-xs mr-2">
              {t('DASHBOARD_FILTERS_APPLIED').replace('{count}', determineFilterQuantity())}
            </span>
            <span className=" gray-subtitle font-weight-500 text-xs">
              {`${alkymers} `}
              {alkymers === 1 ? t('PROFILE_COUNT_SINGULAR') : t('PROFILE_COUNT_PLURAL')}
            </span>
          </div>
          <div className="Navbar-container-options-filter d-lg-none mr-3">
            <button
              type="button"
              className="options-button border-0  font-weight-bold"
              onClick={showFilters}
            >
              {t('PROFILES_FILTER_FILTER')} <img src={smallArrow} alt={t('ICON_ARROW')} />
            </button>
          </div>
        </div>
        <div className="d-flex align-items-center w-50 justify-content-between justify-content-lg-end">
          <button
            className="d-flex align-items-center border-0 bg-transparent p-0 link-router"
            type="button"
            onClick={() => history.push(SAVED_SEARCHES)}
          >
            <div className="text-purple  font-weight-500 text-xs mr-2">{t('MY_FILTERS')}</div>
          </button>
          <button
            className="d-flex align-items-center border-0 bg-transparent p-0 link-router"
            type="button"
            onClick={() => history.push(SAVED_PROFILES)}
          >
            <div className="text-purple  font-weight-500 text-xs mr-2">{t('FAVORITES')}</div>
          </button>
        </div>
      </div>
      <form
        onSubmit={handleSearch}
        className="Navbar-container-input w-100 d-flex d-lg-none align-items-center p-1 mb-3"
      >
        <img src={search} alt="search icon" />
        <input
          className="border-0 input pl-1  input-search text-xs"
          onChange={handleInput}
          type="text"
          placeholder={t('DASHBOARD_INPUT_SEARCH')}
          value={textQuery}
        />
      </form>
    </div>
  );
};

export default ProfilesNavbar;
