import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import Spinner from '../spinner/Spinner';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import { t } from '../../i18n/i18n';
import { getCommunicationsAssociated } from '../../API/services/alkymersService';
import CheckIcon from '../../assets/modal/Check';
import FailedIcon from '../../assets/modal/Failed';
import './InvitedProfileCommunications.scss';
import Collapsed from '../Collapsed/Collapsed';
import { nanoid } from 'nanoid';

const InvitedProfileCommunications = ({ isInvitedProfile }) => {
  const [communications, setCommunications] = useState([]);
  const [loading, setLoading] = useState(true);
  const invitationId = useSelector(
    (state) => state?.profiles?.invitedProfile?.stages?.[0]?.invitation?.id
  );
  const { profileInvitationsAndChallenges } = useSelector((state) => state.profiles);
  const [otherCommunications, setOtherCommunications] = useState([]);
  useEffect(() => {
    if (isInvitedProfile) {
      setLoading(true);

      const getCommunicationsFn = () => getCommunicationsAssociated(invitationId);

      getCommunicationsFn().then((res) => {
        setCommunications(res);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchOtherCommunications = async () => {
      if (profileInvitationsAndChallenges?.invitations?.length) {
        setLoading(true);
        try {
          const allCommunications = await Promise.all(
            profileInvitationsAndChallenges.invitations.map(async (invitation) => {
              if (invitation.id !== invitationId) {
                const communications = await getCommunicationsAssociated(invitation.id);
                if (communications?.length > 0) {
                  return {
                    communications,
                    screeningName: invitation.screeningName,
                  };
                }
              }
              return null;
            })
          );
          const filteredCommunications = allCommunications.filter((item) => item !== null);
          setOtherCommunications(filteredCommunications);
        } catch (error) {
          console.error('Error fetching other communications:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOtherCommunications();
  }, [invitationId]);
  const conditionIcon = (condition) => (condition ? <CheckIcon /> : <FailedIcon />);
  const [collapsedSelected, setCollapsedSelected] = useState([]);
  return loading ? (
    <Spinner show />
  ) : (
    <div className="mt-4">
      {Boolean(!communications?.length) ? (
        <EmptyComponent
          className="p-4"
          title={t('INVITED_PROFILE_COMMUNICATIONS_EMPTY_STATE_TITLE')}
          subtitleClassname="text-center"
        />
      ) : (
        <>
          <CommunicationsColumn classNames="d-none d-sm-flex row no-gutters justify-content-center align-items-center text-xs px-4" />
          {Boolean(communications?.length) && (
            <div className="px-4">
              {communications?.map((communication, i) => (
                <CommunicationItem
                  communication={communication}
                  i={i}
                  conditionIcon={conditionIcon}
                />
              ))}
            </div>
          )}

          {Boolean(otherCommunications?.length) && (
            <div className="mt-4">
              {[
                {
                  title: t('COMMUNICATIONS_FROM_OTHER_EVALUATIONS'),
                  content: (
                    <div className="pb-4">
                      {otherCommunications.map((item, i) => (
                        <div>
                          <span className="font-weight-700 text-md color-blue-principal text-underline mb-2">
                            {item.screeningName}
                          </span>
                          <CommunicationsColumn classNames="d-none d-sm-flex row no-gutters justify-content-center align-items-center text-xs" />
                          {item.communications?.map((communication, j) => (
                            <CommunicationItem
                              communication={communication}
                              i={j}
                              conditionIcon={conditionIcon}
                            />
                          ))}
                        </div>
                      ))}
                    </div>
                  ),
                  isVisible: true,
                  collapsedEnabled: true,
                },
              ].map((item, index) => (
                <Collapsed
                  collapsedSelected={collapsedSelected}
                  setCollapsedSelected={setCollapsedSelected}
                  index={98}
                  options={item}
                  collapsedEnabled={item.collapsedEnabled}
                  containerClassname={'py-0 my-0 px-4'}
                  titleClassnames={
                    'justify-content-end align-items-center gap-1 font-weight-400 text-xs text-muted py-2 '
                  }
                  backgroundColor={'bg-blue-light'}
                  maxHeight="20rem"
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InvitedProfileCommunications;

const CommunicationItem = ({ communication, i, conditionIcon }) => (
  <div
    className="row no-gutters justify-content-center align-items-center font-weight-600 mt-2 p-2  text-xs communication-container"
    key={nanoid()}
  >
    <div className="col-12 col-sm-8">
      <div className="row no-gutters justify-content-center align-items-center ">
        <div className="col-12 col-sm-5">
          <span className="d-flex font-weight-600  text-xs">
            <span className="d-sm-none d-flex pr-2 ">
              {t('INVITED_PROFILE_COMMUNICATIONS_DATE')}:
            </span>
            {format(new Date(communication.sendDate), 'yyyy/MM/dd HH:mm').concat(' hs')}
          </span>
        </div>
        <div className="col-12 col-sm-7">
          <span className="d-flex font-weight-600  text-xs">
            <span className="d-sm-none d-flex pr-2">
              {t('INVITED_PROFILE_COMMUNICATIONS_SUBJECT')}:
            </span>
            {communication.subjectEmail}
          </span>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-4 mt-2 mt-sm-0">
      <div className="row no-gutters justify-content-center align-items-center text-xs text-center">
        <div className="col-5">
          <span className="d-sm-none d-block mb-1 ">
            {t('INVITED_PROFILE_COMMUNICATIONS_RECEIVED')}
          </span>
          {conditionIcon(communication.received)}
        </div>
        <div className="col-4">
          <span className="d-sm-none d-block mb-1">
            {t('INVITED_PROFILE_COMMUNICATIONS_OPENED')}
          </span>
          {conditionIcon(communication.opened)}
        </div>
        <div className="col-3">
          <span className="d-sm-none d-block mb-1">
            {t('INVITED_PROFILE_COMMUNICATIONS_CLICKED')}
          </span>
          {conditionIcon(communication.clicked)}
        </div>
      </div>
    </div>
  </div>
);

const CommunicationsColumn = ({ classNames }) => (
  <div className={classNames}>
    <div className="col-12 col-sm-8">
      <div className="row no-gutters justify-content-center align-items-center text-xs px-1">
        <div className="col-12 col-sm-5 font-weight-600  text-xs">
          {t('INVITED_PROFILE_COMMUNICATIONS_DATE')}
        </div>
        <div className="col-12 col-sm-7 font-weight-600  text-xs">
          {t('INVITED_PROFILE_COMMUNICATIONS_SUBJECT')}
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-4">
      <div className="row no-gutters justify-content-center align-items-center text-xs text-center">
        <div className="col col-sm-5 font-weight-600  text-xs">
          {t('INVITED_PROFILE_COMMUNICATIONS_RECEIVED')}
        </div>
        <div className="col col-sm-4 font-weight-600  text-xs">
          {t('INVITED_PROFILE_COMMUNICATIONS_OPENED')}
        </div>
        <div className="col col-sm-3 font-weight-600  text-xs">
          {t('INVITED_PROFILE_COMMUNICATIONS_CLICKED')}
        </div>
      </div>
    </div>
  </div>
);
