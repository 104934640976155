import { useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalizeString } from '../../utils/stringUtils';
import { t } from '../../i18n/i18n';
import Linkedin from '../../assets/dashboard/LinkedinIcon';
import './ScreeningInvitationsItem.scss';
import Checkbox from '../Checkbox/Checkbox';
import { oneDecimal } from '../../utils/numbersUtils';
import CountryFlag from '../CountryFlag/CountryFlag';
import ExternalLink from '../ExternalLink/ExternalLink';
import { setInvitationIdToScroll } from '../../redux/actions/screeningActions';
import DeleteIcon from '../../assets/profile/Delete';
import EnvelopeOutlinedIcon from '../../assets/dashboard/EnvelopeOutlinedIcon';
import UserCardOutlinedIcon from '../../assets/dashboard/UserCardOutlinedIcon';
import VerticalDotsDropdown from '../VerticalDotsDropdown/VerticalDotsDropdown';
import { SearchDashboardColumns, TestGroupTypes } from '../../constants/enums';
import DropdownSelectDot from '../DropdownSelectDot/DropdownSelectDot';
import { isNullOrUndefined } from '../../utils/typesUtils';
import ScienceIcon from '../../assets/sidebar/icons/ScienceIcon';
import moment from 'moment';

const TextWithOverlay = (text) => (
  <OverlayTrigger placement="top" overlay={<Tooltip>{text}</Tooltip>}>
    <>{text}</>
  </OverlayTrigger>
);

const ProfileColumnContent = (profileRoute, name, email) => (
  <div className="d-flex flex-column justify-content-start font-weight-500 text-truncate">
    <Link
      to={profileRoute}
      className="border-0 bg-transparent outline-none text-black font-weight-500 assessment-invitation-name "
    >
      <span className="text-xs text-truncate  text-black font-weight-500">
        {capitalizeString(name)}
      </span>
    </Link>
    <span className="text-xs text-truncate  text-black font-weight-500">{email}</span>
  </div>
);

const ScoreColumnContent = (percent = 0, isQualitative = false) => (
  <span
    className="font-weight-bold profiles-list-item-percent position-relative  d-flex justify-content-center align-items-center"
    data-color="yellow"
  >
    <span
      className={`profiles-list-item-percent-subitem 
      ${
        !percent
          ? 'no-score-bg'
          : percent < 75
          ? `mix-yellow-${Math.floor(percent)}`
          : `mix-green-${Math.floor(percent) - 75}`
      }`}
    />
    {percent === -1 ? '-' : isQualitative ? t('WITHOUT_SCORE') : oneDecimal(percent)}
  </span>
);

const TotalEventsColumnContent = ({ totalEvents = 0, dataTestId = '', isQualitative = false }) => (
  <span
    data-testid={dataTestId}
    className="font-weight-bold profiles-list-item-percent position-relative  d-flex justify-content-center align-items-center mx-1"
  >
    <span className="profiles-list-item-percent-subitem no-score-bg" />
    {totalEvents === undefined || totalEvents === null
      ? '-'
      : isQualitative
      ? t('WITHOUT_SCORE')
      : totalEvents}
  </span>
);

const CountryColumnContent = (countryCode) => (
  <div className="font-weight-bold d-flex justify-content-center align-items-center">
    <span className="d-flex align-items-center justify-content-end  text-black font-weight-600">
      {countryCode?.length ? countryCode : '-'}
    </span>
    {countryCode && <CountryFlag countryIso={countryCode} size="sm" className="ml-2" />}
  </div>
);

const CityColumnContent = (city) => (
  <span className="text-truncate  w-100  text-black font-weight-500">
    {city ? (city?.length > 5 ? TextWithOverlay(city) : city) : '-'}
  </span>
);

const ProcessStatusColumnContent = (
  handleProcessStatus,
  processStatus,
  id,
  processStatusOptions = [],
  score
) => (
  <div className="d-flex justify-content-center w-100 align-items-center">
    <DropdownSelectDot
      testid="process-status-dropdown-select"
      className=" text-12-px border-maya-blue w-100"
      placeholder={
        score
          ? t('SCREENING_INVITATIONS_PROCESS_STATUS_EVALUATED')
          : t('DASHBOARD_INVITATIONS_PROCESS_STATUS')
      }
      listSelect
      arrowLightBlue
      options={processStatusOptions}
      selectedPlaceholder
      selected={processStatus}
      setState={(e) => handleProcessStatus(e.id, id)}
      padding={false}
    />
  </div>
);

const CompletedColumnContent = (endDate) => (
  <span className="d-flex align-items-center text-nowrap justify-content-center  text-capitalize font-weight-500">
    {endDate ? format(new Date(endDate), 'dd/MM/yyyy') : '-'}
  </span>
);

const limitDateColumnContent = (limitDate, evaluationLimitDate, updateDateLimitInvitation, id) => {
  const handleOk = (e) => {
    const date = moment(e._d).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    updateDateLimitInvitation(date, id);
  };
  return (
    <DatePicker
      showTime
      defaultValue={limitDate ? moment(limitDate) : null}
      onOk={(e) => handleOk(e)}
      format="DD-MM-YYYY HH:mm"
      showSecond={false}
      disabledDate={(current) => {
        return current < moment().add(2, 'hours') || current >= moment(evaluationLimitDate);
      }}
      className="rounded calendar-invitations h-100"
    />
  );
};

const ScreeningStatusColumnContent = (
  startDate,
  endDate,
  score,
  limitDate,
  prefilterInstanceId,
  prefilterScore
) => {
  let status = t('SCREENINGS_INVITATIONS_INVITED');
  if (startDate) {
    if (endDate) {
      if (!isNullOrUndefined(score)) {
        status = t('SCREENINGS_INVITATIONS_FINISHED');
      } else {
        status = t('SCREENINGS_INVITATIONS_PROCESSING');
      }
    } else {
      status = t('SCREENINGS_INVITATIONS_IN_PROGRESS');
    }
  } else {
    if (limitDate && new Date() > new Date(limitDate)) {
      status = t('SCREENINGS_INVITATIONS_EXPIRED');
    } else {
      if (prefilterInstanceId !== null) {
        status =
          prefilterScore === null
            ? t('SCREENINGS_INVITATIONS_INVITED')
            : prefilterScore < 1 && prefilterScore >= 0
            ? t('SCREENINGS_INVITATIONS_PREFILTERED')
            : t('SCREENINGS_INVITATIONS_UNSTARTED');
      }
    }
  }

  return (
    <span className="d-flex align-items-center text-nowrap justify-content-center  mr-2 font-weight-500">
      {status}
    </span>
  );
};

const TestGroupResultColumnContent = ({ header, testGroups }) => {
  const result = testGroups.filter((tg) => tg.testgroupId === header.value);
  let score;

  if (result.length) {
    if (result[0].testgroupType === TestGroupTypes.QUALITATIVE) {
      score = result.map((result) => {
        const axis = header.qualitativeConfig.find((config) => config.id === result.axisId);
        return axis ? (
          <>
            <div className="d-flex flex-wrap mb-1">
              {TotalEventsColumnContent({
                totalEvents: axis.extremes.find((extreme) => extreme.id === result.extremeId)
                  .shortName,
              })}
            </div>
          </>
        ) : (
          <>{TotalEventsColumnContent({ totalEvents: t('QUALITATIVE_RESULTS_EQUILIBRATED') })}</>
        );
      });
    } else {
      score =
        result.length &&
        result.map((result) => (result?.score ? ScoreColumnContent(result?.score) : '-'));
    }
  } else {
    score = TotalEventsColumnContent({ totalEvents: t('WITHOUT_SCORE') });
  }

  return score;
};

const ScreeningInvitationsItem = ({
  className = '',
  recruitSessionId,
  handleProcessStatus,
  processStatusOptions,
  selected,
  allSelected,
  toggleSelect,
  disabled,
  handleSelectCandidate,
  deleteSelectCandidate,
  toggleVisibilitySelectCandidate,
  setKeepData,
  profile,
  headers,
  testGroups,
  qualitativeConfig,
  getPreviousOrderWidth,
  disabledVisualizator,
  toggleVisibilityInviteMembers,
  updateDateLimitInvitation,
  evaluationLimitDate,
  isQualitative,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const redirectToProfile = () => {
    setKeepData(true);
    dispatch(setInvitationIdToScroll(profile?.invitationId));
    history.push(
      profile?.userId
        ? `/search/${recruitSessionId}/profiles/${profile?.userId}`
        : `/screening/profiles?name=${encodeURIComponent(
            `${profile?.firstName} ${profile?.lastName}`
          )}&email=${encodeURIComponent(profile.email)}`
    );
  };

  const profileRoute = profile?.userId
    ? `/search/${recruitSessionId}/profiles/${profile?.userId}`
    : `/screening/profiles?name=${encodeURIComponent(
        `${profile?.firstName} ${profile?.lastName}`
      )}&email=${encodeURIComponent(profile.email)}`;

  const options = [
    {
      icon: <UserCardOutlinedIcon className="icon" />,
      disabled: false,
      text: t('SEARCH_TABLE_VIEW_PROFILE'),
      action: () => {
        setOpenDropdown(false);
        redirectToProfile();
      },
      itemClass: 'dropdown-option',
      testid: 'button-go-to-profile',
    },
  ];

  if (!disabledVisualizator) {
    options.push({
      icon: <EnvelopeOutlinedIcon className="icon" />,
      text: t('SEARCH_TABLE_SEND_MAIL'),
      disabled,
      action: () => {
        setOpenDropdown(false);
        handleSelectCandidate(profile?.invitationId);
      },
      itemClass: 'dropdown-option',
      testid: 'button-send-email',
    });
    options.push({
      icon: <FontAwesomeIcon icon={profile?.visible ? faEye : faEyeSlash} className="icon" />,
      disabled: false,
      visible: !disabledVisualizator,
      text: profile?.visible
        ? t('SEARCH_TOGGLE_VISIBILITY_PROFILE_OFF')
        : t('SEARCH_TOGGLE_VISIBILITY_PROFILE_ON'),
      action: () => {
        setOpenDropdown(false);
        toggleVisibilitySelectCandidate();
      },
      itemClass: 'dropdown-option',
      testid: 'button-toggle-candidate-individual',
    });
    options.push({
      icon: <ScienceIcon width="18" height="18" color="#4987BE" />,
      disabled: false,
      visible: !disabledVisualizator,
      text: t('INVITED_EVALUATION'),
      action: () => {
        setOpenDropdown(false);
        toggleVisibilityInviteMembers();
      },
      itemClass: 'dropdown-option',
      testid: 'button-toggle-candidate-individual',
    });
    options.push({
      icon: <DeleteIcon className="icon" />,
      text: t('SEARCH_TABLE_DELETE'),
      disabled: profile?.startDate || profile?.endDate,
      visible: !disabledVisualizator,
      action: () => {
        setOpenDropdown(false);
        deleteSelectCandidate();
      },
      itemClass: 'dropdown-delete-option',
      testid: 'button-delete-candidate-individual',
    });
  }
  const [openDropdown, setOpenDropdown] = useState(false);

  const rowContent = (header) => {
    let content = <></>;
    switch (header.key) {
      case SearchDashboardColumns.PROFILE:
        content = ProfileColumnContent(
          profileRoute,
          `${profile?.firstName} ${profile?.lastName}`,
          profile.email
        );
        break;
      case SearchDashboardColumns.SCORE:
        content = ScoreColumnContent(profile?.score ?? -1, isQualitative);
        break;
      case SearchDashboardColumns.TOTALEVENTS:
        content = TotalEventsColumnContent({ totalEvents: profile?.totalEvents, isQualitative });
        break;
      case SearchDashboardColumns.COUNTRY:
        content = CountryColumnContent(profile?.countryIsoCode);
        break;
      case SearchDashboardColumns.CITY:
        content = CityColumnContent(profile?.city);
        break;
      case SearchDashboardColumns.PROCESS_STATUS:
        content = ProcessStatusColumnContent(
          handleProcessStatus,
          profile?.processStatus,
          profile.invitationId,
          processStatusOptions,
          profile?.score
        );
        break;
      case SearchDashboardColumns.COMPLETE:
        content = CompletedColumnContent(profile?.endDate);
        break;
      case SearchDashboardColumns.SCREENINGS_STATUS:
        content = ScreeningStatusColumnContent(
          profile?.startDate,
          profile?.endDate,
          profile?.score,
          profile?.limitDate,
          profile?.prefilterInstanceId,
          profile?.prefilterScore
        );
        break;
      case SearchDashboardColumns.LIMIT_DATE:
        content = limitDateColumnContent(
          profile?.limitDate,
          evaluationLimitDate,
          updateDateLimitInvitation,
          profile?.invitationId
        );
        break;
      case SearchDashboardColumns.LIMIT_DATE:
        content = limitDateColumnContent(
          profile?.limitDate,
          evaluationLimitDate,
          updateDateLimitInvitation,
          profile?.invitationId
        );
        break;

      default:
        if (/TG_KEY_/.test(header.key)) {
          content = (
            <div className="d-flex flex-wrap">
              {TestGroupResultColumnContent({ header, testGroups, qualitativeConfig })}
            </div>
          );
        }
        break;
    }
    return content;
  };

  return (
    <div
      className={`border-headers d-flex flex-row align-items-center justify-content-start pl-2 ${className} screening-invitation-item-row ${
        profile?.visible ? '' : 'invisible-background'
      }`}
    >
      <div
        className={`d-flex py-3 flex-shrink-0 align-items-center ${
          headers.filter((item) => item.pinned).length > 0 ? 'pinned' : ''
        }`}
        style={{ width: `${profile?.visible ? '3rem' : '1rem'}` }}
      >
        <Checkbox
          className={`${profile?.visible ? 'pr-3' : 'pr-1'} search-dashboard-checkbox`}
          checked={(selected[profile?.invitationId] || allSelected) ?? false}
          onClick={() => toggleSelect(profile?.invitationId)}
        />
      </div>
      {profile?.visible ? (
        ''
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '1.25rem',
            marginTop: '0.3rem',
            marginLeft: '0.75rem',
          }}
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('NOT_VISIBLE_FOR_VISUALIZATORS')}</Tooltip>}
          >
            <button
              className="bg-transparent border-0 align-items-center"
              type="button"
              disabled={disabledVisualizator}
              onClick={() => {
                toggleVisibilitySelectCandidate();
              }}
            >
              <FontAwesomeIcon icon={faEyeSlash} />
            </button>
          </OverlayTrigger>
        </div>
      )}
      {headers.map((header) => (
        <div
          data-testid={`header-${header.key}`}
          key={header.key}
          className={`pl-3 flex-shrink-0 py-3 ${header.pinned ? 'pinned' : ''}`}
          style={{
            width: header.width ?? 'fit-content',
            left: header.pinned ? getPreviousOrderWidth(header.order) : 'unset',
          }}
        >
          <div className="w-100 d-flex align-items-center text-12-px"> {rowContent(header)} </div>
        </div>
      ))}
      <div
        className="d-flex py-3 flex-shrink-0 align-items-center position-relative pl-2"
        style={{ width: '3.5rem' }}
      >
        <ExternalLink
          role="button"
          tabIndex={0}
          to={profile?.linkedIn}
          disabled={!profile?.linkedIn}
        >
          <Linkedin className={`linkedin ${profile?.linkedIn ? 'withLink' : 'withoutLink'}`} />
        </ExternalLink>
        <VerticalDotsDropdown
          options={options}
          setOpenDropdown={setOpenDropdown}
          openDropdown={openDropdown}
        />
      </div>
    </div>
  );
};

export default ScreeningInvitationsItem;
