import React, { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getProfileName } from '../../API/services/alkymersService';
import { getUserChallengeCertificates } from '../../API/services/screeningService';

import { getPublicToken, getWhiteLabelByCompanyId } from '../../API/services/authService';
import Footer from '../../components/Certificates/Footer/Footer';
import Header from '../../components/Certificates/Header/Header';
import UserCertificates from '../../components/Certificates/UserCertificates/UserCertificates';
import Spinner from '../../components/spinner/Spinner';
import { setLangKey, t } from '../../i18n/i18n';
import { setWhitelabel } from '../../redux/actions/authActions';
import './Certificates.scss';

const CallToAction = () => (
  <div className="d-flex justify-content-center align-items-center my-4 flex-column flex-md-row">
    <span className=" font-weight-400 m-1">
      {t('CERTIFICATES_LABEL_OPTIMIZE_RECRUITMENT_PROCESS')}
    </span>
    <a
      href={`${process.env.RAZZLE_RUNTIME_ALKEMY_URL}/evaluaciones-en-linea/`}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="create-evaluation-open-search-btn"
      className="align-self-center px-5 py-2 rounded-pill  text-xs text-white font-weight-bold border-0 outline-none bg-maya-blue m-1"
    >
      {t('CERTIFICATES_LABEL_DISCOVER_HOW')}
    </a>
  </div>
);

const Certificates = () => {
  const { userId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [certificates, setCertificates] = useState(null);
  const [publicToken, setPublicToken] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingInitialData, setLoadingInitialData] = useState(true);

  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get('lang') ?? 'es';

  useEffect(() => {
    const fetchInitData = async () => {
      const [token, res] = await Promise.all([getPublicToken(), getWhiteLabelByCompanyId(1, lang)]);
      setPublicToken(token);
      dispatch(setWhitelabel(res));
      setLangKey(lang);
      setLoadingInitialData(false);
    };
    document.title = t('ALKEMY_CERTIFICATIONS_TITLE');
    fetchInitData();
  }, []);

  const fetchProfile = async (userId, publicToken) => {
    try {
      const profile = await getProfileName(userId, publicToken);
      if (!profile) throw new Error('User not found');
      setProfile(profile);
    } catch (error) {
      setProfile(null);
      throw error;
    }
  };

  useEffect(() => {
    const fetchCertificates = async (userId, publicToken) => {
      try {
        await fetchProfile(userId, publicToken);
        const certs = await getUserChallengeCertificates(userId, publicToken);
        setCertificates(certs);
        setLoading(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    if (userId && publicToken) fetchCertificates(userId, publicToken);
  }, [userId, publicToken]);

  return loadingInitialData ? (
    <Spinner show />
  ) : (
    <div className="d-flex flex-column justify-content-between">
      <Header />
      <div className="bg-gray py-4 px-2" style={{ minHeight: '75vh' }}>
        <div className="w-100 m-auto">
          <UserCertificates certificates={certificates} profile={profile} loading={loading} />
          <CallToAction />
        </div>
      </div>
      <Footer />
    </div>
  );
};

Certificates.propTypes = {};

export default Certificates;
