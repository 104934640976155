import Modal from '../../Modal/Modal';
import { t } from '../../../i18n/i18n';

export const DeleteRolesTestGroupModal = ({
  showModal,
  setShowModal,
  submitAction,
  disabledButtonProps = false,
  roleToDelete,
}) => {
  const onClickButton = () => {
    submitAction();
  };
  return (
    <Modal
      data-testid="card-screening-search-modal"
      className="modal-buttons-border d-flex justify-content-end"
      showCloseButton={false}
      show={showModal}
      minWidth={false}
      setShow={(e) => {
        setShowModal(e);
      }}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <div className="px-5 mx-5">
        <div className="py-5 d-flex flex-column align-items-center w-100 position-relative">
          <span className="font-weight-600  text-base mb-2">{t('ROL_DELETE_MODAL_TITLE')}</span>
          <span className="font-weight-200  text-base mb-2">
            {t('ROL_DELETE_MODAL_QUESTION')?.replace('{{name}}', roleToDelete?.displayName)}
          </span>

          <div>
            <button
              type="button"
              className="mr-2 px-5 py-3 modal-buttons-border  mt-5 border-0 text-white  outline-none text-xs font-weight-700 ml-2"
              style={{ backgroundColor: '#449cc8' }}
              onClick={() => {
                setShowModal(false);
              }}
            >
              {t('CONFIRM_MODAL_CANCEL')}
            </button>
            <button
              type="button"
              className="p-3 px-5 hover-danger modal-buttons-border bg-transparent mt-5 dont-delete-draft-button-border border-red-danger text-red-danger  outline-none text-xs font-weight-700 mr-2"
              onClick={onClickButton}
            >
              {t('DELETE_MODAL_DELETE')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
