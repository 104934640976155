import ArrowSmall from '../../assets/screening/ArrowSmall';
import PinIcon from '../../assets/screening/PinIcon';
import Checkbox from '../Checkbox/Checkbox';
import './PersonalizeColumnItem.scss';
import { t } from '../../i18n/i18n';

const PersonalizeColumnItem = ({
  columnItem,
  handleColumnCheck,
  handleOrder,
  handlePin,
  disableOrderDown,
  disableOrderUp,
  allowPin,
  disableCheckbox,
  orderKey = 'order',
}) => (
  <div
    key={columnItem.key}
    data-testid={`${columnItem.key}-personalize-column-item`}
    className="personalize-column-item d-flex flex-row align-items-center justify-content-start"
  >
    <div className="flex-grow-1 d-flex flex-row align-items-center justify-content-start">
      <Checkbox
        key={`${columnItem.key}-${JSON.stringify(columnItem.checked)}`}
        onClick={() => handleColumnCheck(columnItem.key)}
        defaultChecked={columnItem.checked}
        checked={columnItem.checked}
        testid={`${columnItem.key}-select-column-checkbox`}
        className="responsive-checkbox"
        data={{ disabled: disableCheckbox }}
      />
      <label className=" text-left font-weight-500 text-xs pl-3 mb-0">{t(columnItem.name)}</label>
    </div>
    <div className="flex-grow-0  flex-shrink-0" style={{ width: '10rem' }}>
      {columnItem.checked && (allowPin || columnItem.pinned) && (
        <button
          type="button"
          data-testid={`${columnItem.key}-pin-column-btn`}
          className={`bg-transparent outline-none border-0  text-xs text-nowrap ${
            columnItem.pinned ? 'text-blue-principal font-weight-700' : 'text-muted'
          }`}
          onClick={() => handlePin(columnItem.key)}
        >
          <PinIcon fill={columnItem.pinned ? '#4987be' : '#5F5F5F'} classNames="mr-1" />
          {columnItem.pinned
            ? t('PERSONALIZE_COLUMNS_COLUMN_FIXED')
            : t('PERSONALIZE_COLUMNS_FIX_COLUMN')}
        </button>
      )}
    </div>
    <div className="flex-grow-0 flex-shrink-0">
      <button
        disabled={disableOrderDown}
        data-testid={`${columnItem.key}-order-down-btn`}
        className="bg-transparent border-0 outline-none mr-1"
        type="button"
        onClick={() => handleOrder(columnItem[orderKey], columnItem[orderKey] + 1)}
      >
        <ArrowSmall color={disableOrderDown ? '#828282' : '#4987be'} />
      </button>
      <button
        disabled={disableOrderUp}
        data-testid={`${columnItem.key}-order-up-btn`}
        className="bg-transparent border-0 outline-none ml-1"
        type="button"
        onClick={() => handleOrder(columnItem[orderKey], columnItem[orderKey] - 1)}
      >
        <ArrowSmall classNames="rotate-180" color={disableOrderUp ? '#828282' : '#4987be'} />
      </button>
    </div>
  </div>
);

export default PersonalizeColumnItem;
