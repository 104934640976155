import React from 'react';

const InputOutputArrow = ({ fill, height, width }) => (
  <svg width={width ?? '100'} height={height ?? '85'} viewBox="0 0 85 100" fill="none">
    <path
      d="M41.595,49.555c-3.225-4.386-6.492-9.232-9.71-14.404c-0.119-0.192-0.32-0.32-0.545-0.348l-10.646-1.316  c-0.279-0.035-0.559,0.093-0.717,0.329c-0.157,0.237-0.167,0.542-0.025,0.789c3.15,5.488,6.341,10.664,9.491,15.394  c-3.149,4.73-6.341,9.907-9.491,15.395c-0.142,0.247-0.132,0.552,0.025,0.789c0.141,0.211,0.376,0.335,0.625,0.335  c0.03,0,0.062-0.002,0.092-0.006l10.646-1.316c0.225-0.027,0.426-0.156,0.545-0.348c3.218-5.171,6.484-10.018,9.71-14.404  C41.79,50.179,41.79,49.819,41.595,49.555z M30.801,63.751l-8.797,1.087c2.981-5.136,5.992-9.982,8.963-14.422  c0.169-0.252,0.169-0.582,0-0.834c-2.971-4.44-5.981-9.286-8.963-14.422l8.797,1.087c3.069,4.917,6.182,9.54,9.26,13.752  C36.982,54.212,33.87,58.834,30.801,63.751z M60.753,50.499c-3.216,3.591-6.478,7.636-9.695,12.021  c-0.122,0.167-0.308,0.276-0.513,0.301l-10.646,1.316c-0.03,0.004-0.062,0.006-0.092,0.006c-0.258,0-0.501-0.133-0.639-0.356  c-0.153-0.249-0.148-0.566,0.015-0.81c3.12-4.675,6.282-9.039,9.406-12.978c-3.123-3.939-6.286-8.302-9.406-12.978  c-0.163-0.244-0.168-0.56-0.015-0.81s0.438-0.391,0.73-0.351l10.646,1.316c0.205,0.025,0.391,0.134,0.513,0.301  c3.216,4.386,6.479,8.431,9.695,12.021C61.007,49.783,61.007,50.214,60.753,50.499z M80.148,49.999c0-0.217-0.094-0.423-0.257-0.565  c-3.208-2.799-6.463-6.042-9.675-9.639c-0.121-0.136-0.287-0.223-0.468-0.245l-10.647-1.316c-0.299-0.036-0.597,0.112-0.746,0.378  s-0.122,0.595,0.067,0.833c3.078,3.861,6.195,7.408,9.276,10.553c-3.081,3.146-6.199,6.693-9.276,10.553  c-0.189,0.238-0.217,0.568-0.067,0.833c0.134,0.239,0.385,0.384,0.654,0.384c0.03,0,0.062-0.002,0.092-0.006l10.647-1.316  c0.181-0.022,0.347-0.109,0.468-0.245c3.211-3.596,6.466-6.839,9.675-9.639C80.054,50.422,80.148,50.216,80.148,49.999z   M69.286,58.994l-8.529,1.054c2.839-3.476,5.701-6.672,8.527-9.521c0.29-0.292,0.29-0.764,0-1.057  c-2.825-2.847-5.688-6.044-8.527-9.52l8.529,1.054c2.979,3.321,5.994,6.342,8.978,8.994C75.28,52.652,72.263,55.673,69.286,58.994z"
      fill={fill ?? '#4987be'}
    />
  </svg>
);

export default InputOutputArrow;
