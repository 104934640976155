import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import closeIcon from '../../assets/header/Close';
import logoutIcon from '../../assets/header/LogoutIcon';
import userIcon from '../../assets/sidebar/icons/UserWhite';
import SideBarDropDown from '../SideBarDropDown/SideBarDropDown';
import { t } from '../../i18n/i18n';
import useRouteSelected from '../../hooks/useRouteSelected';
import useSidebar from '../../hooks/useSidebar';
import sidebarRoutes from '../../constants/sidebarRoutes';
import {
  CREATE_ABM_TESTGROUP_SCREENING,
  HOME,
  PROFILE,
  RoutesKeys,
  WORKSPACE,
} from '../../constants/routes';
import chartIcon from '../../assets/sidebar/icons/Chart';
import homeIcon from '../../assets/sidebar/icons/Home';
import WhiteLabelShow from '../../whiteLabel/WhiteLabelShow';
import './menuResponsive.scss';
import { Behaviors } from '../../whiteLabel/enums';
import useWhiteLabelSidebar from '../../whiteLabel/useWhiteLabelSidebar';
import useRoles from '../../hooks/useRoles';
import { Window } from '../../utils/window';
import { auth0Client } from '../../auth0/auth0';
import GenericToggle from '../GenericToggle/GenericToggle';
import useEnvironment from '../../hooks/useEnvironment';
import WorkspaceIcon from '../../assets/sidebar/icons/WorkspaceIcon';
import WorkspaceDropdown from '../WorkspaceDropdown/WorkspaceDropdown';

const ShowRouteForWorkspaceRole = ({ roles = [], currentRole, children }) => {
  if (roles.includes(currentRole) || roles.length === 0) {
    return children;
  }
  return null;
};

const MenuResponsive = () => {
  const { selected } = useRouteSelected(sidebarRoutes);
  const profile = useSelector((state) => state.auth.user);
  const { currentRole } = useSelector((state) => state.auth);
  const [createTestGroupCondition, setCreateTestGroupCondition] = useState(true);
  const { routes } = useWhiteLabelSidebar();
  const { collapsed, setCollapsed } = useSidebar();
  const { roles } = useRoles();
  const history = useHistory();

  useEffect(() => {
    if (Window()) {
      setCreateTestGroupCondition(
        JSON.parse(localStorage.getItem('roles'))?.includes(roles.TESTGROUPCREATOR)
      );
    }
  }, [profile.user]);

  const { toggleEnvironment, environmentLoading } = useEnvironment();

  const filterSubroutes = (subRoutes) => {
    const filteredRoutes = subRoutes.filter((r) => {
      if (r.workspaceRoles) {
        return r.workspaceRoles.includes(currentRole);
      }
      return true;
    });
    return filteredRoutes;
  };

  return (
    <div
      className={`position-fixed d-lg-none h-100 dropdown-menu-responsive py-4 top-0 right-0 ${
        !collapsed ? 'dropdown-active' : ''
      }`}
    >
      <div className="d-flex justify-content-end pr-4">
        <button
          className="bg-transparent border-0 px-2"
          type="button"
          onClick={() => setCollapsed(!collapsed)}
        >
          {closeIcon}
        </button>
      </div>
      {profile?.hasCompanyGroups && (
        <WhiteLabelShow objectKey={Behaviors.SWITCH_ENVIRONMENT}>
          <GenericToggle
            className="d-md-none w-100 mt-4 px-2 pl-4"
            rightTextClassname=""
            leftTextClassName=""
            defaultActive={profile.environment === 'MY_COMPANY'}
            loading={environmentLoading}
            actionFunction={toggleEnvironment}
            leftText={t('HEADER_GENERAL_ENV')}
            rightText={`${t('HEADER_TRAINING_ENV')} ${profile?.company?.displayName ?? ''}`}
          />
        </WhiteLabelShow>
      )}
      <ul className="nav-links list-unstyled overflow-auto h-100 mt-3 responsive-sidebar-list-container">
        <li>
          <Link
            className="dropdown-link d-flex align-items-center bg-transparent border-0"
            to={PROFILE}
          >
            <SideBarDropDown
              icon={userIcon}
              imgClassName="ml-4"
              buttonText="MENU_ITEM_PROFILE"
              isMobile
            />
          </Link>
        </li>
        <li
          className={`my-2 ${!collapsed && 'overflow-hidden'}`}
          aria-hidden="true"
          key={RoutesKeys.WORKSPACE}
          data-testid="li-sidebar-workspace"
        >
          <Link
            className="dropdown-link d-flex align-items-center bg-transparent border-0"
            to={WORKSPACE}
          >
            <SideBarDropDown
              icon={WorkspaceIcon}
              imgWidth="35"
              imgClassName="ml-2 pl-2"
              collapsed={collapsed}
              selected={history.location.pathname === WORKSPACE}
              buttonText="WORKSPACE_PAGE_BANNER"
            />
          </Link>
        </li>
        <li
          data-testid="li-sidebar-workspace-dropdown"
          aria-hidden="true"
          key={`${RoutesKeys.WORKSPACE}-dropdown`}
        >
          <WorkspaceDropdown collapsed={collapsed} setCollapsed={setCollapsed} />
        </li>
        <li
          className={`my-2 ${!collapsed && 'overflow-hidden'}`}
          aria-hidden="true"
          key={RoutesKeys.HOME}
        >
          <Link
            className="dropdown-link d-flex align-items-center bg-transparent border-0"
            to={HOME}
          >
            <SideBarDropDown
              icon={homeIcon}
              imgWidth="35"
              imgClassName="ml-3"
              imgPadding="pr-0"
              collapsed={collapsed}
              selected={history.location.pathname === HOME}
              buttonText="HOME_PAGE_BANNER"
              isMobile
            />
          </Link>
        </li>
        {!!routes?.length &&
          routes
            .filter((item) => !item.hide)
            .map((route) => (
              <li
                className={`my-2 ${!collapsed && 'overflow-hidden'}`}
                aria-hidden="true"
                key={route.key}
              >
                {route?.path ? (
                  <ShowRouteForWorkspaceRole
                    currentRole={currentRole}
                    roles={route?.workspaceRoles}
                  >
                    <Link to={route?.path} className="m-0">
                      <SideBarDropDown
                        icon={route?.icon}
                        buttonText={route.label}
                        collapsed={collapsed}
                        selected={selected === route.key}
                        imgClassName="ml-3 pl-1"
                        options={filterSubroutes(route?.subRoutes ?? [])}
                        isMobile
                      />
                    </Link>
                  </ShowRouteForWorkspaceRole>
                ) : (
                  <ShowRouteForWorkspaceRole
                    currentRole={currentRole}
                    roles={route?.workspaceRoles}
                  >
                    <SideBarDropDown
                      icon={route?.icon}
                      buttonText={route.label}
                      collapsed={collapsed}
                      selected={selected === route.key}
                      imgClassName="ml-3 pl-1"
                      options={filterSubroutes(route?.subRoutes ?? [])}
                      isMobile
                    />
                  </ShowRouteForWorkspaceRole>
                )}
              </li>
            ))}
        {createTestGroupCondition && (
          <li
            className={`my-2 ${!collapsed && 'overflow-hidden'}`}
            aria-hidden="true"
            key={RoutesKeys.CREATE_ABM_TESTGROUP_SCREENING}
          >
            <Link
              className="dropdown-link d-flex align-items-center bg-transparent border-0"
              to={CREATE_ABM_TESTGROUP_SCREENING}
            >
              <SideBarDropDown
                icon={chartIcon}
                imgClassName="ml-3 pl-1"
                selected={history.location.pathname === CREATE_ABM_TESTGROUP_SCREENING}
                buttonText="MENU_ITEM_CREATE_TEST_GROUP"
                isMobile
              />
            </Link>
          </li>
        )}
        <li>
          <button
            className="dropdown-link d-flex align-items-center bg-transparent border-0"
            onClick={() => {
              localStorage.clear();
              auth0Client().logout({ returnTo: window.location.origin });
            }}
            type="button"
          >
            <SideBarDropDown
              icon={logoutIcon}
              imgClassName="ml-3 pl-1"
              buttonText="BUTTON_LOGOUT"
              isMobile
            />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default MenuResponsive;
