import { t } from '../../../i18n/i18n';
import FailedIcon from '../../../assets/modal/Failed';
import CheckIcon from '../../../assets/modal/Check';

const ResultIcon = ({ submission }) =>
  submission?.submittedCode?.length >= 1 && submission.ratio > 0 ? <CheckIcon /> : <FailedIcon />;

const OptionCode = ({ submission, showResult }) => (
  <>
    <span className=" font-weight-600 text-xs text-color-item-test-group mt-2">
      {t('MODAL_ANSWER')}
    </span>
    <div className="d-flex flex-row align-items-top justify-content-space-between w-100">
      {submission?.submittedCode?.length ? (
        <pre className="w-100 mt-2 invited-dot-bg">{submission?.submittedCode}</pre>
      ) : (
        <pre className="w-100 mt-2 invited-dot-bg">{t('EMPTY_CODE_RESPONSE')}</pre>
      )}
      {showResult && (
        <div className="ml-2 mt-1">
          <ResultIcon submission={submission} />
        </div>
      )}
    </div>
  </>
);

export default OptionCode;
