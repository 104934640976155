const CameraLogo = () => (
  <svg width="24" height="24" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5556 5.11816V1.3265C15.5556 0.730664 15.0556 0.243164 14.4444 0.243164H1.11111C0.5 0.243164 0 0.730664 0 1.3265V12.1598C0 12.7557 0.5 13.2432 1.11111 13.2432H14.4444C15.0556 13.2432 15.5556 12.7557 15.5556 12.1598V8.36816L20 12.7015V0.784831L15.5556 5.11816Z"
      fill="#4987BE"
    />
  </svg>
);

export default CameraLogo;
