import DeleteForever from '../../assets/screening/DeleteForever';
import { t } from '../../i18n/i18n';
import PinIcon from '../../assets/screening/PinIcon';
import CheckboxButton from '../CheckboxButton/CheckboxButton';
import UploadTestFiles from '../UploadTestFiles/UploadTestFiles';
import { TestFileType } from '../../constants/enums';

const RowAnswer = ({
  onRemove,
  correct,
  text,
  formula,
  onChange,
  weight,
  pinnedIndex,
  eachScore,
  testGroupABM,
  index,
  files = [],
  addFile,
  removeFile,
  updateFile,
  withFormula = false,
}) => (
  <>
    <div
      className="row ml-4 mt-4 d-flex flex-nowrap align-items-center gap-2 justify-content-center"
      data-testid="row-answer"
    >
      <textarea
        data-testid="row-answer-text-area"
        className="w-75 py-2 px-3  bottom-border outline-none text-sm font-weight-500  max-height-text-area justify-content-center text-center"
        rows={testGroupABM ? '2' : '1'}
        type="text"
        onChange={(e) => onChange('text', e.target.value)}
        value={text}
        placeholder={`${t('CREATE_QUESTION_MULTIPLE_CHOICE_ADD_ANSWER')} ${index + 1}`}
        maxLength={5000}
      />
      {testGroupABM && withFormula && (
        <textarea
          data-testid="row-answer-text-area"
          className="w-100 py-2 px-3  border-grey-2 outline-none text-xs min-height-text-area max-height-text-area ml-2"
          rows="2"
          type="text"
          onChange={(e) => onChange('formula', e.target.value)}
          value={formula}
          placeholder={t('CREATE_FORMULA_MULTIPLE_CHOICE_ADD_ANSWER')}
          maxLength={500}
        />
      )}
      {eachScore ? (
        <input
          data-testid="row-answer-each-score-input"
          className="border-bottom text-sm px-1 font-weight-500  mr-2 max-char-input outline-none weight-input"
          type="number"
          min={-10}
          max={10}
          value={weight ?? ''}
          name="weight"
          onKeyDown={(e) => {
            const includeKeys = ['Backspace', '-'];
            const excludedKeys = ['e', '+', '.'];
            if (
              excludedKeys.includes(e.key) ||
              (Number.isNaN(Number(e.key)) && !includeKeys.includes(e.key))
            ) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            onChange('weight', e.target.value);
          }}
        />
      ) : (
        <CheckboxButton
          value={correct}
          className="ml-3 size-checkbox"
          checked={correct}
          onChange={() => onChange('correct', !correct)}
        />
      )}
      <button
        type="button"
        data-testid="row-answer-delete-question-button"
        onClick={onRemove}
        className="bg-transparent border-0 outline-none ml-sm-1"
      >
        <DeleteForever fill="#4987be" />
      </button>
      {testGroupABM && (
        <button
          type="button"
          className={`bg-transparent outline-none border-0 text-xs text-nowrap self-center ${
            typeof pinnedIndex === 'number' ? 'text-black' : 'text-muted'
          }`}
          onClick={() => {
            onChange('pinnedIndex', typeof pinnedIndex === 'number' ? undefined : index);
          }}
        >
          <PinIcon
            fill={typeof pinnedIndex === 'number' ? '#41479B' : '#5F5F5F'}
            classNames="mr-1"
          />
          {typeof pinnedIndex === 'number'
            ? t('CREATE_TEST_GROUP_PINNED_ANSWER')
            : t('CREATE_TEST_GROUP_UNPINNED_ANSWER')}
        </button>
      )}
    </div>
    {testGroupABM && (
      <div className="mt-1 ml-1">
        <UploadTestFiles
          fileTypes={[TestFileType.IMAGE]}
          maxFiles={1}
          handleAddFile={(...params) => addFile(index, ...params)}
          handleRemoveFile={(...params) => removeFile(index, ...params)}
          handleUpdateFile={(...params) => updateFile(index, ...params)}
          testFiles={files}
          id={`answer-${index}`}
        />
      </div>
    )}
  </>
);

export default RowAnswer;
