import { useSelector } from 'react-redux';
import { Button as BootstrapButton } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { t } from '../../i18n/i18n';

const Button = ({
  text,
  variant = 'primary',
  type = 'submit',
  onClick,
  className = '',
  size = 'md',
  redirect,
  goBack,
  load = true,
  block = false,
  disabled = false,
  style = null,
  customFont = '',
  dataTestId = null,
}) => {
  const { isLoading } = useSelector((state) => state.buttons);
  const history = useHistory();

  const handleClick = () => {
    if (redirect) {
      history.push(redirect);
    } else if (goBack) {
      history.goBack();
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <BootstrapButton
      data-testid={dataTestId}
      variant={variant}
      type={type}
      disabled={isLoading || disabled}
      className={`${customFont} ${className}`}
      size={size}
      block={block}
      style={style}
      {...(redirect || onClick || goBack ? { onClick: handleClick } : {})}
    >
      {isLoading && load ? t('CUSTOM_BUTTON_TEXT_LOADING') : text}
    </BootstrapButton>
  );
};

export default Button;
