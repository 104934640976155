import React from 'react';

const MailIcon = ({ selected, className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.334 0.166504H1.66732C0.933984 0.166504 0.340651 0.766504 0.340651 1.49984L0.333984 9.49984C0.333984 10.2332 0.933984 10.8332 1.66732 10.8332H12.334C13.0673 10.8332 13.6673 10.2332 13.6673 9.49984V1.49984C13.6673 0.766504 13.0673 0.166504 12.334 0.166504ZM12.334 2.83317L7.00065 6.1665L1.66732 2.83317V1.49984L7.00065 4.83317L12.334 1.49984V2.83317Z"
        fill={`${selected ? '#4987be' : '#BDBDBD'}`}
      />
    </svg>
  );
};

export default MailIcon;
