import React from 'react';
import { Link } from 'react-router-dom';
import logoWhite from '../../../assets/header/logo.white.svg';
import { HOME } from '../../../constants/routes';

import facebook from '../../../assets/socialMedia/facebook.svg';
import instagram from '../../../assets/socialMedia/instagram.svg';
import linkedin from '../../../assets/socialMedia/linkedin.svg';
import twich from '../../../assets/socialMedia/twich.svg';
import x from '../../../assets/socialMedia/x.svg';
import youtube from '../../../assets/socialMedia/youtube.svg';
import { t } from '../../../i18n/i18n';

const NAV_OPTIONS = [
  {
    title: t('ALKEMY_LINK_COMPANIES'),
    link: process.env.RAZZLE_RUNTIME_ALKEMY_URL + '/alkemy-para-empresas/',
  },
  {
    title: t('ALKEMY_LINK_TALENT'),
    link: process.env.RAZZLE_RUNTIME_ALKEMY_URL + '/talento-para-empresas/',
  },
  {
    title: t('ALKEMY_LINK_BLOG'),
    link: process.env.RAZZLE_RUNTIME_ALKEMY_URL + '/blog/',
  },
  {
    title: t('ALKEMY_LINK_FAQ'),
    link: process.env.RAZZLE_RUNTIME_ALKEMY_URL + '/preguntas-frecuentes/',
  },
  {
    title: t('ALKEMY_LINK_CONTACT'),
    link: process.env.RAZZLE_RUNTIME_ALKEMY_URL + '/contacto/',
  },
];

const SOCIAL_NETWORKS = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/AlkemyLATAM',
    logo: facebook,
  },
  {
    name: 'X',
    url: 'https://x.com/alkemy_?t=4_EFg4h_k8-5BszmL-ul1Q&s=08',
    logo: x,
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/alkemy__?igsh=bGhsY3dmcTJmcjI4',
    logo: instagram,
  },
  {
    name: 'YouTube',
    url: 'https://youtube.com/@alkemy__?si=d8PQhrOLxUZfpYya',
    logo: youtube,
  },
  {
    name: 'Twitch',
    url: 'https://www.twitch.tv/alkemylive/',
    logo: twich,
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/company/alkemy2020',
    logo: linkedin,
  },
];
const SocialIcon = (x, index) => (
  <div data-testid={'social-media-logo-' + x.name} key={x.name + index} className="col-4">
    <a key={x?.logo} href={x?.url} target="_blank" rel="noopener noreferrer">
      <img
        src={x?.logo}
        className="d-block cursor-pointer"
        style={{
          height: '2.5em',
          width: '2.5em',
        }}
      ></img>
    </a>
  </div>
);
const Footer = () => (
  <div
    className="d-flex flex-column align-items-center justify-content-between flex-wrap py-3 px-5"
    style={{ backgroundColor: 'black' }}
  >
    <div className="w-100 d-flex align-items-center justify-content-between my-4 flex-lg-row flex-xl-row flex-md-column flex-sm-column flex-xs-column flex-column">
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        <Link to={HOME}>
          <img
            src={logoWhite}
            className="logo mr-4"
            alt={t('HEADER_LOGO')}
            style={{ height: 'auto', width: '12em' }}
          />
        </Link>
        <nav className="d-flex align-items-center justify-content-center flex-wrap m-2">
          {NAV_OPTIONS.map((opt, index) => (
            <a
              key={opt.title + index}
              href={opt.link}
              target="_blank"
              rel="noopener noreferrer"
              className="mx-4  font-weight-700 text-white btn-link cursor-pointer m-2"
            >
              {t(opt.title)}
            </a>
          ))}
        </nav>
      </div>
      <div>
        <div className="container flex-column">
          <div className="row my-2">{SOCIAL_NETWORKS.slice(0, 3).map(SocialIcon)}</div>
          <div className="row my-2">{SOCIAL_NETWORKS.slice(3, 6).map(SocialIcon)}</div>
        </div>
      </div>
    </div>
    <div className="d-flex align-items-center justify-content-center py-2">
      <span className="mx-4  font-weight-400 text-white">
        © Copyright 2024 | Alkemy{' '}
        <span className="  font-weight-400 text-white">
          {t('CERTIFICATES_LABEL_ALL_RIGHTS_RESERVED')}
        </span>
      </span>
    </div>
  </div>
);

export default Footer;
