import React from 'react';

const RoleTestGroupsEmpty = ({ classNames }) => (
  <svg
    width="204"
    className={classNames}
    height="128"
    viewBox="0 0 204 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M80.3613 102.274H0.819084C0.601923 102.274 0.393724 102.187 0.240169 102.034C0.0866131 101.88 0.00024082 101.672 0 101.455V23.9148C0.00024082 23.6976 0.0866131 23.4894 0.240169 23.3359C0.393724 23.1823 0.601923 23.0959 0.819084 23.0957H80.3613C80.5784 23.0959 80.7866 23.1823 80.9402 23.3359C81.0938 23.4894 81.1801 23.6976 81.1804 23.9148V101.455C81.1801 101.672 81.0938 101.88 80.9402 102.034C80.7866 102.187 80.5784 102.274 80.3613 102.274Z"
      fill="#F0F0F0"
    />
    <path
      d="M71.716 30.1946H9.46557C9.24844 30.1949 9.04029 30.2813 8.88675 30.4348C8.73321 30.5884 8.64681 30.7965 8.64648 31.0137V79.2486C8.64681 79.4658 8.73322 79.6739 8.88675 79.8275C9.04029 79.981 9.24844 80.0674 9.46557 80.0677H71.716C71.9331 80.0674 72.1413 79.981 72.2948 79.8275C72.4484 79.6739 72.5348 79.4658 72.5351 79.2486V31.0137C72.5348 30.7965 72.4484 30.5884 72.2948 30.4348C72.1413 30.2813 71.9331 30.1949 71.716 30.1946Z"
      fill="white"
    />
    <path
      d="M68.1653 89.5326H13.0136C12.893 89.5324 12.7773 89.4844 12.692 89.3991C12.6067 89.3138 12.5587 89.1982 12.5586 89.0775V86.5293C12.5587 86.4086 12.6067 86.293 12.692 86.2077C12.7773 86.1223 12.893 86.0744 13.0136 86.0742H68.1653C68.286 86.0744 68.4016 86.1223 68.4869 86.2077C68.5722 86.293 68.6202 86.4086 68.6204 86.5293V89.0775C68.6202 89.1982 68.5722 89.3138 68.4869 89.3991C68.4016 89.4844 68.286 89.5324 68.1653 89.5326Z"
      fill="white"
    />
    <path
      d="M49.0534 95.7213H13.0136C12.893 95.7212 12.7773 95.6732 12.692 95.5879C12.6067 95.5026 12.5587 95.3869 12.5586 95.2663V92.718C12.5587 92.5973 12.6067 92.4817 12.692 92.3964C12.7773 92.3111 12.893 92.2631 13.0136 92.2629H49.0534C49.174 92.2631 49.2897 92.3111 49.375 92.3964C49.4603 92.4817 49.5083 92.5973 49.5084 92.718V95.2663C49.5083 95.3869 49.4603 95.5026 49.375 95.5879C49.2897 95.6732 49.174 95.7212 49.0534 95.7213Z"
      fill="white"
    />
    <path
      d="M39.0526 62.8793C43.4238 62.8793 46.9674 59.3357 46.9674 54.9644C46.9674 50.5932 43.4238 47.0496 39.0526 47.0496C34.6813 47.0496 31.1377 50.5932 31.1377 54.9644C31.1377 59.3357 34.6813 62.8793 39.0526 62.8793Z"
      fill="#FFB8B8"
    />
    <path
      d="M50.6314 80.0675H33.083L33.6563 67.166L33.7929 67.0222L35.7623 64.9419H42.0292L44.3517 67.1916L44.5393 67.3736L49.4847 72.1135L50.6314 80.0675Z"
      fill="#4987be"
    />
    <path
      d="M36.1015 80.0676H22.8251C22.7924 77.1553 22.7742 75.4116 22.7742 75.4116L23.5422 74.9438C23.5432 74.9428 23.5445 74.9421 23.5459 74.942H23.5477L32.008 69.8054L33.7844 66.9204L33.7936 67.0223L34.316 73.1036C34.316 73.1036 35.1787 75.8921 36.1015 80.0676Z"
      fill="#2F2E41"
    />
    <path
      d="M58.4151 80.0676H44.3523C44.5653 71.5746 43.4186 66.9204 44.0084 66.9204L44.3523 67.1916L48.4969 70.4643L53.9593 72.4683H53.963L57.729 73.8499L58.3897 74.0919V74.4032C58.3915 75.1495 58.3951 77.1899 58.4151 80.0676Z"
      fill="#2F2E41"
    />
    <path
      d="M31.5643 57.4408C31.5643 57.4408 32.2368 56.2598 32.0804 52.3146C31.9241 48.3694 37.9317 50.6007 42.9517 50.2244C47.9718 49.848 46.9091 56.811 46.9091 56.811C46.9091 56.811 47.2713 56.3971 48.0467 53.0366C48.822 49.676 47.0615 46.3271 47.0615 46.3271C46.543 42.7958 43.7486 43.9276 43.7486 43.9276C45.0944 44.4515 44.8359 45.5716 44.8359 45.5716C43.6968 43.5744 40.9024 44.7062 40.9024 44.7062C37.1755 42.3675 33.3983 45.4473 33.3983 45.4473C28.896 46.4691 31.5364 50.0496 31.5364 50.0496C27.0086 52.3378 31.5643 57.4408 31.5643 57.4408Z"
      fill="#2F2E41"
    />
    <path
      d="M24.5972 80.0678H19.1421C19.1846 79.3956 19.1858 78.7215 19.1457 78.0492C18.8199 76.7514 23.4032 74.9968 23.5415 74.944C23.5424 74.9429 23.5437 74.9423 23.5451 74.9421H23.547L24.4206 75.4117L24.5972 80.0678Z"
      fill="#2F2E41"
    />
    <path
      d="M59.4001 80.0676H53.6701L52.9548 73.0053L53.7575 72.4192C53.7575 72.4192 53.8304 72.4356 53.9596 72.4683H53.9632C54.6057 72.6267 56.5933 73.1509 57.7292 73.8499C57.9819 73.9917 58.2059 74.1793 58.3899 74.4032C58.5026 74.5384 58.5586 74.7118 58.5464 74.8874C58.5173 75.1513 58.8522 77.0953 59.4001 80.0676Z"
      fill="#2F2E41"
    />
    <path
      d="M202.827 79.1782H123.285C123.068 79.1779 122.86 79.0915 122.706 78.938C122.553 78.7844 122.466 78.5762 122.466 78.3591V0.819084C122.466 0.601923 122.553 0.393729 122.706 0.240173C122.86 0.0866165 123.068 0.000241499 123.285 0H202.827C203.045 0.000237437 203.253 0.0866079 203.406 0.240165C203.56 0.393722 203.646 0.601922 203.646 0.819084V78.3591C203.646 78.5762 203.56 78.7844 203.406 78.938C203.253 79.0915 203.045 79.1779 202.827 79.1782Z"
      fill="#F0F0F0"
    />
    <path
      d="M194.182 7.09888H131.932C131.715 7.0992 131.506 7.18561 131.353 7.33914C131.199 7.49268 131.113 7.70083 131.113 7.91796V56.1529C131.113 56.3701 131.199 56.5782 131.353 56.7318C131.506 56.8853 131.715 56.9717 131.932 56.972H194.182C194.399 56.9717 194.607 56.8853 194.761 56.7318C194.914 56.5782 195.001 56.3701 195.001 56.1529V7.91796C195.001 7.70083 194.914 7.49268 194.761 7.33914C194.607 7.18561 194.399 7.0992 194.182 7.09888Z"
      fill="white"
    />
    <path
      d="M190.633 66.4369H135.481C135.361 66.4367 135.245 66.3887 135.16 66.3034C135.075 66.2181 135.027 66.1025 135.026 65.9818V63.4336C135.027 63.3129 135.075 63.1973 135.16 63.1119C135.245 63.0266 135.361 62.9787 135.481 62.9785H190.633C190.754 62.9787 190.869 63.0266 190.955 63.1119C191.04 63.1973 191.088 63.3129 191.088 63.4336V65.9818C191.088 66.1025 191.04 66.2181 190.955 66.3034C190.869 66.3887 190.754 66.4367 190.633 66.4369Z"
      fill="white"
    />
    <path
      d="M171.521 72.6256H135.481C135.361 72.6254 135.245 72.5775 135.16 72.4922C135.075 72.4069 135.027 72.2912 135.026 72.1705V69.6223C135.027 69.5016 135.075 69.386 135.16 69.3007C135.245 69.2154 135.361 69.1674 135.481 69.1672H171.521C171.642 69.1674 171.757 69.2154 171.843 69.3007C171.928 69.386 171.976 69.5016 171.976 69.6223V72.1705C171.976 72.2912 171.928 72.4069 171.843 72.4922C171.757 72.5775 171.642 72.6254 171.521 72.6256Z"
      fill="white"
    />
    <path
      d="M178.38 56.972C178.731 55.707 179.004 54.6167 179.144 53.8449C179.188 53.6069 179.192 53.3635 179.157 53.1241C178.633 49.3946 169.164 46.242 167.95 45.8525L167.657 42.6053L155.739 41.322L154.23 45.4757L149.952 47.0793C149.797 47.1365 149.65 47.2123 149.513 47.305C149.155 47.5472 148.879 47.8936 148.723 48.2975C148.567 48.7014 148.539 49.1433 148.642 49.5638L150.464 56.972L178.38 56.972Z"
      fill="#4987be"
    />
    <path
      d="M153.673 56.9719H144.425C144.756 54.9078 145.053 53.0822 145.246 51.9209C145.952 47.6944 149.031 47.334 149.514 47.3049C149.552 47.3013 149.576 47.3013 149.578 47.3013H151.523L153.673 56.9719Z"
      fill="#4987be"
    />
    <path
      d="M178.38 56.9719H180.623C180.424 55.1499 179.795 53.9231 179.157 53.124C178.732 52.5623 178.184 52.1058 177.554 51.7899L177.385 51.717L175.413 56.9719H178.38Z"
      fill="#4987be"
    />
    <path
      d="M161.231 39.2667C165.971 39.2667 169.813 35.4246 169.813 30.6851C169.813 25.9456 165.971 22.1035 161.231 22.1035C156.492 22.1035 152.65 25.9456 152.65 30.6851C152.65 35.4246 156.492 39.2667 161.231 39.2667Z"
      fill="#A0616A"
    />
    <path
      d="M155.472 26.5043C156.36 27.5557 157.863 27.8522 159.25 27.9728C162.067 28.2199 166.162 27.8192 168.914 27.1838C169.112 29.0877 168.575 31.1406 169.697 32.7071C170.222 30.8109 170.528 28.8604 170.608 26.8943C170.641 26.0511 170.628 25.1788 170.286 24.4048C169.943 23.6307 169.196 22.9736 168.335 22.9668C168.975 22.4819 169.723 22.1619 170.516 22.0349L167.791 20.6656L168.491 19.9472L163.558 19.6454L164.987 18.7378C162.835 18.4423 160.659 18.3739 158.493 18.5336C157.488 18.6076 156.437 18.7491 155.622 19.3316C154.808 19.9142 154.337 21.0717 154.843 21.9268C154.21 22.0308 153.614 22.2946 153.111 22.6934C152.609 23.0921 152.217 23.6126 151.971 24.2054C151.608 25.126 151.642 26.1485 151.753 27.1296C151.939 28.6985 152.304 30.2411 152.84 31.7272"
      fill="#2F2E41"
    />
    <path
      d="M143.108 128H63.5654C63.3483 128 63.1401 127.913 62.9865 127.76C62.833 127.606 62.7466 127.398 62.7463 127.181V49.6409C62.7466 49.4237 62.833 49.2155 62.9865 49.0619C63.1401 48.9084 63.3483 48.822 63.5654 48.8218H143.108C143.325 48.822 143.533 48.9084 143.687 49.0619C143.84 49.2155 143.926 49.4237 143.927 49.6409V127.181C143.926 127.398 143.84 127.606 143.687 127.76C143.533 127.913 143.325 128 143.108 128Z"
      fill="#E4E4E4"
    />
    <path
      d="M134.462 55.9207H72.2119C71.9948 55.921 71.7866 56.0074 71.6331 56.1609C71.4796 56.3145 71.3932 56.5226 71.3928 56.7397V104.975C71.3932 105.192 71.4796 105.4 71.6331 105.554C71.7866 105.707 71.9948 105.793 72.2119 105.794H134.462C134.679 105.793 134.888 105.707 135.041 105.554C135.195 105.4 135.281 105.192 135.281 104.975V56.7397C135.281 56.5226 135.195 56.3145 135.041 56.1609C134.888 56.0074 134.679 55.921 134.462 55.9207Z"
      fill="white"
    />
    <path
      d="M130.913 115.259H75.7617C75.641 115.259 75.5254 115.211 75.4401 115.125C75.3548 115.04 75.3068 114.924 75.3066 114.804V112.255C75.3068 112.135 75.3548 112.019 75.4401 111.934C75.5254 111.848 75.641 111.8 75.7617 111.8H130.913C131.034 111.8 131.15 111.848 131.235 111.934C131.32 112.019 131.368 112.135 131.368 112.255V114.804C131.368 114.924 131.32 115.04 131.235 115.125C131.15 115.211 131.034 115.259 130.913 115.259Z"
      fill="white"
    />
    <path
      d="M111.801 121.447H75.7617C75.641 121.447 75.5254 121.399 75.4401 121.314C75.3548 121.229 75.3068 121.113 75.3066 120.992V118.444C75.3068 118.323 75.3548 118.208 75.4401 118.122C75.5254 118.037 75.641 117.989 75.7617 117.989H111.801C111.922 117.989 112.038 118.037 112.123 118.122C112.208 118.208 112.256 118.323 112.256 118.444V120.992C112.256 121.113 112.208 121.229 112.123 121.314C112.038 121.399 111.922 121.447 111.801 121.447Z"
      fill="white"
    />
    <path
      d="M109.462 105.794H96.9341L97.7149 94.0754L102.234 95.1184L106.63 93.4275L106.747 93.3838L106.754 93.3801C106.756 93.3836 106.757 93.3873 106.758 93.3911C106.827 93.6695 108.206 99.1938 109.462 105.794Z"
      fill="#4987be"
    />
    <path
      d="M117.013 95.9884L115.842 105.794H105.284L106.631 93.4276L106.643 93.3074L106.747 93.3837L106.758 93.3909L109.363 95.2913L117.013 95.9884Z"
      fill="#2F2E41"
    />
    <path
      d="M98.7874 105.794H89.3316L89.1951 98.0744L96.1536 95.7664L97.7153 94.0754C97.7153 94.0754 98.2086 99.1629 98.7874 105.794Z"
      fill="#2F2E41"
    />
    <path
      d="M121.564 105.794H113.728L114.23 98.7698L117.012 95.9885C117.012 95.9885 119.212 100.605 121.564 105.794Z"
      fill="#2F2E41"
    />
    <path
      d="M91.5607 105.794H87.427C87.4452 103.544 87.4561 101.99 87.4561 101.551C87.4354 100.873 87.5834 100.2 87.8868 99.5935C88.1902 98.9867 88.6395 98.4647 89.1944 98.0745L91.2822 98.7698L91.5607 105.794Z"
      fill="#2F2E41"
    />
    <path
      d="M90.915 82.0131C90.9214 80.107 91.4224 78.2352 92.3691 76.5808C93.3158 74.9264 94.6758 73.5461 96.3159 72.5749C99.8005 70.5178 104.005 70.5551 107.85 72.6767C110.59 74.1885 112.819 77.9923 112.819 81.1562V90.3881H90.915V82.0131Z"
      fill="#2F2E41"
    />
    <path
      d="M105.229 89.1911C109.063 87.0953 110.472 82.2883 108.376 78.4544C106.28 74.6205 101.473 73.2114 97.6395 75.3072C93.8055 77.4031 92.3965 82.2101 94.4923 86.044C96.5881 89.8779 101.395 91.2869 105.229 89.1911Z"
      fill="#FFB8B8"
    />
    <path
      d="M93.1832 82.3239C93.1233 82.2547 92.9829 82.0926 93.9588 74.5836L93.9898 74.4609H94.078C99.0245 72.0275 104.043 71.9663 108.995 74.2782C109.148 74.3505 109.279 74.4612 109.376 74.5994C109.473 74.7376 109.532 74.8986 109.548 75.0666C110.126 82.1215 109.986 82.2753 109.926 82.3411C109.836 82.4406 109.725 82.5201 109.602 82.5746C109.479 82.6291 109.346 82.6573 109.211 82.6574H107.017L106.971 82.5745C106.594 81.8744 106.181 81.1946 105.733 80.538C105.527 81.1916 105.358 81.8564 105.227 82.5292L105.2 82.6574H93.9136C93.7751 82.6574 93.6382 82.6277 93.5121 82.5701C93.3861 82.5126 93.2739 82.4286 93.1832 82.3239Z"
      fill="#2F2E41"
    />
    <path
      d="M72.209 35.673C80.644 33.4939 87.884 27.8706 92.1718 20.244C93.6479 17.5729 95.4052 12.4065 95.6864 9.84082C95.7918 8.78645 95.9675 7.38061 96.073 6.71285L96.2487 5.51789L99.4821 8.71616C101.275 10.5086 102.997 11.9496 103.313 11.9496C104.473 11.9496 105 11.5278 105 10.5789C105 9.27849 95.7567 -8.08079e-07 94.4563 -9.21763e-07C93.7885 -9.80141e-07 92.3827 1.19496 88.7275 4.85012C83.9828 9.5948 83.3502 10.5437 84.3343 11.5278C85.2481 12.4416 86.2321 11.9144 89.6061 8.54043L93.0504 5.13128L92.8396 7.02916C92.5232 10.0517 91.3986 14.3395 90.2739 16.7294C86.197 25.4455 77.5863 31.9475 68.6592 33.0722C62.8602 33.8102 63.0008 33.7751 63.0008 35.1458L63.0008 36.3759L65.9882 36.4462C68.2024 36.5165 69.8191 36.3056 72.209 35.673Z"
      fill="#4987be"
    />
  </svg>
);

export default RoleTestGroupsEmpty;
