import './emptyComponent.scss';

const EmptyComponent = ({ className, title, titleClassname, subtitleClassname, subtitle }) => (
  <div
    className={`d-flex flex-column justify-content-center text-center align-content-center w-100 empty-container ${className}`}
  >
    <span className={`text-black  font-weight-500 text-xs ${titleClassname}`}>{title}</span>
    {!!subtitle && (
      <span className={` font-weight-400 text-xs text-black mt-3 ${subtitleClassname}`}>
        {subtitle}
      </span>
    )}
  </div>
);

export default EmptyComponent;
