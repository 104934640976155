import React from 'react';

const DropDownArrow = ({ classNames, lightBlue, style = {} }) => (
  <svg
    className={classNames}
    style={style}
    width="22"
    height="13"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.37012 2.09741L11.2 11.044L20.8847 1.96331"
      stroke={lightBlue ? '#4987BE' : '#41479B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DropDownArrow;
