import { ReviewTypeEnum } from '../../constants/enums';
import { SET_EVALUATION_DATA } from '../actionTypes';

const initialState = {
  evaluation: {
    name: '',
    startDate: {
      day: undefined,
      hours: '00:00',
    },
    endDate: {
      day: undefined,
      hours: '00:00',
    },
    allowCertificateDownload: true,
    proctoringEnabled: true,
    exclusiveUsers: false,
    requireCompletedProfile: false,
    reminderToCandidates: true,
    reviewType: ReviewTypeEnum.GLOBAL,
    searchType: null,
    langCodesAISelected: [],
  },
};

function evaluationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EVALUATION_DATA: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export default evaluationReducer;
