import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Slider } from 'antd';

import search from '../../../assets/profile/search.svg';
import { t } from '../../../i18n/i18n';
import FiltersSkeleton from './FiltersSkeleton';
import './Search.scss';
import './FilterUsersSummary.scss';
import MultipleSelect from '../../MultipleSelect/MultipleSelect';
import DropDownArrow from '../../../assets/screening/DropDownArrow';
import { setUserSummary } from '../../../redux/actions/userSummaryActions';
import setLanguage from '../../../utils/filters/setLanguage';
import { getScreenings } from '../../../API/services/screeningService';
import useFiltersDashboardSearch from '../../../hooks/useFiltersDashboardSearch';

const today = moment().format('YYYY-MM-DD');
const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
const lastWeek = moment().subtract(1, 'weeks').format('YYYY-MM-DD');
const lastMonth = moment().subtract(1, 'months').format('YYYY-MM-DD');
const last3Month = moment().subtract(3, 'months').format('YYYY-MM-DD');

function FilterUsersSummary({ handleSubmit = () => {}, showMoreFilters }) {
  const optionsDate = [
    { id: 1, value: 1, label: t('YESTERDAY'), fromDate: yesterday, toDate: today },
    { id: 2, value: 2, label: t('LAST_WEEK'), fromDate: lastWeek, toDate: today },
    { id: 3, value: 3, label: t('LAST_MONTH'), fromDate: lastMonth, toDate: today },
    { id: 4, value: 4, label: t('LAST_3_MONTH'), fromDate: last3Month, toDate: today },
  ];
  const optionsLanguages = [
    { id: 'es', value: 'es', label: t('SPANISH') },
    { id: 'pt', value: 'pt', label: t('PORTUGUESE') },
    { id: 'en', value: 'en', label: t('ENGLISH') },
  ];

  const dispatch = useDispatch();
  const userSummary = useSelector((state) => state.userSummary.userSummaryPaginated);
  const [inputs, setInputs] = useState({ score: { min: 0, max: 100 } });
  const [selectOpened, setSelectOpened] = useState({});
  const [optionScreening, setOptionScreening] = useState([]);
  const [screeningLoading, setScreeningLoading] = useState(false);
  const { availableFilters } = useSelector((state) => state.profiles);
  const { currentWorkspace } = useSelector((state) => state?.auth);
  const { loadFilters } = useFiltersDashboardSearch('');
  const onChangeFilter = (filterToAdd) => {
    setInputs({ ...inputs, ...filterToAdd });
    handleSubmit(0, { ...userSummary?.filter, ...filterToAdd });
  };

  const onToggleArray = ({ value, array = [] }) => {
    const valueToFind = value;
    const elementFound = array?.find((item) => item === valueToFind);
    return elementFound ? array?.filter((item) => item !== valueToFind) : [...array, value];
  };

  const handleSearchScreening = async () => {
    try {
      setScreeningLoading(true);
      const response = await getScreenings(currentWorkspace);
      setOptionScreening(response);
    } catch (error) {}
    setScreeningLoading(false);
  };

  useEffect(() => {
    handleSearchScreening();
    loadFilters();
  }, []);

  useEffect(() => {
    const { workspaceIds, ...rest } = userSummary?.filter;
    if (!Object.keys(rest).length) {
      setInputs({ score: { min: 0, max: 100 }, search: '' });
    }
  }, [userSummary?.filter]);

  const parseAvailableFilters = (filters, filterType) =>
    filters ? filters.map((item) => ({ ...item, displayName: item.name, type: filterType })) : [];

  return (
    <>
      <div className="profile-search-container w-100">
        {userSummary.loading ? (
          <FiltersSkeleton dashboard />
        ) : (
          <div className="small" data-tour-id="profile-filters">
            <div className="w-100">
              {showMoreFilters && (
                <div className="d-flex align-items-center w-100 mb-3 filters-container-gap">
                  <div className="w-100">
                    <MultipleSelect
                      title={t('PROFILES_FILTER_LOCATION')}
                      firstSelected
                      showSelectedAmount
                      classNames="filters-dropdown border-maya-blue"
                      options={parseAvailableFilters(availableFilters?.locations, 'country')}
                      onSelect={(e) =>
                        onChangeFilter({
                          countries: onToggleArray({
                            value: e?.id,
                            array: userSummary?.filter?.countries,
                          }),
                        })
                      }
                      selectedList={inputs?.countries}
                      CustomArrow={DropDownArrow}
                      emptyOptionsText={t(
                        'DASHBOARD_FILTERS_PROCTORING_COUNTRIES_EMPTY_STATE_TITLE'
                      )}
                    />
                  </div>
                  {showMoreFilters && (
                    <div className="text-center" style={{ minWidth: '200px' }}>
                      <div className="d-flex justify-content-between w-100">
                        <span className=" text-xs">{t('DASHBOARD_SEARCH_HEADERS_SCORE')}</span>
                        <span className="font-weight-bold ">
                          {`${inputs?.score?.min} - ${inputs?.score?.max}`}
                        </span>
                      </div>
                      <Slider
                        range
                        min={0}
                        max={100}
                        value={[inputs?.score?.min, inputs?.score?.max]}
                        onAfterChange={(e) => onChangeFilter({ score: { min: e[0], max: e[1] } })}
                        onChange={(e) => setInputs({ score: { min: e[0], max: e[1] } })}
                      />
                    </div>
                  )}
                  {showMoreFilters && (
                    <div className="w-100">
                      <Select
                        value={inputs?.preferredLanguage}
                        placeholder={t('PROFILES_FILTER_LANGUAGE')}
                        className="font-weight-500 text-xs text-muted "
                        showArrow
                        allowClear
                        onChange={(value, item) => {
                          onChangeFilter({
                            preferredLanguage: value,
                          });
                        }}
                        options={optionsLanguages}
                        style={{ minWidth: 150, width: '100%' }}
                        filterOption={(input, option) =>
                          option?.label.toLowerCase().includes(input.toLowerCase())
                        }
                        onDropdownVisibleChange={(open) =>
                          setSelectOpened({ ...selectOpened, preferredLanguage: open })
                        }
                        suffixIcon={
                          <DropDownArrow
                            style={{
                              transform: selectOpened?.preferredLanguage
                                ? 'rotate(-180deg)'
                                : 'rotate(0deg)',
                              transition: 'transform 0.3s ease',
                            }}
                          />
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="d-flex align-items-center w-100 filters-container-gap flex-wrap">
                <div
                  data-testid="dashboard-search-form"
                  className=" Navbar-container-input border border-maya-blue  d-flex align-items-center py-1"
                >
                  <img src={search} alt={t('SEARCH_ALT')} className="h-75 ml-2" />
                  <input
                    className="border-0 input px-2 text-xs input-search-dashboard w-100"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onChangeFilter({ search: e.target.value });
                      }
                    }}
                    onChange={(e) => {
                      setInputs({ ...inputs, search: e.target.value });
                    }}
                    type="text"
                    value={inputs?.search}
                    style={{ minWidth: '250px' }}
                    placeholder={t('DASHBOARD_INPUT_SEARCH')}
                  />
                </div>

                <div className=" d-flex align-items-center">
                  <Select
                    showSearch
                    value={inputs?.levelIds || []}
                    mode="multiple"
                    placeholder={t('EVALUATIONS')}
                    className="font-weight-500 text-xs text-muted"
                    showArrow
                    allowClear
                    onChange={(values) =>
                      onChangeFilter({
                        levelIds: values?.length ? values : null,
                      })
                    }
                    options={optionScreening
                      ?.filter((option) => option?.type !== 'PRE_FILTER')
                      ?.map((option) => ({
                        value: option.levelId,
                        label: `${option.screeningsName}${
                          option.type === 'PRE_FILTER' ? ` (${t('PREFILTER')})` : ''
                        }`,
                      }))}
                    style={{ minWidth: 200, borderRadius: '20px', width: '100%' }}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onDropdownVisibleChange={(open) =>
                      setSelectOpened({ ...selectOpened, levelIds: open })
                    }
                    suffixIcon={
                      <DropDownArrow
                        style={{
                          transform: selectOpened?.levelIds ? 'rotate(-180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s ease',
                        }}
                      />
                    }
                  />
                </div>
                <div className=" ">
                  <Select
                    value={inputs?.invitedDate || []}
                    placeholder={t('INVITED_FROM')}
                    className="font-weight-500 text-xs text-muted"
                    showArrow
                    allowClear
                    loading={screeningLoading}
                    onChange={(value, item) => {
                      onChangeFilter({
                        invitedDate: value ? item : null,
                      });
                    }}
                    options={optionsDate}
                    style={{ minWidth: 150, width: '100%' }}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onDropdownVisibleChange={(open) =>
                      setSelectOpened({ ...selectOpened, invitedDate: open })
                    }
                    suffixIcon={
                      <DropDownArrow
                        style={{
                          transform: selectOpened?.invitedDate ? 'rotate(-180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s ease',
                        }}
                      />
                    }
                  />
                </div>
                <div className="">
                  <Select
                    showSearch
                    value={inputs?.contactedDate || []}
                    placeholder={t('CONTACTED_FROM')}
                    className="font-weight-500 text-xs text-muted"
                    showArrow
                    allowClear
                    loading={screeningLoading}
                    onChange={(value, item) => {
                      onChangeFilter({
                        contactedDate: value ? item : null,
                      });
                    }}
                    options={optionsDate}
                    style={{ minWidth: 170, width: '100%' }}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onDropdownVisibleChange={(open) =>
                      setSelectOpened({ ...selectOpened, contactedDate: open })
                    }
                    suffixIcon={
                      <DropDownArrow
                        style={{
                          transform: selectOpened?.contactedDate
                            ? 'rotate(-180deg)'
                            : 'rotate(0deg)',
                          transition: 'transform 0.3s ease',
                        }}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default FilterUsersSummary;
