import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import ScreeningForm from '../../components/ScreeningForm/ScreeningForm';
import ScreeningList from '../../components/ScreeningList/ScreeningList';
import { t } from '../../i18n/i18n';
import './Screening.scss';
import processQueryString from '../../utils/processQueryString';
import {
  setConfirmed,
  setNewSearchData,
  setSelected,
  setTemplate,
} from '../../redux/actions/screeningActions';
import { getCustomSearch } from '../../API/services/screeningService';
import { getLastEmail } from '../../API/services/alkymersService';
import { templateDefault } from '../../constants/emailTemplate';
import { getLocalStorageWorkspace } from '../../utils/workspaceUtils';

const Screening = () => {
  const { screenings, confirmed, selected, template } = useSelector((state) => state.screenings);
  const [dataScreening, setDataScreening] = useState({});
  const history = useHistory();
  const idRecruitSession = useState(processQueryString(history.location.search)?.recruitSessionId);
  const dispatch = useDispatch();
  useEffect(() => {
    const levelId = processQueryString(history.location.search)?.levelId;
    const searchId = processQueryString(history.location.search)?.id;

    if (levelId) {
      dispatch(setSelected(screenings?.find((item) => item?.levelId === levelId)));
      dispatch(setConfirmed(true));
    }
    if (searchId) {
      getCustomSearch(searchId).then((res) => {
        dispatch(
          setNewSearchData({
            stages: res.stages,
          })
        );
        setDataScreening({
          ...res,
          stagesData: res.stages,
        });
      });
    }
  }, [screenings, history.location.search]);

  useEffect(() => {
    if (!template?.html?.length) {
      getLastEmail()
        .then((res) => {
          if (Object.keys(res).length && res?.html?.length) {
            dispatch(setTemplate({ template: res.html, id: res.id }));
          } else {
            dispatch(
              setTemplate({
                template: templateDefault(getLocalStorageWorkspace()?.companyName),
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            setTemplate({
              template: templateDefault(getLocalStorageWorkspace()?.companyName),
            })
          );
        });
    }
  }, []);

  return (
    <div className="position-relative w-100 pb-5 screening-page-container">
      <div>
        <BackgroundTop
          title={t('CREATE_SEARCH_TITLE')}
          className="position-relative"
          titleClassname="text-title"
        />
      </div>
      <div className="px-2 position-relative">
        <div className="m-auto">
          {confirmed ? (
            <ScreeningForm
              selected={selected}
              dataScreening={dataScreening}
              recruitSessionId={idRecruitSession || 0}
            />
          ) : (
            <ScreeningList selected={selected} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Screening;
