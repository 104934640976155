import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '../Checkbox/Checkbox';
import OrderingAngles from '../OrderingAngles/OrderingAngles';
import { Orders } from '../../constants/enums';

const TableFull = ({
  elements = [],
  headers = [],
  onCheckRow = () => { },
  onClickHeader = () => { },
  onCheckAllRow = () => { },
}) => {
  const [localHeaders, setLocalHeaders] = useState(headers);
  const [allSelected, setAllSelected] = useState(false);

  const headerScrollRef = useRef(null);
  const bodyScrollRef = useRef(null);
  const syncScrollRef = useRef(null);

  const syncScroll = () => {
    if (headerScrollRef.current && bodyScrollRef.current && syncScrollRef.current) {
      headerScrollRef.current.scrollLeft = syncScrollRef.current.scrollLeft;
      bodyScrollRef.current.scrollLeft = syncScrollRef.current.scrollLeft;
    }
  };

  useEffect(() => {
    const customScrollElement = syncScrollRef.current;
    if (customScrollElement) {
      customScrollElement.addEventListener('scroll', syncScroll);
    }

    return () => {
      if (customScrollElement) {
        customScrollElement.removeEventListener('scroll', syncScroll);
      }
    };
  }, []);

  const onCheckAllRowHandle = () => {
    const newAllSelected = !allSelected;
    setAllSelected(newAllSelected);
    const newArray = elements?.map((item) => ({ ...item, checked: newAllSelected }));
    onCheckAllRow(newArray);
  };

  const onClickRow = (e, rowIndex) => {
    const checked = e?.target?.checked;
    const newArray = elements?.map((item, index) =>
      index === rowIndex ? { ...item, checked } : item
    );
    onCheckRow(newArray, newArray[rowIndex], rowIndex);
  };

  const onClickHeaderHandle = (headerOption) => {
    const headersCopy = localHeaders?.map((item) => ({ ...item }));
    const index = headersCopy?.findIndex((item) => item?.key === headerOption?.key);
    const header = headersCopy[index];
    const lastSelectedIndex = localHeaders?.findIndex((item) => item?.selected);
    if (header?.order || header?.order === null) {
      switch (header?.order) {
        case Orders.ASC:
          header.order = Orders.DESC;
          break;
        case Orders.DESC:
          header.order = Orders.ASC;
          break;
        case null:
          header.order = Orders.ASC;
          break;
        default:
          break;
      }
      if (lastSelectedIndex !== index) {
        headersCopy[lastSelectedIndex].selected = false;
        headersCopy[lastSelectedIndex].order = Orders.DESC;
      }
      header.selected = true;
      headersCopy[index] = header;
      setLocalHeaders(headersCopy);
      onClickHeader(header, index);
    }
  };

  useEffect(() => {
    setLocalHeaders(headers);
  }, [headers]);

  return (
    <div className="w-full h-full">
      <div id="header-scroll" className="relative overflow-hidden w-full" ref={headerScrollRef}>
        <table
          className="w-full border-collapse table-auto container-search-dashboard table-horizontal-scroll"
          style={{ width: '100%' }}
        >
          <thead style={{ boxShadow: 'none' }}>
            <tr
              className="bg-white font-weight-600 border-headers mb-4"
              style={{ borderSpacing: '10px' }}
            >
              <th className="">
                <Checkbox
                  className="search-dashboard-checkbox"
                  checked={allSelected}
                  onClick={onCheckAllRowHandle}
                />
              </th>

              {localHeaders
                ?.sort((a, b) => a.columnOrder - b.columnOrder)
                ?.map((header, index) => (
                  <th
                    key={header.key}
                    data-testid={`header-${header.key}`}
                    className={`${header.pinned ? 'bg-white' : ''} ${header?.className || 'text-left'
                      } ${header?.checked ? '' : 'd-none'} pe-4`}
                    style={{
                      minWidth: header.width ? header.width : 'auto',
                      maxWidth: header.width ? header.width : 'auto',
                      paddingRight: '10px',
                      paddingLeft: '10px',
                    }}
                    onClick={() => onClickHeaderHandle(header)}
                  >
                    <div
                      className={`d-flex align-items-center ${header?.order && 'cursor-pointer'}`}
                      style={{ width: '100%' }}
                    >
                      <span className="font-weight-600 text-xs mr-2">{header.name}</span>
                      {header?.order && <OrderingAngles order={header?.order} />}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
        </table>
      </div>

      <div
        id="sync-scroll"
        className="table-horizontal-scroll d-flex flex-row p-0 m-0 pb-2"
        ref={syncScrollRef}
      >
        <div
          key="space-a1"
          className="p-0 pl-3 flex-shrink-0"
          style={{ width: '60px', height: '1rem' }}
        />
        {localHeaders
          ?.sort((a, b) => a.columnOrder - b.columnOrder)
          ?.filter((header) => header?.checked)
          ?.map((header) => (
            <div
              key={`space-${header.key}`}
              className="p-0 pl-3 flex-shrink-0"
              style={{ width: header.width, height: '1rem' }}
            />
          ))}
      </div>

      <div id="body-scroll" className="relative overflow-hidden w-full" ref={bodyScrollRef}>
        <table
          className="w-full border-collapse table-auto container-search-dashboard table-horizontal-scroll"
          style={{ width: '100%' }}
        >
          <tbody>
            {elements?.map((item, rowIndex) => (
              <tr key={rowIndex} className="border-headers">
                <td className="">
                  <Checkbox
                    className="search-dashboard-checkbox"
                    checked={item?.checked}
                    onClick={(e) => onClickRow(e, rowIndex)}
                  />
                </td>

                {localHeaders
                  ?.sort((a, b) => a.columnOrder - b.columnOrder)
                  ?.map((header, colIndex) => (
                    <td
                      key={`${rowIndex}-${colIndex}`}
                      className={` ${headers[colIndex]?.pinned ? '' : ''} ${header?.checked ? '' : 'd-none'
                        } text-left pe-4 `}
                      style={{
                        minWidth: headers[colIndex]?.width ? headers[colIndex].width : 'auto',
                        maxWidth: headers[colIndex]?.width ? headers[colIndex].width : 'auto',
                        paddingRight: '10px',
                        paddingLeft: '10px',
                      }}
                    >
                      <span className="text-xs text-gray-900">{item[header?.key] || '—'}</span>
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableFull;
