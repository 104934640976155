import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import qs from 'query-string';
import alkemyLogo from '../../assets/alkemy/logo.png';
import { t } from '../../i18n/i18n';
import { FORGOTPASSWORD, REGISTER, SIGNIN } from '../../constants/routes';
import LoginForm from '../../components/LoginForm/LoginForm';
import { auth0Client } from '../../auth0/auth0';
import HorizontalLoader from '../../components/HorizontalLoader/HorizontalLoader';
import { Window } from '../../utils/window';
import { Auth0Error } from '../../constants/enums';
import './LoggedOutLayout.scss';
import { setLoginLoading, setVerify } from '../../redux/actions/authActions';
import { resendEmailVerifyAccount } from '../../API/services/authService';
import CalendarIcon from '../../assets/login/CalendarIcon';

const LoggedOutLayout = () => {
  const { search } = useLocation();
  const [haveAccount, setHaveAccount] = useState(false);
  const [recoverPassword, setRecoverPassword] = useState(false);
  const [errorKey, setErrorKey] = useState();
  const dispatch = useDispatch();
  const [randomImg, setRandomImg] = useState('');
  const history = useHistory();
  const { loginLoading, isVerify, authenticated, emailUserVerify } = useSelector(
    (state) => state.auth
  );
  const location = useLocation();

  const imgList = [
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/1b3d8251-dac9-46ef-9ef4-ae7889585b9a.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/04bd3c20-fe06-4fed-a7b4-fbdadbc3ceb4.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/17b12564-457d-4907-9474-db51e4e22e29.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/91e8b9ee-18bd-4bf3-889b-5f7eb809e42d.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/531cb089-f554-48f5-bbc3-530615faff98.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/6958c3b2-0d8d-45b9-a1cb-ddb89c5aca94.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/b142e60b-e037-40e7-911d-e22f98696ee1.webp`,
    `${process.env.RAZZLE_RUNTIME_ASSETS}/login/ff321e54-afdc-4500-a3a3-5eca2362b57a.webp`,
  ];

  useEffect(() => {
    if (location.hash) {
      const queryParams = qs.parse(location.hash?.split('#')[1]);
      const actions = {
        [Auth0Error.VALIDATE_EMAIL]: () => {
          dispatch(setLoginLoading(false));
        },
        [Auth0Error.LINKING_ACCOUNTS]: () =>
          notification.open({
            message: t('LINKING_ACCOUNTS_NOTIFICATION'),
            type: 'success',
          }),
        [Auth0Error.USER_EXISTS]: () => {
          dispatch(setLoginLoading(false));
          notification.open({
            message: t('DUPLICATED_EMAIL_VALIDATION_ERROR'),
            type: 'error',
          });
          history.push({
            hash: '',
          });
        },
        [Auth0Error.USER_EXISTS_GOOGLE]: () => {
          dispatch(setLoginLoading(false));
          notification.open({
            message: t('DUPLICATED_EMAIL_VALIDATION_ERROR_GOOGLE'),
            type: 'error',
          });
          history.push({
            hash: '',
          });
        },
        [Auth0Error.USER_EXISTS_PASSWORD]: () => {
          dispatch(setLoginLoading(false));
          notification.open({
            message: t('DUPLICATED_EMAIL_VALIDATION_ERROR_PASSWORD'),
            type: 'error',
          });
          history.push({
            hash: '',
          });
        },
        [Auth0Error.EMAIL_COMPANY_MAIL_REQUIRED]: () => {
          dispatch(setLoginLoading(false));
          notification.open({
            message: t('EMAIL_COMPANY_MAIL_REQUIRED'),
            type: 'error',
          });
          history.push({
            hash: '',
          });
        },
      };
      if (Auth0Error[queryParams.error_description])
        actions[Auth0Error[queryParams.error_description]]();
    }
  }, [location.hash]);

  const singOut = () => {
    dispatch(setVerify());
    if (Window()) {
      localStorage.clear();
      auth0Client().logout({ returnTo: `${window.location.origin}/login` });
    }
  };

  useEffect(() => {
    if (location.pathname === SIGNIN) {
      setHaveAccount(true);
      setRecoverPassword(false);
    }
    if (location.pathname === FORGOTPASSWORD) {
      setHaveAccount(true);
      setRecoverPassword(true);
    }
    if (location.pathname === REGISTER) {
      setHaveAccount(false);
      setRecoverPassword(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    setRandomImg(imgList[Math.floor(Math.random() * imgList.length)]);
  }, []);

  useEffect(() => {
    const queryParam = qs.parse(search);

    if (queryParam?.error) {
      auth0Client.logout({
        returnTo: `${window.location.origin}${SIGNIN}?custom_error=${queryParam?.error_description}`,
      });
    }

    if (queryParam?.custom_error) {
      setErrorKey(queryParam.custom_error);
    }
  }, [search]);

  const sendEmailVerifyAccount = () => {
    try {
      resendEmailVerifyAccount();
      notification.open({
        message: t('RESEND_EMAIL_VERIFY_ACCOUNT_SUCCES'),
        type: 'success',
      });
    } catch (error) {
      notification.open({
        message: t('RESEND_EMAIL_VERIFY_ACCOUNT_ERROR'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row no-gutter flex-row-reverse logged-out-container">
        <div
          data-testid="layout-image-container"
          className="col-md-7 col-lg-7 d-none d-md-flex logged-out-bg-image"
          style={{
            backgroundImage: `url(${randomImg})`,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="position-absolute bottom-0 left-0"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#4987be"
              fillOpacity="1"
              d="M0,288L48,277.3C96,267,192,245,288,229.3C384,213,480,203,576,197.3C672,192,768,192,864,202.7C960,213,1056,235,1152,234.7C1248,235,1344,213,1392,202.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            />
          </svg>
        </div>
        <div className="col-lg-5 bg-light logged-out-children-container d-flex justify-content-center">
          <div className="d-flex flex-column px-2 px-md-2 py-2 steps-children-container min-vh-100">
            <img
              src={alkemyLogo}
              width="122px"
              className="login-content-margin mx-auto"
              alt="logo-alkemy"
              data-testid="ly-logo-img"
            />
            {!isVerify && authenticated ? (
              <div className="mt-2 pt-3 text-center login-content-margin">
                <h4
                  className=" text-center font-weight-bold text-title mt-5"
                  data-testid="ly-verify-email-title-h4"
                >
                  {t('LOGGED_OUT_LAYOUT_VERIFY_EMAIL_TITLE')}
                </h4>
                <h5
                  className=" text-center mt-4 font-weight-600 text-xs gray-subtitle"
                  data-testid="ly-verify-email-subtitle-h5"
                >
                  {t('LOGGED_OUT_LAYOUT_VERIFY_EMAIL_SUBTITLE_FIRST')}
                  <span
                    className=" font-weight-bold text-xs text-dark"
                    data-testid="ly-user-email-span"
                  >
                    {emailUserVerify}
                  </span>
                  {t('LOGGED_OUT_LAYOUT_VERIFY_EMAIL_SUBTITLE_SECOND')}
                </h5>
                <p
                  className=" text-center mt-4 font-weight-600 text-xs gray-subtitle"
                  data-testid="ly-send-email-text-p"
                >
                  {t('LOGGED_OUT_LAYOUT_VERIFY_EMAIL_SEND_EMAIL')}
                  <button
                    type="button"
                    className="bg-transparent outline-none border-0  font-weight-600 text-xs text-blue-principal"
                    onClick={sendEmailVerifyAccount}
                    data-testid="ly-send-email-btn"
                  >
                    {t('LOGGED_OUT_LAYOUT_VERIFY_EMAIL_SEND_EMAIL_LINK')}
                  </button>
                </p>
                <button
                  type="button"
                  className="bg-maya-blue rounded-pill px-3 py-1 outline-none border-0  font-weight-600 text-xs text-white mt-3"
                  onClick={singOut}
                  data-testid="ly-logout-back-login-btn"
                >
                  {t('LOGGED_OUT_LAYOUT_VERIFY_BACK_LOGIN')}
                </button>
              </div>
            ) : (
              <>
                <div className="mt-2 pt-4 px-2 login-content-margin">
                  {errorKey ? (
                    <>
                      <h5
                        className=" text-center mt-4 mx-2 login-subtitle mb-4"
                        data-testid={`ly-login-error-${errorKey}-h5`}
                      >
                        {t(`LOGGED_OUT_LAYOUT_LOGIN_ERROR_${errorKey.toUpperCase()}`, true)}
                      </h5>
                      <h5
                        className=" text-center mx-2 mb-4"
                        data-testid="ly-login-error-try-again-h5"
                      >
                        {t('LOGGED_OUT_LAYOUT_LOGIN_ERROR_TRY_AGAIN')}
                      </h5>
                    </>
                  ) : (
                    <>
                      <h4
                        className=" logged-out-layout-title mb-3 text-center font-weight-bold text-sm"
                        data-testid="ly-login-title-h4"
                      >
                        {haveAccount ? t('LOGIN_FORM_TITLE') : t('REGISTER_FORM_TITLE')}
                      </h4>
                      <h5
                        className="mx-auto  logged-out-layout-subtitle text-center px-4 mt-2 mx-2 text-base mb-2 w-75 text-center"
                        data-testid="ly-login-subtitle-h5"
                      >
                        {t('LOGGED_OUT_LAYOUT_LOGIN_SUBTITLE')}
                      </h5>
                    </>
                  )}
                </div>
                <LoginForm haveAccount={haveAccount} recoverPassword={recoverPassword} />
                {loginLoading && (
                  <div className="d-flex justify-content-center">
                    <HorizontalLoader className="h-auto my-4" />
                  </div>
                )}
                {!recoverPassword && (
                  <>
                    <div className="demo-section-container w-90 mx-auto mt-3 d-flex justify-content-center align-items-center">
                      <CalendarIcon />
                      <div className="d-flex flex-column ml-4">
                        <span className=" need-help-text">
                          {haveAccount
                            ? t('SCHEDULE_MEETING_NEED_HELP')
                            : t('SCHEDULE_MEETING_PICK_DATE')}
                        </span>
                        <a
                          href={process.env.RAZZLE_RUNTIME_SALES_CALENDAR_URL}
                          target="_blank"
                          rel="noreferrer"
                          className=" text-blue-principal font-weight-700 text-xs"
                        >
                          {t('SCHEDULE_MEETING_ACTION')}
                        </a>
                      </div>
                    </div>
                    <div className="w-90 my-3 mx-auto d-flex justify-content-center align-items-center">
                      <span className="mr-1  toggle-have-account-text">
                        {haveAccount ? t('DONT_HAVE_ACCOUNT_QUESTION') : t('HAVE_ACCOUNT_QUESTION')}
                      </span>
                      <button
                        data-testid={
                          haveAccount ? 'DONT_HAVE_ACCOUNT_BUTTON' : 'ALREADY_HAVE_ACCOUNT_BUTTON'
                        }
                        className="text-purple font-weight-600  border-0 bg-transparent text-underline"
                        type="button"
                        onClick={() => {
                          history.push(haveAccount ? REGISTER : SIGNIN);
                          setHaveAccount((state) => !state);
                        }}
                      >
                        {haveAccount ? t('REGISTER') : t('LOGIN')}
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoggedOutLayout;
