import ImageFileIcon from '../../assets/testgroupABM/ImageFileIcon';
import { TestFileType } from '../../constants/enums';
import { t } from '../../i18n/i18n';
import { fileToObject } from '../../utils/fileToObject';

const UploadTestImage = ({ addFile, id }) => (
  <div data-testid="upload-test-image-container" className="d-flex">
    <label
      data-testid="upload-test-image-label"
      className="text-purple text-xs  font-weight-500 cursor-pointer m-0"
      htmlFor={`files-${id}`}
    >
      <ImageFileIcon className="mr-1" /> {t('CREATE_TEST_GROUP_ADD_IMAGE')}
    </label>
    <input
      data-testid="upload-test-image-input"
      onChange={(e) => addFile(fileToObject(e.target.files[0]), TestFileType.IMAGE)}
      type="file"
      accept="image/*"
      id={`files-${id}`}
      className="d-none"
      value=""
    />
  </div>
);

export default UploadTestImage;
