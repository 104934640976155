import { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { t } from '../../i18n/i18n';
import useCreateScreening from '../../hooks/useCreateScreening';
import RowAnswer from '../RowAnswer/RowAnswer';
import parseClip from '../../utils/parseClip';
import GenericToggle from '../GenericToggle/GenericToggle';
import UploadTestFiles from '../UploadTestFiles/UploadTestFiles';
import { TestFileType } from '../../constants/enums';

const QuestionMultipleChoice = ({ testGroupABM, eachScore, setEachScore }) => {
  const {
    currentQuestionData,
    defaultCheckState,
    handleAddAnswers,
    handleAddQuestionText,
    resetCheckWeight,
    handleAddTestFile,
    handleRemoveTestFile,
    handleUpdateTestFile,
    handleAddAnswerFile,
    handleRemoveAnswerFile,
    handleUpdateAnswerFile,
  } = useCreateScreening();

  const textAreaRef = useRef();

  const [rowAnswer, setRowAnswer] = useState(defaultCheckState);
  const [rowAnswerCustom, setRowAnswerCustom] = useState([]);
  const [modal, setModal] = useState(false);
  const [withFormula, setWithFormula] = useState(false);

  useEffect(() => {
    setRowAnswer(currentQuestionData?.options ?? defaultCheckState);
  }, [currentQuestionData?.options]);

  const handleChangeCustom = ({ target: { value } }) => {
    const newArray = [...rowAnswer?.filter((row) => !!row.text)];
    parseClip(value)?.map((parse) =>
      parse?.map((option) => newArray.push({ text: option, correct: false }))
    );
    setRowAnswerCustom(newArray);
  };

  const handleOnChange = (index, name, value) => {
    const copyRows = [...rowAnswer];
    copyRows[index] = {
      ...copyRows[index],
      [name]: value,
    };
    setRowAnswer(copyRows);
    handleAddAnswers(copyRows);
  };

  const handleAddAswer = () => {
    const emptyNewOption = {
      text: '',
      formulas: '',
      files: [],
      ...((testGroupABM && !eachScore) || !testGroupABM ? { correct: false } : {}),
    };
    setRowAnswer(rowAnswer.concat(emptyNewOption));
    handleAddAnswers(rowAnswer.concat(emptyNewOption));
  };

  const handleOnRemove = (index) => {
    const copyRows = [...rowAnswer];
    copyRows.splice(index, 1);
    handleAddAnswers(copyRows);
  };

  const handleOnHideFormula = (value) => {
    if (!value) {
      const copyRows = rowAnswer.map((r) => ({
        ...r,
        formula: '',
      }));
      setRowAnswer(copyRows);
      handleAddAnswers(copyRows);
    }
  };

  return (
    <div data-testid="question-multiple-choice-container">
      <Modal
        title={t('ADD_OPTIONS_TO_EXCEL')}
        open={modal}
        onOk={() => {
          setModal(false);
          setRowAnswer(rowAnswerCustom);
          handleAddAnswers(rowAnswerCustom);
          setRowAnswerCustom([]);
          textAreaRef.current.value = '';
        }}
        onCancel={() => {
          setModal(false);
          setRowAnswerCustom([]);
          textAreaRef.current.value = '';
        }}
        okText={t('CREATE_SEARCH_MODAL_BUTTON_ADD')}
        cancelText={t('CANCEL')}
      >
        <textarea
          ref={textAreaRef}
          rows="5"
          className="hide-placeholder w-100 py-1 px-2"
          placeholder={t('PASTE_THE_ROWS_FROM_EXCEL')}
          onChange={handleChangeCustom}
          data-testid="question-mc-paste-from-excel-textarea"
        />
      </Modal>
      <div className={`d-flex ${testGroupABM && 'flex-column'}`}>
        <textarea
          type="text"
          onChange={handleAddQuestionText}
          name="text"
          value={currentQuestionData?.text ?? ''}
          className={`w-80 py-2 px-3 border-grey-2  outline-none text-xs min-height-add-question  ${
            currentQuestionData?.text?.length ? 'selected-border' : 'unselected-border'
          }`}
          rows="3"
          maxLength={5000}
          placeholder={t('CREATE_SCREENING_YOUR_QUESTIONS_MULTIPLE_CHOICE_PLACEHOLDER')}
          data-testid="question-mc-add-question-textarea"
        />
        {testGroupABM && (
          <textarea
            type="text"
            onChange={handleAddQuestionText}
            name="formula"
            value={currentQuestionData?.formula ?? ''}
            className={`mb-2 w-80 mt-3 py-2 px-3 mx-2  border-grey-2 outline-none text-xs min-height-add-question ${
              currentQuestionData?.formula?.length ? 'selected-border' : 'unselected-border'
            }`}
            rows="1"
            maxLength={500}
            placeholder={t('CREATE_SCREENING_YOUR_FORMULAS_PLACEHOLDER')}
            data-testid="question-mc-add-formula-textarea"
          />
        )}
        {testGroupABM && (
          <>
            <UploadTestFiles
              fileTypes={[TestFileType.IMAGE, TestFileType.AUDIO]}
              handleAddFile={handleAddTestFile}
              handleRemoveFile={handleRemoveTestFile}
              handleUpdateFile={handleUpdateTestFile}
              testFiles={currentQuestionData?.testFiles ?? []}
              id="test"
            />
            <GenericToggle
              rightText={t('TOGGLE_EACH_SCORE')}
              defaultActive={eachScore}
              actionFunction={() => {
                resetCheckWeight();
                setEachScore(!eachScore);
              }}
            />
            <GenericToggle
              rightText={t('TOGGLE_HAS_FORMULA')}
              defaultActive={withFormula}
              actionFunction={() => {
                handleOnHideFormula(withFormula);
                setWithFormula(!withFormula);
              }}
            />
          </>
        )}
        <div className="d-flex flex-column justify-content-end w-100">
          {rowAnswer.map((row, index) => (
            <RowAnswer
              {...row}
              key={index}
              index={index}
              onChange={(name, value) => handleOnChange(index, name, value)}
              eachScore={eachScore}
              onRemove={() => handleOnRemove(index)}
              testGroupABM={testGroupABM}
              addFile={handleAddAnswerFile}
              removeFile={handleRemoveAnswerFile}
              updateFile={handleUpdateAnswerFile}
              withFormula={withFormula}
            />
          ))}
        </div>
      </div>
      <div className="d-flex justify-content-end w-100 mt-5 gap-2">
        <button
          type="button"
          className=" bg-transparent border-0 text-xs text-blue-principal font-weight-600"
          onClick={handleAddAswer}
          data-testid="question-mc-create-option-btn"
        >
          {t('CREATE_QUESTION_MULTIPLE_CHOICE_ADD_OPTION')}
        </button>
        <button
          type="button"
          className=" bg-transparent border-0 text-xs  text-blue-principal font-weight-600"
          onClick={() => setModal(true)}
          data-testid="question-mc-add-option-excel-btn"
        >
          {t('ADD_OPTIONS_TO_EXCEL')}
        </button>
      </div>
    </div>
  );
};

export default QuestionMultipleChoice;
