import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { t } from '../../i18n/i18n';
import profileImageNull from '../../assets/profile/profile.png';
import EditPurpleIcon from '../../assets/common/EditPencilIcon';
import useTranslations from '../../hooks/useTranslations';

export const RolesTestGroupRow = ({
  rol,
  onClickSetRolSelected,
  onAddRol = () => {},
  onRemoveRol = () => {},
  onSelectRolToUpdate = () => {},
  rolSelected,
  canSelect,
  buttonPlus = false,
}) => {
  const { key } = useTranslations();

  const isChildrenOfSelected = useMemo(
    () => !!(rolSelected?.id === rol?.parentId),
    [rolSelected?.id, rol?.parentId]
  );
  const isSiblingOfSelected = useMemo(
    () => !!(rolSelected?.parentId != null && rolSelected?.parentId === rol?.parentId),
    [rolSelected?.parent?.children, rol?.id]
  );
  const isGrandchildrenOfSelected = useMemo(
    () => rol?.parent && rol?.parent?.parentId && rol?.parent?.parentId === rolSelected?.id,
    [rol, rolSelected?.id]
  );
  const isGrandparentOfSelected = useMemo(
    () =>
      rolSelected &&
      rolSelected?.parent &&
      rolSelected?.parent?.parentId &&
      rol?.id === rolSelected?.parent?.parentId,
    [rol?.id, rolSelected?.parent?.parentId]
  );

  const isParentOfSelected = useMemo(
    () => rolSelected?.parentId && rolSelected?.parentId === rol?.id,
    [rol?.id, rolSelected?.parentId]
  );
  const isSomeOfSelected = useMemo(
    () =>
      rolSelected &&
      (isChildrenOfSelected ||
        isSiblingOfSelected ||
        isGrandchildrenOfSelected ||
        isGrandparentOfSelected ||
        isParentOfSelected),
    [
      isChildrenOfSelected,
      isSiblingOfSelected,
      isGrandchildrenOfSelected,
      isGrandparentOfSelected,
      isParentOfSelected,
      rolSelected,
    ]
  );

  const selectedIsGrandchildren = useMemo(
    () => !!(rolSelected?.parent && rolSelected?.parent?.parentId),
    [rolSelected?.parent?.parentId]
  );
  const isGrandchildren = useMemo(
    () => !!(rol?.parent && rol?.parent?.parentId),
    [rol?.parent?.parentId]
  );

  const onClickSelectRol = () => {
    onSelectRolToUpdate(rol);
  };
  return (
    <div
      className="test-button-filters-selected d-flex justify-content-between align-items-center"
      style={
        isSomeOfSelected
          ? {
              backgroundColor: '#4987be',
              color: 'white',
            }
          : {}
      }
    >
      <div className="m-2 d-flex justify-content-around flex-column">
        <span>{rol?.languages ? rol?.languages[key] ?? rol?.displayName : rol?.displayName}</span>
      </div>
      <div className="d-flex">
        {!rolSelected && (
          <button
            type="button"
            data-testid="create-evaluation-second-step-button"
            className={`bg-transparent p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
            style={isSomeOfSelected ? { borderColor: '#FFFF' } : {}}
            onClick={() => onClickSelectRol()}
          >
            <span
              className={` test-text-filters ${'test-text-filters-selected'}`}
              data-testid="create-evaluation-ss-filters-selected-span"
              style={{ color: '#449cc8' }}
            >
              <EditPurpleIcon />
            </span>
          </button>
        )}

        {canSelect && (
          <button
            type="button"
            data-testid="create-evaluation-second-step-button"
            className={`bg-transparent p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
            style={isSomeOfSelected ? { borderColor: '#FFFF' } : {}}
            onClick={() => onClickSetRolSelected(rol)}
          >
            <span
              className={` test-text-filters ${'test-text-filters-selected'}`}
              data-testid="create-evaluation-ss-filters-selected-span"
              style={isSomeOfSelected ? { color: '#FFFF' } : {}}
            >
              {t('CATEGORY_SELECT')}
            </span>
          </button>
        )}
        {buttonPlus &&
          rolSelected &&
          !isParentOfSelected &&
          !selectedIsGrandchildren &&
          !isGrandparentOfSelected &&
          !isGrandchildren &&
          !isChildrenOfSelected &&
          rol?.children?.length === 0 && (
            <button
              type="button"
              data-testid="create-evaluation-second-step-button"
              className={`bg-transparent p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
              onClick={() => onAddRol({ parent: rolSelected, rolToAdd: rol })}
              style={isSomeOfSelected ? { borderColor: '#FFFF' } : {}}
            >
              <span
                className={` test-text-filters ${'test-text-filters-selected'}`}
                data-testid="create-evaluation-ss-filters-selected-span"
                style={isSomeOfSelected ? { color: '#FFFF' } : {}}
              >
                {t('+')}
              </span>
            </button>
          )}
        {rolSelected && isChildrenOfSelected && (
          <button
            type="button"
            data-testid="create-evaluation-second-step-button"
            className={`bg-transparent p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
            style={isSomeOfSelected ? { borderColor: '#FFFF' } : {}}
            onClick={() =>
              onRemoveRol({
                rolToRemove: rol,
              })
            }
          >
            <span
              className={` test-text-filters ${'test-text-filters-selected'}`}
              data-testid="create-evaluation-ss-filters-selected-span"
              style={isSomeOfSelected ? { color: '#FFFF' } : {}}
            >
              <ArrowLeftOutlined />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};
