import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { t } from '../../i18n/i18n';
import deleteIcon from '../../assets/savedSearch/trash-icon.svg';
import { setAgeQuery, setAppliedFilters } from '../../redux/actions/profilesActions';
import { formatSearchData } from '../../utils/filters/formatSearchData';

const SearchItemFilters = ({ search, handleDelete, setSomeSelected, someSelected }) => {
  const dispatch = useDispatch();
  const { appliedFilters } = useSelector((store) => store.profiles);
  const [selected, setSelected] = useState(false);

  const handleSearch = () => {
    if (search.min_age && search.max_age) {
      dispatch(setAgeQuery({ min: search.min_age, max: search.max_age }));
    }
    dispatch(setAppliedFilters(formatSearchData(search, t)));
  };

  useEffect(() => {
    const isSelected =
      JSON.stringify(appliedFilters) === JSON.stringify(formatSearchData(search, t));
    setSelected(isSelected);

    if (selected && !isSelected) setSomeSelected(false);
    if (isSelected) setSomeSelected(true);
  }, [search]);

  return (
    <div className="d-flex w-100 pr-3">
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <span className=" font-weight-500 text-xs">{search.name}</span>
          </Tooltip>
        }
      >
        <button
          className="d-flex align-items-center saved-search-button justify-content-between  border-0 py-1 px-2 font-weight-bold my-1"
          type="button"
          onClick={handleSearch}
          data-not-selected={someSelected && !selected}
        >
          {search.name.length < 20 ? search.name : `${search.name.substring(0, 20)}...`}
        </button>
      </OverlayTrigger>
      {selected && (
        <button
          type="button"
          className="bg-transparent border-0 outline-none ml-2"
          onClick={handleDelete}
        >
          <img src={deleteIcon} alt={t('ICON_DELETE_SEARCH')} />
        </button>
      )}
    </div>
  );
};
export default SearchItemFilters;
