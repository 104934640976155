import React, { useEffect, useState } from 'react';

import Carousel from 'react-elastic-carousel';
import { HiViewGrid } from 'react-icons/hi';
import { TfiLayoutSliderAlt } from 'react-icons/tfi';

import FsLightbox from 'fslightbox-react';
import { t } from '../../../i18n/i18n';
import Lottie from 'lottie-react';
import CertificateAnimation from '../../../assets/certificates/certificate.json';
import EmptyState from '../../../assets/screening/EmptyState.json';
import axios from 'axios';
const UserCertificates = ({ certificates, profile, loading }) => {
  const [sliderView, setSliderView] = useState(true);
  const [imagesData, setImagesData] = useState(null);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });
  function openLightboxOnSlide(index) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index + 1,
    });
  }

  const getImagesData = async () => {
    const urls = certificates?.map((x) => x?.certificateUrl);
    const requestArray = urls?.map((url) => axios.get(url, { responseType: 'arraybuffer' }));
    const dataArray = await Promise.all(requestArray);
    const base64Images = dataArray.map((response, index) => {
      const url = urls[index];
      const extension = url?.split('.').pop().toLowerCase();
      let mimeType;
      switch (extension) {
        case 'jpeg':
        case 'jpg':
          mimeType = 'image/jpeg';
          break;
        case 'png':
          mimeType = 'image/png';
          break;
        case 'svg':
          mimeType = 'image/svg+xml';
          break;
        case 'gif':
          mimeType = 'image/gif';
          break;
        default:
          console.warn(`Unsupported file extension: ${extension}. Defaulting to JPEG.`);
          mimeType = 'image/jpeg';
      }
      const imageBuffer = Buffer.from(response.data, 'binary');
      return `data:${mimeType};base64,${imageBuffer.toString('base64')}`;
    });

    setImagesData(base64Images);
  };

  useEffect(() => {
    if (certificates && certificates?.length) {
      getImagesData();
    }
  }, [certificates]);

  return (
    <section className="bg-white shadow-sm rounded-lg p-4 d-flex justify-content-center align-items-center">
      <div className="w-100 ">
        {!loading && certificates && certificates?.length ? (
          <div className="w-100 d-flex justify-content-end">
            <div
              className="btn-group rounded-0"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <button
                data-testid="carousel-view-button"
                type="button"
                className={`btn ${!sliderView ? '' : 'btn-primary'} rounded-sm`}
                style={{ backgroundColor: '#bae2f6' }}
                onClick={() => {
                  setSliderView(true);
                }}
              >
                <TfiLayoutSliderAlt />
              </button>
              <button
                data-testid="grid-view-button"
                type="button"
                className={`btn ${sliderView ? '' : 'btn-primary'} rounded-sm`}
                style={{ backgroundColor: '#bae2f6' }}
                onClick={() => {
                  setSliderView(false);
                }}
              >
                <HiViewGrid />
              </button>
            </div>
          </div>
        ) : null}
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          {loading ? (
            <div className="w-100 d-flex justify-content-center">
              <div className="d-flex flex-wrap justify-content-center w-100">
                <div className="ph-row ph-item w-50">
                  <div data-testid="skeleton-non-dashboard-column" className="ph-col-12 big" />
                </div>
                <div className="ph-row ph-item w-100 d-flex flex-wrap justify-content-center">
                  <div
                    data-testid="skeleton-non-dashboard-column"
                    className="flex-grow-1 ph-col-3 hiper mx-1"
                  />
                  <div
                    data-testid="skeleton-non-dashboard-column"
                    className="flex-grow-1 ph-col-3 hiper mx-1"
                  />
                  <div
                    data-testid="skeleton-non-dashboard-column"
                    className="flex-grow-1 ph-col-3 hiper mx-1"
                  />
                  <div
                    data-testid="skeleton-non-dashboard-column"
                    className="flex-grow-1 ph-col-3 hiper mx-1"
                  />
                </div>
                <div className="ph-row ph-item w-100 d-flex flex-wrap justify-content-center">
                  <div
                    data-testid="skeleton-non-dashboard-column"
                    className="flex-grow-1 ph-col-3 hiper mx-1"
                  />
                  <div
                    data-testid="skeleton-non-dashboard-column"
                    className="flex-grow-1 ph-col-3 hiper mx-1"
                  />
                  <div
                    data-testid="skeleton-non-dashboard-column"
                    className="flex-grow-1 ph-col-3 hiper mx-1"
                  />
                  <div
                    data-testid="skeleton-non-dashboard-column"
                    className="flex-grow-1 ph-col-3 hiper mx-1"
                  />
                </div>
              </div>
            </div>
          ) : profile ? (
            <>
              <span className="text-center  text-title font-weight-600 w-50">
                {t('CERTIFICATES_LABEL_USER_CERTIFICATES')}{' '}
                <span className="  text-title font-weight-600">
                  {`${profile?.firstName} ${profile?.lastName}`}
                </span>
              </span>
              <div className="mt-4 w-100">
                {imagesData && (
                  <FsLightbox
                    toggler={lightboxController?.toggler}
                    sources={imagesData}
                    slide={lightboxController?.slide}
                  />
                )}

                {certificates && certificates?.length ? (
                  <>
                    {sliderView ? (
                      <div data-testid="carousel-view">
                        <Carousel>
                          {certificates?.map((cert, index) => {
                            return (
                              <div
                                className="bg-light p-2 w-100 cursor-pointer"
                                key={cert?._id}
                                onClick={() => openLightboxOnSlide(index)}
                              >
                                <div
                                  className="d-block w-100"
                                  style={{
                                    backgroundImage: `url(${cert?.certificateUrl})`,
                                    height: '60vh',
                                    width: 'auto',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                  }}
                                ></div>
                              </div>
                            );
                          })}
                        </Carousel>
                      </div>
                    ) : (
                      <div className="d-flex flex-wrap justify-content-start w-100">
                        <div
                          data-testid="grid-view"
                          className="d-flex flex-wrap justify-content-center w-100"
                        >
                          <div className="d-flex flex-wrap justify-content-center">
                            {certificates?.map((cert, index) => {
                              return (
                                <div
                                  className="d-flex flex-column align-items-center m-4 cursor-pointer"
                                  key={cert?._id}
                                  onClick={() => openLightboxOnSlide(index)}
                                >
                                  <div
                                    style={{
                                      backgroundImage: `url(${cert?.certificateUrl})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                      height: '13em',
                                      width: '18em',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  ></div>
                                  <span className=" font-weight-500 text-sm mt-2">
                                    {cert?.screening?.name}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex flex-wrap justify-content-start w-100">
                    <div
                      data-testid="grid-view"
                      className="d-flex flex-wrap justify-content-center w-100"
                    >
                      <div className="d-flex flex-wrap justify-content-center">
                        <div className="d-flex justify-content-center">
                          <Lottie
                            animationData={CertificateAnimation}
                            loop={false}
                            className="w-50"
                          />
                        </div>
                        <span className="text-center  text-title font-weight-600 w-50">
                          {t('CERTIFICATES_LABEL_USER_CERTIFICATES_EMPTY')}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <span className="text-center  text-sm font-weight-600 w-50">
                {t('CERTIFICATES_LABEL_USER_NOT_FOUND')}
              </span>
              <Lottie animationData={EmptyState} loop={false} />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default UserCertificates;
