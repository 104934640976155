const Clip = ({ checked }) => (
  <svg width="77" height="40.6" viewBox="0 0 110 58" fill="none">
    <path
      d="M0 41C0 34.3726 5.37258 29 12 29H98C104.627 29 110 34.3726 110 41V58H0V41Z"
      fill={`${checked ? '#39876A' : '#357b9eff'}`}
    />
    <ellipse
      cx="55"
      cy="24.5106"
      rx="24"
      ry="24.5106"
      fill={`${checked ? '#39876A' : '#357b9eff'}`}
    />
    <circle cx="55" cy="25" r="14" fill="#F3F3F3" />
  </svg>
);
export default Clip;
