import React from 'react';

const PasswordIcon = ({ selected, className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 4.83317H9.33268V3.49984C9.33268 1.65984 7.83935 0.166504 5.99935 0.166504C4.15935 0.166504 2.66602 1.65984 2.66602 3.49984V4.83317H1.99935C1.26602 4.83317 0.666016 5.43317 0.666016 6.1665V12.8332C0.666016 13.5665 1.26602 14.1665 1.99935 14.1665H9.99935C10.7327 14.1665 11.3327 13.5665 11.3327 12.8332V6.1665C11.3327 5.43317 10.7327 4.83317 9.99935 4.83317ZM5.99935 10.8332C5.26602 10.8332 4.66602 10.2332 4.66602 9.49984C4.66602 8.7665 5.26602 8.1665 5.99935 8.1665C6.73268 8.1665 7.33268 8.7665 7.33268 9.49984C7.33268 10.2332 6.73268 10.8332 5.99935 10.8332ZM8.06602 4.83317H3.93268V3.49984C3.93268 2.35984 4.85935 1.43317 5.99935 1.43317C7.13935 1.43317 8.06602 2.35984 8.06602 3.49984V4.83317Z"
        fill={`${selected ? '#4987be' : '#BDBDBD'}`}
      />
    </svg>
  );
};

export default PasswordIcon;
