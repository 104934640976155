import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { t } from '../../../i18n/i18n';
import './portfolioCollapsed.scss';

const PortfolioCollapsed = ({ portfolio: { url } }) => {
  const [urlFormatted, setUrlFormatted] = useState('');
  useEffect(() => {
    const portfolioUrl = new URL(!url.match(/^(http|https)/i) ? `https://${url}` : url);
    setUrlFormatted(portfolioUrl.pathname.length > 1 ? portfolioUrl.pathname : url);
  }, [url]);

  const handleLink = () => {
    window.open(url, '_blank');
  };
  return (
    <div
      className="position-relative mt-3 d-flex flex-row align-items-center style-input-portfolio text-truncate pr-4 cursor-pointer portfolio-container-custom"
      aria-hidden="true"
      onClick={handleLink}
    >
      <img
        src={`http://www.google.com/s2/favicons?domain=${url}`}
        alt={t('LOGO')}
        className="portfolio-margin-img-custom"
      />
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <span className=" font-weight-500 text-xs">{url}</span>
          </Tooltip>
        }
      >
        <span className=" font-weight-600 text-xs dashboard-group-name text-truncate portfolio-margin-span-custom">
          {urlFormatted}
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default PortfolioCollapsed;
