import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import SideBar from '../../components/SideBar/SideBar';
import MenuResponsive from '../../components/MenuResponsive/MenuResponsive';
import useSidebar from '../../hooks/useSidebar';
import useRoles from '../../hooks/useRoles';
import { getMaintenance, getWhiteLabelByCompanyId } from '../../API/services/authService';
import {
  setMaintenance,
  setPlan,
  setPlanFeatures,
  setWhitelabel,
} from '../../redux/actions/authActions';
import Maintenance from '../../pages/Maintenance';
import Spinner from '../../components/spinner/Spinner';
import './loggedLayout.scss';
import onboardingTour from '../../utils/tours/onboardingTour';
import useWindowSize from '../../hooks/useWindowSize';
import Modal from '../../components/Modal/Modal';
import { t, setLangKey } from '../../i18n/i18n';
import useTermsAndConditions from '../../hooks/useTermsAndConditions';
import { getPlanByWorkspaceId, getPlanFeatures } from '../../API/services/alkymersService';
import PDFViewer from '../../components/PDFViewer/PDFViewer';
import ModalPreferredLanguage from '../../components/ModalPreferredLanguage/ModalPreferredLanguage';
import { getLocalStorageWorkspace } from '../../utils/workspaceUtils';

const LoggedLayout = ({ children, register }) => {
  const { collapsed, setCollapsed } = useSidebar();
  const { refs } = useSelector((state) => state.ux);
  const { user, plan, currentWorkspace } = useSelector((state) => state.auth);
  const { isRole, roles } = useRoles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [alreadyOpen, setAlreadyOpen] = useState(true);
  const maintenance = useSelector((state) => state.auth.maintenance);
  const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const languageKey = useSelector((store) => store.ux.language);
  const { width } = useWindowSize();

  useEffect(() => {
    if (isAuthenticated) {
      if (!Object.values(plan).length && currentWorkspace) {
        getPlanByWorkspaceId(currentWorkspace).then((res) => {
          dispatch(setPlan(res));
          getPlanFeatures(res.subscription.subscriptionPending.planId).then((response) => {
            dispatch(setPlanFeatures(response));
          });
        });
      }
      getMaintenance().then((res) => {
        dispatch(setMaintenance({ maintenance: res.isOnMaintenance }));
        if (!res.isOnMaintenance) {
          const language = localStorage.getItem('language');
          setLoading(true);
          getWhiteLabelByCompanyId(getLocalStorageWorkspace()?.companyId || 1, language).then(
            (res) => {
              dispatch(setWhitelabel(res));
              setLoading(false);
            }
          );
          setLangKey(languageKey);
        } else {
          setLoading(false);
        }
      });
    }
  }, [isAuthenticated, currentWorkspace, languageKey]);

  useEffect(() => {
    if (
      document.querySelector('#DROPDOWN_WORKSPACE') &&
      document.querySelector('#MENU_ITEM_CREATE_EVALUATION') &&
      document.querySelector('#MENU_ITEM_SEARCHES') &&
      document.querySelector('#COMMUNITY_ALKEMY') &&
      JSON.parse(localStorage.getItem('isOnboarding')) &&
      width >= 992 &&
      isRole(roles.TESTSCREENINGSPARTNER) &&
      user?.termsAndConditionsAcceptance &&
      termsAndConditionsData?.createdAt <= user?.termsAndConditionsAcceptance &&
      alreadyOpen
    ) {
      setAlreadyOpen(false);
      onboardingTour(setOpen);
    }
  }, [
    refs,
    document.querySelector('#DROPDOWN'),
    document.querySelector('#MENU_ITEM_CREATE_SCREENING'),
    document.querySelector('#MENU_ITEM_SEARCHES'),
    document.querySelector('#TALENT'),
    JSON.parse(localStorage.getItem('isOnboarding')),
    isRole(roles.TESTSCREENINGSPARTNER),
  ]);

  const {
    showTermsAndConditionsModal,
    acceptTermsAndConditions,
    termsAndConditionsData,
    termsAndConditionsLoading,
    acceptedTermsAndConditions,
    setAcceptedTermsAndConditions,
  } = useTermsAndConditions({ platform: 'PARTNERS' });

  useEffect(() => {
    showTermsAndConditionsModal();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner show={loading} />
      ) : (
        <>
          {!user?.termsAndConditionsAcceptance ||
          termsAndConditionsData?.createdAt > user?.termsAndConditionsAcceptance ? (
            <div className="not-accepted-terms-and-conditions-modal-container">
              <div className="modal-bg-image" />
              <Modal
                className="h-100 pt-3 pb-2 px-4 modal-tac-container b-radius-8"
                show
                showCloseButton={false}
                closeBtnClassname="text-blue-principal modal-close-icon"
              >
                <>
                  {termsAndConditionsLoading ? (
                    <Spinner show />
                  ) : termsAndConditionsData ? (
                    <div className="d-flex flex-column h-100 py-4">
                      <span className=" font-weight-600 text-xs mb-2">
                        {t('TERMS_AND_CONDITIONS_HAS_BEEN_UPDATED')}
                      </span>
                      <PDFViewer pdf={termsAndConditionsData?.link} />
                      <div className="d-flex align-items-center mt-3 w-100 p-0">
                        <div>
                          <input
                            className="cursor-pointer"
                            type="checkbox"
                            onChange={() => setAcceptedTermsAndConditions((state) => !state)}
                            value={acceptedTermsAndConditions}
                            defaultChecked={acceptedTermsAndConditions}
                          />
                          <span className="ml-2  text-xs">
                            {t('HAVE_READ_AND_ACCEPTED')}
                            <span className="ml-1 border-0  text-purple">
                              {t('TERMS_AND_CONDITIONS')}
                            </span>
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={() => acceptTermsAndConditions()}
                          disabled={!acceptedTermsAndConditions}
                          className={`ml-auto rounded-pill text-white border-0 px-4 text-xs font-weight-500  login-form-button w-fit close-modal-button ${
                            !acceptedTermsAndConditions
                              ? 'disabled-button-background cursor-not-allowed'
                              : 'bg-maya-blue'
                          }`}
                        >
                          {t('ACCEPT_TERMS_AND_CONDITIONS')}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <span>{t('CANNOT_LOAD_TERMS_AND_CONDITIONS')}</span>
                  )}
                </>
              </Modal>
            </div>
          ) : (
            <>
              {register ? (
                <Header approved={false} open={open} setOpen={setOpen} />
              ) : (
                <>
                  <MenuResponsive />
                  <Header maintenance={maintenance} open={open} setOpen={setOpen} />
                </>
              )}
              {maintenance ? (
                <Maintenance />
              ) : register ? (
                children
              ) : (
                <>
                  <SideBar />
                  <div
                    className={
                      !collapsed
                        ? 'position-fixed w-100 h-100 top-0 right-0 left-0 bottom-0 overlay d-lg-none'
                        : ''
                    }
                    onClick={() => setCollapsed(true)}
                    aria-hidden="true"
                  />
                  <div className="home-section position-relative d-flex content-min-height overflow-hidden">
                    <div className="home-content d-flex align-items-center">{children}</div>
                    {user?.termsAndConditionsAcceptance &&
                      termsAndConditionsData?.createdAt <= user?.termsAndConditionsAcceptance &&
                      !JSON.parse(localStorage.getItem('isOnboarding')) && (
                        <ModalPreferredLanguage />
                      )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default LoggedLayout;
