import { useMemo } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { t } from '../../i18n/i18n';
import profileImageNull from '../../assets/profile/profile.png';
import EditPurpleIcon from '../../assets/common/EditPencilIcon';
import useTranslations from '../../hooks/useTranslations';

export const CategoryRow = ({
  category,
  onClickSetCategorySelected,
  onAddCategory = () => {},
  onRemoveCategory = () => {},
  onSelectCategoryToUpdate = () => {},
  categorySelected,
  canSelect,
  buttonPlus = false,
}) => {
  const { key } = useTranslations();

  const isChildrenOfSelected = useMemo(
    () => !!(categorySelected?.id === category?.parentId),
    [categorySelected?.id, category?.parentId]
  );
  const isSiblingOfSelected = useMemo(
    () =>
      !!(categorySelected?.parentId != null && categorySelected?.parentId === category?.parentId),
    [categorySelected?.parent?.children, category?.id]
  );
  const isGrandchildrenOfSelected = useMemo(
    () =>
      category?.parent &&
      category?.parent?.parentId &&
      category?.parent?.parentId === categorySelected?.id,
    [category, categorySelected?.id]
  );
  const isGrandparentOfSelected = useMemo(
    () =>
      categorySelected &&
      categorySelected?.parent &&
      categorySelected?.parent?.parentId &&
      category?.id === categorySelected?.parent?.parentId,
    [category?.id, categorySelected?.parent?.parentId]
  );

  const isParentOfSelected = useMemo(
    () => categorySelected?.parentId && categorySelected?.parentId === category?.id,
    [category?.id, categorySelected?.parentId]
  );
  const isSomeOfSelected = useMemo(
    () =>
      isChildrenOfSelected ||
      isSiblingOfSelected ||
      isGrandchildrenOfSelected ||
      isGrandparentOfSelected ||
      isParentOfSelected,
    [
      isChildrenOfSelected,
      isSiblingOfSelected,
      isGrandchildrenOfSelected,
      isGrandparentOfSelected,
      isParentOfSelected,
    ]
  );

  const selectedIsGrandChildren = useMemo(
    () => !!(categorySelected?.parent && categorySelected?.parent?.parentId),
    [categorySelected?.parent?.parentId]
  );

  const isGrandchildren = useMemo(
    () => !!(category?.parent && category?.parent?.parentId),
    [category?.parent?.parentId]
  );

  const onClickSelectCategory = () => {
    onSelectCategoryToUpdate(category);
  };
  return (
    <div
      className="test-button-filters-selected d-flex justify-content-between align-items-center"
      style={
        isSomeOfSelected
          ? {
              backgroundColor: '#4987be',
              color: 'white',
            }
          : {}
      }
    >
      <div className="ml-2">
        <img
          className="user-heading-img rounded-circle p-"
          style={{ width: '50px', height: '50px', filter: isSomeOfSelected ? 'invert(1)' : 'none' }}
          src={category?.iconUrl || profileImageNull}
          alt={t('PROFILE_MENU_LOGO_USER')}
        />
      </div>
      <div className="m-2 d-flex justify-content-around flex-column">
        <span>
          {category?.languages ? category?.languages[key] ?? category?.name : category?.name}
        </span>
      </div>
      <div className="d-flex">
        {!categorySelected && (
          <button
            type="button"
            data-testid="create-evaluation-second-step-button"
            className={`bg-transparent p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
            style={isSomeOfSelected ? { borderColor: '#FFFF' } : {}}
            onClick={() => onClickSelectCategory()}
          >
            <span
              className={` test-text-filters ${'test-text-filters-selected'}`}
              data-testid="create-evaluation-ss-filters-selected-span"
              style={{ color: '#449cc8' }}
            >
              <EditPurpleIcon />
            </span>
          </button>
        )}

        {canSelect && (
          <button
            type="button"
            data-testid="create-evaluation-second-step-button"
            className={`bg-transparent p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
            style={isSomeOfSelected ? { borderColor: '#FFFF' } : {}}
            onClick={() => onClickSetCategorySelected(category)}
          >
            <span
              className={` test-text-filters ${'test-text-filters-selected'}`}
              data-testid="create-evaluation-ss-filters-selected-span"
              style={isSomeOfSelected ? { color: '#FFFF' } : {}}
            >
              {t('CATEGORY_SELECT')}
            </span>
          </button>
        )}
        {buttonPlus &&
          categorySelected &&
          !selectedIsGrandChildren &&
          !isParentOfSelected &&
          !isGrandparentOfSelected &&
          !isGrandchildren &&
          !isChildrenOfSelected &&
          category?.children?.length === 0 && (
            <button
              type="button"
              data-testid="create-evaluation-second-step-button"
              className={`bg-transparent p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
              onClick={() => onAddCategory({ parent: categorySelected, categoryToAdd: category })}
              style={isSomeOfSelected ? { borderColor: '#FFFF' } : {}}
            >
              <span
                className={` test-text-filters ${'test-text-filters-selected'}`}
                data-testid="create-evaluation-ss-filters-selected-span"
                style={isSomeOfSelected ? { color: '#FFFF' } : {}}
              >
                {t('+')}
              </span>
            </button>
          )}
        {categorySelected && isChildrenOfSelected && (
          <button
            type="button"
            data-testid="create-evaluation-second-step-button"
            className={`bg-transparent p-2 d-flex m-2 justify-content-center align-items-center  ${'test-button-filters'}`}
            style={isSomeOfSelected ? { borderColor: '#FFFF' } : {}}
            onClick={() =>
              onRemoveCategory({
                categoryToRemove: category,
              })
            }
          >
            <span
              className={` test-text-filters ${'test-text-filters-selected'}`}
              data-testid="create-evaluation-ss-filters-selected-span"
              style={isSomeOfSelected ? { color: '#FFFF' } : {}}
            >
              <ArrowLeftOutlined />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};
