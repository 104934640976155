import { useRef, useState } from 'react';
import { t } from '../../i18n/i18n';
import { ProcessStatusStageColor } from '../../constants/enums';
import useClickOutside from '../../hooks/useClickOutside';
import './DropdownButton.scss';

const DropdownButton = ({ options = [], setState, allOption, content, id = 'id' }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false));

  const handleClick = (value) => {
    setState(value);
    setOpen(false);
  };

  return (
    <div>
      <div ref={ref}>
        <div aria-hidden onClick={() => setOpen((state) => !state)}>
          {content}
        </div>
        <ul
          className="dropdown-button-options-container bg-white overflow-auto custom-scrollbar m-0 position-absolute rounded py-2 gray-subtitle"
          data-open={open}
        >
          {allOption && (
            <li
              className="py-1 px-3 cursor-pointer dropdown-selec-option text-xs  letter-spacing-05"
              onClick={() => handleClick({ name: t('ALL'), id: null })}
              onKeyPress={() => handleClick({ name: t('ALL'), id: null })}
              role="option"
              aria-selected
            >
              {t('ALL')}
            </li>
          )}
          {options.map((option, i) => (
            <div key={i} className="d-flex p-2">
              <div
                className={`screening-completion-dot mt-1 mr-2 ${
                  ProcessStatusStageColor[option.value]
                }`}
              />
              <li
                className="py-1 px-1 cursor-pointer dropdown-selec-option text-xs  font-italic"
                key={i}
                onClick={() =>
                  handleClick({
                    id: option[id],
                    ...option,
                  })
                }
                onKeyPress={() =>
                  handleClick({
                    id: option[id],
                    ...option,
                  })
                }
                role="option"
                aria-selected
              >
                {option.name}
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownButton;
