import React, { useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import { t } from '../../i18n/i18n';
import useCreateScreening from '../../hooks/useCreateScreening';
import { isNullOrUndefined } from '../../utils/typesUtils';
import Checkbox from '../Checkbox/Checkbox';
import useCreateEvaluation from '../../hooks/useCreateEvaluation';
import QuestionMark from '../../assets/createEvaluation/question_mark.png';
import { IoMdArrowRoundBack } from 'react-icons/io';
import useTranslations from '../../hooks/useTranslations';
import { EVALUATION_SEARCH_TYPES, ReviewTypeEnum } from '../../constants/enums';
import Spinner from '../spinner/Spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

const CreateEvaluationFirstStep = ({
  evaluation,
  handleSetEvaluationData,
  draftId,
  handleCreateDraft,
  handleEditDraft,
  searchTypesLoading,
}) => {
  const {
    testGroupRolesFilterOptions,
    fetchRoles,
    testGroupQuickRolesOptions,
    fetchQuickRolesWithTestGroups,
  } = useCreateScreening();

  const {
    handleSetSearchType,
    searchTypes,
    setSearchTypesData,
    handleBackButton,
    isSelected,
    clearDataOnSelectChange,
    loadingCreateDraft,
  } = useCreateEvaluation();

  const [subRoles, setSubRoles] = useState([]);
  const [detailedRoles, setDetailedRoles] = useState([]);
  const { key } = useTranslations();

  useEffect(() => {
    fetchRoles();
    if (!testGroupQuickRolesOptions?.length) {
      fetchQuickRolesWithTestGroups();
    }
    handleSetEvaluationData({
      ...evaluation,
      startDate: {
        day: new Date().toISOString(),
        hours: '00:00',
      },
      endDate: {
        day: new Date('9999-01-01').toISOString(),
        hours: '00:00',
      },
      allowCertificateDownload: true,
    });

    return () => {};
  }, []);

  useEffect(() => {
    if (!testGroupRolesFilterOptions.length) return;
    const filteredDetailedRoles = testGroupRolesFilterOptions.filter(
      (role) => role.parent?.parent && role.parent?.parent?.parentId == null
    );
    const filteredSubRoles = testGroupRolesFilterOptions.filter(
      (role) => role.parent && role.parent?.parentId == null
    );
    setSubRoles(filteredSubRoles);
    setDetailedRoles(filteredDetailedRoles);
  }, [testGroupRolesFilterOptions]);

  const handleSelectRole = async (roleId) => {
    await handleSetEvaluationData({
      ...evaluation,
      roleId,
    });
    clearDataOnSelectChange();
  };

  const handleSelectLanguage = (language) => {
    // Funcion para cambiar de lenguaje la evaluacion cuando este la logica
  };

  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(async () => {
    const setData = async () => {
      if (!isNullOrUndefined(evaluation.searchTypes) && firstLoad) {
        if (Object.keys(evaluation?.searchTypes)?.length) {
          await setSearchTypesData(evaluation.searchTypes);
          setFirstLoad(false);
        }
      }
    };
    await setData();
  }, [evaluation]);

  const searchTypesSelected = () => {
    return (
      Boolean(evaluation.searchTypes?.[2]?.length) && Boolean(evaluation.searchTypes?.[3]?.length)
    );
  };

  const isPsychotechnic = () => {
    const selectedSearchType = evaluation?.searchTypes?.[1]?.[0];
    return (
      searchTypes?.[1]?.find((item) => item._id === selectedSearchType)?.type ===
      ReviewTypeEnum.PSYCHOTECHNICS
    );
  };

  const nextStepEnabled = () => {
    if (!loadingCreateDraft) {
      if (isPsychotechnic()) {
        return Boolean(evaluation?.searchTypes?.[2]?.length);
      } else {
        return searchTypesSelected();
      }
    } else {
      return false;
    }
  };

  return (
    <div data-testid="create-evaluation-first-step" className="d-flex flex-column h-100">
      {searchTypesLoading ? (
        <div className="d-flex h-100 w-100 justify-content-center align-items-center">
          <Spinner show />
        </div>
      ) : (
        <div>
          <div className="d-flex align-items-center mt-2 w-100">
            {/* Keep comment until languaged are being managed by the system. 
            <DropdownSelect
              options={languagesList.map((item) => ({
                id: item.value,
                name: item.label,
              }))}
              className="create-screening-dropdown-language"
              selected="es"
              maxHeight={false}
              arrowPurple
              arrow={false}
              selectedPlaceholder
              setState={(e) => handleSelectLanguage(e)}
              alignCenter={false}
              disabled
            /> */}
          </div>
          <div className="d-flex flex-column  mt-4 align-items-center">
            <div className="d-flex justify-content-start align-items-center gap-1 w-100">
              {!isNullOrUndefined(searchTypes[0]) &&
                isNullOrUndefined(evaluation?.searchTypes?.[0]) && (
                  <div className="d-flex flex-column align-items-center justify-content-center w-100 gap-2">
                    <img src={QuestionMark} alt="" className="first-step-question-mark" />
                    <span className="text-sm font-weight-600">
                      {t('CREATE_EVALUATION_TITLE_SEARCH_TYPES')}
                    </span>
                    <div className="d-flex flex-column justify-content-center flex-lg-row gap-2">
                      {searchTypes?.[0]
                        ?.sort((a, b) => (a.type === "DON'T_NEED_HELP" ? -1 : 1))
                        .map((item) => (
                          <Button
                            key={item?._id}
                            type={item.type == 'NEED_HELP' && 'primary'}
                            className="rounded-0 d-flex align-items-center justify-content-center px-3 py-2 text-xs font-weight-600"
                            onClick={() => {
                              if (item.type == 'NEED_HELP') {
                                handleSetSearchType(item?._id, 0);
                              } else {
                                !draftId ? handleCreateDraft() : handleEditDraft();
                              }
                            }}
                          >
                            <span className="">{t(item?.whitelabelName)}</span>
                          </Button>
                        ))}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {' '}
                            <div className="d-flex align-items-center flex-column py-2 px-2">
                              <span className=" text-xs mb-2">
                                {t('CREATE_EVALUATION_INFO_SEARCH_TYPES')}
                              </span>
                            </div>
                          </Tooltip>
                        }
                      >
                        <AiOutlineQuestionCircle
                          color="black"
                          size="1.5em"
                          className="align-self-center justify-self-center"
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                )}
            </div>
            <div className="w-100 d-flex flex-column flex-md-row justify-content-center  p-0 m-0 gap-2">
              <div className="p-0 m-0 d-flex align-self-start justify-self-end mx-4">
                {!isNullOrUndefined(evaluation.searchTypes?.[0]) && (
                  <Button
                    onClick={handleBackButton}
                    className="rounded-0 button-back-create-screening text-base align-self-center d-flex align-items-center"
                    onFocus={(e) => e.target.blur()}
                  >
                    <IoMdArrowRoundBack size={'2rem'} />
                  </Button>
                )}
              </div>
              <div className="row p-0 m-0 gap-2 w-100 d-flex justify-items-center">
                <div className="col-12 row p-0 m-0 gap-1 d-flex justify-content-center w-100">
                  {!isNullOrUndefined(searchTypes[1]) &&
                    searchTypes?.[1]?.map((item) => (
                      <div
                        key={item?._id}
                        className={`col-12 col-md-3 p-0 px-2 py-2 gap-1 m-0 d-flex flex-column align-items-center
                          ${
                            isSelected(item?._id, 1)
                              ? 'selected-search-type'
                              : 'border-item-search-type'
                          }`}
                        onClick={() => handleSetSearchType(item?._id, 1)}
                      >
                        <span className="text-center text-xs font-weight-600">
                          {t(item?.whitelabelName || item?.categoryId?.name)}
                        </span>
                        {item.icon && (
                          <img
                            src={`${process.env.RAZZLE_RUNTIME_ASSETS}${item.icon}`}
                            alt="Type of search"
                            className="type-of-search-option-icon"
                          />
                        )}
                        {item?.description?.[key] && (
                          <p className="text-center font-weight-500 text-xs">
                            {item?.description?.[key]}
                          </p>
                        )}
                      </div>
                    ))}
                  {searchTypes?.[1]
                    ?.filter((obj) => obj.type === EVALUATION_SEARCH_TYPES.RECRUITMENT)
                    ?.some((obj) => evaluation?.searchTypes?.[1]?.includes(obj._id)) && (
                    <div className="col-9 d-flex w-100 flex-column align-items-center gap-2 mt-4">
                      <span className="font-weight-600 text-xs">
                        {t('SEARCH_TYPE_DETAILED_ROLE')}
                      </span>
                      <Select
                        showSearch
                        className="d-flex p-0 m-0 w-100 "
                        placeholder={t('CREATE_EVALUATION_ROLE_TESTS_INPUT_PLACEHOLDER')}
                        optionFilterProp="label"
                        options={(detailedRoles ?? []).map((item) => ({
                          value: item.id,
                          label: t(item?.whitelabelName),
                        }))}
                        allowClear
                        onClear={() => handleSelectRole(null)}
                        notFoundContent={
                          <div className="d-flex justify-content-center text-centeralign-items-center mt-2">
                            <Button
                              onClick={fetchRoles}
                              className=" bg-gray px-3 py-1 text-xs font-weight-600"
                            >
                              {t('RECHARGE_ROLES')}
                            </Button>
                          </div>
                        }
                        onSelect={async (e) => {
                          await handleSelectRole(e);
                        }}
                        defaultValue={evaluation.roleId}
                      />
                      <Button
                        onClick={
                          evaluation?.roleId && (draftId ? handleEditDraft : handleCreateDraft)
                        }
                        className={`rounded-0 align-items-center px-3 d-flex font-weight-600 text-xs  text-white bg-blue-principal
                    ${!evaluation.roleId || loadingCreateDraft ? 'disabled-button' : 'bg-maya-blue'}
                    `}
                        type="button"
                        disabled={!evaluation.roleId || loadingCreateDraft}
                        data-testid="create-evaluation-create-draft-btn"
                      >
                        {t('CREATE_EVALUATION_NEXT_STEP_BUTTON')}
                      </Button>
                    </div>
                  )}
                </div>
                <div className="row d-flex justify-content-center w-100 gap-2">
                  {!isNullOrUndefined(searchTypes[2]) && (
                    <div className="col-10 col-md-4 d-flex flex-column gap-1">
                      <span className="text-xs font-weight-600 text-center">
                        {t('CREATE_EVALUATION_SECTIONS')}
                      </span>
                      {searchTypes?.[2]?.map((item) => (
                        <div
                          key={item?._id}
                          className={`d-flex align-items-center
                                                        ${
                                                          isSelected(item?._id, 2)
                                                            ? 'selected-search-type'
                                                            : 'border-item-search-type'
                                                        }
                            `}
                          onClick={() => handleSetSearchType(item?._id, 2)}
                        >
                          <span
                            className={`d-flex align-items-center px-3 py-1 text-xs font-weight-600 justify-content-between
                                `}
                          >
                            {t(item?.whitelabelName || item?.categoryId?.name)}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                  {!isNullOrUndefined(searchTypes[3]) && (
                    <div className="col-10 col-md-4 d-flex flex-column gap-1">
                      <span className="text-xs font-weight-600 text-center">
                        {t('CREATE_EVALUATION_SUB_SECTIONS')}
                      </span>
                      {searchTypes?.[3]
                        ?.sort((a, b) => {
                          if (a.type === 'OTHER') return 1;
                          if (b.type === 'OTHER') return -1;
                          return 0;
                        })
                        .map((item) => (
                          <div
                            key={item?._id}
                            className={`d-flex align-items-center
                            ${
                              isSelected(item?._id, 3)
                                ? 'selected-search-type'
                                : 'border-item-search-type'
                            }
                          `}
                            onClick={() => handleSetSearchType(item?._id, 3)}
                          >
                            <span
                              className={`d-flex align-items-center px-3 py-1 text-xs font-weight-600 justify-content-between `}
                            >
                              <Checkbox
                                className="create-evaluation-checkbox"
                                searchTypesSelected={evaluation.searchTypes}
                                isSelected={isSelected}
                                testid={item?._id}
                                filterIndex={3}
                              />
                              {item?.categoryId?.languages?.[key] ?? t(item?.whitelabelName)}
                            </span>
                          </div>
                        ))}
                    </div>
                  )}
                  {(!isNullOrUndefined(searchTypes[2]) || isPsychotechnic()) && (
                    <div className="col-12 d-flex w-100 justify-content-center">
                      <Button
                        onClick={() => {
                          if (nextStepEnabled() && draftId) {
                            handleEditDraft();
                          } else {
                            handleCreateDraft();
                          }
                        }}
                        className={`rounded-0 align-items-center px-3 d-flex justify-content-center font-weight-600 text-xs  text-white bg-blue-principal
                    ${!nextStepEnabled() ? 'disabled-button' : 'bg-maya-blue'}
                    `}
                        disabled={!nextStepEnabled()}
                        type="button"
                        data-testid="create-evaluation-create-draft-btn"
                      >
                        {t('CREATE_EVALUATION_NEXT_STEP_BUTTON')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateEvaluationFirstStep;
