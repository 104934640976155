import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatNumberWithThousandsSeparator, nextMultiple } from '../../utils/numbersUtils';
import './HorizontalBarsChart.scss';

export default function HorizontalBarsChart({
  matrix,
  rows,
  columns,
  colors,
  maxVal,
  getTranslation,
}) {
  const [references, setReferences] = useState([]);
  const [referenceMaxValue, setReferenceMaxValue] = useState(0);

  const getStepMultiplier = (value) => {
    let step = 1;
    if (value <= 5) {
      step = 1;
    } else if (value <= 20) {
      step = 2;
    } else if (value <= 100) {
      step = 5;
    } else if (value <= 500) {
      step = 10;
    } else if (value <= 1000) {
      step = 25;
    } else if (value <= 5000) {
      step = 50;
    } else if (value <= 10000) {
      step = 100;
    } else if (value <= 50000) {
      step = 500;
    } else {
      step = 1000;
    }
    return step;
  };

  const getReferencesList = (maxValue) => {
    const sections = 5;
    const stepMultiplier = getStepMultiplier(maxValue);
    const nextMultipleValue = nextMultiple(maxValue / sections, stepMultiplier);
    const referenceMaxValue = nextMultipleValue * sections;
    setReferenceMaxValue(referenceMaxValue);
    const stepSize = referenceMaxValue / sections;

    const references = [];
    let reference = 0;

    while (reference <= referenceMaxValue) {
      references.push(reference);
      reference += stepSize;
    }

    return references;
  };

  useEffect(() => {
    if (maxVal) setReferences(getReferencesList(maxVal));
  }, [maxVal]);

  return (
    <div className="mt-3 d-flex position-relative">
      <div>
        {rows.map((row) => (
          <div
            className="d-flex align-items-center justify-content-end my-3 horizontal-bars-row-height"
            key={row}
            data-testid="horizontal-bar-chart-row-legend"
          >
            <span className=" horizontal-bar-text">{getTranslation(row)}</span>
          </div>
        ))}
      </div>
      <div className="flex-grow-1 ml-3 pr-3">
        {matrix.map((row, index) => (
          <div
            key={index}
            className="my-3 d-flex align-items-center horizontal-bars-bar-section"
            data-testid="horizontal-bar-chart-row"
          >
            {row.map((rowVal, index) => (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    <>
                      <span className=" ml-1 text-xs">{getTranslation(columns[index])}</span>
                      <b className="ml-1  text-xs">
                        ({formatNumberWithThousandsSeparator(rowVal)})
                      </b>
                    </>
                  </Tooltip>
                }
                key={index}
              >
                <div
                  className="horizontal-bars-bar-section"
                  style={{
                    backgroundColor: colors[index],
                    width: `${(rowVal * 100) / referenceMaxValue}%`,
                  }}
                />
              </OverlayTrigger>
            ))}
          </div>
        ))}
        <div className="d-flex justify-content-between pt-2 horizontal-bar-chart-lines">
          {references.map((value) => (
            <span
              className=" d-flex justify-content-center horizontal-bar-column-value"
              key={value}
              data-testid="horizontal-bars-chart-reference"
            >
              {formatNumberWithThousandsSeparator(value)}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
