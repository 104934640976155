import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { t } from '../../i18n/i18n';
import FormRow from './FormRow';
import { validateEmail, validateName } from '../../validations/screeningValidation';
import { chunkArray, findDuplicates } from '../../utils/arrayUtils';
import ScreeningImport from '../ScreeningImport/ScreeningImport';
import ModalCustom from '../ModalCustom/ModalCustom';
import { CREATE_TEMPLATE, SCREENING_SUCCESS } from '../../constants/routes';
import './ScreeningForm.scss';
import useRefillRows from '../../hooks/useRefillRows';
import { setEmailsData } from '../../redux/actions/screeningActions';
import useTableBatchAction from '../../hooks/useTableBatchAction';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import SearchLink from '../SearchLink/SearchLink';
import { getDuplicateInvitations } from '../../API/services/alkymersService';
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
import { inHtmlTag } from '../../utils/inHtmlTag';
import { determineDisabledFeature } from '../../utils/determineDisabled';
import { PlanFeaturesIds } from '../../constants/enums';
import DisabledFeature from '../DisabledFeature/DisabledFeature';

const ScreeningForm = ({ viewSubtitle = true }) => {
  const dispatch = useDispatch();
  const { searchInvitations, searchWasAlreadyCreated } = useTableBatchAction();
  const { emailsData, sendEmailFrom, newSearch, selectedSearch } = useSelector(
    (state) => state.screenings
  );
  const { planFeatures } = useSelector((state) => state.auth);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { emailsList } = useRefillRows();
  const [handleValidation, setHandleValidation] = useState(0);
  const initialState = [v4(), v4(), v4()];
  const [rows, setRows] = useState(Object.keys(emailsData).length ? emailsList : initialState);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [data, setData] = useState(emailsData);
  const [numberRowsAdd, setnumberRowsAdd] = useState(1);
  const [duplicatedEmails, setDuplicatedEmails] = useState([]);
  const [showModalAddRow, setShowModalAddRow] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [rowsError, setRowsError] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [duplicatedInvitations, setDuplicatedInvitations] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const planInvitationsAmount = planFeatures?.features?.find(
    (feature) => feature.id === PlanFeaturesIds.MAX_INVITATIONS
  )?.amount;

  const maxInvitationsBatch = Math.min(planInvitationsAmount, searchInvitations.invitationsLeft);

  const handleChangeAddRows = (e) => {
    const parsedNumber = parseInt(e.target.value, 10);
    setRowsError(null);
    if (parsedNumber + rows.length > maxInvitationsBatch) {
      setRowsError({ message: t('ONLY_CAN_INVITE_UP_TO_300_PERSONS') });
    }
    setnumberRowsAdd(parsedNumber);
  };

  const addRow = () => {
    if (!rowsError) {
      const newRows = [];
      for (let i = 0; i < numberRowsAdd; i += 1) {
        if (rows.length < maxInvitationsBatch) {
          rows.push(v4());
        }
      }
      setRows([...rows, ...newRows]);
      setHandleValidation(0);
      setShowModalAddRow(false);
      setnumberRowsAdd(1);
      setRowsError(null);
    }
  };

  const addRows = (arr = []) => {
    setRowsError(null);
    const notEmptyRows = [];

    Object.entries(data).forEach(([key, value]) => {
      if (Object.values(value).some((field) => field?.length)) notEmptyRows.push(key);
    });

    const keys = arr.map(() => v4());

    const newData = {};
    arr.forEach((row, index) => {
      newData[keys[index]] = row;
    });
    const filteredEmpty = {};
    Object.entries(data).forEach(([value, index]) => {
      if (notEmptyRows.includes(index)) filteredEmpty[index] = value;
    });
    const finalData = {
      ...data,
      ...newData,
    };

    if (Object.keys(finalData).length > maxInvitationsBatch) {
      setRowsError({ message: t('ONLY_CAN_INVITE_UP_TO_300_PERSONS') });
      notification.open({
        message: t('ONLY_CAN_INVITE_UP_TO_300_PERSONS'),
        type: 'error',
      });
    } else {
      setRows([...notEmptyRows, ...keys]);
      setData({ ...data, ...newData });
      setRowsError(null);
    }

    setHandleValidation(0);
  };

  const removeRow = (index) => {
    if (rows.length > 1) {
      setRows((currentRows) => currentRows.filter((row) => row !== index));
      setErrors((currentErrors) => {
        const newErrors = {};
        Object.keys(currentErrors)
          .filter((key) => key !== index)
          .forEach((key) => {
            newErrors[key] = currentErrors[key];
          });
        return newErrors;
      });
      setData((currentData) => {
        const newData = {};
        Object.keys(currentData)
          .filter((key) => key !== index)
          .forEach((key) => {
            newData[key] = currentData[key];
          });
        return newData;
      });
    } else {
      setErrors({});
      setData({});
      setRows([v4()]);
      setHandleValidation(0);
    }
  };

  const removeAllRows = () => {
    setData({});
    setRows(initialState);
  };

  const processDuplicatedEmails = () => {
    setDuplicatedEmails(findDuplicates(Object.values(data).map((obj) => obj.email?.toLowerCase())));
  };

  const continueStep = async () => {
    setIsLoading(true);
    const stage = newSearch?.stages[0] ?? selectedSearch?.stages[0];
    const emailsData = Object.values(data).map((data) => data.email?.toLowerCase());
    const emails = chunkArray(emailsData, Number(process.env.RAZZLE_RUNTIME_EMAIL_BATCH_SIZE));
    const duplicateCandidates = [];

    await Promise.all(
      emails.map(async (items) => {
        const candidates = await getDuplicateInvitations(
          stage?.id,
          items.filter((email) => validateEmail(email)).map((email) => email.toLowerCase())
        );
        duplicateCandidates.push(...candidates);
      })
    );

    setIsLoading(false);
    if (Object.keys(data).length > maxInvitationsBatch || !maxInvitationsBatch) {
      notification.open({
        message: t('ONLY_CAN_INVITE_UP_TO_300_PERSONS'),
        type: 'error',
      });
    } else if (Object.values(data).length) {
      const error = Object.values(data).some(
        (obj) =>
          !validateName(obj.firstName) || !validateName(obj.lastName) || !validateEmail(obj.email)
      );
      if (duplicatedEmails?.length || Object.values(errors).some((item) => item) || error) {
        setEmailError(true);
        setLastNameError(true);
        setFirstNameError(true);
        notification.open({
          message: t('EMAILS_LIST_ERROR_NOTIFICATION'),
          type: 'error',
        });
      } else if (duplicateCandidates.length) {
        setDuplicatedEmails(duplicateCandidates);
        setDuplicatedInvitations(true);
        notification.open({
          message:
            duplicateCandidates.length === 1
              ? t('DUPLICATE_INVITATION_ERROR_NOTIFICATION_SINGULAR')
              : t('DUPLICATE_INVITATION_ERROR_NOTIFICATION'),
          type: 'error',
        });
      } else {
        if (sendEmailFrom === 'new') {
          TrackEvent('create-search-next-p3');
        }
        history.push(CREATE_TEMPLATE);
        dispatch(setEmailsData(data));
      }
    } else {
      setShowModalConfirm(true);
    }
  };

  useEffect(() => {
    setButtonDisabled(
      Object.values(errors).some((value) => value) ||
        Object.values(data).some((obj) => duplicatedEmails.includes(obj.email?.toLowerCase())) ||
        searchInvitations.invitationsLeft === 0
    );
  }, [errors, duplicatedEmails]);

  useEffect(() => {
    processDuplicatedEmails();
  }, [data, rows]);

  return (
    <div className="bg-white rounded p-2">
      {showModalAddRow && (
        <ModalCustom
          datatestid="modal-add-rows-email"
          datatestidbuttonok="button-ok"
          title={t('CREATE_SEARCH_MODAL_ADD_ROWS')}
          htmlSubtitle
          subtitle={t('ADD_INVITATIONS_ROWS_FEW_INVITATIONS_LEFT').replace(
            '{invitationsLeft}',
            inHtmlTag({ tag: 'b', content: maxInvitationsBatch })
          )}
          show={showModalAddRow}
          setShow={setShowModalAddRow}
          onOkText={t('CREATE_SEARCH_MODAL_BUTTON_ADD')}
          onOk={rowsError ? undefined : addRow}
          onOkDisabled={rowsError}
          classModal="modal-wrap-add-row"
          classCross="text-base text-blue-principal"
          input={
            <input
              data-testid="input-quantity-add-rows"
              ref={(ref) => ref && ref.focus()}
              className="outline-none custom-date-button ml-2 mt-3 py-2 px-4 text-center font-weight-600 text-xs w-25 mx-auto"
              value={numberRowsAdd}
              max={maxInvitationsBatch}
              onChange={handleChangeAddRows}
              type="number"
            />
          }
        />
      )}
      {showModalConfirm && (
        <ModalCustom
          title={t('CONFIRM_MODAL_CREATE_SEARCH_ALLOW_CANDIDATES')}
          subtitle={t('MODAL_CREATE_SEARCH_ALLOW_CANDIDATES_SUBTITLE')}
          show={showModalConfirm}
          setShow={setShowModalConfirm}
          onOkText={
            searchWasAlreadyCreated
              ? t('CREATE_SCREENING_CONTINUE')
              : t('MODAL_CREATE_SEARCH_ALLOW_CANDIDATES_OK')
          }
          onOk={() => {
            history.push(SCREENING_SUCCESS, { from: 'ADD_CANDIDATES' });
          }}
          onCancel={() => setShowModalConfirm(false)}
          onCancelText={t('MODAL_CREATE_SEARCH_ALLOW_CANDIDATES_BACK')}
          classModal="modal-wrap-add-row"
          classCross="text-base text-blue-principal"
        />
      )}
      {viewSubtitle && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className=" font-weight-bold text-base screening-name-title">
            {t('SCREENINGS_FORM_SUBTITLE')}
          </span>
        </div>
      )}
      <div className="row p-1">
        <DisabledFeature
          featureId={PlanFeaturesIds.MAX_INVITATIONS}
          validateAmount
          usedAmount={searchInvitations.invitationsUsed}
          className="d-flex flex-column align-items-center"
        >
          {Object.keys(data).length > 0 && (
            <p className=" font-weight-bold text-xs m-0">
              {`${t('CREATE_SEARCH_ENTERED_CANDIDATES')}: `}
              <span className="text-blue-principal">{Object.keys(data).length}</span>
            </p>
          )}
          <div className="w-100 email-form-max-height overflow-auto">
            {rows.map((index) => (
              <FormRow
                data={data[index] ?? {}}
                setData={setData}
                setError={setErrors}
                emailError={emailError}
                firstNameError={firstNameError}
                lastNameError={lastNameError}
                rowIndex={index}
                key={index}
                handleValidation={handleValidation}
                allErrors={errors}
                remove={removeRow}
                duplicatedEmails={duplicatedEmails}
                duplicatedInvitations={duplicatedInvitations}
                setDuplicatedInvitations={setDuplicatedInvitations}
              />
            ))}
          </div>
          <div className="my-2 mx-1 d-flex justify-content-between w-100">
            <button
              className="text-blue-principal outline-none border-0 bg-transparent text-xs  font-weight-600 add-more-rows-button"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title={t('ONLY_CAN_INVITE_UP_TO_300_PERSONS')}
              disabled={rows.length > maxInvitationsBatch}
              onClick={() => {
                TrackEvent(`search-add-rows-${sendEmailFrom}`);
                setShowModalAddRow(true);
              }}
            >
              {t('ADD_MOST_ROWS')}
            </button>
            <button
              type="button"
              className="text-danger outline-none border-0 mr-1 bg-transparent text-xs  font-weight-600"
              onClick={() => {
                TrackEvent(`search-delete-all-rows-${sendEmailFrom}`);
                removeAllRows();
              }}
            >
              {t('CREATE_SEARCH_DELETE_ALL')}
            </button>
          </div>
          <ScreeningImport
            className="py-2"
            addRows={addRows}
            maxInvitationsBatch={maxInvitationsBatch}
          />
        </DisabledFeature>
        <SearchLink
          track="copy-link-dashboard"
          link={
            determineDisabledFeature({ featureId: PlanFeaturesIds.SEARCH_LINKS })
              ? t('NOT_AVAILABLE')
              : searchInvitations.invitationPublicLink
          }
        />
        <div className="d-flex justify-content-end w-100">
          <DisabledFeature
            featureId={PlanFeaturesIds.MAX_INVITATIONS}
            validateAmount
            usedAmount={searchInvitations.invitationsUsed + Object.keys(data).length - 1}
            className="d-flex flex-column align-items-center"
          >
            {isLoading ? (
              <HorizontalLoader />
            ) : (
              <button
                disabled={buttonDisabled}
                className={`mt-4 align-self-end px-3 py-2 rounded-0  text-white text-xs font-weight-bold border-0 outline-none ${
                  buttonDisabled ? 'bg-disabled' : 'bg-maya-blue'
                }`}
                type="button"
                onClick={async () => {
                  TrackEvent('send-email-mails-continue');
                  await continueStep();
                }}
              >
                {t('CREATE_SCREENING_CONTINUE')}
              </button>
            )}
          </DisabledFeature>
        </div>
      </div>
    </div>
  );
};

export default ScreeningForm;
