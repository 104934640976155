const Reopen = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.70078 11.2C1.31578 11.2 0.986315 11.063 0.712381 10.7891C0.438448 10.5152 0.301248 10.1855 0.300781 9.8V1.4C0.300781 1.015 0.437981 0.685533 0.712381 0.4116C0.986781 0.137667 1.31625 0.000466667 1.70078 0H12.9008C13.2858 0 13.6155 0.1372 13.8899 0.4116C14.1643 0.686 14.3012 1.01547 14.3008 1.4V6.3H12.9008V2.8H1.70078V9.8H7.30078V11.2H1.70078ZM12.2008 14C11.3491 14 10.6055 13.7347 9.96988 13.2041C9.33428 12.6735 8.93458 12.0055 8.77078 11.2H9.85578C10.0074 11.7133 10.2963 12.1333 10.7224 12.46C11.1484 12.7867 11.6412 12.95 12.2008 12.95C12.8774 12.95 13.4549 12.7108 13.9333 12.2325C14.4116 11.7542 14.6508 11.1767 14.6508 10.5C14.6508 9.82333 14.4116 9.24583 13.9333 8.7675C13.4549 8.28917 12.8774 8.05 12.2008 8.05C11.8624 8.05 11.5474 8.11113 11.2558 8.2334C10.9641 8.35567 10.7074 8.52787 10.4858 8.75H11.5008V9.8H8.70078V7H9.75078V7.9975C10.0658 7.69417 10.4333 7.4522 10.8533 7.2716C11.2733 7.091 11.7224 7.00047 12.2008 7C13.1691 7 13.9946 7.34137 14.6774 8.0241C15.3601 8.70683 15.7012 9.53213 15.7008 10.5C15.7008 11.4683 15.3594 12.2939 14.6767 12.9766C13.9939 13.6593 13.1686 14.0005 12.2008 14Z"
      fill="#357b9eff"
    />
  </svg>
);

export default Reopen;
