import React, { useRef } from 'react';
import './Accordion.scss';

function Accordion(props) {
  const content = useRef(null);
  const { open, children, fixWidth, maxHeightPercent, maxHeight, isProctoringDetail } = props;
  const maxHeightStyles = {
    maxHeight: maxHeight
      ? open
        ? maxHeight
        : '0px'
      : isProctoringDetail
      ? open
        ? '15.625rem'
        : '0px'
      : open
      ? maxHeightPercent
        ? `${maxHeightPercent}%`
        : `${content?.current?.scrollHeight * 2}px`
      : '0px',
  };
  return (
    <div className="accordion__section position-sticky left-0" style={{ width: fixWidth }}>
      <div
        ref={content}
        style={maxHeightStyles}
        className={`accordion__content ${
          isProctoringDetail || maxHeight ? 'overflow-auto' : 'overflow-hidden'
        }`}
      >
        <div className="accordion__text">{children}</div>
      </div>
    </div>
  );
}

export default Accordion;
