import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeSectionContainer from '../HomeSectionContainer/HomeSectionContainer';
import CardScreeningSearch from '../CardScreeningSearch/CardScreeningSearch';
import './ActiveSearches.scss';
import Spinner from '../spinner/Spinner';
import { t } from '../../i18n/i18n';
import AddEvaluationPlus from '../../assets/home/AddEvaluationPlus';
import { CREATE_EVALUATION, SCREENING_SEARCHES } from '../../constants/routes';
import { getCustomSearchs } from '../../API/services/screeningService';
import { PartnerWorkspaceRole } from '../../constants/enums';

const ActiveSearches = ({ activeSearches, loading, setActiveSearches, setLoading }) => {
  const history = useHistory();
  const maxOfProfilesToDisplay = 6;
  const { currentRole } = useSelector((state) => state.auth);

  const reloadSearches = async () => {
    await getCustomSearchs({ offset: 0, active: true, search: '' })
      .then((res) => {
        setActiveSearches(res.collection);
      })
      .finally(() => setLoading(false));
  };

  return (
    <HomeSectionContainer className="mb-3 active-searches-container">
      <div className="d-flex bg-grey-4">
        <span className="pl-3 w-75 text-sm font-weight-600 py-2 color-grey-1 align-items-center">
          {t('HOME_ACTIVE_SEARCHES')}
        </span>
        <button
          type="button"
          onClick={() => history.push(SCREENING_SEARCHES)}
          className="w-25 text-xxs font-weight-600 border-0 outline-none bg-blue-light py-2 color-blue-dark "
        >
          {t('HOME_SEARCHES_SEE_ALL_EVALUATIONS')}
        </button>
      </div>
      <div className="row px-2 pt-3 p-0 d-flex justify-content-center">
        {loading && <Spinner show />}
        <div className="col-12 col-xl-10 row p-0">
          {activeSearches?.length > 0 &&
            activeSearches
              .slice(0, maxOfProfilesToDisplay)
              .map((search) => (
                <CardScreeningSearch
                  data={{ ...search, isDraft: false }}
                  key={search.id}
                  reloadSearches={reloadSearches}
                />
              ))}
        </div>
        {currentRole !== PartnerWorkspaceRole.VISUALIZATOR && activeSearches?.length > 0 && (
          <div className="card-height col-12 col-xl-2 pt-5">
            <div className="m-0 d-flex flex-column align-items-center justify-content-center empty-screening-search-container">
              <button
                type="button"
                data-testid="empty-searches-add-evaluation-button"
                onClick={() => history.push(CREATE_EVALUATION)}
                className="bg-white border-0 outline-none bg-transparent d-flex flex-column align-items-center"
              >
                <AddEvaluationPlus />
                <span className="mt-2 text-xs font-weight-600">
                  {t('HOME_EMPTY_SEARCH_CREATE_EVALUATION')}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </HomeSectionContainer>
  );
};

export default ActiveSearches;
