const FullScreenLogo = () => (
  <svg width="21" height="21" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.57143 11.1717H0V17.2432H6.42857V14.8146H2.57143V11.1717ZM0 6.31459H2.57143V2.67174H6.42857V0.243164H0V6.31459ZM15.4286 14.8146H11.5714V17.2432H18V11.1717H15.4286V14.8146ZM11.5714 0.243164V2.67174H15.4286V6.31459H18V0.243164H11.5714Z"
      fill="#4987BE"
    />
  </svg>
);

export default FullScreenLogo;
