import { useState } from 'react';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import { t } from '../../i18n/i18n';
import InviteMembersForm from '../../components/InviteMembersForm/InviteMembersForm';
import MembersList from '../../components/MembersList/MembersList';
import './MembersConfiguration.scss';

const MembersConfiguration = () => {
  const [rerender, setRerender] = useState(0);
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [pagination, setPagination] = useState(null);

  return (
    <div data-testid="members-configuration-page" className="members-configuration-page-background">
      <div className="position-relative w-100 members-configuration-page-container d-flex flex-column overflow-md-hidden">
        <BackgroundTop
          title={t('MENU_ITEM_MEMBERS')}
          routes={[
            {
              text: 'CONFIGURATION_PAGE_BANNER',
              clickable: false,
            },
          ]}
          className="position-relative mb-3 py-3"
          titleClassname="text-title"
          contained={false}
        />
        <div className="d-flex flex-column flex-md-row-reverse flex-grow-1 members-configurations-page-content">
          <InviteMembersForm
            pagination={pagination}
            className="flex-grow-1 ml-md-5"
            loadingPage={loading}
            onSubmit={() => setRerender((value) => value + 1)}
          />
          <MembersList
            members={members}
            setMembers={setMembers}
            pagination={pagination}
            setLoadingPage={setLoading}
            setPagination={setPagination}
            className="flex-grow-1"
            rerender={rerender}
          />
        </div>
      </div>
    </div>
  );
};

export default MembersConfiguration;
