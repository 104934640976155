import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import ResetTestgroupStep1 from '../../components/ResetTestgroupStep1/ResetTestgroupStep1';
import ResetTestgroupStep2 from '../../components/ResetTestgroupStep2/ResetTestgroupStep2';
import ResetTestgroupStep3 from '../../components/ResetTestgroupStep3/ResetTestgroupStep3';
import ResetTestgroupStep4 from '../../components/ResetTestgroupStep4/ResetTestgroupStep4';
import ResetTestgroupStep5 from '../../components/ResetTestgroupStep5/ResetTestgroupStep5';
import useResetTestgroup from '../../hooks/useResetTestgroup';
import { t } from '../../i18n/i18n';
import './ResetTestgroups.scss';

const ResetTestgroups = () => {
  const {
    step,
    companies,
    companySearches,
    loading,
    selectedCompany,
    selectedSearch,
    testgroups,
    selectedTestgroups,
    invitations,
    selectedInstances,
    selectedPrefilterInstances,
    loadingInvitations,
    selectedInvitations,
    restartPrefilters,
    setRestartPrefilter,
    fetchSearchInvitationsNextPage,
    fetchSearchScreening,
    fetchCompanySearches,
    fetchCompanies,
    fetchSearchInvitations,
    handleSelectInstance,
    handleSelectTestgroup,
    handleSelectSearch,
    handleSelectCompany,
    handleNextStep,
    handlePreviousStep,
    clearSelectedInvitations,
    clearSelectedTestgroups,
  } = useResetTestgroup();
  return (
    <div data-testid="reset-testgroups-container" className="w-100 mx-auto h-100">
      <BackgroundTop
        title={t('RESET_TESTGROUP_SECTION_TITLE')}
        showBase
        className="position-relative"
        titleClassname="text-title"
      />
      <div className="px-2">
        <div className="description-container reset-testgroup-container w-100 bg-white position-relative mx-auto p-4 rounded d-flex flex-column">
          {step === 1 && (
            <ResetTestgroupStep1
              companies={companies}
              selectedCompany={selectedCompany}
              loading={loading}
              fetchCompanies={fetchCompanies}
              handleSelectCompany={handleSelectCompany}
              handleNextStep={handleNextStep}
            />
          )}
          {step === 2 && (
            <ResetTestgroupStep2
              companySearches={companySearches}
              selectedCompany={selectedCompany}
              selectedSearch={selectedSearch}
              loading={loading}
              fetchCompanySearches={fetchCompanySearches}
              handleSelectSearch={handleSelectSearch}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
            />
          )}
          {step === 3 && (
            <ResetTestgroupStep3
              invitations={invitations}
              selectedInstances={selectedInstances}
              selectedPrefilterInstances={selectedPrefilterInstances}
              loading={loading}
              loadingInvitations={loadingInvitations}
              fetchNextPage={fetchSearchInvitationsNextPage}
              handleSelectInstance={handleSelectInstance}
              fetchSearchInvitations={fetchSearchInvitations}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
              clearSelectedInvitations={clearSelectedInvitations}
              restartPrefilters={restartPrefilters}
              setRestartPrefilter={setRestartPrefilter}
            />
          )}
          {step === 4 && (
            <ResetTestgroupStep4
              selectedTestgroups={selectedTestgroups}
              testgroups={testgroups}
              loading={loading}
              selectedInvitations={selectedInvitations}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
              fetchSearchScreening={fetchSearchScreening}
              handleSelectTestgroup={handleSelectTestgroup}
              clearSelectedTestgroups={clearSelectedTestgroups}
              restartPrefilters={restartPrefilters}
            />
          )}
          {step === 5 && <ResetTestgroupStep5 />}
        </div>
      </div>
    </div>
  );
};

export default ResetTestgroups;
