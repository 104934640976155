import { useState, useEffect } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Input.scss';
import { t } from '../../i18n/i18n';

const PASSWORD = 'password';
const TEXT = 'text';

const Input = ({
  register,
  name,
  type,
  error,
  placeholder,
  defaultValue,
  label,
  className = 'my-3',
  fixed,
  disabled,
}) => {
  const [viewPassword, setViewPassword] = useState(false);
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const [value, setValue] = useState(!!defaultValue);

  useEffect(() => {
    setValue(!!defaultValue);
  }, [defaultValue]);

  const changePassword = () => {
    setViewPassword((visibility) => !visibility);
  };

  const handleChange = (e) => {
    if (e.target.value && !value) {
      setValue(true);
    } else if (!e.target.value && value) {
      setValue(false);
    }
  };

  return (
    <div className={className}>
      <label
        className={`d-block input-container mb-0 w-100  ${focus ? 'input-container-active' : ''} ${
          hover ? 'input-container-hover' : ''
        } ${error?.message ? 'input-error' : ''}`}
      >
        {label ? (
          <span
            className={`custom-input-label  font-weight-500 ${
              value || focus || placeholder || fixed ? 'custom-input-label-active' : ''
            }`}
          >
            {label}
          </span>
        ) : null}
        <input
          type={type === PASSWORD ? (viewPassword ? TEXT : PASSWORD) : type}
          placeholder={placeholder}
          name={name}
          ref={register}
          defaultValue={defaultValue}
          className="w-100 mt-4 custom-input"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onChange={handleChange}
          disabled={disabled}
        />
        {type === PASSWORD && (
          <button
            type="button"
            className="input-show-pass-btn"
            onClick={changePassword}
            tabIndex="-1"
          >
            <FontAwesomeIcon icon={viewPassword ? faEye : faEyeSlash} />
          </button>
        )}
      </label>
      <p className="danger-text text-xs">{error && t(error.message)}</p>
    </div>
  );
};

export default Input;
