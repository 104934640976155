import { useEffect, useState } from 'react';
import { t } from '../../i18n/i18n';
import './NotApprovedInput.scss';

const NotApprovedInput = ({
  register,
  name,
  type,
  error,
  placeholder,
  defaultValue,
  label,
  disabled,
  readOnly,
  className = '',
  isActive = true,
  inputClassName = '',
}) => {
  const [value, setValue] = useState(!!defaultValue);

  useEffect(() => {
    setValue(!!defaultValue);
  }, [defaultValue]);

  const handleChange = (e) => {
    if (e.target.value && !value) {
      setValue(true);
    } else if (!e.target.value && value) {
      setValue(false);
    }
  };

  return (
    <div className={className}>
      {label && <label className="font-weight-500  text-xs">{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        ref={register}
        defaultValue={defaultValue}
        className={`bg-transparent position-relative d-inline-block not-approved-input ${
          isActive ? 'active' : 'disabled'
        } ${inputClassName}`}
        onChange={handleChange}
        disabled={disabled}
        readOnly={readOnly}
      />
      <p className="error-message text-xs mt-1">{error && t(error.message)}</p>
    </div>
  );
};

export default NotApprovedInput;
