const MouseLogo = () => (
  <svg width="21" height="21" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 0.243164V6.39045H12C12 3.22766 9.7125 0.623009 6.75 0.243164ZM0 11.0416C0 14.468 2.685 17.2432 6 17.2432C9.315 17.2432 12 14.468 12 11.0416V7.94084H0V11.0416ZM5.25 0.243164C2.2875 0.623009 0 3.22766 0 6.39045H5.25V0.243164Z"
      fill="#4987BE"
    />
  </svg>
);

export default MouseLogo;
