import React from 'react';

const EditPencilIcon = ({ className = '', fill = '#4987be', width = '21', height = '24' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5349 0.377297C13.1676 0.564776 10.1904 3.32071 6.92326 6.50784L1.00757 12.2822L0.775577 13.407C0.659583 14.0257 0.427595 15.0569 0.272936 15.713C-0.249037 18.019 0.00228334 19.2751 1.16222 19.95C1.47154 20.1188 2.01285 20.25 2.38016 20.25C3.32745 20.25 7.63856 19.3689 8.2572 19.0501C9.20448 18.5627 20.2433 7.6702 20.5912 6.90154C20.9585 6.0204 20.9585 4.31434 20.5912 3.45194C20.1659 2.43956 18.6387 0.827245 17.7107 0.396045C16.6281 -0.128894 14.5015 -0.128894 13.5349 0.377297ZM17.2467 3.50819C18.2907 4.50182 18.5227 5.17674 18.0974 5.9454C17.9427 6.22662 15.3522 8.85132 12.3363 11.7572L6.86527 17.0254L4.87404 17.3441C3.77209 17.5128 2.82481 17.6066 2.76681 17.5503C2.70881 17.4941 2.84414 16.5379 3.07613 15.4318L3.48211 13.407L8.8565 8.19514C11.795 5.30798 14.4822 2.85201 14.7915 2.70203C15.6228 2.32707 16.2028 2.5333 17.2467 3.50819Z"
      fill={fill}
    />
    <path
      d="M0.428175 21.8623C0.13819 22.0498 0.00286349 22.331 0.00286349 22.781C0.00286349 24.0558 -0.461113 23.9996 10.423 23.9996C21.2491 23.9996 20.7465 24.0558 20.7465 22.8372C20.7465 22.4623 20.5918 22.1248 20.3212 21.8998C19.8958 21.5811 19.3159 21.5624 10.365 21.5624C2.07142 21.5624 0.814822 21.5999 0.428175 21.8623Z"
      fill={fill}
    />
  </svg>
);

export default EditPencilIcon;
