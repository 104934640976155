import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCategoriesAll,
  getCompanyScreenings,
  getCompanyTestgroups,
  getTags,
} from '../../API/services/screeningService';
import SearchIcon from '../../assets/screening/SearchIcon';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import DropdownSelect from '../../components/DropdownSelect/DropdownSelect';
import GenericToggle from '../../components/GenericToggle/GenericToggle';
import HorizontalLoader from '../../components/HorizontalLoader/HorizontalLoader';
import OverflowContainer from '../../components/OverflowContainer/OverflowContainer';
import useOnScreen from '../../hooks/useOnScreen';
import { t } from '../../i18n/i18n';
import './ABMScreeningsTestgroups.scss';
import levelsMapper from './levelMapper/levelMapper';
import ListItem from './ListItem/ListItem';
import Astronaut from '../../assets/search/Astronaut.json';
import { getAllTechnologies } from '../../API/services/alkymersService';
import { setDisplayTestGroup } from '../../redux/actions/screeningActions';
import TreeMultiSelect from '../../components/TreeMultiSelect/TreeMultiSelect';
import useTranslations from '../../hooks/useTranslations';
import { Button } from 'antd';

const ABMScreeningsTestGroups = () => {
  const history = useHistory();
  const { displayTestgroups } = useSelector((state) => state.screenings);
  const dispatch = useDispatch();
  const [screeningItems, setScreeningItems] = useState([]);
  const [testGroupItems, setTestGroupItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(undefined);
  const [intersectionLoading, setIntersectionLoading] = useState(false);
  const [listLoading, setListLoading] = useState(true);
  const [statusOptions, setStatusOptions] = useState([
    { id: 1, name: t('ABM_ALL_STATUS'), selected: false, value: '' },
    { id: 2, name: t('ABM_ENABLED'), selected: true, value: true },
    { id: 3, name: t('ABM_DISABLED'), selected: false, value: false },
  ]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [technologiesOptions, setTechnologiesOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    category: '',
    categories: [],
    level: '',
    status: statusOptions.find((op) => op?.selected)?.value ?? '',
    name: searchQuery,
    technology: '',
  });
  const { key } = useTranslations();

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const [initialFetch, setInitialFetch] = useState(false);

  const resetFilters = () => {
    const defaultStatusOptions = statusOptions.map((status) => ({
      ...status,
      selected: status.value,
    }));
    setStatusOptions(defaultStatusOptions);
    const defaultLevelOptions = levelOptions.map((level) => ({ ...level, selected: false }));
    setLevelOptions(defaultLevelOptions);
    const defaultCategoryOptions = categoryOptions.map((category) => ({
      ...category,
      selected: false,
    }));
    setCategoryOptions(defaultCategoryOptions);
    const defaultTechnologyOptions = technologiesOptions.map((technology) => ({
      ...technology,
      selected: false,
    }));
    setTechnologiesOptions(defaultTechnologyOptions);

    setSearchQuery('');

    const newSelectedOptions = selectedOptions;

    newSelectedOptions.category = '';
    newSelectedOptions.categories = [];
    newSelectedOptions.name = '';
    newSelectedOptions.level = '';
    newSelectedOptions.status = true;
    newSelectedOptions.technology = '';

    setSelectedOptions(newSelectedOptions);
  };

  const toggleList = () => {
    setListLoading(true);
    dispatch(setDisplayTestGroup(!displayTestgroups));
    setNextPageUrl(undefined);
    resetFilters();
  };

  useEffect(() => {
    if (!displayTestgroups && !technologiesOptions.length) {
      getAllTechnologies().then((response) => {
        const technologies = response.map((technology) => ({
          ...technology,
          name: technology.displayName,
          selected: false,
          value: technology.id,
        }));
        const allOption = {
          id: 'all',
          name: t('ABM_ALL_TECHNOLOGIES'),
          selected: false,
          value: '',
        };
        setTechnologiesOptions([allOption, ...technologies]);
      });
    }
  }, [displayTestgroups]);

  const fetchTestgroups = async (queryParams) =>
    getCompanyTestgroups(queryParams).then((response) => {
      setNextPageUrl(response.pagination.nextPage ?? undefined);
      const testGroups = response.collection.map((testgroup) => ({
        ...testgroup,
        categoryTag: categories.find((category) => testgroup.categoryId === category.id)?.name,
        levelTag: levels.find(
          (level) => testgroup.tags.find((tag) => tag.type === 'LEVEL')?.id === level?.id
        )?.displayName,
      }));
      return testGroups;
    });

  const fetchScreenings = async (queryParams) =>
    getCompanyScreenings(queryParams).then((response) => {
      const screenings = response.map((screening) => ({
        ...screening,
        categoryTag: screening.certifiableTech,
      }));
      return screenings;
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedOptions = {
      category: categoryOptions.find((op) => op.selected)?.value ?? '',
      categories: categoryOptions.filter((op) => op.selected)?.map((item) => item?.value) ?? [],
      level: levelOptions.find((op) => op.selected)?.value ?? '',
      status: statusOptions.find((op) => op.selected)?.value ?? '',
      name: searchQuery ?? '',
      technology: technologiesOptions.find((op) => op.selected)?.value ?? '',
    };

    setListLoading(true);
    if (displayTestgroups) {
      const queryParams = {
        text: selectedOptions.name,
        categoryIds: selectedOptions.categories,
        tagsIds: selectedOptions.level,
        enabled: selectedOptions.status,
        lang: `${key}`,
      };
      const testGroups = await fetchTestgroups(queryParams);
      setTestGroupItems(testGroups);
    } else {
      const queryParams = {
        name: selectedOptions.name,
        lang: `${key}`,
        technologyId: selectedOptions.technology,
        ...(selectedOptions.status !== '' ? { isEnabled: selectedOptions.status } : {}),
      };
      const screenings = await fetchScreenings(queryParams);
      setScreeningItems(screenings);
    }
    setListLoading(false);
  };

  useEffect(() => {
    const getFilters = async () => {
      await getCategoriesAll().then((response) => {
        const mappedCategories = response.map((category) => ({
          ...category,
          value: category.id,
        }));
        setCategories(response);
        setCategoryOptions([...mappedCategories]);
      });
      await getTags({ types: 'LEVEL' }).then((response) => {
        const allOption = { id: 1, name: t('ABM_ALL_LEVELS'), selected: false, value: '' };
        const mappedLevels = response.map((level) => levelsMapper(level));
        setLevels(response);
        setLevelOptions([allOption, ...mappedLevels]);
      });
      setInitialFetch(true);
    };
    getFilters();
  }, []);

  useEffect(() => {
    setListLoading(true);
    if (displayTestgroups && initialFetch) {
      const queryParams = {
        name: selectedOptions.name,
        // categoryId: selectedOptions.category,
        categoryIds: selectedOptions.categories,
        tagsIds: selectedOptions.level,
        enabled: selectedOptions.status,
        lang: `${key}`,
      };
      const getTestgroups = async () => {
        const testGroups = await fetchTestgroups(queryParams);
        setListLoading(false);
        setTestGroupItems(testGroups);
      };
      getTestgroups();
    }

    if (!displayTestgroups) {
      const queryParams = {
        technologyId: selectedOptions.technology,
        name: selectedOptions.name,
        isEnabled: selectedOptions.status !== '' ? selectedOptions.status : undefined,
      };

      const getScreenings = async () => {
        const screenings = await fetchScreenings(queryParams);
        setListLoading(false);
        setScreeningItems(screenings);
      };
      getScreenings();
    }
  }, [displayTestgroups, JSON.stringify(selectedOptions), initialFetch]);

  const handleFilterChange = (optionsList, setState, selectedOption, filterName) => {
    const newState = optionsList.map((option) => ({
      ...option,
      selected: option.id === selectedOption.id,
    }));

    setState(newState);

    const newSelectedOptions = selectedOptions;
    newSelectedOptions[filterName] = selectedOption.value ?? '';
    newSelectedOptions.name = searchQuery ?? '';
    setSelectedOptions(newSelectedOptions);
  };

  const handleFilterChangeValues = (values, filterName) => {
    const newSelectedOptions = selectedOptions;
    newSelectedOptions[filterName] = values ?? [];
    newSelectedOptions.name = searchQuery ?? '';
    setSelectedOptions(newSelectedOptions);
  };

  const fetchNextUrlRef = useRef();
  const fetchNextUrl = useOnScreen(fetchNextUrlRef, '0px', true);
  const [fetched, setFetched] = useState(false);

  const getNextPage = async () => {
    setIntersectionLoading(true);
    const queryString = new URLSearchParams(nextPageUrl?.split('?')[1]);
    const queryParams = {
      name: searchQuery,
      categoryIds: selectedOptions.categories,
      tagsIds: selectedOptions.level,
      enabled: selectedOptions.status,
      offset: queryString.get('offset'),
      limit: queryString.get('limit'),
      lang: `${key}`,
    };

    getCompanyTestgroups(queryParams).then((response) => {
      setNextPageUrl(response.pagination.nextPage ?? undefined);
      const testGroups = response.collection.map((testgroup) => ({
        ...testgroup,
        categoryTag: categories.find((category) => testgroup.categoryId === category.id)?.name,
        levelTag: levels.find(
          (level) => testgroup.tags.find((tag) => tag.type === 'LEVEL')?.id === level?.id
        )?.displayName,
      }));
      setTestGroupItems([...testGroupItems, ...testGroups]);
      setFetched(true);
      setIntersectionLoading(false);
    });
  };

  useEffect(() => {
    if (!fetchNextUrl) {
      setFetched(false);
    }

    if (fetchNextUrl && !fetched && nextPageUrl && !listLoading) {
      getNextPage();
    }
  }, [fetchNextUrl]);

  const contentToDisplay = displayTestgroups ? testGroupItems : screeningItems;

  return (
    <div data-testid="abm-screenings-testgroup-container" className="w-100 mx-auto abm-wrapper">
      <div>
        <BackgroundTop
          title={t('ABM_PAGE_TITLE')}
          className="position-relative"
          showBase
          titleClassname="text-title"
        />
      </div>
      <div className="container-content bg-white b-radius-8 py-4 px-5">
        <div>
          <GenericToggle
            state={displayTestgroups}
            actionFunction={() => toggleList()}
            leftText={t('ABM_TESTGROUP')}
            rightText={t('ABM_SCREENING')}
            defaultActive={!displayTestgroups}
            loading={listLoading}
          />
        </div>
        <div className="d-flex flex-column mt-4">
          <span className=" mb-2 font-weight-600 text-xs">
            {displayTestgroups ? t('ABM_CREATED_TESTGROUPS') : t('ABM_CREATED_SCREENINGS')}
          </span>
          <span className=" font-weight-500 text-xs view-subtitle">{t('ABM_PAGE_SUBTITLE')}</span>
        </div>
        <div className="d-flex mt-4 filters-container flex-wrap">
          <div className="d-flex flex-row flex-wrap">
            {displayTestgroups ? (
              <div className="p-2">
                <TreeMultiSelect
                  options={categoryOptions.filter(
                    (category) => category?.id !== process.env.RAZZLE_RUNTIME_CATEGORY_ID
                  )}
                  value={categorySelected}
                  textSize="12px"
                  width="150"
                  onChange={(values, items) => {
                    setCategorySelected(values);
                    handleFilterChangeValues(values, 'categories');
                  }}
                  className="b-radius-8 abm-dropdowns mr-4"
                  style={{ fontSize: 14, minWidth: 120 }}
                  label={t('MULTIPLE_SELECT_CATEGORIES_PLACEHOLDER')}
                  labelPosition={{ top: 10, left: 10 }}
                  dropdownWidth="300px"
                />
              </div>
            ) : (
              <DropdownSelect
                className="b-radius-8 abm-dropdowns mr-4"
                setState={(selectedOption) =>
                  handleFilterChange(
                    technologiesOptions,
                    setTechnologiesOptions,
                    selectedOption,
                    'technology'
                  )
                }
                placeholder={
                  technologiesOptions?.find((category) => category?.selected)?.name ||
                  t('ABM_TECHNOLOGY')
                }
                selected={technologiesOptions?.find((category) => category?.selected)?.id}
                selectedPlaceholder
                options={technologiesOptions}
              />
            )}

            {displayTestgroups && (
              <DropdownSelect
                className="b-radius-8 abm-dropdowns mr-4"
                setState={(selectedOption) =>
                  handleFilterChange(levelOptions, setLevelOptions, selectedOption, 'level')
                }
                placeholder={
                  levelOptions?.find((category) => category?.selected)?.name || t('ABM_LEVELS')
                }
                selected={levelOptions?.find((category) => category?.selected)?.id}
                selectedPlaceholder
                options={levelOptions}
              />
            )}
            <DropdownSelect
              className="b-radius-8 abm-dropdowns"
              setState={(selectedOption) =>
                handleFilterChange(statusOptions, setStatusOptions, selectedOption, 'status')
              }
              placeholder={statusOptions.find((option) => option.selected)?.name || t('ABM_STATUS')}
              selected={statusOptions?.find((category) => category?.selected)?.id}
              selectedPlaceholder
              options={statusOptions}
            />
          </div>
          <div className="right-buttons-container">
            {displayTestgroups && (
              <Button
                className=" font-weight-700 bg-maya-blue text-white abm-redirect-button rounded-0"
                onClick={() => history.push('/createTestgroup')}
                type="button"
              >
                {t('ABM_CREATE_TESTGROUP')}
              </Button>
            )}
            <div className="ml-auto">
              <form
                onSubmit={handleSubmit}
                className={`my-1 my-sm-0 d-flex align-items-center transition-2-ms p-2 w-100 b-radius-8 ${
                  searchQuery.length
                    ? 'tests-search-input-full bg-white'
                    : 'tests-search-input-empty bg-gray'
                }`}
              >
                <SearchIcon color={searchQuery.length ? '#4987be' : '#424242'} />
                <input
                  onChange={handleInputChange}
                  value={searchQuery}
                  className=" text-xs bg-transparent border-0 outline-none ml-2"
                  placeholder={t('ABM_SEARCH_PLACEHOLDER')}
                  type="text"
                />
              </form>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <OverflowContainer className="px-2 b-radius-8 overflow-items-container">
            <div className="abm-titles-container d-flex align-items-center justify-content-between">
              <span className=" text-xs font-weight-bold">{t('ABM_TITLE_NAME')}</span>
              {displayTestgroups && (
                <div className="abm-metric-titles">
                  <span className="text-center  text-xs font-weight-bold">
                    {t('ABM_TITLE_MEDIAN')}
                  </span>
                  <span className="text-center  text-xs font-weight-bold">
                    {t('ABM_TITLE_AVERAGE')}
                  </span>
                </div>
              )}
            </div>
            {listLoading ? (
              <div className="d-flex justify-content-center align-items-center h-100">
                <HorizontalLoader className="d-block mx-auto my-auto" />
              </div>
            ) : contentToDisplay.length > 0 ? (
              <>
                {contentToDisplay.map((item) => (
                  <ListItem {...item} isTestgroup={displayTestgroups} key={item.id} />
                ))}
              </>
            ) : (
              <div className="abm-list-empty-state">
                <span className=" font-weight-600">
                  {t('ABM_NO_RESULTS_FOUND').replace(
                    '{obj}',
                    displayTestgroups
                      ? t('ABM_NO_RESULTS_FOUND_TESTS')
                      : t('ABM_NO_RESULTS_FOUND_SCREENINGS')
                  )}
                </span>
                <Lottie
                  animationData={Astronaut}
                  loop
                  style={{ width: '15rem', height: '15rem' }}
                />
              </div>
            )}
            <div ref={fetchNextUrlRef} className="text-white">
              .
            </div>
            {intersectionLoading && <HorizontalLoader className="d-block mx-auto" />}
          </OverflowContainer>
        </div>
      </div>
    </div>
  );
};

export default ABMScreeningsTestGroups;
