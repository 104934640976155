import { t } from '../i18n/i18n';
import { auth0Client } from '../auth0/auth0';
import './maintenance.scss';

const Maintenance = () => (
  <div className="d-flex flex-column align-items-center">
    <span className="maintenance-title text-center  font-weight-bold mt-5 pt-5">
      {t('MAINTENANCE_TITLE')}
    </span>
    <span className="maintenance-subtitle text-center mt-2">{t('MAINTENANCE_SUBTITLE')}</span>
    <button
      className="maintenance-button border-0 mt-5"
      type="button"
      onClick={() => {
        localStorage.clear();
        auth0Client().logout({ returnTo: window.location.origin });
      }}
    >
      <span className="maintenance-button-text text-white  font-weight-bold">
        🤓 {t('MAINTENANCE_BUTTON')}
      </span>
    </button>
  </div>
);

export default Maintenance;
