import { useDispatch, useSelector } from 'react-redux';
import { setUniqueProfileData } from '../../../redux/actions/profilesActions';
import { getInvitedProfile } from '../../../API/services/alkymersService';
import { t } from '../../../i18n/i18n';
import HorizontalLoader from '../../HorizontalLoader/HorizontalLoader';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoIcon from '../../../assets/search/InfoIcon';
import { ProfileDataTypes } from '../../../constants/enums';

const InvitationOrChallengeItem = ({ item }) => {
  const {
    name,
    score,
    recruitSessionId,
    screeningInstanceId,
    profileId,
    isQualitative,
    isChallenge,
    id,
    pendingCredits,
    index,
    search,
  } = item;
  const dispatch = useDispatch();
  const { uniqueProfileData } = useSelector((state) => state.profiles);
  const [loading, setLoading] = useState(false);
  const handleProfileData = async () => {
    setLoading(true);
    if (isChallenge) {
      const progressTechsModified = [
        {
          bestScore: score,
          id: screeningInstanceId,
          screeningInstanceId,
          isPrimary: true,
          alkymetricsId: 1,
          technology: { name, id: 1, displayName: name },
        },
      ];
      let modifiedUniqueProfileData = {
        ...uniqueProfileData,
        recruitSession: {
          ...uniqueProfileData.recruitSession,
          name,
          id: screeningInstanceId,
        },
        type: ProfileDataTypes.CHALLENGE,
        data: progressTechsModified,
      };
      dispatch(setUniqueProfileData(modifiedUniqueProfileData));
    } else {
      const invitedProfileData = await getInvitedProfile(recruitSessionId, profileId);
      dispatch(
        setUniqueProfileData({
          recruitSession: invitedProfileData.recruitSession,
          type: ProfileDataTypes.EVALUATION,
          data: invitedProfileData.stages,
        })
      );
    }
    setLoading(false);
  };
  const isSelected =
    uniqueProfileData?.recruitSession?.id === (recruitSessionId ?? screeningInstanceId);

  useEffect(() => {
    if (index === 0 && !search) {
      handleProfileData();
    }
  }, []);
  return (
    <div
      onClick={() => !isSelected && !loading && !pendingCredits && handleProfileData()}
      className={`invitation-item-container d-flex flex-column justify-content-center align-items-center position-relative ${
        pendingCredits ? 'pending-credits' : isSelected ? 'active' : 'cursor-pointer'
      }`}
      key={id ?? screeningInstanceId}
    >
      {!loading ? (
        <div className="d-flex align-items-center justify-content-center">
          {pendingCredits && (
            <div className="align-items-center justify-content-center pr-2">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className="">{t('EVALUATIONS_PENDING_CREDITS')}</Tooltip>}
              >
                <div>
                  <a
                    className="text-sm diamond-background d-flex align-items-center justify-content-center rounded-circle"
                    href={process.env.RAZZLE_RUNTIME_SALES_CALENDAR_URL}
                  >
                    <InfoIcon color="#ff7676" />
                  </a>
                </div>
              </OverlayTrigger>
            </div>
          )}
          <div>
            <span className="invitation-item-score p-2 text-xs font-weight-600">
              {isQualitative
                ? t('PROFILE_QUALITATIVE')?.slice(0, 5) + '.'
                : score
                ? `${score}%`
                : 'S/R'}
            </span>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip className="tooltip">{name ?? '-'}</Tooltip>}
            >
              <span className="font-weight-600 text-sm">
                {name?.length > 20 ? name.slice(0, 20) + '...' : name}
              </span>
            </OverlayTrigger>
          </div>
        </div>
      ) : (
        <HorizontalLoader white className={'custom-loader-position'} />
      )}
    </div>
  );
};

export default InvitationOrChallengeItem;
