import React from 'react';

const Pause = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C5.37097 0 0 5.37097 0 12C0 18.629 5.37097 24 12 24C18.629 24 24 18.629 24 12C24 5.37097 18.629 0 12 0ZM17.5984 13.1613L9.08226 18.0484C8.31774 18.4742 7.35484 17.9274 7.35484 17.0323V6.96774C7.35484 6.07742 8.3129 5.52581 9.08226 5.95161L17.5984 11.129C18.3919 11.5742 18.3919 12.721 17.5984 13.1613Z"
      fill="#4987BE"
    />
  </svg>
);

export default Pause;
