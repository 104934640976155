import SafeContainer from '../SafeContainer/SafeContainer';

export const GenericTextArea = ({
  richText = false,
  maxLength,
  minLength,
  placeholder,
  title,
  disabled,
  className,
  onChange,
  value,
  richTextClassName,
  headers = [1, 2, 3, 4, false],
  fontsType = ['bold', 'italic', 'underline', 'strike'],
  listType = ['ordered', 'bullet'],
  alignType = ['', 'center', 'right'],
  link = [],
}) => {
  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div className={`align-items-center mt-2 my-2 ${className}`}>
      {richText ? (
        <SafeContainer
          theme="snow"
          content={value}
          style={`p-2 w-100  rounded text-xs screening-name-input evaluation-name-input mr-2 ${richTextClassName}`}
          modulesAllow={{
            toolbar: [
              [{ header: headers }],
              fontsType,
              link,
              listType.map((l) => ({ list: l })),
              alignType.map((a) => ({ align: a })),
            ],
          }}
          handleChange={(e) => handleChange(e)}
          readOnly={disabled}
          placeholder={placeholder}
          title={title}
        />
      ) : (
        <textarea
          minLength={minLength ?? 1}
          maxLength={maxLength ?? 750}
          placeholder={placeholder}
          title={title}
          value={value}
          disabled={disabled}
          className="p-2 w-100  rounded text-xs screening-name-input evaluation-name-input mr-2"
          onChange={handleChange}
        />
      )}
    </div>
  );
};
