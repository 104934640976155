import { useIntl } from 'react-intl';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import Checkbox from '../Checkbox/Checkbox';

const FilterItem = ({
  filter,
  addFilter,
  type,
  className,
  closeModal,
  selected,
  removeAppliedFilter,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={className} data-testid={type}>
      <label className="profile-search-option mb-1  font-weight-600 text-xs gray-subtitle profile-search-filters-options d-flex align-items-center">
        <Checkbox
          checked={selected}
          onClick={(e) => {
            if (!selected) {
              TrackEvent(`filter-${type.replace('_', '-')}`, {
                FilterType: type,
                FilterValue: formatMessage({ id: filter.name }),
              });
              addFilter(e);
            } else {
              removeAppliedFilter(e);
            }
            if (closeModal) {
              closeModal();
            }
          }}
          data={{
            'data-name': filter.tag ?? formatMessage({ id: filter.name }),
            'data-value': filter.id,
            'data-id': filter.id,
            'data-type': type,
            'data-testid': `checkbox-${type}`,
          }}
          className="mr-1"
        />
        {formatMessage({ id: filter.name })}
      </label>
    </div>
  );
};

export default FilterItem;
