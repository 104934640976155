import moment from 'moment';
import { t } from '../../../i18n/i18n';
import './formationCollapsed.scss';

const FormationCollapsed = ({
  index,
  formation: {
    institute,
    customInstitute,
    formationCareer,
    customCareer,
    studyArea,
    customStudyArea,
    formationStatus,
    startDate,
    endDate,
    certificateUrl,
  },
}) => (
  <div
    className={`d-flex flex-row align-items-center position-relative w-full my-4 ${
      index > 0 ? 'formation-border-increment' : ''
    }`}
    key={index}
  >
    <div
      className={`d-flex flex-column w-full formation-collapsed-container-width ${
        index > 0 ? 'mt-4' : ''
      }`}
    >
      <div className="d-flex flex-row w-full">
        <div className="d-flex flex-column formation-width-custom">
          <span className=" font-italic text-xs font-weight-300 dashboard-group-name">
            {t('FORMATION_COLLAPSED_INSTITUTE')}
          </span>
          <span className=" font-weight-600 text-xs dashboard-group-name text-truncate formation-collapsed-container formation-collapsed-label-anchor">
            {institute?.displayName ?? customInstitute}
          </span>
        </div>
        <div className="d-flex flex-column formation-width-custom">
          <span className=" font-italic text-xs font-weight-300 dashboard-group-name">
            {t('FORMATION_COLLAPSED_TITLE')}
          </span>
          <span className=" font-weight-600 text-xs dashboard-group-name text-truncate formation-collapsed-container formation-collapsed-label-anchor">
            {formationCareer?.displayName ?? customCareer}
          </span>
        </div>
        <div className="d-flex flex-column formation-width-custom">
          <span className=" font-italic text-xs font-weight-300 dashboard-group-name">
            {t('FORMATION_COLLAPSED_STUDY_AREA')}
          </span>
          <span className=" font-weight-600 text-xs dashboard-group-name text-truncate formation-collapsed-container formation-collapsed-label-anchor">
            {studyArea?.displayName ?? customStudyArea}
          </span>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-around mt-2">
        <div className="d-flex flex-column formation-width-custom">
          <span className=" font-italic text-xs font-weight-300 dashboard-group-name">
            {t('FORMATION_COLLAPSED_STATUS')}
          </span>
          <span className=" font-weight-600 text-xs dashboard-group-name text-truncate formation-collapsed-container formation-collapsed-label-anchor">
            {t(formationStatus?.displayName)}
          </span>
        </div>
        <div className="d-flex flex-column formation-width-custom">
          <span className=" font-italic text-xs font-weight-300 dashboard-group-name">
            {t('FORMATION_COLLAPSED_START_DATE')}
          </span>
          <span className=" font-weight-600 text-xs dashboard-group-name text-truncate formation-collapsed-container formation-collapsed-label-anchor">
            {moment(startDate).format('MM/yyyy')}
          </span>
        </div>
        <div className="d-flex flex-column formation-width-custom">
          {!!endDate && (
            <>
              <span className=" font-italic text-xs font-weight-300 dashboard-group-name">
                {t('FORMATION_COLLAPSED_END_DATE')}
              </span>
              <span className=" font-weight-600 text-xs dashboard-group-name text-truncate formation-collapsed-container formation-collapsed-label-anchor">
                {moment(endDate).format('MM/yyyy')}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
    {!!certificateUrl && (
      <a
        href={certificateUrl}
        target="_blank"
        rel="noreferrer"
        className=" text-xs font-weight-600 formation-certificate-custom total-number-text mt-2"
      >
        {t('COLLAPSED_SEE_CERTIFICATE')}
      </a>
    )}
  </div>
);

export default FormationCollapsed;
