import React, { useState } from 'react';
import AddQuestions from '../../AddQuestions/AddQuestions';
import { generateMinutes } from '../../../constants/hours';
import { getMinimunScreeningTimeInSeconds } from '../../../utils/time';
import { t } from '../../../i18n/i18n';
import TestGroupsItem from '../../TestGroupsItem/TestGroupsItem';
import Accordion from '../../Accordion/Accordion';
import Arrow from '../../../assets/modal/Arrow';
import NewStyleSelect from '../../NewStyleSelect/NewStyleSelect';

export default function AditionalQuestions({
  newScreening,
  incompleteQuestion,
  setEditData,
  handleOrder,
  handleDelete,
  handleCustomScreeningsTime,
}) {
  const [showDropwdown, setShowDropdown] = useState(false);
  const minCustomScreeningsTimeInSeconds = getMinimunScreeningTimeInSeconds(newScreening);

  const minCustomScreeningsTimeInHours = minCustomScreeningsTimeInSeconds
    ? minCustomScreeningsTimeInSeconds / 3600
    : 0;

  const minutes = generateMinutes(5, 2.05, minCustomScreeningsTimeInHours ?? null);
  return (
    <div data-testid="create-evaluation-third-step" className="d-flex flex-column">
      <div className="d-flex justify-content-center">
        <AddQuestions incompleteQuestion={incompleteQuestion} setShowDropdown={setShowDropdown} />
      </div>
      <div
        className="bg-blue-principal w-100 d-flex justify-content-between align-items-center p-2 cursor-pointer color-white font-weight-600"
        onClick={() => setShowDropdown(!showDropwdown)}
      >
        <div className="d-flex gap-2">
          <span>{`${newScreening?.customTestGroup?.length} ${t(
            'CREATE_SCREENING_YOUR_QUESTIONS_ADDED_QUESTIONS'
          )}`}</span>
          <span>{`${newScreening?.customScreeningTGDuration ?? '00:00'}  ${t(
            'CREATE_EVALUATION_THIRD_STEP_CUSTOM_SET_TIME'
          )}`}</span>
        </div>
        <Arrow
          className={`${
            showDropwdown ? 'arrow-dropdown-modal-collapsed' : 'arrow-dropdown-modal-uncollapsed'
          }`}
        />
      </div>
      <div className="row pt-4 pb-2">
        {showDropwdown &&
          newScreening.customTestGroup
            .sort((a, b) => a.order - b.order)
            .map((item, index) => (
              <div className="col-3 mb-3" key={`add-question-${index}`}>
                <TestGroupsItem
                  test={item}
                  key={item.id}
                  disableOrderUp={index === 0}
                  handleEdit={() => setEditData(item)}
                  disableOrderDown={index === newScreening.customTestGroup.length - 1}
                  // handleOrder={handleOrder}
                  handleDelete={() => handleDelete(item.deleteId)}
                  isComplementaryQuestion
                />
              </div>
            ))}
      </div>
      <Accordion
        title={t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_SET_TIME')}
        className="mt-3"
        open={showDropwdown}
      >
        <div className="my-1 my-sm-2 ml-0 d-flex justify-content-center align-items-center">
          <span
            className=" text-light-black font-weight-500 text-base"
            data-testid="create-evaluation-ts-set-time-span"
          >
            {t('CREATE_EVALUATION_THIRD_STEP_CUSTOM_SET_TIME')}
          </span>
          <NewStyleSelect
            error={false}
            className="select-width text-center justify-content-center"
            value={newScreening?.customScreeningTGDuration ?? '0 min'}
            options={minutes}
            onChange={(e) => {
              handleCustomScreeningsTime({ id: e.target.value });
            }}
            name="customScreeningTGDuration"
            borderClasses="bottom-border text-center"
          />
        </div>
      </Accordion>
    </div>
  );
}
