import { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumberWithThousandsSeparator } from '../../utils/numbersUtils';
import { isNullOrUndefined } from '../../utils/typesUtils';
import { t } from '../../i18n/i18n';
import HeaderArrowIcon from '../../assets/header/HeaderArrowIcon';
import FilterUsersSummary from './FilterUserSummary/FilterUsersSummary';
import PersonalizeSearchTable from '../PersonalizeSearchTable/PersonalizeSearchTable';
import PersonalizeCanidatesTable from '../PersonalizeCanidatesTable/PersonalizeCanidatesTable';

export const HeaderUsersSummary = ({
  addTabFilter,
  handleSubmit,
  headers,
  setHeaders,
  idCustomTable,
}) => {
  const userSummary = useSelector((state) => state.userSummary.userSummaryPaginated);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const statusTabs = [
    {
      title: t('PENDING_ACCEPTANCE'),
      filter: ['WITHOUT_ACCEPT'],
      statKey: 'invitedProfiles',
      track: 'search-tab-invited-candidates',
    },
    {
      title: t('PENDING_RENDER'),
      filter: ['WITHOUT_START'],
      statKey: 'createdAccounts',
      track: 'search-tab-created-accounts',
    },
    {
      title: t('COMPLETE'),
      filter: ['PROCESSED'],
      statKey: 'startedInvitations',
      track: 'search-tab-started-invitations',
    },
  ];

  return (
    <div className="container-info container-search-dashboard pb-0 mb-0 bg-white rounded px-4 pt-4 mx-auto">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex flex-row">
          <div className="d-flex flex-column align-items-center justify-content-start mb-4">
            <span
              data-testid="assessment-invitations-total-candidates"
              className="total-invitations-quantity  font-weight-600"
            >
              {formatNumberWithThousandsSeparator(userSummary?.pagination?.total || 0)}
            </span>

            <span className="font-weight-500  text-xs text-muted">
              {t('SEARCH_CANDIDATES_STATUS_COUNT_TOTAL_CANDIDATES')}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-start justify-content-between mb-4">
        {statusTabs?.map((tab, index) => {
          const itemFound = userSummary?.filter?.customFilters;
          const selected = itemFound?.length
            ? tab?.filter?.find((value) => itemFound[0]?.includes(value))
            : false;
          const total = userSummary?.tabsFilter?.length
            ? userSummary?.tabsFilter?.find((item) =>
                tab?.filter?.find((value) => value?.includes(item?.name))
              )?.total
            : 0;

          return (
            <button
              data-testid="assessment-invitations-status-tab"
              key={tab.title}
              type="button"
              className={`filter-tab-button text-nowrap d-flex align-items-center justify-content-center  text-xs ${
                selected && 'selected-tab-button'
              } ${index !== statusTabs.length - 1 && 'mr-3'}`}
              onClick={() => {
                addTabFilter(tab.filter);
              }}
            >
              {tab.title}
              <span className=" font-weight-600 ml-2">{total}</span>
            </button>
          );
        })}
      </div>
      <div className="mt-2 mb-4 w-100 d-flex align-items-center">
        <div className="mr-3">
          <PersonalizeCanidatesTable
            idCustomTable={idCustomTable}
            headers={headers}
            onChangeHeaders={setHeaders}
          />
        </div>
        <button
          type="button"
          data-testid="dashboard-show-more-filters-button"
          onClick={() => setShowMoreFilters(!showMoreFilters)}
          className="d-flex align-items-center bg-transparent border-0 outline-none ml-auto"
        >
          <span className="mr-2  text-muted font-weight-600">
            {showMoreFilters
              ? t('DASHBOARD_FILTERS_LESS_FILTERS')
              : t('DASHBOARD_FILTERS_MORE_FILTERS')}
          </span>
          <HeaderArrowIcon
            color="#757575"
            className={`dropdown-select-arrow ${showMoreFilters && 'dropdown-select-arrow-open'}`}
          />
        </button>
      </div>
      <div className="d-flex mt-2">
        <div className="w-100">
          <FilterUsersSummary handleSubmit={handleSubmit} showMoreFilters={showMoreFilters} />
        </div>
      </div>
    </div>
  );
};
