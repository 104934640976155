import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import arrowSmall from '../../assets/profile/arrow-small.svg';
import { t } from '../../i18n/i18n';

const OtherPartnersProfile = ({ partners, id }) => {
  const [showAll, setShowAll] = useState(false);
  const filteredPartners = partners?.filter((partner) => partner.partnerId !== id);
  const flagLengthFilter = filteredPartners?.length > 15;
  const displayPartners = showAll ? filteredPartners : filteredPartners?.slice(0, 15);

  return (
    <div className="user-heading rounded w-100 py-4 pr-4 pl-4 d-flex mt-2 mb-3 d-flex flex-column">
      <span className="font-weight-bold  d-flex text-base dashboard-group-name">
        {t('OTHER_PARTNERS')}
      </span>
      <ul className="mt-2 list-partners-company text-xs dashboard-group-name">
        {displayPartners?.map(({ partner }) => (
          <OverlayTrigger
            key={partner?.id}
            placement="top"
            overlay={
              <Tooltip>
                {partner?.firstName} {partner?.lastName}{' '}
                {partner?.role ? ` (${partner?.role})` : ''}
              </Tooltip>
            }
          >
            <li
              key={partner?.id}
              className=" font-weight-400 invited-profile-skill-title text-xs font-italic text-truncate"
            >
              <b className=" font-weight-600 dashboard-group-name text-xs">
                {partner?.firstName} {partner?.lastName}
              </b>
              {partner?.role ? ` (${partner?.role})` : ''}
            </li>
          </OverlayTrigger>
        ))}
        {!showAll && flagLengthFilter && <li>...</li>}
      </ul>
      {flagLengthFilter && (
        <button
          className="font-weight-700 mt-4 mb-4  text-xs font-bold complete-profile-button bg-white align-self-start"
          type="button"
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {!showAll ? t('MORE_PARTNERS') : t('LESS_PARTNERS')}
          <img
            src={arrowSmall}
            alt={t('ICON_ARROW')}
            className={`${showAll ? 'rotate-180 pr-2' : 'pl-2'}`}
          />
        </button>
      )}
    </div>
  );
};

export default OtherPartnersProfile;
