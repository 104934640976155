import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BsCheckCircleFill } from 'react-icons/bs';
import { t } from '../../../i18n/i18n';
import { getFeaturesGroupedByIdAndPrice } from '../../../utils/plans/features';
import { formatNumberGermanLocale } from '../../../utils/numbersUtils';

const ComparativeTable = ({ plans, features, currency }) => {
  const featureObject = getFeaturesGroupedByIdAndPrice(plans, features, currency);

  return (
    <section
      data-testid="plans-features-comparative-table"
      className="container-fluid p-4 custom-column bg-white w-100"
      style={{ overflowX: 'scroll' }}
    >
      <div style={{ whiteSpace: 'nowrap', minWidth: '800px' }}>
        {/* Columnas */}
        <span className="row my-1  text-sm font-weight-700">
          {`${t('PLANS_COMPARISION_LABEL')}:`}
        </span>
        <div
          className="row flex-nowrap no-gutters my-2 py-3 px-2 justify-content-center custom-column"
          // style={{ columnGap: '.5em' }}
        >
          {[{ name: '', id: 0 }, ...plans].map((p, i) => {
            if (!p) return <></>;
            return (
              <div
                key={`column-header-plan-${p.id}`}
                className="col-sm-2 col-md-2 col-lg-2 col-xl-2"
              >
                <div
                  className="custom-column py-1 mx-1 text-white  text-xs text-center font-weight-700"
                  style={{ backgroundColor: `#${p.color}` }}
                >
                  {p.name}
                </div>
              </div>
            );
          })}
        </div>
        {/* mapeo los values */}
        {Object.keys(featureObject).map((key) => (
          <div
            key={`row-features-plan-${key}`}
            className="row flex-nowrap no-gutters my-2 py-3 px-2 justify-content-center custom-column"
            style={
              {
                // border: `1px solid #4987be`,
                // columnGap: '.5em'
              }
            }
          >
            {/* Mapeo cada value en su posicion */}
            {featureObject[key].map((x, i) => {
              const { hasAmount, value, isIncludedFeature } = x;
              return (
                <div
                  key={`row-values-features-plan-${i}`}
                  className={`col-sm-2 col-md-2 col-lg-2 col-xl-2  text-xs font-weight-700 text-wrap py-2 ${
                    i ? 'd-flex align-items-center justify-content-center' : ''
                  }`}
                  style={{ borderBottom: `1px solid #4987be` }}
                >
                  {hasAmount ? (
                    `${formatNumberGermanLocale(value) ?? t('NEGOTIABLE_PRICE_MESSAGE')}`
                  ) : isIncludedFeature ? (
                    <BsCheckCircleFill style={{ color: '#44D196', fontSize: '2em' }} />
                  ) : (
                    <AiFillCloseCircle style={{ color: '#DC251C', fontSize: '2em' }} />
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ComparativeTable;
