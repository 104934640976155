import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowDown, BsFillFunnelFill } from 'react-icons/bs';
import { Button, Select, Tree } from 'antd';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import { t } from '../../i18n/i18n';
import { getAllCustomSearchs } from '../../API/services/screeningService';
import {
  setKeepSearchData,
  setSearches,
  setSelectedSearch,
  setStageCandidates,
} from '../../redux/actions/screeningActions';
import processQueryString from '../../utils/processQueryString';
import searchSvg from '../../assets/profile/search.svg';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import Spinner from '../../components/spinner/Spinner';
import { setAppliedFilters } from '../../redux/actions/profilesActions';
import CardScreeningSearch from '../../components/CardScreeningSearch/CardScreeningSearch';

const Screening = () => {
  const history = useHistory();
  const { currentWorkspace } = useSelector((state) => state.auth);
  const { collection, pagination } = useSelector((state) => state.screenings.searches);
  const [searchs, setSearchs] = useState(collection);
  const [setSearchesQuantity] = useState({});
  const [pag, setPag] = useState(pagination?.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();
  const filters = [
    {
      title: t('SCREENING_SEARCH_FILTER_STATES'),
      key: 'all',
      children: [
        {
          title: t('SCREENING_SEARCH_OPEN'),
          key: 'open',
        },
        {
          title: t('SCREENING_SEARCH_CLOSE'),
          key: 'closed',
        },
        {
          title: t('SCREENING_SEARCH_DRAFT'),
          key: 'drafts',
        },
      ],
    },
    {
      title: t('SCREENING_SEARCH_IN_USE'),
      key: 'inuse',
    },
    {
      title: t('SCREENING_SEARCH_PYCHOTECHNICS'),
      key: 'psychotecnic',
    },
  ];
  const [partners, setPartners] = useState([]);
  const [partnerSelected, setPartnerSelected] = useState('');
  const [checkedKeys, setCheckedKeys] = useState(['open']);
  const [sort, setSort] = useState('createdAt');

  const handleRequest = async (offset = 0, search = '', page = 1) => {
    setLoading(true);
    setCurrentPage(page);
    const statusArray = [];
    if (checkedKeys.includes('open')) statusArray.push('open');
    if (checkedKeys.includes('closed')) statusArray.push('closed');
    if (checkedKeys.includes('drafts')) statusArray.push('drafts');

    const filters = {
      status: statusArray.join(','),
      inuse: checkedKeys.includes('inuse'),
      psychotechnics: checkedKeys.includes('psychotecnic'),
      createdby: partnerSelected.length ? partnerSelected : null,
      sort,
      search,
      offset,
      limit: 6,
    };

    const data = await getAllCustomSearchs(filters);
    setSearchs(data.collection);
    setPag(data?.pagination?.total);
    dispatch(setSearches(data));
    addPartners(data.partners);
    setLoading(false);
  };
  const handleRequestDraft = () => {
    handleRequest();
  };
  useEffect(async () => {
    dispatch(setKeepSearchData(false));
    dispatch(setSelectedSearch({}));
    dispatch(setStageCandidates(null));
    const params = processQueryString(history.location.search)?.page;
    const searchParam = processQueryString(history.location.search)?.search;
    await handleRequest(0, searchParam);
  }, [reload, currentWorkspace, sort]);

  useEffect(() => {
    dispatch(setAppliedFilters([]));
  }, []);

  const goToPage = async (offset, page) => {
    await handleRequest(offset, search, page);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    await handleRequest(0, search);
  };

  const reloadSearches = () => {
    setReload(true);
  };

  const handleApplyFilters = async () => {
    await handleRequest(0, search);
  };

  const addPartners = (partnersToAdd) => {
    if (partners.length == 0) {
      setPartners(partnersToAdd);
    }
  };

  const totalFilters = () =>
    checkedKeys.length + (partnerSelected.length ? 1 : 0) + (search.length > 3 ? 1 : 0);

  const handleClearFilters = async () => {
    setLoading(true);
    setSearch('');
    setCheckedKeys(['open']);
    setPartnerSelected('');
    const recruitSessions = await handleRequest(0, '');
    setSearchs(recruitSessions.collection);
    setPag(recruitSessions?.pagination?.total);
    dispatch(setSearches(recruitSessions));
    addPartners(recruitSessions.partners);
    setLoading(false);
  };

  const existsNextPage = () =>
    Math.ceil(pag / 6) > currentPage && Math.ceil(pag / 6) !== currentPage + 1;

  const noStatusSelected = () =>
    !(
      checkedKeys.includes('open') ||
      checkedKeys.includes('closed') ||
      checkedKeys.includes('drafts')
    );
  return (
    <div className="position-relative w-100 screening-page-container">
      <div>
        <BackgroundTop
          title={t('MENU_ITEM_SEARCHES')}
          routes={[
            {
              text: 'MENU_ITEM_SCREENING',
              clickable: false,
            },
          ]}
          className="position-relative"
          titleClassname="text-title"
        />
      </div>
      <div className="position-relative">
        <div className="m-auto">
          <div className="bg-white rounded px-3 py-2 h-100">
            <div className="d-flex flex-column h-100">
              <div>
                <div className="d-flex flex-row justify-content-between pl-1 pt-1">
                  <h3 className=" font-weight-bold text-title m-0">{t('SEARCHES_CREATED')}</h3>
                </div>
              </div>
              <div className="row mt-3 h-100">
                <div className="col-11 col-lg-10 w-100 d-flex flex-column align-items-end h-100 order-lg-1 order-2 overflow-container">
                  <div className="row w-100 h-100" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                    {loading ? (
                      <div
                        className="d-flex align-items-center justify-content-center w-100"
                        style={{ height: '30rem' }}
                      >
                        <Spinner show />
                      </div>
                    ) : searchs?.length ? (
                      searchs.map((search) => (
                        <CardScreeningSearch
                          key={search.recruitSessionId || search.id}
                          data={search}
                          fetchDraft={handleRequestDraft}
                          setSearchesQuantity={setSearchesQuantity}
                          reloadSearches={reloadSearches}
                        />
                      ))
                    ) : (
                      <div
                        className="d-flex align-items-center justify-content-center w-100"
                        style={{ height: '30rem' }}
                      >
                        <EmptyComponent
                          className="p-4 h-100"
                          title={t('NOT_FOUND_SEARCHES')}
                          subtitle={t('EMPTY_SEARCHS_COMPONENT')}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-2 d-flex flex-column h-min gap-1 order-lg-2 order-1">
                  <div
                    className="d-flex align-items-center font-weight-700 gap-1 cursor-pointer flex-column"
                    onClick={() => setSort(sort === 'createdAt' ? 'use' : 'createdAt')}
                  >
                    <div
                      className={`d-flex align-items-center font-weight-700 gap-1 cursor-pointer ${
                        sort === 'createdAt' ? 'text-xs text-black' : 'text-xxs text-gray-2'
                      }`}
                    >
                      <span>{t('SCREENING_SEARCH_ORDER_DATE')}</span>{' '}
                      <BsArrowDown strokeWidth={1} />
                    </div>
                    <div
                      className={`d-flex align-items-center font-weight-700 gap-1 cursor-pointer ${
                        sort !== 'createdAt' ? 'text-xs text-black' : 'text-xxs text-gray-2'
                      }`}
                    >
                      <span>{t('SCREENING_SEARCH_ORDER_USE')}</span> <BsArrowDown strokeWidth={1} />
                    </div>
                  </div>
                  <div className="d-flex flex-column border-filters">
                    <div className="p-2 d-flex justify-content-center align-items-center gap-1 border-bottom-filter">
                      <div className="screening-filters-title">
                        <span className="pr-1">{t('CREATE_SCREENING_FILTERS')}</span>
                        <BsFillFunnelFill />
                      </div>
                      <span>{`(${totalFilters()})`}</span>
                    </div>
                    <div className="p-2 d-flex flex-column gap-1">
                      <form
                        data-testid="form-search"
                        onSubmit={handleSearch}
                        onKeyDown={(e) => e.key === 'Enter' && search.length > 3 && handleSearch(e)}
                        className="search-bar-screenings w-100 d-none d-lg-flex align-items-center"
                      >
                        <input
                          data-testid="input-search"
                          className="border-0 pr-2 w-100"
                          onChange={(e) => setSearch(e.target.value)}
                          type="text"
                          placeholder={t('PROFILES_SEARCH_PLACEHOLDER')}
                          value={search}
                        />
                        <img src={searchSvg} alt="search icon" />
                      </form>
                      <Tree
                        checkable
                        checkedKeys={checkedKeys}
                        onCheck={setCheckedKeys}
                        treeData={filters}
                        className="screenings-tree-filters"
                        textTruncateProps={{ showTooltip: true }}
                        defaultExpandAll
                      />
                      <Select
                        showSearch
                        placeholder="Creado por"
                        optionFilterProp="label"
                        onChange={(e) => setPartnerSelected(e ?? '')}
                        options={partners}
                        allowClear
                      />
                      <Button
                        type="primary"
                        className="bg-principal rounded-0"
                        onClick={handleApplyFilters}
                        disabled={noStatusSelected()}
                      >
                        {t('SCREENING_FILTERS_APPLY')}
                      </Button>
                      <Button type="secondary" onClick={handleClearFilters} className="rounded-0">
                        {t('SCREENING_FILTERS_CLEAR')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {Boolean(searchs?.length) && (
                <div
                  data-testid="screening-searches-pagination"
                  className="d-flex justify-content-center align-items-center mt-2 gap-1 text-sm"
                >
                  <IoIosArrowDropleftCircle
                    alt="navigate-left"
                    size={30}
                    className={currentPage !== 1 ? 'cursor-pointer hover-arrow-page' : ''}
                    onClick={() =>
                      currentPage !== 1 ? goToPage((currentPage - 1) * 6 - 6, currentPage - 1) : {}
                    }
                    color={currentPage !== 1 ? '#BAE2F6' : '#D9D9D9'}
                  />
                  {Number(currentPage) > 2 && (
                    <span
                      className=" text-decoration-none cursor-pointer hover-arrow-page"
                      aria-hidden="true"
                      onClick={() => goToPage(0, 1)}
                    >
                      1{Math.ceil(pag / 6) === 3 ? '' : '...'}
                    </span>
                  )}
                  {Number(currentPage) > 1 && (
                    <span
                      className="cursor-pointer hover-arrow-page"
                      aria-hidden="true"
                      onClick={() =>
                        currentPage !== 1
                          ? goToPage((currentPage - 1) * 6 - 6, currentPage - 1)
                          : {}
                      }
                    >
                      {Number(currentPage) - 1}
                    </span>
                  )}
                  {currentPage !== 0 && (
                    <span className=" font-weight-bold text-sm color-principal hover-arrow-page">
                      {currentPage}
                    </span>
                  )}
                  <span
                    className=" text-decoration-none cursor-pointer hover-arrow-page"
                    aria-hidden="true"
                    onClick={() => goToPage(currentPage * 6, currentPage + 1)}
                  >
                    {Math.ceil(pag / 6) > currentPage ? currentPage + 1 : ''}
                  </span>
                  {existsNextPage() && Number(Math.ceil(pag / 6)) > 3 && <span>...</span>}
                  {existsNextPage() && (
                    <span
                      className=" text-decoration-none cursor-pointer hover-arrow-page"
                      aria-hidden="true"
                      onClick={() => {
                        goToPage(Number(Math.ceil(pag / 6) - 1) * 6, Number(Math.ceil(pag / 6)));
                      }}
                    >
                      {`${Number(Math.ceil(pag / 6))}`}
                    </span>
                  )}
                  <IoIosArrowDroprightCircle
                    alt="navigate-right"
                    size={30}
                    className={
                      currentPage !== Number(Math.ceil(pag / 6))
                        ? 'cursor-pointer hover-arrow-page'
                        : ''
                    }
                    onClick={() =>
                      currentPage !== Number(Math.ceil(pag / 6))
                        ? goToPage(currentPage * 6, currentPage + 1)
                        : {}
                    }
                    color={currentPage !== Number(Math.ceil(pag / 6)) ? '#BAE2F6' : '#D9D9D9'}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screening;
