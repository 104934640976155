import { notification } from 'antd';
import { deleteDraft, getSearchesQuantity } from '../../API/services/screeningService';
import Modal from '../Modal/Modal';
import { t } from '../../i18n/i18n';

const ModalDeleteDraft = ({
  showDeleteModal,
  user,
  partnerId,
  setShowDeleteModal,
  draftId,
  fetchDrafts,
  setSearchesQuantity,
}) => (
  <Modal
    data-testid="card-screening-search-modal"
    className="modal-buttons-border d-flex justify-content-end"
    showCloseButton={false}
    show={showDeleteModal}
    minWidth={false}
    setShow={(e) => {
      setShowDeleteModal(e);
    }}
    onClose={() => {
      setShowDeleteModal(false);
    }}
  >
    <div className="px-5 mx-5">
      {user.id !== partnerId ? (
        <div className="py-4 d-flex flex-column align-items-center">
          <span className="font-weight-600  text-base mb-2">
            {t('DELETE_DRAFT_NOT_THE_OWNER_MODAL_TITLE')}
          </span>
          <button
            type="button"
            className="px-5 py-3 modal-buttons-border bg-red-danger mt-5 border-0 text-white  outline-none text-xs font-weight-700"
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            {t('DELETE_DRAFT_NOT_THE_OWNER_MODAL_BUTTON')}
          </button>
        </div>
      ) : (
        <div className="py-5 d-flex flex-column align-items-center">
          <span className="font-weight-600  text-base mb-2">
            {t('DELETE_DRAFT_THE_OWNER_MODAL_TITLE')}
          </span>
          <span className="font-weight-500  text-xs mb-2">
            {t('DELETE_DRAFT_THE_OWNER_MODAL_SUBTITLE')}
          </span>
          <div>
            <button
              type="button"
              className="px-5 py-3 modal-buttons-border bg-transparent mt-5 dont-delete-draft-button-border border-red-danger text-red-danger  outline-none text-xs font-weight-700 mr-2"
              onClick={() => {
                setShowDeleteModal(false);
              }}
            >
              {t('DELETE_DRAFT_THE_OWNER_MODAL_NO_BUTTON')}
            </button>
            <button
              type="button"
              className="px-5 py-3 modal-buttons-border bg-red-danger mt-5 border-0 text-white  outline-none text-xs font-weight-700 ml-2"
              onClick={async () => {
                try {
                  await deleteDraft(draftId);
                  await fetchDrafts();
                  setShowDeleteModal(false);
                  await getSearchesQuantity().then((res) => setSearchesQuantity(res ?? {}));
                  notification.open({
                    message: t('DELETE_DRAFT_SUCCESS'),
                    type: 'success',
                  });
                } catch (error) {
                  notification.open({
                    message: t('DELETE_DRAFT_NOT_THE_OWNER_MODAL_TITLE'),
                    type: error,
                  });
                }
              }}
            >
              {t('DELETE_DRAFT_THE_OWNER_MODAL_YES_BUTTON')}
            </button>
          </div>
        </div>
      )}
    </div>
  </Modal>
);

export default ModalDeleteDraft;
