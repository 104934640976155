import './TimePicker.scss';

const TimePicker = ({
  values,
  onChange,
  classNames,
  classNamesInputs,
  maxHours = 23,
  maxMinutes = 59,
}) => {
  const conditionalFormat = (value) => {
    const numberValue = Number(value);
    if (numberValue < 10) return `0${numberValue}`;
    return numberValue.toString();
  };

  const formatNumber = (value, maxValue) => {
    if (value > maxValue) return maxValue.toString();
    return conditionalFormat(value);
  };

  const onBlurHour = (e) => {
    e.target.value = formatNumber(e.target.value, maxHours);
    onChange(e);
  };

  const onBlurMinutesAndSeconds = (e) => {
    e.target.value = formatNumber(e.target.value, maxMinutes);
    onChange(e);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const isNumber = !Number.isNaN(Number(e.target.value));
    e.target.value = conditionalFormat(value);
    const exceedsMaxLength = e.target.value.length > 2;
    if (isNumber && !exceedsMaxLength) onChange(e);
  };

  const handleFocus = (e) => {
    const { value } = e.target;
    const input = e.target;
    const end = value.length;
    input.setSelectionRange(end, end);
  };

  return (
    <div
      data-testid="time-picker"
      className={`time-input-container  ml-2 py-1 border-thin-blue-principal font-weight-bold ${classNames}`}
    >
      <div className="d-flex justify-content-center align-items-center">
        <input
          type="text"
          name="hours"
          value={values?.hours ?? '00'}
          onChange={handleChange}
          onClick={handleFocus}
          onBlur={onBlurHour}
          onFocus={handleFocus}
          maxLength="4"
          placeholder="00"
          className={`outline-none text-muted text-right time-input font-weight-bold  text-xs border-0 ${classNamesInputs}`}
        />
        <span className={`time-input-separators  text-muted ${classNamesInputs}`}>:</span>
        <input
          type="text"
          name="minutes"
          onChange={handleChange}
          value={values?.minutes ?? '00'}
          onClick={handleFocus}
          onBlur={onBlurMinutesAndSeconds}
          onFocus={handleFocus}
          maxLength="4"
          placeholder="00"
          className={`outline-none text-muted text-center time-input font-weight-bold  text-xs border-0 ${classNamesInputs}`}
        />
        <span className={`time-input-separators  text-muted ${classNamesInputs}`}>:</span>
        <input
          type="text"
          name="seconds"
          onChange={handleChange}
          value={values?.seconds ?? '00'}
          onClick={handleFocus}
          onBlur={onBlurMinutesAndSeconds}
          onFocus={handleFocus}
          maxLength="4"
          placeholder="00"
          className={`outline-none text-muted text-center time-input font-weight-bold  text-xs border-0 ${classNamesInputs}`}
        />
      </div>
    </div>
  );
};

export default TimePicker;
