import { useEffect, useRef, useState } from 'react';
import arrowIcon from '../../assets/dashboard/arrow-small.svg';
import whiteArrow from '../../assets/savedSearch/fe_drop-down.svg';
import DropDownArrow from '../../assets/screening/DropDownArrow';
import arrowBlue from '../../assets/savedSearch/fe_drop-down-blue.svg';
import { ProcessStatusStageColor } from '../../constants/enums';
import { t } from '../../i18n/i18n';
import useClickOutside from '../../hooks/useClickOutside';
import './DropdownSelectDot.scss';

const DropdownSelectDot = ({
  placeholder,
  options = [],
  testid = 'dropdown-select-dot',
  className,
  setState,
  selected,
  placeholderClassname,
  roundBorder = false,
  allOption,
  selectedPlaceholder,
  listSelect,
  alignCenter,
  outsidePadding,
  position = 'right',
  arrow = false,
  arrowPurple,
  arrowLightBlue,
  bigArrowLightBlue,
  maxHeight = true,
  responsive,
  disabled,
  dot = false,
  fullWidth = true,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false));
  const optionSelected =
    selectedPlaceholder && selected
      ? options.find((op) => op?.id === selected || op?.value === selected)?.tag ??
        options.find((op) => op?.id === selected || op?.value === selected)?.value ??
        placeholder
      : placeholder;

  const handleClick = (value) => {
    setState(value);
    setOpen(false);
  };

  useEffect(() => {
    if (options.length && selected) {
      const selectedOption = options.find(
        (option) =>
          (option.id && option.id === selected) || (option.value && option.value === selected)
      );
      if (selectedOption) {
        setState({
          id: selectedOption.value ?? selectedOption.id,
          name: selectedOption.label ?? '',
          ...selectedOption,
        });
      } else {
        setState(null);
      }
    }
  }, []);

  const optionSelectedText = selected
    ? options.find((op) => op?.id === selected || op?.value === selected)?.label ??
      options.find((op) => op?.id === selected || op?.value === selected)?.name ??
      placeholder
    : placeholder;

  return (
    <div
      data-testid={testid}
      className={`${outsidePadding ?? 'py-2'} d-flex w-100 ${fullWidth && 'w-100'} ${
        maxHeight && 'dropdown-max-height'
      } ${open && 'dropdown-container-index'} ${
        alignCenter ?? 'align-self-end align-self-sm-center'
      }`}
    >
      <div
        ref={ref}
        className={` font-weight-bold non-selectable dropdown-select-container position-relative w-max-content ${className} ${
          roundBorder && 'border rounded'
        }`}
      >
        <div
          onClick={() => !disabled && setOpen((state) => !state)}
          aria-hidden
          className={`dropdown-select-button cursor-pointer w-100 d-flex justify-content-between align-items-center ${
            listSelect ? 'px-2 py-1' : 'p-2'
          }`}
        >
          {optionSelected !== t('DASHBOARD_INVITATIONS_PROCESS_STATUS') && dot && (
            <div
              className={`screening-completion-dot mr-2 ${ProcessStatusStageColor[optionSelected]}`}
            />
          )}
          <span
            className={`dropdown-select-placeholder overflow-hidden  ${
              disabled && 'text-muted'
            } ${placeholderClassname}`}
          >
            {selectedPlaceholder && optionSelectedText}
          </span>
          {arrow && (
            <img
              src={listSelect ? whiteArrow : arrowIcon}
              alt={t('ARROW_ALT')}
              className={`dropdown-select-arrow ${listSelect ? 'ml-2' : 'mx-2'} ${
                open && 'dropdown-select-arrow-open'
              }`}
            />
          )}
          {arrowPurple && <DropDownArrow classNames={`${open && 'dropdown-select-arrow-open'}`} />}
          {bigArrowLightBlue && (
            <DropDownArrow lightBlue classNames={`${open && 'dropdown-select-arrow-open'}`} />
          )}
          {arrowLightBlue && (
            <img
              src={arrowBlue}
              alt={t('ARROW_ALT')}
              className={`dropdown-select-arrow ${listSelect ? 'ml-2' : 'mx-2'} ${
                open && 'dropdown-select-arrow-open'
              }`}
            />
          )}
        </div>
        <ul
          className={`dropdown-select-options-container bg-white overflow-auto custom-scrollbar m-0 position-absolute line-height-20 gray-subtitle ${
            open && 'dropdown-select-options-container-open py-2 border'
          } ${position === 'right' ? 'left-0' : 'right-0'} ${listSelect && 'text-dark'}`}
        >
          {allOption && (
            <li
              className="ml-2 p-2 cursor-pointer dropdown-selec-option letter-spacing-05 text-xs "
              onClick={() => handleClick({ name: t('ALL'), id: null })}
              onKeyPress={() => handleClick({ name: t('ALL'), id: null })}
              role="option"
              aria-selected
            >
              {t('ALL')}
            </li>
          )}
          {options.map((option, i) => (
            <div className="d-flex p-2">
              <div
                className={`screening-completion-dot mt-1 mr-2 ${
                  ProcessStatusStageColor[option.value]
                }`}
              />
              <li
                className={`cursor-pointer dropdown-selec-option font-weight-normal font-italic letter-spacing-05 text-xs  ${
                  selected &&
                  (selected === option.value || selected === option.id) &&
                  'dropdown-selected-option'
                } ${responsive && 'text-dark'}`}
                key={i}
                onClick={() =>
                  handleClick({
                    id: option.value ?? option.id,
                    ...(option.label ? { name: option.label } : {}),
                    ...option,
                  })
                }
                onKeyPress={() =>
                  handleClick({
                    id: option.value ?? option.id,
                    ...(option.label ? { name: option.label } : {}),
                    ...option,
                  })
                }
                role="option"
                aria-selected
              >
                {option.label ?? option.name}
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownSelectDot;
