import ImageFileIcon from '../../assets/testgroupABM/ImageFileIcon';
import FileCross from '../../assets/testgroupABM/FileCross';
import { t } from '../../i18n/i18n';
import UploadTestImage from '../UploadTestImage/UploadTestImage';
import UploadTestAudio from '../UploadTestAudio/UploadTestAudio';
import { TestFileType } from '../../constants/enums';
import AudioFileIcon from '../../assets/testgroupABM/AudioFileIcon';
import NumberInput from '../NumberInput/NumberInput';
import GenericToggle from '../GenericToggle/GenericToggle';
import { Window } from '../../utils/window';
import OpenExternalLinkIcon from '../../assets/testgroupABM/OpenExternalLinkIcon';
import './UploadTestFiles.scss';
import UploadTestMultipleImage from '../UploadTestMultipleImages/UploadTestMultipleImages';

const UploadTestFiles = ({
  fileTypes = [],
  maxFiles,
  testFiles,
  handleAddFile,
  handleRemoveFile,
  handleUpdateFile,
  id,
  multipleFiles = false,
}) => {
  const fileInputs = [
    {
      type: TestFileType.IMAGE,
      element: multipleFiles ? (
        <UploadTestMultipleImage addFile={handleAddFile} id={id} />
      ) : (
        <UploadTestImage addFile={handleAddFile} id={id} />
      ),
    },
    {
      type: TestFileType.AUDIO,
      element: (
        <UploadTestAudio
          addFile={(file, type) => handleAddFile({ ...file, infinitePlays: true }, type)}
          id={id}
        />
      ),
    },
  ];

  return (
    <div data-testid="upload-files-container" className="ml-1 mb-4">
      {(!maxFiles || testFiles?.length < maxFiles) && (
        <div className="d-flex align-items-start h-100 text-purple mb-4">
          {fileTypes &&
            fileInputs
              .filter((input) => fileTypes.includes(input.type))
              .map((input, index) => (
                <>
                  {index !== 0 && <div className="upload-files-input-separator" key={index} />}
                  {input.element}
                </>
              ))}
        </div>
      )}
      <div>
        {testFiles?.map((file, index) => (
          <>
            {index !== 0 && <div className="upload-files-uploaded-separator my-3" />}
            <div key={file.id}>
              {file?.fileType === TestFileType.IMAGE && (
                <div data-testid="upload-files-image-container">
                  <div className="d-flex align-items-center">
                    <div
                      data-testid="upload-files-image-label"
                      className="text-xs  font-weight-500"
                      htmlFor="files"
                    >
                      <ImageFileIcon className="mr-1" />
                      {file.extension ? `${file.name}.${file.extension}` : file.name}
                    </div>
                    <button
                      data-testid="upload-files-image-remove-button"
                      type="button"
                      onClick={() => handleRemoveFile(file.id)}
                      className="ml-2 border-0 bg-transparent cursor-pointer"
                    >
                      <FileCross />
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        Window().open(file.url ?? URL.createObjectURL(file.fileObject), '_blank')
                      }
                      className="upload-files-image-open-button d-flex align-items-center text-xs "
                      data-testid="upload-files-image-open-button"
                    >
                      <OpenExternalLinkIcon className="mr-1" />
                      {t('UPLOAD_TEST_FILES_OPEN_IMAGE')}
                    </button>
                  </div>
                  <img
                    src={file.url ?? URL.createObjectURL(file.fileObject)}
                    alt={file.name}
                    className="upload-files-image-uploaded mt-3"
                  />
                </div>
              )}
              {file?.fileType === TestFileType.AUDIO && (
                <div data-testid="upload-files-audio-container">
                  <div>
                    <span className="text-xs  font-weight-500">
                      <AudioFileIcon className="mr-1" />
                      {file.extension ? `${file.name}.${file.extension}` : file.name}
                    </span>
                    <button
                      data-testid="upload-files-audio-remove-button"
                      type="button"
                      onClick={() => handleRemoveFile(file.id)}
                      className="ml-2 border-0 bg-transparent cursor-pointer"
                    >
                      <FileCross />
                    </button>
                  </div>
                  <div className="mt-3 d-flex">
                    <audio
                      data-testid="upload-files-audio-tag"
                      id="upload-files-audio-tag"
                      controls
                      onLoadedMetadata={(e) =>
                        !file.duration && handleUpdateFile(file.id, { duration: e.target.duration })
                      }
                      src={file.url ?? URL.createObjectURL(file.fileObject)}
                    />
                    <div className="d-flex align-items-center">
                      <GenericToggle
                        actionFunction={() =>
                          handleUpdateFile(file.id, { infinitePlays: !file.infinitePlays })
                        }
                        defaultActive={file.infinitePlays}
                      />
                      <span
                        className={` text-xs font-weight-500 ${
                          !file.infinitePlays ? 'text-muted' : 'text-light-black'
                        }`}
                      >
                        {t('TESTGROUP_ABM_ADD_AUDIO_INFINITE_PLAYS_TOGGLE')}
                      </span>
                    </div>
                    {!file.infinitePlays && (
                      <div className="d-flex align-items-center ml-1">
                        <span className=" text-xs font-weight-600 font-italic text-light-black ml-3">
                          {t('TESTGROUP_ABM_ADD_AUDIO_AMOUNT_OF_PLAYS')}
                        </span>
                        <NumberInput
                          onChange={(value) => handleUpdateFile(file.id, { numberOfPlays: value })}
                          value={file.numberOfPlays}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default UploadTestFiles;
