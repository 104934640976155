import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './PersonalizeSearchTable.scss';
import { v4 } from 'uuid';
import ModalCustom from '../ModalCustom/ModalCustom';
import PersonalizeColumnItem from '../PersonalizeColumnItem/PersonalizeColumnItem';
import GearIcon from '../../assets/dashboard/GearIcon';
import { setSearchTablePersonalization } from '../../redux/actions/screeningActions';
import { TestGroupTypes } from '../../constants/enums';
import { defaultSearchTableHeaders } from '../../constants/searchTablePersonalization';
import { t } from '../../i18n/i18n';

const PersonalizeSearchTable = ({ stageScreening, savedPersonalization, id }) => {
  const [showPersonalize, setShowPersonalize] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(v4());
  const [tablePersonalization, setTablePersonalization] = useState([]);
  const dispatch = useDispatch();

  const saveNewPersonalization = (newPersonalization) => {
    localStorage.setItem(`searchTablePersonalization-${id}`, JSON.stringify(newPersonalization));
    dispatch(setSearchTablePersonalization([...newPersonalization]));
  };

  const getAndSetSavedPersonalization = (defaultPersonalization) => {
    if (savedPersonalization) {
      const parsedPersonalization = JSON.parse(savedPersonalization);
      const personalization = defaultPersonalization.map((item) => {
        const itemFound = parsedPersonalization.find((element) => element.key === item.key);
        return {
          ...item,
          checked: itemFound?.checked ?? item.checked,
          pinned: itemFound?.pinned ?? item.pinned,
          order: itemFound?.order ?? item.order,
        };
      });
      dispatch(setSearchTablePersonalization(personalization));
      setTablePersonalization(personalization);
    } else {
      dispatch(setSearchTablePersonalization(defaultPersonalization));
      setTablePersonalization(defaultPersonalization);
    }
  };

  const setDefaultConfig = () => {
    const tempColumns = defaultSearchTableHeaders.map((x) => ({ ...x }));
    stageScreening?.testGroups.map((tg) =>
      tempColumns.splice(tempColumns.length, 0, {
        key: `TG_KEY_${tg.id}`,
        name: tg.name,
        width: tg.type === TestGroupTypes.QUALITATIVE ? `15rem` : '8.5rem',
        value: tg.id,
        checked: false,
        pinned: false,
        sortable: true,
        order: tempColumns.length + 1,
        ...(tg.qualitativeConfig ? { qualitativeConfig: tg.qualitativeConfig } : null),
      })
    );
    getAndSetSavedPersonalization(tempColumns);
  };

  useEffect(() => {
    setDefaultConfig();
  }, [stageScreening]);

  const handleColumnCheck = (key) => {
    const tempColumns = [...tablePersonalization];

    tempColumns.forEach((element, index) => {
      if (element.key === key) {
        tempColumns[index].checked = !tempColumns[index].checked;
        if (!element.checked) {
          tempColumns[index].pinned = false;
        }
      }
    });

    setTablePersonalization(tempColumns);
  };

  const handleOrder = (currentOrder, nextOrder) => {
    const tempColumns = [...tablePersonalization];
    const currentOrderItem = tempColumns?.find((item) => item.order === currentOrder);
    const nextOrderItem = tempColumns?.find((item) => item.order === nextOrder);
    const filteredData = tempColumns?.filter(
      (item) => item.key !== nextOrderItem.key && item.key !== currentOrderItem.key
    );
    currentOrderItem.order = nextOrder;
    nextOrderItem.order = currentOrder;
    setTablePersonalization([...filteredData, nextOrderItem, currentOrderItem]);
  };

  const handlePin = (key) => {
    const tempColumns = [...tablePersonalization];
    const itemToPin = tempColumns.findIndex((item) => item.key === key);
    tempColumns[itemToPin].pinned = !tempColumns[itemToPin].pinned;
    if (tempColumns[itemToPin].order > 2) {
      const firstItemIndex = tempColumns.findIndex((item) => item.order === 1);
      const secondItemIndex = tempColumns.findIndex((item) => item.order === 2);

      if (firstItemIndex >= 0) {
        if (!tempColumns[firstItemIndex].pinned) {
          tempColumns[firstItemIndex].order = tempColumns[itemToPin].order;
          tempColumns[itemToPin].order = 1;
        } else {
          if (secondItemIndex >= 0) {
            tempColumns[secondItemIndex].order = tempColumns[itemToPin].order;
          }
          tempColumns[itemToPin].order = 2;
        }
      } else {
        tempColumns[itemToPin].order = 1;
      }
    }

    setTablePersonalization(tempColumns);
  };

  const disableOrder = (direction, index) => {
    let isDisabled = false;

    if (direction === 'UP') {
      isDisabled = index === 0;
    }
    if (direction === 'DOWN') {
      const amountPinned = tablePersonalization.filter((item) => item.pinned).length;
      isDisabled =
        index === tablePersonalization.length - 1 ||
        (amountPinned > 0 && index === amountPinned - 1);
    }
    return isDisabled;
  };

  const applyEdition = () => {
    setShowPersonalize(false);
    saveNewPersonalization(tablePersonalization);
  };

  const cancelEdition = () => {
    setShowPersonalize(false);
  };

  const openEdition = () => {
    setRerenderKey(v4());
    setDefaultConfig();
    setShowPersonalize(true);
  };

  const checkboxDisabled = (index) => {
    const amountChecked = tablePersonalization.filter((item) => item.checked);
    return amountChecked.length < 2 && tablePersonalization[index].checked;
  };

  return (
    <div>
      <button
        data-testid="personalize-search-table-button"
        className="personalize-button"
        type="button"
        onClick={openEdition}
      >
        <div className="d-flex align-items-center ">
          <GearIcon fill="#757575" />
          <span className="ml-2  font-weight-600 text-sm"> {t('PERSONALIZE_COLUMNS')}</span>
        </div>
      </button>
      <ModalCustom
        show={showPersonalize}
        setShow={setShowPersonalize}
        showCross={false}
        onOkText={t('PERSONALIZE_COLUMNS_APPLY')}
        onCancelText={t('PERSONALIZE_COLUMNS_CANCEL')}
        onOk={applyEdition}
        onCancel={cancelEdition}
        datatestid="personalize-search-table-modal"
        datatestidbuttonok="personalize-search-table-apply-button"
        datatestidbuttoncancel="personalize-search-table-cancel-button"
        classModal="personalization-modal-w"
        input={
          <div>
            <h1 className=" font-weight-600 personalize-title">{t('PERSONALIZE_COLUMNS')}</h1>
            <div className="personalization-modal-h">
              {tablePersonalization
                .sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <PersonalizeColumnItem
                    key={`${item.key}-${rerenderKey}`}
                    columnItem={item}
                    index={index}
                    handleColumnCheck={handleColumnCheck}
                    handleOrder={handleOrder}
                    handlePin={handlePin}
                    allowPin={tablePersonalization.filter((item) => item.pinned).length < 2}
                    disableOrderDown={disableOrder('DOWN', index)}
                    disableOrderUp={disableOrder('UP', index)}
                    disableCheckbox={checkboxDisabled(index)}
                  />
                ))}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default PersonalizeSearchTable;
