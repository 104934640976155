import React from 'react';
import { t } from '../../../i18n/i18n';
import emailIcon from '../../../assets/profileItem/email-white.svg';
import gmailIcon from '../../../assets/profileItem/gmail-icon.svg';
import { TrackEvent } from '../../../utils/filters/segmentUtils';
import './ContactModal.scss';

const ContactModal = ({ show, alkymer, partner, profileDetails }) => {
  const ccMail = 'contacto@alkemy.org';
  return (
    <div
      className={`bg-dark position-absolute right-0 mr-2 z-index-2 ${
        show ? 'show-contact-modal' : 'not-show-contact-modal'
      }`}
    >
      <div className={`${show ? 'd-flex p-3' : 'd-none'}`}>
        <a
          onClick={() => {
            TrackEvent(profileDetails ? 'Contact-Alkymer-details' : 'Contact-profile-list', {
              ContactType: 'Gmail',
            });
          }}
          href={`https://mail.google.com/mail/u/0/?fs=1&to=${alkymer?.email}&cc=${ccMail}&su=${`${t(
            'CONTACT_TITLE_1'
          )}${alkymer?.firstName} ${alkymer?.lastName}${t('CONTACT_TITLE_2')}`}&body=${encodeURI(
            `${t('CONTACT_BODY_1')}${alkymer?.firstName} 👋!\n\n${t('CONTACT_BODY_2')}${
              partner?.firstName
            } ${t('CONTACT_BODY_3')} ${partner?.company?.displayName}${t('CONTACT_BODY_4')}\n\n`
          )}&tf=cm`}
          target="_blank"
          rel="noreferrer"
          className="text-center text-decoration-none d-flex flex-column align-items-center px-4 border-white border-right"
        >
          <img src={gmailIcon} className="contact-gmail-icon" alt={t('ICON_GMAIL')} />
          <span className="text-white ">{t('GMAIL')}</span>
        </a>
        <a
          onClick={() => {
            TrackEvent(profileDetails ? 'Contact-Alkymer-details' : 'Contact-profile-list', {
              ContactType: 'Outlook',
            });
          }}
          href={`mailto:${alkymer?.email}?cc=${ccMail}&subject=${`${t('CONTACT_TITLE_1')}${
            alkymer?.firstName
          } ${alkymer?.lastName}${t('CONTACT_TITLE_2')}`}&body=${encodeURI(
            `${t('CONTACT_BODY_1')}${alkymer?.firstName} 👋!\n\n${t('CONTACT_BODY_2')}${
              partner?.firstName
            } ${t('CONTACT_BODY_3')} ${partner?.company?.displayName}${t('CONTACT_BODY_4')}\n\n`
          )}`}
          className="text-center text-decoration-none d-flex flex-column align-items-center px-4 border-white border-left"
        >
          <img className="contact-gmail-icon" src={emailIcon} alt={t('ICON_OUTLOOK')} />
          <span className="text-white ">{t('OUTLOOK')}</span>
        </a>
      </div>
    </div>
  );
};
export default ContactModal;
