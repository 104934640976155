import { useEffect, useState } from 'react';
import { t } from '../../../i18n/i18n';
import { formatNumberWithThousandsSeparator } from '../../../utils/numbersUtils';
import InvertIcon from '../../../assets/chart/InvertIcon';
import HorizontalBarsChart from '../../HorizontalBarsChart/HorizontalBarsChart';
import { invertMatrix } from '../../../utils/arrayUtils';
import './AgeAndGenderChart.scss';

const chartColors = ['#4987be', '#5EDCA7', '#FFDC57', '#FD7779', '#4987BE', '#F38FED'];

export default function AgeAndGenderChart({ agesAndGendersMetrics }) {
  const [counts, setCounts] = useState({});
  const [matrix, setMatrix] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [maxRowsValues, setMaxRowsValues] = useState(0);

  const sumRowsAndColumns = (matrix, columnTitles, rowTitles) => {
    const result = {};

    columnTitles.forEach((title) => {
      result[title] = 0;
    });
    rowTitles.forEach((title) => {
      result[title] = 0;
    });

    for (let i = 0; i < matrix.length; i += 1) {
      for (let j = 0; j < matrix[i].length; j += 1) {
        const value = matrix[i][j];
        result[rowTitles[i]] += value;
        result[columnTitles[j]] += value;
      }
    }

    return result;
  };

  const invertAxes = () => {
    const tempCols = columns;
    const tempRows = rows;
    setRows(tempCols);
    setColumns(tempRows);
    setMatrix((matrix) => invertMatrix(matrix));
  };

  const getTranslation = (legend = '') => t(`AGE_GENDER_LEGEND_${legend.toUpperCase()}`, true);

  useEffect(() => {
    setCounts(
      sumRowsAndColumns(
        agesAndGendersMetrics.matrix,
        agesAndGendersMetrics.columns,
        agesAndGendersMetrics.rows
      )
    );
    setMatrix(agesAndGendersMetrics.matrix);
    setRows(agesAndGendersMetrics.rows);
    setColumns(agesAndGendersMetrics.columns);
  }, [agesAndGendersMetrics]);

  useEffect(() => {
    setMaxRowsValues(rows.reduce((prev, row) => Math.max(prev, counts[row] ?? 0), 0));
  }, [counts, rows]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <p
            className=" font-weight-600 age-gender-chart-total"
            data-testid="age-and-gender-chart-total"
          >
            {formatNumberWithThousandsSeparator(agesAndGendersMetrics.total ?? 0)}
          </p>
          <span className=" age-gender-chart-total-text">
            {t('AGE_GENDER_CHAR_TOTAL_ACCOUNTS')}
          </span>
          <div className="mt-4">
            <span
              className="cursor-pointer"
              onClick={invertAxes}
              aria-hidden
              data-testid="age-and-gender-chart-invert-axes"
            >
              <span className=" invert-axes-text mr-2 non-selectable">
                {t('AGE_GENDER_CHAR_INVERT_AXES')}
              </span>
              <InvertIcon />
            </span>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center">
          {columns.map((column, i) => (
            <div className="d-flex align-items-center age-gender-legend-container" key={column}>
              <span
                className="age-gender-legend-circle"
                style={{ backgroundColor: chartColors[i] }}
              />
              <span className=" age-gender-legend ml-1">{getTranslation(column)}</span>
              <b className="ml-1  text-xs">
                ({formatNumberWithThousandsSeparator(counts[column] ?? 0)})
              </b>
            </div>
          ))}
        </div>
      </div>
      <HorizontalBarsChart
        rows={rows}
        columns={columns}
        matrix={matrix}
        colors={chartColors}
        maxVal={maxRowsValues}
        getTranslation={getTranslation}
      />
    </div>
  );
}
