import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMissingData } from '../../hooks/useMissingData';
import Progress from '../Progress/Progress';
import { t } from '../../i18n/i18n';
import emptyUserPhoto from '../../assets/header/profile.svg';
import happyFaceSvg from '../../assets/homeProfile/faces/happyFace.svg';
import mediumFaceSvg from '../../assets/homeProfile/faces/mediumFace.svg';
import badFaceSvg from '../../assets/homeProfile/faces/badFace.svg';
import noCreditsFaceSvg from '../../assets/homeProfile/faces/noCreditsFace.svg';
import clockSvg from '../../assets/homeProfile/faces/clock.svg';
import { PLANS_CONSUMPTIONS } from '../../constants/routes';
import './HomeProfile.scss';
import useTranslations from '../../hooks/useTranslations';

const HomeProfile = ({ workspacePage, isCredits = false }) => {
  const history = useHistory();
  const percentage = useMissingData();
  const { key } = useTranslations();
  const { user, plan } = useSelector((state) => state.auth);

  const percentageUsedPlan = Object.values(plan).length
    ? plan?.screeningsUsed > 0
      ? (plan?.screeningsUsed / plan?.screeningsAmount) * 100
      : 0
    : 100;

  const nextRenewal = moment(plan?.subscription?.nextRenewal);
  const subscriptionDuration = plan?.subscription?.renewalFrequency;
  const daysLeft = moment(nextRenewal).diff(new Date(), 'days');
  const percentDaysLeft = Math.round((daysLeft * 100) / subscriptionDuration);
  let formattedNextRenewal = nextRenewal.locale(key ?? 'es');

  switch (key) {
    case 'en':
      formattedNextRenewal = formattedNextRenewal.format('MMMM D');
      break;
    case 'pt':
    case 'es':
      formattedNextRenewal = formattedNextRenewal.format('D [de] MMMM');
    default:
  }

  const calculateImg = (percentage) => {
    if (percentage >= 86) return badFaceSvg;
    if (percentage >= 61 && percentage <= 85) return mediumFaceSvg;
    if (percentage <= 60 && percentage >= 0) return happyFaceSvg;
    return noCreditsFaceSvg;
  };

  const calculatePlanDaysLeft = (daysLeft, percentDaysLeft, planUsage) => {
    let content = '';

    if (planUsage < 100 && planUsage > 0) {
      if (percentDaysLeft > 20) {
        content = (
          <>
            {t('HOME_PROFILE_PLAN_RENEWAL_TITLE')}
            <b> {formattedNextRenewal} </b>
          </>
        );
      } else {
        content = (
          <>
            <img src={clockSvg} alt="clock-svg" width="21.71" height="24" className="mr-2" />¡
            {t('HOME_PROFILE_PLAN_RENEWAL_TITLE')}
            <b> {formattedNextRenewal} </b>!
          </>
        );
      }
    } else {
      content = (
        <>
          {plan?.subscription?.subscriptionPending?.isRenewable === true && (
            <>
              {t('HOME_PROFILE_THE')}&nbsp;
              <b>
                {moment(plan?.subscription?.subscriptionPending?.startedDate).format('D')}{' '}
                {t('HOME_PROFILE_OF')}&nbsp;
                {t(
                  `SUBSCRIPTION_MONTH_${moment(
                    plan?.subscription?.subscriptionPending?.startedDate
                  ).format('M')}`
                )}
              </b>
              &nbsp;
              {plan?.subscription?.subscriptionPending?.planId === 1
                ? t('HOME_PROFILE_PLAN_RENEWAL_FREE')
                : t('HOME_PROFILE_PLAN_RENEWAL_PAY').replace(
                    'x',
                    plan?.subscription?.subscriptionPending?.screeningsAmount
                  )}
            </>
          )}
        </>
      );
    }

    return content;
  };

  const ScreeningsAmountLeftText = ({ plan }) => {
    const { screeningsAmount, screeningsUsed, assessmentInvitationPendingCredits } = plan;
    const screeningsRemaining = screeningsAmount - screeningsUsed;
    return (
      <>
        {assessmentInvitationPendingCredits === 0 || screeningsRemaining > 0 ? (
          <>
            {`${t('HOME_PROFILE_YOU_HAVE_LEFT')} `}
            <b>{screeningsRemaining}</b>
            {` ${t('FEATURE_SCREENINGS')}`}
          </>
        ) : (
          <>
            {`${t('HOME_PROFILE_ASSESSMENT_INVITATION_PENDING_CREDITS')} `}
            <b>{assessmentInvitationPendingCredits}</b>
            {` ${t('FEATURE_SCREENINGS_PENDING_CREDITS')}`}
            <a
              className=" text-xs font-weight-500 text-link"
              href={process.env.RAZZLE_RUNTIME_SALES_CALENDAR_URL}
            >
              link
            </a>
          </>
        )}
      </>
    );
  };

  const determineImageClassname = () =>
    workspacePage ? 'size-profile-img' : isCredits ? 'home-plan-image' : 'home-profile-img';
  return (
    <div
      data-testid="home-profile"
      className={`home-profile d-flex align-items-center ${
        workspacePage ? 'px-4 w-100 b-radius-8 bg-white' : 'w-100 px-4'
      }`}
    >
      <div
        className={`d-flex flex-row ${
          workspacePage ? 'px-2 w-100 workspace-profile-content' : 'gap-2 w-100'
        }`}
      >
        <div className={`d-flex align-items-center ${isCredits && 'p-lg-2 bg-white'}`}>
          <img
            data-testid="profile-img"
            className={determineImageClassname()}
            src={
              isCredits
                ? calculateImg(percentageUsedPlan)
                : user?.picture
                ? user?.picture
                : emptyUserPhoto
            }
            alt={t('PROFILE_PICTURE_OF_PROFILE')}
          />
        </div>
        <div
          className={`d-flex w-100 p-1 ${
            workspacePage
              ? 'flex-row  align-items-center justify-content-between'
              : 'justify-content-between flex-column w-100'
          }`}
        >
          <span
            className={`font-weight-700  text-title ${
              workspacePage ? 'position-profile-name' : 'mt-3'
            } ${isCredits ? 'mt-3' : ''}`}
          >
            {isCredits ? (
              `${t('HOME_PROFILE_PLAN')} ${plan?.planName}`
            ) : (
              <span>{`${user.firstName} ${user.lastName}`}</span>
            )}
          </span>
          <div
            className={`d-flex gap-1 ${
              workspacePage
                ? 'button-create-workspace w-50 align-items-center justify-self-end'
                : 'w-100 flex-column'
            }`}
          >
            <div className={`${workspacePage ? 'position-progress-bar w-100' : 'w-100'}`}>
              {isCredits && (
                <span
                  className={`text-xs font-weight-500 ${
                    daysLeft > 0 && percentDaysLeft <= 20
                      ? 'completed-percentage-text-red'
                      : 'completed-percentage-text'
                  }`}
                >
                  <ScreeningsAmountLeftText plan={plan} />
                  <br />
                </span>
              )}
              <span
                className={`${
                  isCredits && daysLeft > 0 && percentDaysLeft <= 20
                    ? 'completed-percentage-text-red'
                    : 'completed-percentage-text'
                } ${isCredits ? 'font-weight-300 text-xs' : 'font-weight-600 text-xs '}`}
              >
                {isCredits
                  ? calculatePlanDaysLeft(daysLeft, percentDaysLeft, 100 - percentageUsedPlan)
                  : percentage < 100
                  ? `${t('YOUR_PROFILE_IS_AT_PERCENT')} ${percentage}%`
                  : t('HOME_YOUR_PROFILE_IS_COMPLETED')}
              </span>
              <Progress
                percent={isCredits ? percentageUsedPlan : percentage}
                className={`home-progress ${isCredits ? 'custom-margin-home-progress' : ''}`}
                isCredits={isCredits}
              />
            </div>
            <div
              className={`d-flex ${
                workspacePage ? 'w-50' : 'flex-column flex-lg-row gap-1 home-profile-content w-100'
              } justify-content-end`}
            >
              <button
                type="button"
                data-testid="redirect-profile-button"
                onClick={() =>
                  isCredits ? history.push(PLANS_CONSUMPTIONS) : history.push('/profile')
                }
                className={`font-weight-700  text-xs font-bold complete-profile-button bg-white mr-0 ${
                  isCredits && percentageUsedPlan === 100
                    ? 'font-weight-bold border-0 outline-none bg-maya-blue text-white'
                    : ''
                } ${workspacePage ? '' : 'w-100 w-md-auto'}`}
              >
                {isCredits
                  ? percentageUsedPlan > 0
                    ? t('HOME_PROFILE_SEE_PLANS')
                    : t('HOME_PROFILE_GET_MORE_PLAN')
                  : percentage !== 100
                  ? t('HOME_COMPLETE_YOUR_PROFILE')
                  : t('HOME_EDIT_YOUR_PROFILE')}
              </button>
              {isCredits && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip className="">{t('RECOMMEND_WIN_DESCRIPTION')}</Tooltip>}
                >
                  <button
                    type="button"
                    data-testid="redirect-profile-button-recommended"
                    onClick={() =>
                      window.open(process.env.RAZZLE_RUNTIME_MARKETING_LANDING_RECOMMEND, '_blank')
                    }
                    className={`${
                      isCredits ? 'w-100 w-md-fit' : 'd-flex'
                    } font-weight-700  text-xs font-bold complete-profile-button-recommended ml-2 mr-0 font-weight-bold border-0 outline-none`}
                  >
                    {t('RECOMMEND_WIN')}
                  </button>
                </OverlayTrigger>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProfile;
