import { useRef, useState } from 'react';
import './QuickConfigurationsItem.scss';

const QuickConfigurationsItem = ({ title, subtitle, toggleData, contentBody }) => (
  <div className="d-flex flex-column w-100 quick-config-container py-4  px-2">
    <div className="d-flex justify-content-between">
      <div className="d-flex flex-column">
        <span className="text-sm font-weight-600">{title}</span>
        <span className="text-xs font-weight-500">{subtitle}</span>
      </div>
      <div className="d-flex mt-2 align-items-center">{toggleData}</div>
    </div>
    {contentBody}
  </div>
);

export default QuickConfigurationsItem;
