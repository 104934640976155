import { useSelector } from 'react-redux';
import { t } from '../../../i18n/i18n';
import './ApplicationMethod.scss';
import { useEffect, useState } from 'react';
import Collapsed from '../../Collapsed/Collapsed';
import { nanoid } from 'nanoid';

const ApplicationMethod = ({ index = 1, recruitSessionStage }) => {
  const { profileInvitationsAndChallenges } = useSelector((state) => state.profiles);
  const [collapsedSelected, setCollapsedSelected] = useState([]);
  return (
    <div
      className="d-flex flex-row align-items-center position-relative w-full formation-border-increment"
      key={index}
    >
      <div className="d-flex flex-column w-100 mt-4">
        <div className="px-4">
          <ApplicationMethodItem item={recruitSessionStage} />
        </div>
        {profileInvitationsAndChallenges?.invitations?.length && (
          <div className="mt-4">
            {[
              {
                title: t('COMMUNICATIONS_FROM_OTHER_EVALUATIONS'),
                content: (
                  <div className="pb-4 w-full">
                    {profileInvitationsAndChallenges?.invitations?.map((item) => (
                      <div
                        key={nanoid()}
                        className="w-100 d-flex flex-column formation-border-increment"
                      >
                        <span className="font-weight-700 text-md text-underline mb-2">
                          {item.name}
                        </span>
                        <ApplicationMethodItem item={item} />
                      </div>
                    ))}
                  </div>
                ),
                isVisible: true,
                collapsedEnabled: true,
              },
            ].map((item, index) => (
              <Collapsed
                collapsedSelected={collapsedSelected}
                setCollapsedSelected={setCollapsedSelected}
                index={97}
                options={item}
                collapsedEnabled={item.collapsedEnabled}
                containerClassname={'py-0 my-0 px-4'}
                titleClassnames={
                  'justify-content-end align-items-center gap-1 font-weight-400 text-xs text-muted py-2 '
                }
                backgroundColor={'bg-blue-light'}
                maxHeight="20rem"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicationMethod;

const ApplicationMethodItem = ({ item }) => (
  <span className="font-italic text-xs font-weight-300">
    {`${t('CANDIDATE_APPLIED_METHOD')} ${
      item?.mailSent
        ? t('APPLIED_METHOD_EMAIL_INVITATION')
        : item?.jobId
        ? t('APPLIED_METHOD_JOBPOST')
        : t('APPLIED_METHOD_LINK')
    }`}
  </span>
);
