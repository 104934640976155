import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import NotificationsEmpty from '../../assets/header/notifications-empty';
import { NOTIFICATIONS } from '../../constants/routes';
import useClickOutside from '../../hooks/useClickOutside';
import { t } from '../../i18n/i18n';
import './DropdownNotification.scss';

const DropdownNotification = ({ className, position = 'right' }) => {
  const optionesNotifications = [
    { label: t('NOTIFICATIONS_DROPDOWN_SETTING'), value: 'es', icon: faCog, href: NOTIFICATIONS },
  ];
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false));

  return (
    <div
      className={`d-flex align-self-end align-self-sm-center dropdown-lang-max-height ${
        open && 'dropdown-lang-container-index'
      }`}
    >
      <div
        ref={ref}
        className={` rounded  font-weight-bold non-selectable dropdown-lang-select-container position-relative w-max-content ${className}`}
      >
        <div
          onClick={() => setOpen((state) => !state)}
          aria-hidden
          className="dropdown-lang-select-button cursor-pointer p-2 w-100 d-flex align-items-center justify-content-between position-relative"
        >
          {/* <span className="notification_badge">0</span> */}
          <NotificationsEmpty color={open ? '#000' : 'grey'} />
        </div>
        <ul
          className={`notification-container dropdown-lang-select-options-container bg-white overflow-auto custom-scrollbar m-0 position-absolute ${
            open && 'dropdown-lang-select-options-container-open py-2'
          } `}
        >
          {optionesNotifications.map((option, i) => (
            <li
              className={`p-2 cursor-pointer dropdown-lang-select-option`}
              key={i}
              role="option"
              aria-selected
            >
              {option.href ? (
                <a href={option.href} className="without-decoration">
                  <FontAwesomeIcon icon={option.icon} className="mr-2" />{' '}
                  {option.label ?? option.name}
                </a>
              ) : (
                <>
                  <FontAwesomeIcon icon={option.icon} className="mr-2" />
                  <p>{option.label ?? option.name}</p>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownNotification;
