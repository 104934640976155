import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { getSavedSearches, deleteSavedSearch } from '../../API/services/companyService';
import { t } from '../../i18n/i18n';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import Spinner from '../../components/spinner/Spinner';
import SearchItem from '../../components/SearchItem/SearchItem';
import { setSavedSearches } from '../../redux/actions/profilesActions';
import { breadcrumbAlkemyCommunity } from '../../constants/breadcrumb';

const Searches = () => {
  const dispatch = useDispatch();
  const profilesState = useSelector((state) => state.profiles);
  const [loading, setLoading] = useState(true);
  const [searches, setSearches] = useState([]);
  const handleDelete = (id) => {
    deleteSavedSearch(id)
      .then(() => {
        dispatch(
          setSavedSearches({
            ...profilesState?.savedSearches,
            filters: searches.filters.filter((search) => search.id !== id),
          })
        );
        setSearches({
          ...searches,
          filters: searches.filters.filter((search) => search.id !== id),
        });
        notification.open({
          message: t('FILTER_DELETED_SUCCESSFULLY'),
          icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (
      (!profilesState.savedSearches?.length && typeof profilesState.savedSearches !== 'object') ||
      !Object.keys(profilesState.savedSearches).length
    ) {
      getSavedSearches()
        .then((res) => setSearches(res))
        .finally(() => setLoading(false));
    } else {
      setSearches(profilesState?.savedSearches);
      setLoading(false);
    }
  }, []);

  return (
    <div data-testid="searches-page-container" className="w-100 mx-auto h-100">
      {loading ? (
        <Spinner show />
      ) : (
        <>
          <div>
            <BackgroundTop
              title={t('SAVED_SEARCHES_TITLE')}
              routes={breadcrumbAlkemyCommunity}
              className="position-relative"
              titleClassname="text-title"
            />
          </div>
          <div className="position-relative description-container px-2 d-flex mx-auto justify-content-between flex-wrap">
            {searches?.filters?.length ? (
              searches.filters.map((item, index) => (
                <SearchItem key={index} handleDelete={handleDelete} search={item} />
              ))
            ) : (
              <div className="w-100 p-3 bg-white rounded">
                <EmptyComponent
                  className="p-4"
                  title={t('SAVED_SEARCH_EMPTY_TITLE')}
                  subtitle={t('SAVED_SEARCH_EMPTY_SUBTITLE')}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Searches;
