import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setNewScreeningData, setNewScreeningFilters } from '../redux/actions/screeningActions';
import { getAllTechnologies } from '../API/services/alkymersService';
import {
  createScreeningAlkemy,
  getImageSignedUrl,
  getTestGroupAllTests,
  getScreeningAllTests,
  patchTestGroup,
  postTestGroup,
  uploadFileToS3,
  getTestDifficulty,
  patchScreeningAlkemy,
  getAudioSignedUrl,
  getTestFormats,
} from '../API/services/screeningService';
import {
  AnswerTypeEnum,
  TestEditionAction,
  TestFileType,
  languagesToImplement,
} from '../constants/enums';
import { t } from '../i18n/i18n';
import { formatOrder } from '../utils/formatArrayOrder';
import useCreateScreening from './useCreateScreening';
import { CREATE_ABM_TESTGROUP_SCREENING, CREATE_EVALUATION } from '../constants/routes';
import { formatSecondsToHHSSMM } from '../utils/formatSecondsToSSMM';
import { inHtmlTag } from '../utils/inHtmlTag';
import { removeQueryParams } from '../utils/removeQueryParams';
import { getMinimunScreeningTimeInSeconds, convertTimeObjectToSeconds } from '../utils/time';
import { languagesList } from '../components/DropdownLanguage/DropdownLanguage';
import { getGroupSliderTags } from '../utils/scoreTags';
import { getLocalStorageWorkspace } from '../utils/workspaceUtils';

const AIDifficultyId = '64f5e4f13b7d7759631e6f09';

const useCreateTestGroup = (id) => {
  const [isEdit, setIsEdit] = useState(false);
  const {
    customTestGroupTotalTime,
    testGroupFilterOptions,
    currentTypeQuestion,
    handleAddQuestionDifficulty,
    fetchCategories,
    fetchLevels,
    fetchRoles,
    fetchTags,
    fetchEndorsementCreators,
    fetchEndorsementCompanies,
    testGroupRolesFilterOptions,
    testGroupTagsFilterOptions,
    endorsementCreatorsOptions,
    endorsementCompaniesOptions,
  } = useCreateScreening();
  const { newScreening } = useSelector((state) => state.screenings);
  const dispatch = useDispatch();
  const [testGroupData, setTestGroupData] = useState({
    name: '',
    description: '',
    level: undefined,
    category: undefined,
    categories: [],
    rolesIds: [],
    tagsIds: [],
    endorsementId: '',
    companyUsageIds: [],
    withThemes: undefined,
    withoutTime: false,
    objetives: undefined,
    detail: undefined,
    summary: undefined,
    minimumTier: undefined,
    languages: [],
    testFormatIds: [],
    withCustomLangCodesAI: false,
  });

  const [creationSuccess, setCreationSuccess] = useState(false);
  const [testGroupDifficult, setTestGroupDifficult] = useState('');
  const [testGroupTheme, setTestGroupTheme] = useState('');
  const [testGroupQuantityQuestions, setTestGroupQuantityQuestions] = useState();
  const [testGroupListeningTest, setTestGroupListeningTest] = useState(undefined);
  const [testGroupQuestions, setTestGroupQuestions] = useState([]);
  const [challengeAlkemy, setChallengeAlkemy] = useState(false);
  const [testgroupWithThemes, setTestgroupWithThemes] = useState(false);
  const [technologies, setTechnologies] = useState([]);
  const [techChallengeAlkemy, setTechChallengeAlkemy] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [oldTestGroup, setOldTestGroup] = useState({});
  const history = useHistory();
  const isScreening = new URLSearchParams(useLocation().search).get('isScreening');
  const [idTGChallenge, setIdTGChallenge] = useState('');
  const [testDifficulty, setTestDifficulty] = useState([]);
  const [testFormats, setTestFormats] = useState([]);
  const [testGroupInitialData, setTestGroupInitialData] = useState({
    name: '',
    description: '',
    level: undefined,
    category: undefined,
    categories: [],
    rolesIds: [],
    tagsIds: [],
    endorsementId: '',
    companyUsageIds: [],
    withThemes: undefined,
  });
  const [customTestgroupInitialData, setCustomTestgroupInitialData] = useState([]);
  const [testgroupInitialDuration, setTestgroupInitialDuration] = useState(undefined);
  const [testGroupScoreTags, setTestGroupScoreTags] = useState(null);
  const [groupSliderInitialData, setGroupSliderInitialData] = useState(null);
  const [toggleScoreTags, setToggleScoreTags] = useState(false);
  const [step, setStep] = useState({
    title: '',
    subtitle: '',
    button: { show: false, title: undefined, link: undefined },
  });

  const isSummaryCompleted =
    Object.values(testGroupData?.summary ?? {})?.filter((x) => x)?.length ==
    testGroupData?.languages?.length;

  const disabled =
    !testGroupData?.name ||
    !testGroupData.languages?.length ||
    !isSummaryCompleted ||
    !testGroupData?.level ||
    !newScreening?.customTestGroup?.length ||
    !customTestGroupTotalTime ||
    (challengeAlkemy &&
      !technologies.find((tech) => tech.selected)?.id &&
      Boolean(!techChallengeAlkemy)) ||
    submitLoading ||
    (testgroupWithThemes && !newScreening?.customTestGroup?.every((item) => item.theme)) ||
    (toggleScoreTags && !testGroupScoreTags?.isFullRange) ||
    testGroupData?.rolesIds?.length === 0 ||
    testGroupData?.languages?.some((lang) => !testGroupData?.objetives?.[lang]);

  const availableThemes =
    newScreening?.customTestGroup?.filter((item) => item.theme).map((item) => item.theme) ?? [];

  const disabledCreateSet =
    !testGroupDifficult || !testGroupQuantityQuestions || (testgroupWithThemes && !testGroupTheme);

  const counter = newScreening?.customTestGroup.reduce(
    (acc, { difficultyId, theme, listeningTest }) => {
      acc[`${difficultyId}/${listeningTest}/${theme}`] =
        (acc[`${difficultyId}/${listeningTest}/${theme}`] || 0) + 1;
      return acc;
    },
    {}
  );

  const testQuantity = Object.keys(counter)
    .map((key) => {
      const splittedKey = key.split('/');
      return {
        name: `${testDifficulty?.find((t) => t.id === splittedKey[0])?.name}/${
          splittedKey[1] === 'true'
        }/${testgroupWithThemes ? splittedKey[2] : undefined}`,
        quantity: counter[key],
      };
    })
    .reduce((acum, current) => {
      const existentConfig = acum.find((item) => item.name === current.name);
      if (existentConfig) {
        existentConfig.quantity += current.quantity;
      } else {
        acum.push(current);
      }
      return acum;
    }, []);

  const testQuantityToMap = testQuantity.reduce((acum, curr) => {
    const splittedKey = curr?.name?.split('/');
    const existentDifficulty = acum.find((item) => item.name === splittedKey[0]);
    if (existentDifficulty) {
      existentDifficulty.quantity += curr.quantity;
    } else {
      acum.push({ name: splittedKey[0], quantity: curr.quantity });
    }
    return acum;
  }, []);

  const fetchTestDifficulty = async () => {
    const difficulties = await getTestDifficulty();
    setTestDifficulty(difficulties);
  };
  const fetchTestFormats = async () => {
    const formats = await getTestFormats();
    setTestFormats(formats);
  };

  useEffect(() => {
    fetchTestDifficulty();
    fetchTestFormats();
    fetchRoles();
    fetchTags();
    fetchEndorsementCreators();
    fetchEndorsementCompanies();

    if (!testGroupFilterOptions?.levels.length) {
      fetchLevels();
    }
    if (!testGroupFilterOptions?.categories.length) {
      fetchCategories();
    }

    return () => {
      if (history.location.pathname !== CREATE_EVALUATION) {
        dispatch(
          setNewScreeningData({
            step: 1,
            name: '',
            testGroups: [],
            customTestGroup: [],
            customTestGroupDuration: {
              hours: undefined,
              minutes: undefined,
              seconds: undefined,
            },
            availableWeight: 100,
            isEdit: false,
            currentTypeQuestion: 'CHECK',
            currentQuestionData: undefined,
            allTests: [],
          })
        );
        dispatch(
          setNewScreeningFilters({
            category: [],
            levelId: [],
            name: '',
            rolesIds: [],
          })
        );
      }
    };
  }, []);

  /**
   * Converts time from minutes to hours, minutes, and seconds format.
   *
   * @param {number} time - The time in minutes to be converted.
   * @returns {Object} - An object representing the converted time with hours, minutes, and seconds.
   */
  const timeConvert = (time) => {
    const hours = Math.floor(time / 60);
    const minutes = Math.floor(time % 60);
    const seconds = Math.round((time % 1) * 60);

    return {
      hours: String(hours < 10 ? `0${hours}` : hours),
      minutes: String(minutes < 10 ? `0${minutes}` : minutes),
      seconds: String(seconds < 10 ? `0${seconds}` : seconds),
    };
  };

  const formatTests = (array) => {
    const arrayFormat = array.map((test, index) => {
      const testFormatted = {
        id: test.testId,
        answerType: test.test.answerType,
        type: 'NON_TECHNICAL',
        name: test.test?.name,
        order: test.test.order || index,
        hasGlobalScore: test.test.hasGlobalScore,
        difficultyId: test.test.difficultyId,
        weight: testDifficulty.find((d) => d.id === test.test.difficultyId)?.score,
        text: test.test.text,
        formula: test.test.formula,
        theme: test.test.theme,
        listeningTest: test.test.listeningTest,
        options: test.test.options.map((op) => ({
          text: op.text,
          formula: op.formula,
          correct:
            test.test.acceptedOptions.length === 1
              ? test.test.acceptedOptions[0].options.includes(op.id)
              : test.test.answerType === 'RADIO'
              ? test.test.acceptedOptions.some(
                  (acceptedOption) =>
                    acceptedOption.weight > 0 && acceptedOption.options.includes(op.id)
                )
              : undefined,
          weight:
            test.test.acceptedOptions.length === 1
              ? undefined
              : test.test.acceptedOptions.find((acceptedOption) =>
                  acceptedOption.options.includes(op.id)
                )?.weight,
          files: op.files ?? [],
          ...(typeof op.pinnedIndex === 'number' ? { pinnedIndex: op.pinnedIndex } : {}),
          ...(op.id ? { id: op.id } : {}),
        })),
        testFiles: (test.test.files ?? []).map((file) => ({
          ...file,
          infinitePlays: !file.numberOfPlays,
        })),
        concepts: test.test?.concepts ?? '',
        maxChar: test.test?.maxChar,
        writeResponse: test.test?.writeResponse,
        isTyping: test.test?.isTyping,
      };
      if (test?.test?.answerType === AnswerTypeEnum.VIDEO) {
        testFormatted.maxAttempts = test?.test?.maxAttempts;
        testFormatted.preparationTime = test?.test?.preparationTime;
        testFormatted.totalTime = test?.test?.totalTime;
      }
      if (test?.test?.answerType === AnswerTypeEnum.CODE) {
        testFormatted.existingCode = test?.test?.existingCode;
        testFormatted.expectedResult = test?.test?.expectedResult;
        testFormatted.langCode = test?.test?.langCode;
        testFormatted.langVersion = test?.test?.langCode;
        testFormatted.langDisplayName =
          languagesToImplement.find((l) => l.id === test?.test?.langCode)?.displayName ??
          test?.test?.langCode;
      }
      if (test?.test?.answerType === AnswerTypeEnum.CODE_AI) {
        testFormatted.existingCode = test?.test?.existingCode;
      }
      return testFormatted;
    });

    return arrayFormat;
  };

  const fetchData = async (id) => {
    setIsLoading(true);
    let testGroup;
    if (isScreening === '0') {
      testGroup = await getTestGroupAllTests(id);
    } else {
      const res = await getScreeningAllTests(id);
      testGroup = res.testGroups.at(0);
      setIdTGChallenge(testGroup.id);
      setChallengeAlkemy(true);
      setTechChallengeAlkemy(res.technologyId);
    }

    setTestGroupData({
      name: testGroup?.name || '',
      description: testGroup?.description || '',
      level: testGroup?.tags.length ? testGroup?.tags[0].id : '',
      category: testGroup?.category?.id || '',
      categories: testGroup?.categoryIds || [],
      rolesIds: testGroup?.rolesIds || [],
      tagsIds: testGroup?.tagsIds || [],
      companyUsageIds: testGroup?.companyUsageIds ?? [],
      endorsementId: testGroup?.endorsementId,
      withThemes: testGroup?.withThemes,
      withoutTime: testGroup?.withoutTime,
      withCustomLangCodesAI: testGroup?.tests.some(
        (test) => test.answerType === AnswerTypeEnum.CODE_AI
      ),
      languages: testGroup?.languages,
      minimumTier: testGroup?.minimumTier,
      objetives: testGroup?.objetives,
      detail: testGroup?.detail,
      summary: testGroup?.summary,
      workspaceId: testGroup?.workspaceId,
      hideTitle: testGroup?.hideTitle,
      isAutomaticContinue: testGroup?.isAutomaticContinue,
      testFormatIds: testGroup?.testFormatIds,
    });
    setTestGroupInitialData({
      name: testGroup?.name || '',
      description: testGroup?.description || '',
      level: testGroup?.tags.length ? testGroup?.tags[0].id : '',
      category: testGroup?.category?.id || '',
      categories: testGroup?.categoryIds || [],
      rolesIds: testGroup?.rolesIds || [],
      tagsIds: testGroup?.tagsIds || [],
      companyUsageIds: testGroup?.companyUsageIds ?? [],
      endorsementId: testGroup?.endorsementId,
      withThemes: testGroup?.withThemes,
      withoutTime: testGroup?.withoutTime,
      withCustomLangCodesAI: testGroup?.tests.some(
        (test) => test.answerType === AnswerTypeEnum.CODE_AI
      ),
      languages: testGroup?.languages,
      minimumTier: testGroup?.minimumTier,
      objetives: testGroup?.objetives,
      detail: testGroup?.detail,
      summary: testGroup?.summary,
      scoreTags: testGroup?.scoreTags,
      testFormatIds: testGroup?.testFormatIds,
    });
    if (testGroup.customStep) {
      setStep(testGroup.customStep);
    }
    const initialGroupSliderData = getGroupSliderTags(testGroup?.scoreTags?.tags);
    if (initialGroupSliderData) setToggleScoreTags(true);
    setGroupSliderInitialData(initialGroupSliderData);

    dispatch(
      setNewScreeningData({
        ...newScreening,
        customTestGroupDuration: timeConvert(testGroup.duration),
        customTestGroup: formatTests(testGroup.tests),
      })
    );

    setTestgroupInitialDuration(timeConvert(testGroup.duration));
    setCustomTestgroupInitialData(formatTests(testGroup.tests));

    if (testGroup?.withThemes) {
      setTestgroupWithThemes(true);
    }

    if (testGroup?.difficultyConfig) {
      setTestGroupQuestions(
        testGroup?.difficultyConfig.map((item) => ({
          ...item,
          ordererId: v4(),
        }))
      );
    }
    setOldTestGroup({
      name: testGroup?.name || '',
      description: testGroup?.description || '',
      tagsIds: testGroup?.tagsIds,
      companyUsageIds: testGroup?.companyUsageIds ?? [],
      endorsementId: testGroup?.endorsementId,
      categoryId: testGroup?.category?.id || '',
      categoryIds: testGroup?.categoryIds || [],
      duration: testGroup.duration,
      difficultyConfig: testGroup?.difficultyConfig,
      rolesIds: testGroup?.rolesIds || [],
    });
    setIsLoading(false);
  };

  useEffect(() => {
    const getData = async () => {
      if (id && testDifficulty.length) {
        await fetchData(id).then(() => setIsLoading(false));
        setIsEdit(true);
      }

      if (!id && testDifficulty.length) {
        setIsLoading(false);
      }
    };
    getData();
  }, [id, isScreening, testDifficulty]);

  useEffect(() => {
    if (
      currentTypeQuestion === AnswerTypeEnum.TEXT_AI &&
      typeof newScreening?.currentQuestionData === 'undefined'
    ) {
      const IADifficulty = testDifficulty?.find((test) => test?.id === AIDifficultyId);
      handleAddQuestionDifficulty({
        id: IADifficulty?.id,
        name: t(`CREATE_TEST_GROUP_QUESTION_${IADifficulty?.name}`),
        weight: IADifficulty?.score,
      });
    }
  }, [newScreening?.currentQuestionData]);

  const handleModifyLangData = (language, e) => {
    setTestGroupData({
      ...testGroupData,
      [e.target.name]: {
        ...(testGroupData[e.target.name] ?? {}),
        [language]: e.target.value,
      },
    });
  };

  const handleModifyData = (e) => {
    if (e.target.name === 'workspaceId') {
      if (Number.isNaN(Number(e.target.value)) || e.target.value === '') {
        setTestGroupData({
          ...testGroupData,
          [e.target.name]: undefined,
        });
      } else {
        setTestGroupData({
          ...testGroupData,
          [e.target.name]: Number(e.target.value),
        });
      }
    } else {
      setTestGroupData({
        ...testGroupData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSelectLevelOption = (e) => {
    setTestGroupData({
      ...testGroupData,
      level: e.id,
    });
  };
  const handleSelectMinimumTierOption = (e) => {
    setTestGroupData({
      ...testGroupData,
      minimumTier: e.id,
    });
  };
  const getMissingLanguages = (languages) => {
    const languagesSet = new Set(languages);
    return languagesList.filter((x) => !languagesSet.has(x.value))?.map((x) => x.value);
  };

  const handleSelectLanguageOption = (languages) => {
    const missingLanguages = getMissingLanguages(languages);
    const testGroupDataEdited = { ...testGroupData };
    testGroupDataEdited.languages = languages;

    if (!languages?.length) {
      testGroupDataEdited.objetives = undefined;
      testGroupDataEdited.detail = undefined;
      testGroupDataEdited.summary = undefined;
    } else {
      for (let index = 0; index < missingLanguages.length; index++) {
        const lang = missingLanguages[index];
        testGroupDataEdited.objetives = {
          ...(testGroupDataEdited.objetives ?? {}),
          [lang]: undefined,
        };
        testGroupDataEdited.detail = {
          ...(testGroupDataEdited.detail ?? {}),
          [lang]: undefined,
        };
        testGroupDataEdited.summary = {
          ...(testGroupDataEdited.summary ?? {}),
          [lang]: undefined,
        };
      }
    }

    setTestGroupData({
      ...testGroupData,
      ...testGroupDataEdited,
    });
  };

  const handleSelectObjetivesOption = (language, objetives) => {
    setTestGroupData({
      ...testGroupData,
      objetives: {
        ...(testGroupData.objetives ?? {}),
        [language]: objetives,
      },
    });
  };

  const getTestsAudiosDuration = () => {
    const configsWithAudio = testGroupQuestions.filter((item) => item.listeningTest);
    const tests = newScreening?.customTestGroup;

    if (!configsWithAudio.length) return false;

    const totalAudiosDuration = configsWithAudio
      .map((config) =>
        tests
          .filter(
            (item) =>
              item.difficultyId === config.difficultyId &&
              item.listeningTest === config.listeningTest &&
              item?.theme === config?.theme
          )
          .map((item) => item.testFiles.reduce((acum, el) => acum + (el.audioDuration ?? 0), 0))
          .sort((a, b) => b - a)
          .slice(0, config.quantity)
          .reduce((acum, duration) => acum + duration, 0)
      )
      .reduce((acum, duration) => acum + duration, 0);

    return totalAudiosDuration;
  };

  const uploadAndUpdateFile = async (file) => {
    if (!TestFileType[file.fileType]) return;
    let signedUrl;
    if (file.fileType === TestFileType.IMAGE)
      signedUrl = await getImageSignedUrl(file.fileObject.type);
    if (file.fileType === TestFileType.AUDIO)
      signedUrl = await getAudioSignedUrl(file.fileObject.type);

    await uploadFileToS3(signedUrl.fileUrl, file.fileObject, file.fileObject.type);

    file.name = signedUrl.fileId;
    file.extension = signedUrl.fileExtension;
    file.url = removeQueryParams(signedUrl.fileUrl);
    delete file.fileObject;
  };

  const uploadAndUpdateFiles = async (tests) => {
    const testsPromises = tests?.flatMap(
      (test) =>
        test.testFiles
          ?.filter((file) => file.fileObject)
          .map((file) => uploadAndUpdateFile(file)) ?? []
    );

    const optionsPromises =
      tests.flatMap((test) =>
        test.options
          ?.filter((option) => option.files?.length)
          .flatMap((option) => option.files)
          .filter((file) => file.fileObject)
          .map((file) => uploadAndUpdateFile(file))
      ) ?? [];

    const promises = [...testsPromises, ...optionsPromises];

    await Promise.all(promises);
  };

  const handleSelectCategoryOption = (e) => {
    setTestGroupData({
      ...testGroupData,
      category: e?.id,
    });
  };
  const handleSelectCategoryOptionMultiple = (values = [], newCategory = {}) => {
    setTestGroupData({
      ...testGroupData,
      categories: values,
      category: newCategory?.id,
    });
  };

  const handleSelectRolesOptions = (e) => {
    const optionSelected = testGroupData.rolesIds.includes(e);
    if (optionSelected) {
      setTestGroupData({
        ...testGroupData,
        rolesIds: testGroupData.rolesIds.filter((role) => role !== e),
      });
    } else {
      setTestGroupData({ ...testGroupData, rolesIds: [...testGroupData.rolesIds, e] });
    }
  };
  const handleSelectRolesOptionsMultiple = (values = []) => {
    setTestGroupData({
      ...testGroupData,
      rolesIds: values,
    });
  };

  const handleSelectTagsOptions = (e) => {
    const optionSelected = testGroupData.tagsIds.includes(e);
    if (optionSelected) {
      setTestGroupData({
        ...testGroupData,
        tagsIds: testGroupData.tagsIds.filter((role) => role !== e),
      });
    } else {
      setTestGroupData({ ...testGroupData, tagsIds: [...testGroupData.tagsIds, e] });
    }
  };
  const handleSelectCompanyUsageOptions = (e) => {
    const { companyUsageIds } = testGroupData;
    const newCompanyUsageIds = companyUsageIds.includes(e)
      ? companyUsageIds.filter((value) => value !== e)
      : [...companyUsageIds, e];
    setTestGroupData({ ...testGroupData, companyUsageIds: newCompanyUsageIds });
  };
  const handleSelectEndorsementOptions = (e) => {
    setTestGroupData({ ...testGroupData, endorsementId: e?.id });
  };

  const handleHideTitle = (action) => {
    if (action) {
      setTestGroupData({
        ...testGroupData,
        hideTitle: true,
      });
    } else {
      setTestGroupData({
        ...testGroupData,
        hideTitle: false,
      });
    }
  };

  const handleIsAutomaticContinue = (action) => {
    if (action) {
      setTestGroupData({
        ...testGroupData,
        isAutomaticContinue: true,
      });
    } else {
      setTestGroupData({
        ...testGroupData,
        isAutomaticContinue: false,
      });
    }
  };

  const handleWithoutTime = (action) => {
    setTestGroupData({
      ...testGroupData,
      withoutTime: action,
    });
  };

  const handleSelectDifficulty = (e) => {
    setTestGroupDifficult(e.id);
  };

  const handleSelectTheme = (e) => {
    setTestGroupTheme(e.id);
  };

  const formatDifficultyConfig = (testGroupQuestions) =>
    testGroupQuestions
      ?.filter((item) => (testgroupWithThemes ? item.theme : !item.theme))
      .map((item) => ({
        difficultyId: item.difficultyId,
        quantity: item.quantity,
        order: item.order,
        listeningTest: item?.listeningTest,
        ...(item?.theme ? { theme: item?.theme } : {}),
      }));

  const handleCreateQuestionsSet = () => {
    const setQuestion = {
      quantity: Number(testGroupQuantityQuestions),
      difficultyId: testGroupDifficult,
      ...(testGroupTheme && testgroupWithThemes ? { theme: testGroupTheme } : {}),
      ...(testGroupListeningTest ? { listeningTest: true } : {}),
      ordererId: v4(),
    };

    const filterTestGroupQuestions = testGroupQuestions.filter((item) => {
      if (testgroupWithThemes) {
        return item.difficultyId === testGroupDifficult &&
          item?.listeningTest === testGroupListeningTest
          ? item?.theme !== testGroupTheme
          : true;
      }
      return (
        item.difficultyId !== testGroupDifficult || item?.listeningTest !== testGroupListeningTest
      );
    });

    setTestGroupQuestions([
      ...filterTestGroupQuestions,
      { ...setQuestion, order: filterTestGroupQuestions.length },
    ]);
    setTestGroupDifficult('');
    setTestGroupTheme('');
    setTestGroupQuantityQuestions('');
    setTestGroupListeningTest(undefined);
  };

  const handleAudioDifficulty = () => {
    if (typeof testGroupListeningTest === 'undefined') {
      setTestGroupListeningTest(true);
    } else {
      setTestGroupListeningTest(undefined);
    }
  };

  const handleDeleteSetTestGroup = (id) => {
    const filterTestGroupQuestions = testGroupQuestions.filter((item) => item.ordererId !== id);
    setTestGroupQuestions(filterTestGroupQuestions);
  };

  const handleOrderSetQuestions = (currentOrder, nextOrder) => {
    const currentOrderItem = testGroupQuestions[currentOrder];
    const nextOrderItem = testGroupQuestions[nextOrder];
    const filteredData = testGroupQuestions.filter(
      (item) =>
        item.ordererId !== nextOrderItem.ordererId && item.ordererId !== currentOrderItem.ordererId
    );
    currentOrderItem.order = nextOrder;
    nextOrderItem.order = currentOrder;
    setTestGroupQuestions([...filteredData, nextOrderItem, currentOrderItem]);
  };

  const testGroupConfigIsCorrect = (testGroupConfig, tests) => {
    if (testGroupConfig.length > tests.length) {
      return true;
    }
    const configTG = [];
    testGroupConfig.forEach((d) => {
      configTG.push(tests.find((t) => t.name === d.name && t.quantity >= d.quantity));
    });
    return configTG.includes(undefined);
  };
  const determineErrorNotification = () => {
    let missingQuestions = false;
    const difficultyConfigs = formatDifficultyConfig(testGroupQuestions);
    difficultyConfigs.forEach((difficulty) => {
      const tests = newScreening.customTestGroup.filter((test) => {
        const baseValidation =
          test.difficultyId === difficulty.difficultyId &&
          difficulty?.listeningTest === test?.listeningTest;

        let validTest = baseValidation;

        if (testgroupWithThemes) {
          validTest = baseValidation && difficulty?.theme === test?.theme;
        }

        return validTest;
      });
      if (tests?.length < difficulty.quantity && !missingQuestions) {
        missingQuestions = true;
        const difficultyName = t(
          `CREATE_TEST_GROUP_QUESTION_${
            testDifficulty.find((item) => item.id === difficulty.difficultyId)?.name
          }`
        );
        const parsedTheme = difficulty?.theme ? `-${difficulty.theme}` : '';
        const parsedListeningTest = difficulty?.listeningTest
          ? `-${t('TESTGROUP_ABM_QUESTION_WITH_AUDIO')}`
          : '';
        const parsedTextToReplace = `${difficultyName}${parsedTheme}${parsedListeningTest}`;
        notification.open({
          type: 'error',
          message: (
            <div
              dangerouslySetInnerHTML={{
                __html: t('TESTGROUP_ABM_WRONG_DIFFICULTY_CONFIG')
                  .replace(
                    '{difficultyQuantity}',
                    inHtmlTag({
                      tag: 'b',
                      className: ' text-xs',
                      content: difficulty.quantity,
                    })
                  )
                  .replace(
                    '{difficultyConfig}',
                    inHtmlTag({
                      tag: 'b',
                      className: ' text-xs',
                      content: parsedTextToReplace,
                    })
                  )
                  .replace(
                    '{totalTests}',
                    inHtmlTag({
                      tag: 'b',
                      className: ' text-xs',
                      content: tests.length,
                    })
                  ),
              }}
            />
          ),
        });
      }
    });
  };

  const validateMinimumScreeningTime = (newScreening) => {
    const minCustomScreeningsTimeInSeconds = getMinimunScreeningTimeInSeconds(newScreening);
    const customTestGroupDurationInSeconds = convertTimeObjectToSeconds(
      newScreening?.customTestGroupDuration
    );
    if (customTestGroupDurationInSeconds >= minCustomScreeningsTimeInSeconds) return true;

    const formattedTime = formatSecondsToHHSSMM(minCustomScreeningsTimeInSeconds);

    notification.open({
      message: t('TESTGROUP_ABM_MINIMUM_TESTGROUP_DURATION').replace('{duration}', formattedTime),
      type: 'error',
      className: 'testgroup-abm-message',
    });
    return false;
  };

  const handlePostData = async () => {
    setSubmitLoading(true);
    if (!validateMinimumScreeningTime(newScreening)) {
      setSubmitLoading(false);
      return;
    }
    const tests = newScreening?.customTestGroup;
    await uploadAndUpdateFiles(tests);
    dispatch(
      setNewScreeningData({
        ...newScreening,
        customTestGroup: tests,
      })
    );

    const dataToPost = {
      name: testGroupData?.name,
      description: testGroupData?.description,
      duration: customTestGroupTotalTime,
      withThemes: testgroupWithThemes,
      difficultyConfig: formatDifficultyConfig(testGroupQuestions),
      hideTitle: testGroupData?.hideTitle ?? false,
      isAutomaticContinue: testGroupData?.isAutomaticContinue,
      withoutTime: testGroupData?.withoutTime,
      withCustomLangCodesAI: newScreening?.customTestGroup?.some(
        (test) => test.answerType === AnswerTypeEnum.CODE_AI
      ),
      workspaceId: testGroupData?.workspaceId,
      tests: formatOrder(
        newScreening?.customTestGroup?.map((item) => ({
          ...item,
          answerType: [AnswerTypeEnum.CHECK, AnswerTypeEnum.RADIO].includes(item.answerType)
            ? (item?.hasGlobalScore &&
                item.options.filter((option) => option.correct).length === 1) ||
              !item?.hasGlobalScore
              ? AnswerTypeEnum.RADIO
              : AnswerTypeEnum.CHECK
            : item.answerType,
          deleteId: undefined,
          hasGlobalScore: item?.hasGlobalScore,
          image: undefined,
          id: undefined,
          companyId: getLocalStorageWorkspace()?.companyId,
          difficultyId: item.difficultyId,
          weight: item.eachScore ? null : Number(item.weight),
          testFiles: item.testFiles?.map((file) => ({
            ...file,
            numberOfPlays:
              file?.numberOfPlays && !file.infinitePlays ? Number(file?.numberOfPlays) : null,
          })),
          testImagesCount: item.image ? 1 : 0,
          ...(testgroupWithThemes && item?.theme ? { theme: item?.theme } : { theme: undefined }),
          ...(item?.options?.length
            ? {
                options: item?.options.map((option) => {
                  let optionResponse = {
                    text: option.text,
                    files: (option.files ?? []).map((file) => file),
                    formula: option.formula ?? '',
                  };
                  if (item?.eachScore) {
                    optionResponse = {
                      ...optionResponse,
                      weight: Number(option.weight),
                    };
                  } else {
                    optionResponse = {
                      ...optionResponse,
                      correct: Boolean(option.correct),
                    };
                  }
                  return {
                    ...optionResponse,
                    ...(typeof option.pinnedIndex === 'number'
                      ? { pinnedIndex: option.pinnedIndex }
                      : {}),
                    ...(option.id ? { id: option.id } : {}),
                  };
                }),
              }
            : {}),
          ...(item?.expectedResult?.length
            ? {
                expectedResult: item?.expectedResult,
              }
            : {}),
        }))
      ),
      tagsIds: [
        testGroupData?.level,
        ...testGroupData?.tagsIds?.filter((value) => value !== testGroupData?.level),
      ],
      companyUsageIds: testGroupData?.companyUsageIds ?? [],
      endorsementId: testGroupData?.endorsementId,
      categoryId: testGroupData?.category,
      categoryIds: testGroupData?.categories,
      rolesIds: testGroupData?.rolesIds,
      objetives: testGroupData?.objetives,
      detail: testGroupData?.detail,
      summary: testGroupData?.summary,
      minimumTier: testGroupData?.minimumTier,
      languages: testGroupData?.languages,
      scoreTags: getScoreTags(testGroupScoreTags),
      testFormatIds: testGroupData?.testFormatIds,
      ...(step.subtitle?.trim() !== '' ? { customStep: step } : {}),
    };
    const difficultyConfigFormat = dataToPost.difficultyConfig.map((d) => ({
      name: `${testDifficulty?.find((t) => t.id === d.difficultyId)?.name}/${Boolean(
        d?.listeningTest
      )}/${d?.theme}`,
      quantity: d.quantity,
    }));

    if (testGroupConfigIsCorrect(difficultyConfigFormat, testQuantity)) {
      setSubmitLoading(false);
      determineErrorNotification();
      return;
    }

    if (Math.round(customTestGroupTotalTime * 60) < getTestsAudiosDuration()) {
      setSubmitLoading(false);
      const formattedTime = formatSecondsToHHSSMM(getTestsAudiosDuration());
      notification.open({
        message: t('TESTGROUP_ABM_MINIMUM_TESTGROUP_DURATION').replace('{duration}', formattedTime),
        type: 'error',
        className: 'testgroup-abm-message',
      });
      return;
    }

    if (challengeAlkemy) {
      await createScreeningAlkemy({
        name: dataToPost.name,
        description: dataToPost.description,
        technologyId: technologies.find((tech) => tech.selected)?.id,
        cooldownDays: 5,
        duration: dataToPost.duration,
        testGroups: [
          {
            name: dataToPost.name,
            weight: 100,
            description: dataToPost.description,
            duration: dataToPost.duration,
            tagsIds: dataToPost.tagsIds,
            companyUsageIds: testGroupData?.companyUsageIds ?? [],
            endorsementId: testGroupData?.endorsementId,
            categoryId: dataToPost.categoryId,
            categoryIds: dataToPost.categoryIds,
            tests: dataToPost.tests,
            difficultyConfig: dataToPost.difficultyConfig,
            companyId: 1,
          },
        ],
        companyId: null,
        type: 'TECHNICAL',
      })
        .then(() => {
          setCreationSuccess(true);
          setSubmitLoading(false);
        })
        .catch(() => {
          setSubmitLoading(false);
          notification.open({
            message: t('CREATE_TEST_GROUP_REQUEST_ERROR'),
            type: 'error',
          });
        });
    } else {
      await postTestGroup(dataToPost)
        .then(() => {
          setCreationSuccess(true);
          setSubmitLoading(false);
        })
        .catch(() => {
          setSubmitLoading(false);
          notification.open({
            message: t('CREATE_TEST_GROUP_REQUEST_ERROR'),
            type: 'error',
          });
        });
    }
  };

  const [patchSuccess, setPatchSuccess] = useState(false);

  const handlePatchData = async (replaceFlag) => {
    setSubmitLoading(true);
    if (!validateMinimumScreeningTime(newScreening)) {
      setSubmitLoading(false);
      return;
    }
    const deletedIds = newScreening?.deleteTests?.map((test) => test.id);
    const persistTests = newScreening.customTestGroup.filter(
      (test) => !deletedIds?.includes(test.id)
    );
    dispatch(
      setNewScreeningData({
        ...newScreening,
        customTestGroup: persistTests,
      })
    );

    const allTestGroupTest = [...(newScreening?.customTestGroup ?? [])];
    await uploadAndUpdateFiles(allTestGroupTest);

    dispatch(
      setNewScreeningData({
        ...newScreening,
        customTestGroup: allTestGroupTest,
      })
    );

    const allTestGroupTestCopy = [...allTestGroupTest];

    if (newScreening?.deleteTests) {
      allTestGroupTestCopy.push(...newScreening?.deleteTests);
    }
    const testGroupsTests = allTestGroupTestCopy.filter((item) => item.action !== undefined);

    const newTestGroupData = {
      name: testGroupData?.name,
      description: testGroupData?.description,
      withoutTime: testGroupData?.withoutTime,
      withCustomLangCodesAI: testGroupsTests.some(
        (test) => test.answerType === AnswerTypeEnum.CODE_AI
      ),
      withThemes: testgroupWithThemes,
      duration: customTestGroupTotalTime,
      difficultyConfig: formatDifficultyConfig(testGroupQuestions),
      categoryId: testGroupData?.category ?? '-1',
      tagsIds: [
        testGroupData?.level,
        ...testGroupData?.tagsIds?.filter((value) => value !== testGroupData?.level),
      ],
      companyUsageIds: testGroupData?.companyUsageIds ?? [],
      endorsementId: testGroupData?.endorsementId,
      categoryIds: testGroupData?.categories,
      rolesIds: testGroupData?.rolesIds,
      objetives: testGroupData?.objetives,
      detail: testGroupData?.detail,
      summary: testGroupData?.summary,
      minimumTier: testGroupData?.minimumTier,
      languages: testGroupData?.languages,
      workspaceId: testGroupData?.workspaceId,
      hideTitle: testGroupData?.hideTitle ?? false,
      isAutomaticContinue: testGroupData?.isAutomaticContinue,
    };

    const fieldsEdit = Object.entries(newTestGroupData).filter(
      ([key, value]) => JSON.stringify(value) !== JSON.stringify(oldTestGroup[key])
    );

    const dataToSend = Object.fromEntries(fieldsEdit);

    const dataToPatch = {
      ...dataToSend,
      id,
      tests: formatOrder(
        testGroupsTests.map((item) => ({
          ...item,
          answerType: [AnswerTypeEnum.CHECK, AnswerTypeEnum.RADIO].includes(item.answerType)
            ? (item?.hasGlobalScore &&
                item.options.filter((option) => option.correct).length === 1) ||
              !item?.hasGlobalScore
              ? AnswerTypeEnum.RADIO
              : AnswerTypeEnum.CHECK
            : item.answerType,
          id: item.id && !item.id.includes('temp-') ? item.id : undefined,
          action:
            !item.id && item.action === TestEditionAction.EDIT
              ? TestEditionAction.CREATE
              : item.action,
          deleteId: undefined,
          type: 'NON_TECHNICAL',
          difficulty: item.difficultyId,
          hasGlobalScore: item?.hasGlobalScore,
          weight: item.eachScore ? null : Number(item.weight),
          writeResponse: item?.writeResponse,
          testFiles: item.testFiles?.map((file) => ({
            ...file,
            numberOfPlays:
              file?.numberOfPlays && !file.infinitePlays ? Number(file?.numberOfPlays) : null,
          })),
          ...(testgroupWithThemes && item?.theme ? { theme: item?.theme } : { theme: undefined }),
          ...(item?.options?.length
            ? {
                options: item?.options.map((option) => {
                  let optionResponse = {
                    text: option.text,
                    formula: option.formula ?? '',
                    files: option.files,
                  };
                  if (!item?.hasGlobalScore) {
                    optionResponse = {
                      ...optionResponse,
                      weight: Number(option.weight),
                    };
                  } else {
                    optionResponse = {
                      ...optionResponse,
                      correct: Boolean(option.correct),
                    };
                  }
                  return {
                    ...optionResponse,
                    ...(typeof option.pinnedIndex === 'number'
                      ? { pinnedIndex: option.pinnedIndex }
                      : {}),
                    ...(option.id ? { id: option.id } : {}),
                  };
                }),
              }
            : {}),
        }))
      ),
      scoreTags: toggleScoreTags ? getScoreTags(testGroupScoreTags) : undefined,
      testFormatIds: testGroupData?.testFormatIds,
      ...(step.subtitle?.trim() !== '' ? { customStep: step } : {}),
    };

    const difficultyConfigFormat = newTestGroupData?.difficultyConfig.map((d) => ({
      name: `${testDifficulty?.find((t) => t.id === d.difficultyId)?.name}/${Boolean(
        d?.listeningTest
      )}/${d?.theme}`,
      quantity: d.quantity,
    }));

    if (testGroupConfigIsCorrect(difficultyConfigFormat, testQuantity)) {
      setSubmitLoading(false);
      determineErrorNotification();
      return;
    }

    if (!persistTests.every((test) => test.difficultyId !== '')) {
      setSubmitLoading(false);
      notification.open({
        message: t('EDIT_TEST_GROUP_TEST_DIFFICULTY_ERROR'),
        type: 'warning',
      });
      return;
    }

    const minCustomScreeningsTimeInSeconds = getMinimunScreeningTimeInSeconds(newScreening);
    const customTestGroupDurationInSeconds = convertTimeObjectToSeconds(
      newScreening?.customTestGroupDuration
    );
    if (customTestGroupDurationInSeconds < minCustomScreeningsTimeInSeconds) {
      setSubmitLoading(false);
      const formattedTime = formatSecondsToHHSSMM(minCustomScreeningsTimeInSeconds);
      notification.open({
        message: t('TESTGROUP_ABM_MINIMUM_TESTGROUP_DURATION').replace('{duration}', formattedTime),
        type: 'error',
        className: 'testgroup-abm-message',
      });
      return;
    }

    if (Math.round(customTestGroupTotalTime * 60) < getTestsAudiosDuration()) {
      setSubmitLoading(false);
      const formattedTime = formatSecondsToHHSSMM(getTestsAudiosDuration());
      notification.open({
        message: t('TESTGROUP_ABM_MINIMUM_TESTGROUP_DURATION').replace('{duration}', formattedTime),
        type: 'error',
        className: 'testgroup-abm-message',
      });
      return;
    }
    if (challengeAlkemy) {
      await patchScreeningAlkemy({
        id,
        name: dataToPatch?.name || null,
        description: dataToPatch?.description || null,
        duration: dataToPatch.duration || null,
        testGroup: { ...dataToPatch, id: idTGChallenge, duration: dataToPatch.duration || null },
        companyId: null,
        type: 'TECHNICAL',
      })
        .then(() => {
          notification.open({
            message: t('EDIT_TEST_GROUP_REQUEST_SUCCESS'),
            type: 'success',
          });
          history.push(CREATE_ABM_TESTGROUP_SCREENING);
          setSubmitLoading(false);
        })
        .catch(() => {
          setSubmitLoading(false);
          notification.open({
            message: t('EDIT_TEST_GROUP_REQUEST_ERROR'),
            type: 'error',
          });
        });
    } else {
      await patchTestGroup(dataToPatch, !!replaceFlag)
        .then(() => {
          setPatchSuccess(true);
          notification.open({
            message: t('EDIT_TEST_GROUP_REQUEST_SUCCESS'),
            type: 'success',
          });
          history.push(CREATE_ABM_TESTGROUP_SCREENING);
          setSubmitLoading(false);
        })
        .catch(() => {
          setSubmitLoading(false);
          notification.open({
            message: t('EDIT_TEST_GROUP_REQUEST_ERROR'),
            type: 'error',
          });
        });
    }
  };

  const handleFilterChange = (optionsList, setState, selectedOption) => {
    const newState = optionsList.map((option) => ({
      ...option,
      selected: option.id === selectedOption.id,
    }));

    setState(newState);
  };

  const onBlurInputNumber = (e) => {
    if (e.target.value < 1 && testGroupQuantityQuestions) {
      notification.open({
        message: t('ADD_QUANTITY_QUESTIONS_IN_TEST_GROUP_ERROR'),
        type: 'warning',
      });
      setTestGroupQuantityQuestions(1);
    }
    if (e.target.value % 1 !== 0) {
      notification.open({
        message: t('ADD_QUANTITY_QUESTIONS_DECIMAL_ERROR'),
        type: 'warning',
      });
      setTestGroupQuantityQuestions(Math.round(e.target.value));
    }
  };

  useEffect(() => {
    if (challengeAlkemy && !technologies.length) {
      getAllTechnologies().then((response) => {
        const technologies = response.map((technology) => ({
          ...technology,
          name: technology.displayName,
          selected: false,
          value: technology.id,
        }));
        setTechnologies(technologies);
      });
    }
  }, [challengeAlkemy]);

  const handleAddExcelQuestions = (excelQuestions, isEdit) => {
    const existingCustomTestGroup = newScreening?.allTests?.find((item) => item.type === 'custom');
    let customTestGroup = newScreening?.customTestGroup ?? [];
    let allTests = newScreening?.allTests;

    const handleAdd = (excelQuestion) => {
      const orderedQuestions = formatOrder([
        ...customTestGroup,
        {
          ...excelQuestion,
          deleteId: v4(),
          id: `temp-${v4()}`,
          answerType: excelQuestion.answerType,
          action: TestEditionAction.CREATE,
          eachScore: excelQuestion.eachScore,
        },
      ]);
      customTestGroup = orderedQuestions;
      if (existingCustomTestGroup) {
        existingCustomTestGroup.tests = orderedQuestions;
      } else {
        allTests = formatOrder([
          ...newScreening.allTests,
          {
            name: t('CREATE_SCREENING_CUSTOM_TEST_GROUP_TITLE'),
            type: 'custom',
            duration: customTestGroupTotalTime,
            tests: orderedQuestions,
            id: v4(),
          },
        ]);
      }
    };

    excelQuestions.forEach((test) => {
      if (isEdit) {
        const testAlreadyExists = customTestGroup.find(
          (existingTest) => existingTest.id === test.id
        );
        if (testAlreadyExists) {
          const excelQuestion = { ...testAlreadyExists, ...test, options: test.options };
          const newOptions = customTestGroup.filter((item) => item?.id !== excelQuestion?.id);
          const customTestGroupData = formatOrder([
            ...newOptions,
            { ...excelQuestion, action: TestEditionAction.EDIT },
          ]);

          customTestGroup = customTestGroupData;
        } else {
          handleAdd(test);
        }
      } else {
        handleAdd(test);
      }
    });
    dispatch(
      setNewScreeningData({
        ...newScreening,
        customTestGroup,
        currentQuestionData: undefined,
        allTests,
      })
    );
  };

  const getScoreTags = (data) => {
    if (!data) return undefined;
    const { descriptions, isFullRange, ranges } = data;
    const tags = {};
    for (let i = 0; i < ranges.length; i++) {
      const [min, max] = ranges[i];
      const description = descriptions[i];
      tags[min] = description;
    }
    return tags;
  };

  const handleScoreTags = ({ descriptions, isFullRange, ranges }) => {
    setTestGroupScoreTags({ descriptions, isFullRange, ranges });
  };

  const handleSelectTestFormats = (ids) => {
    setTestGroupData({
      ...testGroupData,
      testFormatIds: ids,
    });
  };

  const handleSetAssignment = (field, value) => {
    setStep((prevStep) => ({
      ...prevStep,
      [field]: value,
    }));
  };

  return {
    isEdit,
    isLoading,
    submitLoading,
    testGroupDifficult,
    testGroupQuestions,
    testgroupWithThemes,
    setTestGroupQuantityQuestions,
    testGroupListeningTest,
    setTestGroupListeningTest,
    testGroupQuantityQuestions,
    challengeAlkemy,
    setTechnologies,
    technologies,
    testGroupData,
    handleModifyData,
    handleModifyLangData,
    handleCreateQuestionsSet,
    handleSelectDifficulty,
    handleSelectCategoryOption,
    handleSelectCategoryOptionMultiple,
    handleSelectLevelOption,
    handleSelectMinimumTierOption,
    handleSelectLanguageOption,
    handleSelectObjetivesOption,
    handleDeleteSetTestGroup,
    handleOrderSetQuestions,
    handlePostData,
    disabledCreateSet,
    handleAudioDifficulty,
    disabled,
    creationSuccess,
    patchSuccess,
    setPatchSuccess,
    setChallengeAlkemy,
    setTestgroupWithThemes,
    handleFilterChange,
    onBlurInputNumber,
    handlePatchData,
    fetchRoles,
    fetchTags,
    fetchEndorsementCreators,
    fetchEndorsementCompanies,
    testGroupRolesFilterOptions,
    testGroupTagsFilterOptions,
    endorsementCreatorsOptions,
    endorsementCompaniesOptions,
    handleSelectRolesOptions,
    handleSelectTagsOptions,
    handleSelectCompanyUsageOptions,
    handleSelectEndorsementOptions,
    handleSelectRolesOptionsMultiple,
    handleSelectTheme,
    formatDifficultyConfig,
    testGroupTheme,
    availableThemes: [...new Set(availableThemes)],
    techChallengeAlkemy,
    testDifficulty,
    testQuantity,
    testQuantityToMap,
    handleAddExcelQuestions,
    testGroupInitialData,
    customTestgroupInitialData,
    testgroupInitialDuration,
    handleHideTitle,
    handleIsAutomaticContinue,
    handleWithoutTime,
    handleScoreTags,
    groupSliderInitialData,
    toggleScoreTags,
    setToggleScoreTags,
    testFormats,
    handleSelectTestFormats,
    step,
    handleSetAssignment,
    testGroupScoreTags,
  };
};

export default useCreateTestGroup;
