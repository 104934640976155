import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import useImportCsv from '../../hooks/useImportCsv';
import { inHtmlTag } from '../../utils/inHtmlTag';
import { t } from '../../i18n/i18n';
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
import ModalCustom from '../ModalCustom/ModalCustom';
import upload from '../../assets/sentScreening/upload.svg';
import './ScreeningImport.scss';
import { TrackEvent } from '../../utils/filters/segmentUtils';

const ScreeningImport = ({ className, addRows, maxInvitationsBatch }) => {
  const { sendEmailFrom } = useSelector((state) => state.screenings);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [valueFile, setValueFile] = useState('');
  const { importRef, readFile, result } = useImportCsv(
    ['firstName', 'lastName', 'email', 'limitDate'],
    setLoading
  );

  useEffect(() => {
    if (result) {
      if (result.length > Number(process.env.RAZZLE_RUNTIME_MAX_SEARCH_EMAILS_BATCH_SIZE)) {
        notification.open({
          message: t('IMPORT_EXCEL_MAX_CANDIDATES_EXCEEDED'),
          type: 'error',
        });
        setLoading(false);
        return;
      }
      if (result?.length <= maxInvitationsBatch) {
        addRows(result);
      } else {
        notification.open({
          message: (
            <div
              dangerouslySetInnerHTML={{
                __html: t('IMPORT_EXCEL_NOT_ENOUGH_INVITATIONS_LEFT').replace(
                  '{invitationsLeft}',
                  inHtmlTag({
                    tag: 'b',
                    className: '',
                    content: maxInvitationsBatch,
                  })
                ),
              }}
            />
          ),
          type: 'error',
        });
      }
    }
    setLoading(false);
  }, [result]);

  return (
    <div className={className}>
      <ModalCustom
        title={t('CREATE_SEARCH_ MODAL_SELECT_IMPORT')}
        onOkDisabled={!valueFile}
        show={showModal}
        setShow={setShowModal}
        onOkText={t('CREATE_SEARCH_MODAL_BUTTON_ADD')}
        onOk={() => {
          readFile();
          setShowModal(false);
          setValueFile('');
        }}
        classCross="text-base text-blue-principal"
        input={
          <div className="outline-none custom-file-button ml-2 mt-3 py-2 px-4 font-weight-600 text-xs mx-auto w-75">
            <input
              type="file"
              name="file"
              id="fileEmails"
              hidden
              ref={importRef}
              onChange={(e) => setValueFile(e.target.value.slice(12))}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            {valueFile && (
              <p className="my-0 float-left font-weight-600 text-xs">
                {`${valueFile.slice(0, 30)}...`}
              </p>
            )}
            <label htmlFor="fileEmails" className="float-right">
              <img src={upload} alt={t('CREATE_SEARCH_UPLOAD_ICON')} />
            </label>
          </div>
        }
      />
      {loading && <HorizontalLoader />}
      <h5 className="text-base  font-weight-600 mt-2">{t('SCREENING_IMPORT_TITLE')}</h5>
      <p
        className=" font-weight-500 text-xs mt-2 gray-subtitle"
        dangerouslySetInnerHTML={{
          __html: t('SCREENING_IMPORT_TEXT')
            .replace(
              '{firstBold}',
              inHtmlTag({
                tag: 'b',
                content: t('SCREENING_IMPORT_TEXT_FIRST_BOLD'),
                className: '',
              })
            )
            .replace(
              '{secondBold}',
              inHtmlTag({
                tag: 'b',
                content: t('SCREENING_IMPORT_TEXT_SECOND_BOLD'),
                className: '',
              })
            )
            .replace(
              '{thirdBold}',
              inHtmlTag({
                tag: 'b',
                content: t('SCREENING_IMPORT_TEXT_THIRD_BOLD'),
                className: '',
              })
            )
            .replace(
              '{fourthBold}',
              inHtmlTag({
                tag: 'b',
                content: t('SCREENING_IMPORT_TEXT_FOURTH_BOLD'),
                className: '',
              })
            ),
        }}
      />
      <button
        className="outline-none border-0 bg-transparent"
        type="button"
        data-testid="button-import-excel"
        onClick={() => {
          TrackEvent(`search-import-users-list-${sendEmailFrom}`);
          setShowModal(true);
        }}
      >
        <span className="text-blue-principal text-xs  font-weight-600">
          {t('SCREENING_IMPORT_BUTTON')}
        </span>
      </button>
    </div>
  );
};

export default ScreeningImport;
